// お顔写真のトリミングコンポーネント

<template lang="pug">
.okao-trimmer
  .wrapper(
    ref='wrapper',
    @mousedown.prevent='moveStart',
    @touchstart.prevent='moveStart'
  )
    .image(
      :style='[imageStyle, imageTransform]',
    )
    .target-mark
    .note
      p 赤枠にお顔を合わせてください
</template>

<script>
export default {
  name: 'OkaoTrimmer',

  data() {
    return {
      image: null,
      imageScale: 0,
      imageUrl: null,
      imageWidth: 0,
      imageHeight: 0,
      imageOffsetX: 0,
      imageOffsetY: 0,
      translateX: 0,
      translateY: 0,
      prevPageXY: null,
    }
  },

  props: {
    // ファイルオブジェクト
    file: {},
    // 画像の回転 。 0, 90, 180, 270 を想定
    rotate: {
      type: Number,
      default: 0,
    },
    scale: {
      type: Number,
      default: 1.0,
    },
  },

  computed: {
    imageStyle() {
      return {
        backgroundImage: this.imageUrl ? `url(${this.imageUrl})` : null,
        width: this.imageWidth + 'px',
        height: this.imageHeight + 'px',
        marginLeft: -this.imageOffsetX + 'px',
        marginTop: -this.imageOffsetY + 'px',
      }
    },
    imageTransform() {
      const transforms = [
        `translateX(${this.translateX}px)`,
        `translateY(${this.translateY}px)`,
        `rotate(${this.rotate}deg)`,
        `scale(${this.scale})`,
      ]
      return {
        transform: transforms.join(' '),
      }
    },
  },

  mounted() {
    this.updateImage()
  },

  watch: {
    file() {
      this.updateImage()
    },
  },

  methods: {
    // 画像の更新
    updateImage() {
      this.image = null
      const reader = new FileReader()
      const image = new Image()
      reader.onload = () => {
        image.src = reader.result
      }
      image.onload = () => {
        this._updateSize(image.width, image.height)
        this.imageUrl = image.src
        this.image = image
      }
      reader.readAsDataURL(this.file)
    },

    _updateSize(imageWidth, imageHeight) {
      const domWidth = this.$refs.wrapper.clientWidth
      const domHeight = this.$refs.wrapper.clientHeight
      const scale = Math.max(domWidth / imageWidth, domHeight / imageHeight)
      this.imageWidth = imageWidth * scale
      this.imageHeight = imageHeight * scale
      this.imageOffsetX = (this.imageWidth - domWidth) / 2
      this.imageOffsetY = (this.imageHeight - domHeight) / 2
      // 画像生成時に必要になるので保持
      this.imageScale = scale
    },

    // 画像の移動の開始
    moveStart(e) {
      this.prevPageXY = this._getPageXY(e)
      this._setupEvents(e.type, this.move)
    },

    // ドラッグイベントのセットアップ
    _setupEvents(type, dragHandler) {
      let events
      if (type === 'mousedown') {
        events = { move: 'mousemove', up: 'mouseup' }
      } else {
        events = { move: 'touchmove', up: 'touchend' }
      }
      const throttledDrag = _.throttle(dragHandler, 100, { trailing: false })
      const removeEvents = () => {
        window.removeEventListener(events.up, removeEvents, { passive: false })
        window.removeEventListener(events.move, throttledDrag, { passive: false })
      }
      window.addEventListener(events.up, removeEvents, { passive: false })
      // 本当は { passive: true } にしたいが、Android標準ブラウザにて、
      // preventDefault()しないと、touchEndが来ないことがあるので。
      window.addEventListener(events.move, throttledDrag, { passive: false })
    },

    move(e) {
      const pageXY = this._getPageXY(e)
      const dx = pageXY.x - this.prevPageXY.x
      const dy = pageXY.y - this.prevPageXY.y
      this.prevPageXY = pageXY
      this.translateX += dx
      this.translateY += dy
    },

    _getPageXY(e) {
      const x = e.pageX || _.get(e, 'changedTouches[0].pageX') || 0
      const y = e.pageY || _.get(e, 'changedTouches[0].pageY') || 0
      return { x, y }
    },

    getImageAsync() {
      return new Promise(resolve => {
        const zoomOffsetX = (this.imageWidth * (this.scale - 1.0)) / 2
        const zoomOffsetY = (this.imageHeight * (this.scale - 1.0)) / 2
        const x = this.imageOffsetX + zoomOffsetX - this.translateX
        const y = this.imageOffsetY + zoomOffsetY - this.translateY
        const domWidth = this.$refs.wrapper.clientWidth
        const domHeight = this.$refs.wrapper.clientHeight
        const scaledX = x / this.imageScale / this.scale
        const scaledY = y / this.imageScale / this.scale
        const scaledWidth = domWidth / this.imageScale / this.scale
        const scaledHeight = domHeight / this.imageScale / this.scale
        const dstWidth = domWidth * 2
        const dstHeight = domHeight * 2

        const canvas = document.createElement('canvas')
        canvas.width = dstWidth
        canvas.height = dstHeight
        const ctx = canvas.getContext('2d')

        ctx.rotate((this.rotate * Math.PI) / 180)
        console.log(scaledX, scaledY, scaledWidth, scaledHeight, 0, 0, dstWidth, dstHeight)

        if (this.rotate === 0) {
          ctx.drawImage(
            this.image,
            scaledX,
            scaledY,
            scaledWidth,
            scaledHeight,
            0,
            0,
            dstWidth,
            dstHeight
          )
        } else if (this.rotate === 90) {
          const sub = (this.image.width - this.image.height) / 2
          const _x = scaledY + sub
          const _y = this.image.width - scaledWidth - scaledX - sub
          ctx.drawImage(
            this.image,
            _x,
            _y,
            scaledHeight,
            scaledWidth,
            0,
            -dstWidth,
            dstHeight,
            dstWidth
          )
        } else if (this.rotate === 180) {
          const _x = this.image.width - scaledX - scaledWidth
          const _y = this.image.height - scaledY - scaledHeight
          ctx.drawImage(
            this.image,
            _x,
            _y,
            scaledWidth,
            scaledHeight,
            -dstWidth,
            -dstHeight,
            dstWidth,
            dstHeight
          )
        } else if (this.rotate === 270) {
          const sub = (this.image.width - this.image.height) / 2
          const _x = this.image.height - scaledHeight - scaledY + sub
          const _y = scaledX - sub
          ctx.drawImage(
            this.image,
            _x,
            _y,
            scaledHeight,
            scaledWidth,
            -dstHeight,
            0,
            dstHeight,
            dstWidth
          )
        }

        canvas.toBlob(blob => {
          resolve(blob)
        }, 'image/jpeg')
      })
    },

    // 位置のリセット
    resetPosition() {
      this.translateX = 0
      this.translateY = 0
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../../sass/variables.sass'
@import '../../sass/mixins.sass'

.okao-trimmer
  .wrapper
    +responsive-block(300, 400)
    background-color: $white
    overflow: hidden
    cursor: move
    > .image
      background-size: cover
      background-repeat: no-repeat
    > .target-mark
      width: auto
      height: auto
      top: 10px
      left: 10px
      bottom: 38px
      right: 10px
      border-radius: 50%
      border: 5px solid rgba(red, 0.5)
    > .note
      width: 100%
      height: auto
      top: auto
      left: 0
      bottom: 10px
      text-align: center
      p
        display: inline-block
        font-size: $size-smallest
        background-color: rgba(red, 0.6)
        color: $white
        padding: 3px
        border-radius: 6px
</style>
