// 閲覧中の販売とその販売に紐づく販売(過去販売)の
// 価格設定とサイズ設置情報

import rangesUtil from '../../../helpers/ranges-util'

// ステート
const state = {
  sizePricesMap: null,
}

// ゲッタ
const getters = {
  // 「イベントId」(saleIdではない)と写真indexから価格設定を取得
  getSizePricesByEventIdNotSubSaleIdAndIndex: state => (eventId, index) => {
    const eventSizePrices = _.get(state.sizePricesMap, eventId, [])
    return _.filter(eventSizePrices, item => rangesUtil.isContain(item.ranges, index)).map(
      item => item.info
    )
  },
  // 「イベントId」(saleIdではない)からサイズ表を取得する。ただしデータ販売は除く
  getSizeTableByEventIdNotSubSaleId: state => eventId => {
    const eventSizePrices = _.get(state.sizePricesMap, eventId, [])
    // 同じサイズで複数のランク(ゴールド、プレミアム)が存在することがあるのでユニークに
    let uniqued = _.uniqBy(eventSizePrices, item => item.info.sizeCode)
    // さらにデータ販売は取り除く
    uniqued = _.filter(uniqued, value => !value.info.dataSale)
    // ソートして必要な情報を返す
    return _.sortBy(uniqued, item => item.info.printLongEdge).map(item => {
      return {
        code: item.info.sizeCode,
        name: item.info.sizeName,
        long: item.info.printLongEdge,
        short: item.info.printShortEdge,
      }
    })
  },
  // 「イベントId」(saleIdではない)から価格表を取得する
  getPriceTableByEventIdNotSubSaleId: state => eventId => {
    const eventSizePrices = _.get(state.sizePricesMap, eventId, [])
    // 各サイズ・価格が存在するかを調べるため
    const flags = {}
    const map = {}
    eventSizePrices.forEach(item => {
      const info = item.info
      const category = info.group ? 'group' : 'snap'

      // 年間販売では、複数の販売が紐付けられるので、
      // 同一条件・同一サイズに複数の価格が設定される可能性がある
      let prices = _.get(map, `${info.sizeCode}.${category}.${info.rank}`)
      if (!prices) {
        prices = []
        _.set(map, `${info.sizeCode}.${category}.${info.rank}`, prices)
      }
      if (prices.indexOf(info.price) === -1) prices.push(info.price)

      // サイズ名
      _.set(map, `${info.sizeCode}.name`, info.sizeName)
      // 長辺短辺
      _.set(map, `${info.sizeCode}.long`, info.printLongEdge)
      _.set(map, `${info.sizeCode}.short`, info.printShortEdge)
      // この区分(group or snap)・ランクが使用されていることをフラグ立て
      flags[`${category}-${info.rank}`] = true
    })
    // 長辺の昇順に
    return {
      items: _.sortBy(_.values(map), item => item.long),
      hasGroupPremium: !!flags['group-premium'],
      hasGroupGold: !!flags['group-gold'],
      hasGroupNormal: !!flags['group-normal'],
      hasSnapPremium: !!flags['snap-premium'],
      hasSnapGold: !!flags['snap-gold'],
      hasSnapNormal: !!flags['snap-normal'],
    }
  },
}

// ミューテーション
const mutations = {
  setSizePricesMap(state, payload) {
    state.sizePricesMap = payload
  },
}

export default { state, getters, mutations }
