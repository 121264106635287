// 利用可能な支払い方法の取得

import apiFactory from '../api-factory'

export default apiFactory({
  path: '/sales/<%= id %>/payments',
  method: 'GET',
  expectedParams: ['id'],
  needAuth: true,
  responseMapper: {
    payments: {
      key: 'items',
      transform: values => values.map(value => value.code),
    },
  },
})
