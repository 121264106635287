import apiFactory from '../api-factory'

export default apiFactory({
  path:
    '/sales/<%= id %>/<%= subId %>/photos?index_from=<%= from %>&num=<%= num %>&dir=<%= direction %>',
  method: 'GET',
  expectedParams: ['id', 'subId', 'from', 'num', 'direction'],
  needAuth: true,
  responseMapper: {
    thumbnail_token: 'token',
    'more.index': {
      key: 'nextIndex',
      default: 999999,
    },
    'less.index': {
      key: 'prevIndex',
      // functionにしないとデフォルト0のプロパティは
      // 削除されてしまう？
      default: () => {
        return 0
      },
    },
    'photos[].id': 'items[].id',
    'photos[].data_width': 'items[].dataWidth',
    'photos[].data_height': 'items[].dataHeight',
    'photos[].index': 'items[].index',
    'photos[].event_id': 'items[].eventIdNotSaleId',
    // 先頭5文字だけ使用
    'photos[].data_checksum': {
      key: 'items[].check',
      transform: values => values.map(value => value.slice(0, 5)),
    },
    // 各サイズのサムネイルのURL
    'photos[].thumbnail_url': 'items[].thumbnailUrls',
    // ルーペ画像取得に使うトークン
    'photos[].thumbnail_token': 'items[].lupeToken',
    // 期間外かどうか
    'photos[].is_overdue': 'items[].isOverdue',
  },
})
