<template lang="pug">
.term

  .outer

    ps-medium-heading 写真販売サイト「フォトいいね」利用規約

    ul.contents
      li(v-for='item, i in contents', :key='i')
        h3 {{ item.title }}
        template(v-for='desc in item.descriptions')
          p(v-if='desc.type === "text"') {{ desc.value }}
          ul(v-else-if='desc.type === "list"')
            li(v-for='listItem, k in desc.value', :key='k') {{ listItem }}

  .buttons
    ps-button(@click='$router.back()') 戻る
</template>

<script>
import term from '../../../../helpers/term'

export default {
  name: 'PublicPageTerm',

  computed: {
    contents() {
      return term
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../../../../sass/variables.sass'
@import '../../../../sass/mixins.sass'

.term
  margin-bottom: 2rem

  .outer

    background-color: $white
    padding: 1.5rem 1rem 4rem
    border-radius: $radius-small
    box-shadow: 0 1px 6px 2px rgba(0, 0, 0, .25)
    +mobile
      padding: 1rem 0.5rem
      box-shadow: 0 1px 1px 1px rgba(0, 0, 0, .3)

    .contents
      > li
        margin-top: 2rem
        h3
          font-size: 1.1rem
          border-bottom: 1px dashed #ccc
          padding: 0.2rem 0
          letter-spacing: 0.05rem
        p
          margin-top: 0.5rem
          line-height: 1.3
        ul
          margin-top: 0.5rem
          li
            list-style-type: disc
            list-style-position: inside
            margin-left: 0.5rem
            margin-top: 0.5rem
            &:first-child
              margin-top: 0

  .buttons
    text-align: center
    margin: 3rem 0
</style>
