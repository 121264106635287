// css
// リセットCSS
import 'ress/dist/ress.min.css'

// import style (>= Swiper 6.x)
import 'swiper/swiper-bundle.css'

// photospotのUIのスタイル
// font-awesomeより後に読み込まないと、
// アイコン周りのスタイルが、font-awesomeのスタイルに上書きてしまう
import 'photospot-ui/lib/theme-netps.css'

// Promiseのポリフィル
import 'es6-promise/auto'
// toBlobのポリフィル
import 'blueimp-canvas-to-blob'

// グローバルなオブジェクトの追加
import lodash from 'lodash'
window._ = lodash
import storage from 'store'
window.storage = storage
// クレジットカード決済用
window.payjp = Payjp(process.env.VUE_APP_PAYJP_PUBLIC_KEY)

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import PhotoSpotUi from 'photospot-ui'
import Components from './components/plugin'
import PsDialog from './dialogs/main/plugin'
import BodyScroll from './helpers/body-scroll-plugin'
import MyIdDirective from './helpers/my-id-and-class-directive-plugin.js'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import Raven from 'raven-js'
import RavenVue from 'raven-js/plugins/vue'
import VueAnalytics from 'vue-analytics'

Vue.config.productionTip = false

Vue.use(PhotoSpotUi)
// コンポーネントをプラグインとして登録
Vue.use(Components)
// ダイアログ表示プラグイン
Vue.use(PsDialog)
// スクロール関連のプラグイン
Vue.use(BodyScroll)
Vue.use(MyIdDirective)
Vue.use(VueAwesomeSwiper)

// for sentry
if (process.env.VUE_APP_SENTRY_DSN) {
  Raven.config(process.env.VUE_APP_SENTRY_DSN, { release: process.env.VUE_APP_VERSION })
    .addPlugin(RavenVue, Vue)
    .install()
}

// Google AnalyticsのトラッキングIDがあれば、Analyticsを利用
if (process.env.VUE_APP_GA_ID) {
  Vue.use(VueAnalytics, {
    id: process.env.VUE_APP_GA_ID,
    router,
    autoTracking: {
      pageviewTemplate: function(route) {
        return {
          page: route.path,
          title: document.title,
          location: window.location.href,
        }
      },
    },
  })
}

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
