// 2020年度からの新しいお子様情報(学年とクラス)のための
// ユーティリティ関数
//
// 想定しているattributesの構造
// [
//   {
//     "name": "年少",
//     "items": [ "たんぽぽ組", "れんげ組" ]
//   },
//   ...
// ]

// 特別なグループ(gradeの代わり)用のオフセット
const specialOffset = 101

export default {
  getGrades,
  getAllClasses,
  getClasses,
  convertGradeValueToName,
}

// attributesから学年の情報だけを取得する
function getGrades(attributes) {
  if (!attributes) return []
  const grades = attributes.grade.map((item, i) => {
    // この学年を表す整数値
    const value = item.index || i
    return {
      label: item.name,
      value,
    }
  })
  const specials = attributes.special.map((item, i) => {
    const value = item.index || i + specialOffset
    return {
      label: item.name,
      value,
    }
  })
  return [...grades, ...specials]
}

// すべてのクラスの取得
function getAllClasses(attributes) {
  const classes = _.flatten(
    attributes.grade.map((item, i) => {
      if (!item.items) return []
      // この学年を表す整数値
      const grade = item.index || i
      return item.items.map(cls => {
        return {
          label: cls,
          value: cls,
          grade,
        }
      })
    })
  )
  const specials = _.flatten(
    attributes.special.map((item, i) => {
      if (!item.items) return []
      const grade = item.index || i + specialOffset
      return item.items.map(cls => {
        return {
          label: cls,
          value: cls,
          grade,
        }
      })
    })
  )
  return [...classes, ...specials]
}

// attributesから条件にあったクラス情報だけ取得する
// なおgradeは数値とする
function getClasses(attributes, grade, inclusiveClasses = []) {
  if (!attributes) return []

  // すべてのクラスの情報
  const allClasses = getAllClasses(attributes)

  // gradeが数値でないならすべてのクラスを返す
  if (!_.isFinite(grade)) return _.uniq(_.map(allClasses, item => item.value)).sort()

  // 選択された学年のクラスに絞る
  // または選択中のクラスはこの学年になくても追加
  const classes = _.filter(allClasses, item => {
    return item.grade === grade || inclusiveClasses.indexOf(item.value) !== -1
  }).map(item => item.value)

  // 小学校以降の場合、組名は学年で被るので、重複したものを除去
  // また、辞書順にソート（選択時に順番が変わることがあるので
  return _.uniq(classes).sort()
}

// 学年を名前に変換
function convertGradeValueToName(attributes, value) {
  const grades = getGrades(attributes)
  const target = _.find(grades, item => item.value === value)
  return target ? target.label : null
}
