// 写真アイテムの情報を補完して返す

import getPhotoZoom from './get-photo-zoom'

export default function(item, token, thumbBase, lupeBase, printType, sizePrices, saleCustomizable) {
  // サムネイルのURL情報がすでにあればそれを利用
  const url = item.thumbnailUrls || `${thumbBase}${item.eventIdNotSaleId}/${item.id}/`
  const lupeUrl = `${lupeBase}${item.eventIdNotSaleId}/${item.id}/`
  // 写真の向き
  const orientation = item.dataWidth >= item.dataHeight ? 'landscape' : 'portrait'
  // 写真マスクのための拡大率
  const zoom = getPhotoZoom(printType, sizePrices[0].sizeCode)
  // 写真のランク。normal or gold or premium
  const rank = sizePrices[0].rank
  // 販売自体がカスタムを許可しているか、かつ、集合写真でないか
  const customizable = saleCustomizable && !sizePrices[0].group

  return _.assign({}, item, {
    type: 'photo',
    token,
    url,
    lupeUrl,
    orientation,
    zoom,
    rank,
    customizable,
    sizePrices,
  })
}
