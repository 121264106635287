// お知らせコンテナクラス

<script>
import getInfoSummaryApiAsync from '@/api/public/get-info-summary'
import InfoDetail from '@/dialogs/contents/info-detail'

export default {
  name: 'PsInfoContainer',

  props: {
    // お知らせページ用のモード
    large: Boolean,
  },

  data() {
    return {
      items: [],
    }
  },

  async created() {
    const response = await getInfoSummaryApiAsync(null, null, true)
    if (response.ok) this.items = response.payload.items
  },

  methods: {
    goToInfoPage() {
      this.$router.push({ name: 'PublicPageInfo' })
    },
    clickItem(item) {
      this.$psdialog.open({
        title: 'お知らせ詳細',
        component: InfoDetail,
        props: { id: item.id },
        size: 'm',
        closeByClickBg: true,
        closeButton: true,
      })
    },
  },
}
</script>

<template lang="pug">
.ps-info-container
  ps-info(
    :items='items',
    :size='large ? "l" : "s"',
    :hide-link='large ? true : false',
    @goToList='goToInfoPage',
    @clickItem='clickItem'
  )
</template>

<style lang="sass" scoped></style>
