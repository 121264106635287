// 一定量スクロール後fixするナビゲーション用の領域

<template lang="pug">
  .fixed-nav(:style='{ minHeight: minHeight }')
    nav(:class='{ fixed: fixed }')
      slot
</template>

<script>
// ナビゲーションをfixするスクロール量
// ヘッダの幅は通常55pxだが、少し早めに
const fixedThreshold = 50

export default {
  name: 'FixedNav',

  props: {
    minHeight: {
      type: String,
      default: '60px',
    },
  },

  data() {
    return {
      throttledScroll: null,
      fixed: true,
    }
  },

  mounted() {
    this.throttledScroll = _.throttle(this.scroll, 100)
    window.addEventListener('scroll', this.throttledScroll, { passive: true })
  },

  methods: {
    scroll() {
      console.log(fixedThreshold)
      // if (window.pageYOffset > fixedThreshold) this.fixed = true
      // else this.fixed = false
    },
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.throttledScroll, { passive: true })
  },
}
</script>

<style lang="sass" scoped>
.fixed-nav
  > nav
    background-color: #6db7f6
    z-index: 100
    &.fixed
      position: fixed
      top: 0
      left: 0
      width: 100%
</style>
