// 注文内容確認ページ

<template lang="pug">
.outro-page-confirm

  ps-cart-info.cart-info-mobile(
    v-if='screenType === "mobile"', :num='cartNum', :sum='cartTotal',
    :details='cartDetails', :photosSummary='cartPhotosSummary'
  )

  ps-row(:gutter='20')

    // メイン部分
    ps-col.main

      .main-inner

        article.confirm

          ps-medium-heading ご注文者内容の確認

          // 注文者の情報
          ps-form-item.user(label='ご注文者')
            p
              | {{ userName }} 様
              br
              | ({{ userNameKana }} 様)

            // ネット決済の場合は住所等がある
            template(v-if='!isPouch')
              p
                | &#12306;{{ userZipcode }}
                br
                | {{ userAddress1 }}
                br
                | {{ userAddress2 }}
              p TEL: {{ userTel }}

          // お子様情報
          ps-form-item.child(v-if='!isPublic', label='お子様(参加者)情報')
            ul.new-child-list(v-if='role === "member" && useNewChildrenInfoSystem')
              li(v-for='child in selectedChildren')
                | {{ child.name1 }} {{ child.name2 }} 様
                | ({{ child.attribute }})
            p(v-else)
              | ご関係: {{ childRelation }}
              br
              | {{ childName }} 様
              br
              | ({{ childNameKana }} 様)
              br
              | {{ childAttribute }}

          // お届け先情報
          ps-form-item.destination(v-if='isIndividual', label='お届け先')
            template(v-if='destType === "same"')
              p ご注文者と同じ
            template(v-else)
              p
                | {{ destinationName }} 様
                br
                | ({{ destinationNameKana }} 様)
              p
                | &#12306;{{ destinationZipcode }}
                br
                | {{ destinationAddress1 }}
                br
                | {{ destinationAddress2 }}
              p TEL: {{ destinationTel }}

          // 支払い情報
          ps-form-item.payment(v-if='!isPouch', label='お支払い方法')
            p {{ paymentMethod }}

        article
        
          ps-medium-heading お届けについて

          p(v-if='!isIndividual || isPouch')
            | 園・学校 または 写真館 から写真をお渡しします。
          p(v-else-if='isPsServer')
            | お客様に直接お送りいたします。
          p(v-else)
            | ヤマト運輸のネコポス等にてお客様に直接お送りいたします。

        article.buttons

          ps-agreement.agreement(v-model='agreement')
            | 内容に間違いがないことを確認しました。
            p(slot='content')
              ps-button.commit-button(size='large', color='red', full-width,
                text-centered, :disabled='!agreement || lock', @click='commitOrderAsync', v-my-id='"commit"')
                ps-icon(icon='check')
                | &nbsp;注文を確定する
          p
            ps-button(@click='goToPrev')
              ps-icon(icon='chevron-circle-left')
              | &nbsp;内容修正


    // サイドのカゴの情報
    ps-col.side(v-if='screenType !== "mobile"', :span='0')
      ps-fixed-region(:threshold='20')
        .cart-info-outer
          ps-cart-info(
            :num='cartNum', :sum='cartTotal', :details='cartDetails',
            :photosSummary='cartPhotosSummary', transparent
          )
            ps-button(
              full-width,
              size='small',
              text-centered,
              @click='goToPrev'
            )
              ps-icon(icon='chevron-circle-left')
              | &nbsp;内容修正
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import getChildrenByOrgIdAndYearApiAsync from '@/api/user/get-children-by-org-id-and-year'

import * as errorCodes from '@/errors/codes'

export default {
  name: 'OutroPageConfirm',

  beforeRouteEnter(to, from, next) {
    // 通常は情報入力ページから遷移してくる
    // そうでない場合は入力ページへ
    const name = 'OutroPageRegister'
    if (from.name !== name) {
      next({ name, params: to.params })
    } else {
      next()
    }
  },

  data() {
    return {
      agreement: false,
      lock: false,
      // 2020年〜の新しいお子様情報管理用
      userChildren: [],
    }
  },

  computed: {
    ...mapGetters({
      role: 'app/role',
      cartNum: 'cart/num',
      cartTotal: 'cart/total',
      cartDetails: 'cart/details',
      cartPhotosSummary: 'cart/photosSummary',
      saleIsInTerm: 'sale/isInTerm',
      saleIsInExtra: 'sale/isInExtra',
      isPouch: 'sale/isPouch',
      isPublic: 'sale/isPublic',
      isIndividual: 'sale/isIndividual',
      isPsServer: 'sale/isPsServer',
      screenType: 'app/screenType',
      // = other なら、他のお届け先に送る
      destType: 'order/destType',
      // 販売に紐づく年度
      fiscalYear: 'sale/fiscalYear',
      // 販売に紐づく園・学校のID
      orgId: 'sale/orgId',
      // 新しいお子様情報管理を利用するか
      useNewChildrenInfoSystem: 'sale/useNewChildrenInfoSystem',
      // 注文情報
      // ユーザ
      userName: 'order/userName',
      userNameKana: 'order/userNameKana',
      userZipcode: 'order/userZipcode',
      userAddress1: 'order/userAddress1',
      userAddress2: 'order/userAddress2',
      userTel: 'order/userTel',
      // お子様
      childRelation: 'order/childRelation',
      childName: 'order/childName',
      childNameKana: 'order/childNameKana',
      childAttribute: 'order/childAttribute',
      selectedNewChildIds: 'order/selectedNewChildIds',
      // お届け先
      destinationName: 'order/destinationName',
      destinationNameKana: 'order/destinationNameKana',
      destinationZipcode: 'order/destinationZipcode',
      destinationAddress1: 'order/destinationAddress1',
      destinationAddress2: 'order/destinationAddress2',
      destinationTel: 'order/destinationTel',
      // 支払い方法
      paymentMethod: 'order/paymentMethod',
    }),
    saleId() {
      return this.$route.params.saleId
    },
    // 2020年〜の新しいお子様管理において、選択済みのお子様情報
    selectedChildren() {
      return _.filter(this.userChildren, child => {
        return this.selectedNewChildIds.indexOf(child.id) !== -1
      })
    },
  },

  async created() {
    try {
      // 最初にsaleの情報を取得することで販売期間ないかどうかをチェック
      await this.initSaleAsync({ id: this.saleId })
      // 販売期間または販売猶予期間かどうか
      if (!this.saleIsInTerm && !this.saleIsInExtra) {
        throw new Error(errorCodes.SALE_IS_OUT_OF_TERM)
      }
      // メンバーかつ新しいお子様情報管理を利用するか
      if (this.role === 'member' && this.useNewChildrenInfoSystem) {
        await this.getChildrenAsync()
      }
    } catch (e) {
      this.setError(e)
    }
  },

  mounted() {
    window.addEventListener('beforeunload', this.unload, false)
  },

  methods: {
    ...mapActions({
      initSaleAsync: 'sale/initAsync',
      commitAsync: 'order/commitAsync',
      clearCartAll: 'cart/clearAll',
      setError: 'app/setError',
    }),

    // お子様情報の取得
    // 2020年〜の新しいお子様情報管理利用時にのみ使う
    async getChildrenAsync() {
      try {
        const res = await getChildrenByOrgIdAndYearApiAsync({
          orgId: this.orgId,
          fiscalYear: this.fiscalYear,
        })
        if (!res.ok) throw new Error(errorCodes.GET_CHILDREN_ERROR)
        this.userChildren = res.payload.items
      } catch (e) {
        this.setError(e)
      }
    },

    // 注文確定
    async commitOrderAsync() {
      // lockは成功時にはfalseにはもどさない
      if (this.lock || !this.agreement) return
      this.lock = true

      let response
      try {
        response = await this.commitAsync({
          saleId: this.saleId,
          isPouch: this.isPouch,
          isPublic: this.isPublic,
          isIndividual: this.isIndividual,
          useNewChildrenInfoSystem: this.role === 'member' && this.useNewChildrenInfoSystem,
        })
      } catch (e) {
        // 復帰可能なエラーならロックを解除
        // それ以外はORDER_ERRORを返す
        if (
          e.message === errorCodes.ORDER_RETRYABLE_CREDIT_ERROR ||
          e.message === errorCodes.ORDER_RETRYABLE_ERROR ||
          e.message === errorCodes.ORDER_RETRYABLE_CREDIT_EXPIRY
        ) {
          // カード決済失敗時のリトライようにlockを解除
          this.lock = false
          this.setError(e)
        } else {
          this.setError(new Error(errorCodes.ORDER_ERROR))
        }
        return
      }

      // ストアのカート情報をクリア
      this.clearCartAll()

      // 成功
      this.$router.push({
        name: 'OutroPageCompleted',
        params: {
          saleId: this.saleId,
          code: response.orderCode,
        },
      })
    },

    goToPrev() {
      this.$router.push({ name: 'OutroPageRegister', params: { saleId: this.saleId } })
    },

    // アンロード時のイベントハンドラ
    unload(e) {
      e.returnValue =
        '再読み込み、または別のページへの移動を行うと、入力内容がクリアされますがよろしいですか？'
    },
  },

  beforeDestroy() {
    window.removeEventListener('beforeunload', this.unload, false)
  },
}
</script>

<style lang="sass" scoped>
@import '../../../../sass/variables.sass'
@import '../../../../sass/mixins.sass'

.outro-page-confirm

  .cart-info-mobile
    margin-bottom: 1.5rem

  .main

    .main-inner
      background-color: $white
      padding: 1.5rem 1rem
      border-radius: $radius-small
      box-shadow: 0 1px 6px 2px rgba(0, 0, 0, .25)
      +mobile
        padding: 1rem 0.5rem
        box-shadow: 0 1px 1px 1px rgba(0, 0, 0, .3)

      > article
        margin-bottom: 3rem
        &:last-child
          margin-bottom: 2rem

        &.confirm
          p
            line-height: 1.4
            margin-bottom: 0.5rem
            &:last-child
              margin-bottom: 0

          .new-child-list
            li
              list-style-type: disc
              list-style-position: inside

        &.buttons
          > .agreement
            margin-bottom: 1.5rem
            p
              padding: 0.5rem 1rem 1.5rem
              max-width: 400px
              margin: 0 auto
          > p
            text-align: center
          > .wrapper
            margin: 0 auto
            text-align: center
            max-width: 400px
            .commit-button
              margin-bottom: 1rem


  .side
    width: 260px
    .cart-info-outer
      background-color: #e4f7b7
</style>
