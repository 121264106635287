// 閲覧画面用拡大画面

<script>
import ZoomBase from '../zoom-base'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'BrowseZoom',

  components: {
    ZoomBase,
  },

  props: {
    // 読み込む写真番号
    photoIndex: {
      type: Number,
      required: true,
    },
    // 先読みする枚数
    prefetchNum: {
      type: Number,
      default: 2,
    },
  },

  data() {
    return {
      // 写真の情報を入れておくバッファ
      buffer: [],
      // バッファのどの位置を参照中か
      pos: 0,
      // 前方・後方の読み込み中ロック
      lockForward: false,
      lockBackward: false,
      // 次に読み込む写真のインデックス
      forwardFromIndex: this.photoIndex,
      backwardFromIndex: null,
      // 最初と最後の写真のインデックス番号
      // 通常の販売であれば、minPhotoIndexとmaxPhotoIndexと同値だが、
      // 年間販売の場合は異なる
      firstPhotoIndex: null,
      lastPhotoIndex: null,
    }
  },

  async created() {
    await this.loadForwardAsync()
  },

  computed: {
    ...mapGetters({
      // タイトル挿入するかどうか
      insertSectionTitle: 'sale/insertSectionTitle',
      minPhotoIndex: 'subSale/minPhotoIndex',
      maxPhotoIndex: 'subSale/maxPhotoIndex',
    }),

    current() {
      return this.buffer[this.pos]
    },

    // 次・前が存在するか
    hasNext() {
      if (!this.current) return false
      // タイトルなら次があるはずなので無条件でtrue
      if (this.current.type === 'title') return true
      return this.lastPhotoIndex !== this.current.index
    },
    hasPrev() {
      if (!this.current) return false
      return this.firstPhotoIndex !== this.current.index
    },

    // 残りの枚数
    forwardLeftNum() {
      return this.buffer.length - this.pos - 1
    },
    // 後ろの残りの枚数
    backwardLeftNum() {
      return this.pos < 0 ? 0 : this.pos
    },
  },

  methods: {
    ...mapActions({
      getPhotosAsync: 'subSale/getPhotosAsync',
      setError: 'app/setError',
    }),

    // 前方の写真情報の読み込み
    async loadForwardAsync() {
      // 2重にロードされないようにロック
      if (this.lockForward) return
      this.lockForward = true

      try {
        const params = {
          from: this.forwardFromIndex,
          // 10個分読み込む
          num: 10,
          // ローディングスピナーは表示しない
          silent: true,
          // タイトル挿入するか
          withoutTitle: !this.insertSectionTitle,
        }
        const payload = await this.getPhotosAsync(params)
        if (payload.items.length === 0) {
          this.lockForward = false
          return
        }

        this.buffer = _.concat(this.buffer, payload.items)

        // 次回の読み込み開始番号
        this.forwardFromIndex = payload.nextIndex
        // 初回だけ前の開始番号を記録
        // また、タイトルや年間販売の販売境界の場合スキップ
        if (_.isNull(this.backwardFromIndex)) {
          this.backwardFromIndex = payload.prevIndex
          if (this.current.type === 'sale') this.goNext()
          if (this.current.type === 'title') this.goNext()
        }

        // 販売全体の中での先頭と末尾を取得可能なら取得する
        this.updateFirstAndLastPhotoIndex()
      } catch (e) {
        // 読み込み完了前にダイアログが閉じられると、
        // 処理の中で参照している変数がなくなり、TypeErrorが呼ばれる
        // この場合、何もせず終了する。
        if (e instanceof TypeError) return
        this.setError(e)
        return
      } finally {
        this.lockForward = false
      }
    },

    // 後方の写真情報の読み込み
    async loadBackwardAsync() {
      // 2重にロードされないようにロック
      if (this.lockBackward) return
      this.lockBackward = true

      try {
        const params = {
          from: this.backwardFromIndex,
          num: 10,
          reverse: true,
          silent: true,
          withoutTitle: !this.insertSectionTitle,
        }
        const payload = await this.getPhotosAsync(params)
        if (payload.items.length === 0) {
          this.lockBackward = false
          return
        }
        this.buffer = _.concat(payload.items, this.buffer)
        this.pos += payload.items.length

        // 次回の読み込み開始番号
        this.backwardFromIndex = payload.prevIndex

        // 販売全体の中での先頭と末尾を取得可能なら取得する
        this.updateFirstAndLastPhotoIndex()
      } catch (e) {
        // 読み込み完了前にダイアログが閉じられると、
        // 処理の中で参照している変数がなくなり、TypeErrorが呼ばれる
        // この場合、何もせず終了する。
        if (e instanceof TypeError) return
        this.setError(e)
        return
      } finally {
        this.lockBackward = false
      }
    },

    // 先読み(前方のみ)
    prefetch() {
      // 先読み可能な個数
      const num = Math.min(this.forwardLeftNum, this.prefetchNum)
      for (let i = 1; i <= num; ++i) {
        const item = this.buffer[this.pos + i]
        if (item.type !== 'photo') continue
        let image = new Image()
        image.src = `${item.url}l.jpg?token=${item.token}&${item.check}`
        if (_.isString(item.url)) {
          image.src = `${item.url}l.jpg?token=${item.token}&${item.check}`
        } else {
          image.src = item.url['l']
        }
      }
    },

    // 最初と最後のphotoIndexが取得できれば取得する
    updateFirstAndLastPhotoIndex() {
      // これ以上後ろがないところまで取得したか
      if (this.forwardFromIndex > this.maxPhotoIndex) {
        this.lastPhotoIndex = _.last(this.buffer).index
      }
      // これ以上前がないところまで取得したか
      if (this.backwardFromIndex < this.minPhotoIndex) {
        this.firstPhotoIndex = _.first(this.buffer).index
      }
    },

    // 次の写真へ移動
    goNext() {
      if (!this.hasNext) return
      // 残りが少なくなったら読み込み
      if (this.forwardLeftNum < 5) this.loadForwardAsync()
      ++this.pos
      this.prefetch()
    },

    // 前の写真へ移動
    goPrev() {
      if (!this.hasPrev) return
      // 残りが少なくなったら読み込み
      if (this.backwardLeftNum < 5) this.loadBackwardAsync()
      --this.pos
    },

    clickBg() {
      // 子コンポーネントがトリミングモードかどうかをチェック
      if (!_.get(this.$refs.zoomBase, 'trimMode')) this.close()
    },

    close() {
      let params
      // まだ写真情報が読み込まれていない場合がある
      if (this.current) {
        params = {
          photoIndex: this.current.index,
          updated: this.current.index !== this.photoIndex,
        }
      }
      this.$psdialog.close(params)
    },
  },
}
</script>

<template lang="pug">
  .browse-zoom
    zoom-base(
      v-if='current',
      menu-type='normal',
      :item='current',
      :has-next='hasNext',
      :has-prev='hasPrev',
      @goNext='goNext',
      @goPrev='goPrev',
      @close='close',
      ref='zoomBase'
    )
</template>

<style lang="sass" scoped>
.browse-zoom
  width: 100%
  height: 100%
</style>
