// トリミングオプション // conditionsは // { // landscape: [ // [選択可能なsizePrice要素]... // ],
// portrait: [ // [選択可能なsizePrice要素]... // ] // } // という構造を想定

<template lang="pug">
  .trimming-options
    
    .orientation
      ps-row(valign='stretch', :gutter='5')
        ps-col.col-first
          p.caption 写真の向き
        ps-col(:span='2')
          ul
            li(v-for='ori in orientations')
              ps-radio(:radio-value='ori', v-model='selectedOrientation', full-width)
                | {{ ori === 'landscape' ? '横向き' : '縦向き' }}

    .size
      ps-row(valign='stretch', :gutter='5')
        ps-col.col-first
          p.caption プリントサイズ
        ps-col(:span='2')
          ul
            li(v-for='sp in sizePrices')
              ps-radio(:radio-value='sp.sizeCode', v-model='selectedSizeCode', full-width)
                | {{ sp.sizeName }} / {{ sp.price }}円{{ isTaxExcluded ? '(税別)' : '' }}

    .buttons
      ps-button(@click='close(false)') 戻る
      ps-button(color='green', large-width, @click='close(true)') 変更

</template>

<script>
export default {
  name: 'TrimmingOptions',

  props: {
    // 現在の向き
    orientation: null,
    // 現在のsizePrice
    sizePrice: null,
    // 選択可能な設定
    conditions: null,
    // 外税かどうか
    isTaxExcluded: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      selectedOrientation: this.orientation,
      selectedSizeCode: this.sizePrice.sizeCode,
    }
  },

  computed: {
    orientations() {
      return _.keys(this.conditions)
    },
    sizePrices() {
      return this.conditions[this.selectedOrientation]
    },
  },

  methods: {
    close(update = false) {
      this.$psdialog.close({
        update,
        orientation: this.selectedOrientation,
        sizePrice: _.find(this.sizePrices, sp => sp.sizeCode === this.selectedSizeCode),
      })
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../../../sass/variables.sass'

.trimming-options
  > *
    margin-bottom: 1rem
    &:last-child
      margin-bottom: 0

    .col-first
      background-color: lighten($lime, 25%)
      border-radius: $radius
      display: flex
      align-items: center
      justify-content: center
      p.caption
        text-align: center

    ul li
      margin-bottom: 0.3rem
      &:last-child
        margin-bottom: 0

    &.buttons
      text-align: center
      > :first-child
        margin-right: 1rem
</style>
