// 2020年度〜の新しいお子様情報

<template lang="pug">
.new-child-info

  .form

    ps-form-item(label='学年', required, :error='errors["grade_index"] || errors["grade"]')
      ps-select(
        :items='grades',
        v-model='form.grade',
        @input='form.attribute = null'
      )

    ps-form-item(label='クラス', required, :error='errors["attribute"]')
      ps-select(
        :items='classes',
        v-model='form.attribute'
      )

    ps-form-item.child-name(label='お子様のお名前', required, :error='errors["name1"] || errors["name2"]')
      .wrapper
        .left
          ps-input(
            placeholder='姓',
            v-model='form.name1'
            ime-mode='active',
            full-width
          )
        .right
          ps-input(
            placeholder='名',
            v-model='form.name2',
            ime-mode='active',
            full-width
          )

    ps-form-item.child-name-kana(label='お子様のふりがな', required, :error='errors["name1_kana"] || errors["name2_kana"]')
      .wrapper
        .left
          ps-input(
            placeholder='せい',
            v-model='form.name1Kana',
            ime-mode='active',
            full-width
          )
        .right
          ps-input(
            placeholder='めい',
            v-model='form.name2Kana',
            ime-mode='active',
            full-width
          )

    ps-form-item.relation(label='お子様とのご関係', required, :error='errors["relation"]')
      ps-select(
        :items='relations',
        v-model='form.relation'
      )

  .buttons
    ps-button(
      @click='$psdialog.close'
    ) 閉じる
    ps-button(
      v-if='targetId',
      @click='updateAsync',
      :disabled='!isValid',
      color='green'
    ) 更新する
    ps-button(
      v-else
      @click='addAsync',
      :disabled='!isValid',
      color='green'
    ) 追加する
</template>

<script>
import { mapActions } from 'vuex'

import attributesUtil from '@/helpers/new-attributes-util'
import parseSwaggerSchemaError from '@/helpers/parse-swagger-schema-error'

import addChildApiAsync from '@/api/user/add-child'
import updateChilApiAsync from '@/api/user/update-child'

import * as errorCodes from '@/errors/codes'

const relations = [
  'お母さん',
  'お父さん',
  'おばあちゃん',
  'おじいちゃん',
  'おば・おじ',
  '本人',
  '園・学校関係者',
  'その他',
]

export default {
  name: 'NewChildInfo',

  props: {
    attributes: {
      type: Object,
      required: true,
    },
    fiscalYear: {
      type: Number,
      required: true,
    },
    orgId: {
      type: Number,
      required: true,
    },
    // 更新時に使用するお子様のId
    targetId: {
      type: Number,
    },
    // 更新時に使用する現在の値
    targetValues: {
      type: Object,
    },
  },

  data() {
    return {
      form: {
        grade: _.get(this.targetValues, 'grade', null),
        attribute: _.get(this.targetValues, 'attribute', null),
        name1: _.get(this.targetValues, 'name1', null),
        name2: _.get(this.targetValues, 'name2', null),
        name1Kana: _.get(this.targetValues, 'name1Kana', null),
        name2Kana: _.get(this.targetValues, 'name2Kana', null),
        relation: _.get(this.targetValues, 'relation', null),
      },
      errors: {},
    }
  },

  computed: {
    grades() {
      return [
        { label: '選択してください...', value: null },
        ...attributesUtil.getGrades(this.attributes),
      ]
    },
    classes() {
      return [
        { label: '選択してください...', value: null },
        ...attributesUtil.getClasses(this.attributes, parseInt(this.form.grade, 10), [
          this.form.attribute,
        ]),
      ]
    },
    relations() {
      return [{ label: '選択してください', value: null }, ...relations]
    },
    // すべてnullでないか
    isValid() {
      return (
        _.every([
          this.form.attribute,
          this.form.name1,
          this.form.name2,
          this.form.name1Kana,
          this.form.name2Kana,
          this.form.relation,
        ]) &&
        (this.form.grade || _.isFinite(this.form.grade))
      )
    },
  },

  methods: {
    ...mapActions({
      setError: 'app/setError',
    }),

    // お子様情報の新規追加
    async addAsync() {
      try {
        const body = {
          ...this.form,
          grade: parseInt(this.form.grade, 10),
          fiscalYear: this.fiscalYear,
          orgId: this.orgId,
        }
        const res = await addChildApiAsync(null, body)
        if (!res.ok) this.onError(res)
        else this.$psdialog.close({ updated: true })
      } catch (e) {
        this.setError(e)
      }
    },

    // お子様情報の更新
    async updateAsync() {
      try {
        const params = {
          id: this.targetId,
        }
        const body = {
          ...this.form,
          grade: parseInt(this.form.grade, 10),
          fiscalYear: this.fiscalYear,
          orgId: this.orgId,
        }
        const res = await updateChilApiAsync(params, body)
        if (!res.ok) this.onError(res)
        else this.$psdialog.close({ updated: true })
      } catch (e) {
        this.setError(e)
      }
    },

    onError(response) {
      if (_.get(response, 'payload.code') === 'SCHEMA_VALIDATION_FAILED') {
        this.errors = _.mapValues(parseSwaggerSchemaError(response.payload), error => error.message)
        alert('入力に誤りがあります。')
      } else if (/^number/i.test(_.get(response, 'payload.message'))) {
        alert('登録可能なお子様(参加者)情報の数の上限に達しました。')
      } else {
        // 予期せぬエラー。通常はここに来ないはず。
        throw new Error(errorCodes.UNKNOWN_ERROR)
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.new-child-info

  .form
    .child-name, .child-name-kana
      .wrapper
        display: flex
        .left, .right
          flex: 1
        .left
          margin-right: 0.3rem
        .right
          margin-left: 0.3rem

  .buttons
    margin-top: 1.5rem
    text-align: center
    > :nth-child(n+2)
      margin-left: 0.6rem
</style>
