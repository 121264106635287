// カート

import getCartDetailApiAsync from '@/api/cart/get-detail'
import updateCartItemApiAsync from '@/api/cart/update-item'

import * as errorCodes from '@/errors/codes'

// アクション

import complementPhotoItem from '../../../helpers/complement-photo-item'

export default {
  // カート情報の初期化
  // sale/customThumbnailBaseUrl 等に依存しているので、
  // saleの初期化が完了したあとに呼ぶ必要がある
  async initAsync({ commit, state, rootGetters }, force = false) {
    // 販売のIdの取得
    const saleId = rootGetters['sale/id']
    // saleストアのinitAsyncを先に呼ぶ必要がある
    if (!saleId) throw new Error('you should init sale store module.')

    // すでに取得済みならなにもしない
    if (saleId === state.saleId && _.isNumber(state.num) && _.isNumber(state.sum) && !force) return
    // どの販売のかご情報かを更新
    commit('setSaleId', saleId)

    const params = { saleId }
    const response = await getCartDetailApiAsync(params)

    if (!response.ok) throw new Error(errorCodes.GET_CART_INFO_ERROR)

    _setEntire(commit, rootGetters, saleId, response.payload)
  },

  // 写真アイテムの追加
  async addPhotoAsync(
    { commit, state, rootGetters },
    { photoId, sizeId, num, photoCustomId, silent }
  ) {
    const params = { saleId: state.saleId }
    const body = { photoId, sizeId, num, photoCustomId }
    const response = await updateCartItemApiAsync(params, body, silent)
    if (!response.ok) throw new Error(errorCodes.UPDATE_CART_ERROR)

    _setEntire(commit, rootGetters, state.saleId, response.payload)
  },

  // 写真アイテムの削除。sizeIdの指定がない場合、すべてのサイズを削除
  async removePhotoAsync({ dispatch }, { photoId, sizeId, silent }) {
    if (!_.isNumber(sizeId)) sizeId = -1
    const num = 0
    await dispatch('addPhotoAsync', { photoId, sizeId, num, silent })
  },

  // オプション販売のアイテムの追加
  async addGoodsAsync({ commit, state, rootGetters }, { goodsId, num }) {
    const params = { saleId: state.saleId }
    const body = { goodsId, num }
    const response = await updateCartItemApiAsync(params, body)
    if (!response.ok) {
      // 406ならオプション販売の追加上限に達したとみなす
      if (response.status === 406) throw new Error(errorCodes.ADD_OPT_LIMIT_ERROR)
      else throw new Error(errorCodes.UPDATE_OPT_ERROR)
    }

    _setEntire(commit, rootGetters, state.saleId, response.payload)
  },

  // オプション販売のアイテムの追加
  async removeGoodsAsync({ dispatch }, goodsId) {
    await dispatch('addGoodsAsync', { goodsId, num: 0 })
  },

  // ストアの情報をクリア
  clearAll({ commit }) {
    console.log('store cart clear all.')
    commit('setSaleId', null)
    commit('setNum', null)
    commit('setSum', null)
    commit('setTotal', null)
    commit('setPhotoNum', null)
    commit('setPhotoSum', null)
    commit('setOptionNum', null)
    commit('setOptionSum', null)
    commit('setDetails', null)
    commit('setToken', null)
    commit('setPhotos', [])
    commit('setPhotosMap', {})
    commit('setCustomsMap', {})
    commit('setOptionsMap', {})
    commit('setPhotosSummary', [])
    commit('setOverdueNum', 0)
  },
}

function _setEntire(commit, rootGetters, saleId, payload) {
  const customsMap = _complementCustomsMap(rootGetters, saleId, payload.customsMap, payload.token)
  const photos = _complementPhotos(rootGetters, payload.photos, payload.token)
  commit('setNum', payload.num)
  commit('setSum', payload.sum)
  commit('setTotal', payload.total)
  commit('setPhotoNum', payload.photoNum)
  commit('setPhotoSum', payload.photoSum)
  commit('setOptionNum', payload.optionNum)
  commit('setOptionSum', payload.optionSum)
  commit('setDetails', payload.details)
  commit('setToken', payload.token)
  commit('setPhotos', photos)
  commit('setPhotosMap', payload.photosMap)
  commit('setCustomsMap', customsMap)
  commit('setOptionsMap', payload.optionsMap)
  commit('setPhotosSummary', payload.photosSummary)
  commit('setOverdueNum', payload.overdueNum)
}

// カスタムの情報にサムネイルやルーペのアドレスと
// カスタム写真取得用のトークンを追加
function _complementCustomsMap(rootGetters, saleId, customsMap, token) {
  const base = rootGetters['sale/customThumbnailBaseUrl']
  const lupe = rootGetters['sale/customLupeBaseUrl']
  console.log(base, customsMap)
  return _.mapValues(customsMap, item => {
    return _.assign(
      {
        token,
        url: `${base}${saleId}/${item.customId}/`,
        lupeUrl: `${lupe}${saleId}/${item.customId}/`,
        orientation: item.dataWidth >= item.dataHeight ? 'landscape' : 'portrait',
      },
      item
    )
  })
}

// 写真情報の補完
function _complementPhotos(rootGetters, items, token) {
  // 補完に必要な情報
  const thumbnailBaseUrl = rootGetters['sale/thumbnailBaseUrl']
  const lupeBaseUrl = rootGetters['sale/lupeBaseUrl']
  const printType = rootGetters['sale/printType']
  const saleCustomizable = rootGetters['sale/customizable']
  const getSizePricesByEventIdNotSubSaleIdAndIndex =
    rootGetters['sizePrice/getSizePricesByEventIdNotSubSaleIdAndIndex']

  // 補完
  let complemented = []
  items.forEach(item => {
    // 価格設定を取得
    const sizePrices = getSizePricesByEventIdNotSubSaleIdAndIndex(item.eventIdNotSaleId, item.index)
    // 価格設定がないということは通常ないはずだが、ない場合はスキップ
    if (sizePrices.length === 0) return

    complemented.push(
      complementPhotoItem(
        item,
        token,
        thumbnailBaseUrl,
        lupeBaseUrl,
        printType,
        sizePrices,
        saleCustomizable
      )
    )
  })

  return complemented
}
