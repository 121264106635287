// アクション

import complementPhotoItem from '../../../helpers/complement-photo-item'
import rangesUtil from '../../../helpers/ranges-util'

import getSubSaleDetailApiAsync from '@/api/sale/get-sub-detail'
import getSubSectionApiAsync from '@/api/sale/get-sub-section'
import getPhotosApiAsync from '@/api/sale/get-photos'

import * as errorCodes from '@/errors/codes'

export default {
  async initAsync({ commit, state, rootGetters }, { id, force }) {
    const saleId = rootGetters['sale/id']
    // saleストアのinitAsyncを先に呼ぶ必要がある
    if (!saleId) throw new Error('you should init sale store module.')

    // すでにすべての情報を取得済みなら何もしない
    if (
      state.id &&
      id === state.id &&
      state.basicInfo &&
      state.categoryTree &&
      state.categoryBoundMap &&
      !force
    )
      return true

    // sub sale id を保持
    commit('setId', id)

    const params = { id: saleId, subId: id }
    // 表示中の販売の情報の取得
    const p1 = getSubSaleDetailApiAsync(params)
    // カテゴリ構成の情報の取得
    const p2 = getSubSectionApiAsync(params)
    const [r1, r2] = await Promise.all([p1, p2])

    if (!r1.ok || !r2.ok) {
      throw new Error(errorCodes.GET_SUB_SALE_INFO_ERROR)
    }

    commit('setBasicInfo', r1.payload)
    commit('setCategoryTree', r2.payload)
    // カテゴリの境界を表すマップを事前に作成
    const map = _createCategoryBoundMap(r2.payload)
    commit('setCategoryBoundMap', map)
  },

  // 写真の一覧表示に必要な最低限の情報を返す
  async getPhotosAsync({ state, rootGetters }, { from, num, reverse, silent, withoutTitle }) {
    const saleId = rootGetters['sale/id']
    // directionはfなら通常、bは以前の写真
    let direction = 'f'
    if (reverse) {
      direction = 'b'
      // from 自体のindexは含まれないので、インクリメントしてく
      ++from
    }
    const params = { id: saleId, subId: state.id, from, num, direction }
    const response = await getPhotosApiAsync(params, null, silent)
    // 失敗した場合もフォローしない(基本的にsilentで使用するので)
    if (!response.ok) return null

    return {
      items: _complementPhotos(
        state,
        rootGetters,
        response.payload.token,
        response.payload.items,
        !withoutTitle
      ),
      nextIndex: response.payload.nextIndex,
      prevIndex: response.payload.prevIndex,
    }
  },

  clearAll({ commit }) {
    commit('setId', null)
    commit('setBasicInfo', null)
    commit('setCategoryTree', null)
    commit('setCategoryBoundMap', null)
  },
}

// カテゴリの境界マップの作成
function _createCategoryBoundMap(tree, map = null, titles = null) {
  // mapがないのはルート要素の場合
  if (!map) {
    map = {}
    titles = []
  } else {
    // このカテゴリ自身のindexの最小値 を持っているかの確認
    const minIndexLocal = rangesUtil.getMin(tree.localRanges)
    if (minIndexLocal) {
      map[minIndexLocal] = _.concat(titles, tree.name)
      // titles自体の参照は維持したまま配列をクリア
      titles.splice(0, titles.length)
    } else {
      // 最小値を持っていな = 写真を持っていない = タイトルを保持
      titles.push(tree.name)
    }
  }
  if (tree.items) {
    tree.items.forEach(item => _createCategoryBoundMap(item, map, titles))
  }
  return map
}

// APIから取得した写真情報にサイズ・価格情報等を付加して補完する
// また、カテゴリ境界用のタイトルを挿入する
function _complementPhotos(state, rootGetters, token, items, addTitle = true) {
  // 補完に必要な情報
  const thumbnailBaseUrl = rootGetters['sale/thumbnailBaseUrl']
  const lupeBaseUrl = rootGetters['sale/lupeBaseUrl']
  const printType = rootGetters['sale/printType']
  const saleCustomizable = rootGetters['sale/customizable']
  const overdueCharge = rootGetters['sale/overdueCharge']
  const getSizePricesByEventIdNotSubSaleIdAndIndex =
    rootGetters['sizePrice/getSizePricesByEventIdNotSubSaleIdAndIndex']
  // 年間販売かどうか
  const isLongrun = rootGetters['sale/isLongrun']
  const getSubSaleByPhotoStartIndex = rootGetters['sale/getSubSaleByPhotoStartIndex']

  // 補完
  let complemented = []
  items.forEach(item => {
    // 価格設定を取得
    const sizePrices = getSizePricesByEventIdNotSubSaleIdAndIndex(item.eventIdNotSaleId, item.index)
    // 価格設定がないということは通常ないはずだが、ない場合はスキップ
    if (sizePrices.length === 0) return

    // カテゴリ境界ならタイトル用のアイテムを前に挿入
    if (addTitle && state.categoryBoundMap[item.index]) {
      let titles = state.categoryBoundMap[item.index]
      if (isLongrun) {
        const subSale = getSubSaleByPhotoStartIndex(item.index)
        if (subSale) {
          const saleItem = _.pick(subSale, ['name', 'due', 'isOverdue'])
          saleItem.type = 'sale'
          saleItem.index = item.index
          saleItem.id = item.id
          saleItem.overdueCharge = overdueCharge
          complemented.push(saleItem)
          // タイトルの配列から販売名を除去
          titles = titles.slice(1)
        }
      }

      const titleItem = {
        type: 'title',
        id: item.id,
        index: item.index,
        titles,
      }

      complemented.push(titleItem)
    }

    complemented.push(
      complementPhotoItem(
        item,
        token,
        thumbnailBaseUrl,
        lupeBaseUrl,
        printType,
        sizePrices,
        saleCustomizable
      )
    )
  })

  return complemented
}
