// サイズと価格情報

import getSubSizePriceApiAsync from '@/api/sale/get-sub-size-price'

import * as errorCodes from '@/errors/codes'

// アクション

export default {
  // 紐づくすべての販売の価格設定情報を取得
  // 過去販売がある場合はAPIを複数回叩く
  // saleの情報に依存しているので、saleの初期化が先
  async initAsync({ commit, rootGetters }) {
    // 販売のIdの取得
    const saleId = rootGetters['sale/id']
    // saleストアのinitAsyncを先に呼ぶ必要がある
    if (!saleId) throw new Error('you should init sale store module.')

    // 過去販売のリストを取得
    const oldSales = rootGetters['sale/oldSales'] || []
    // 現在の販売 + 過去の販売
    const sales = _.concat(
      [saleId],
      oldSales.map(value => value.id)
    )
    // すべての価格設定を取得
    const pList = sales.map(subSaleId => {
      const params = { id: saleId, subId: subSaleId }
      return getSubSizePriceApiAsync(params)
    })
    const responses = await Promise.all(pList)

    // 1つでも失敗があるか
    if (!_.every(_.map(responses, response => response.ok))) {
      throw new Error(errorCodes.GET_SIZE_PRICE_ERROR)
    }

    let map = {}
    responses.forEach(response => {
      const eventIdNotSubSaleId = response.payload.eventIdNotSaleId
      map[eventIdNotSubSaleId] = response.payload.items
    })

    commit('setSizePricesMap', map)
  },

  clearAll({ commit }) {
    commit('setSizePricesMap', null)
  },
}
