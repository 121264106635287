// アクション

export default {
  // 現在見ている写真のindexをセット
  // ローカルストレージにもその情報をセットし、リロード時や別のページから戻ってきた時に備える
  setCurrentPhotoIndex({ commit, rootGetters }, { index, silent }) {
    if (!silent) commit('setCurrentPhotoIndex', index)
    else commit('setCurrentPhotoIndexSilent', index)

    // ローカルストレージにもセット
    const key = _getStorageKey(rootGetters)
    if (!key) return
    storage.set(key, index)
  },

  // 特定の販売の閲覧位置を更新
  // storeを操作するわけではないので、本来アクションに置くべきではない？
  setPhotoIndexManual({ rootGetters }, { saleId, subSaleId, index }) {
    const prefix = 'browse'
    const userId = rootGetters['app/userId']
    const key = `${prefix}:${userId}:${saleId}:${subSaleId}`
    storage.set(key, index)
  },

  // 現在の位置の初期化。ローカルストレージに値があればその値を採用。
  // なければ、渡された値を使用する。
  initCurrentPhotoIndex({ commit, rootGetters }) {
    const key = _getStorageKey(rootGetters)
    // 通常の販売向け
    const minPhotoIndex = rootGetters['subSale/minPhotoIndex']
    // 年間販売向け
    const photoStartIndex = _.get(
      rootGetters['sale/subSales'],
      '[0].photoStartIndex',
      minPhotoIndex
    )
    // どちらかが存在していないということは通常無いはず
    // (subSaleストアの準備が完了していないケースで呼ばれる、という可能性はある)
    if (!key || !photoStartIndex) return
    const index = parseInt(storage.get(key), 10)
    commit('setCurrentPhotoIndex', index || photoStartIndex)
  },

  setDisplayPhotoSize({ commit }, size) {
    commit('setDisplayPhotoSize', size)
  },

  // 通常の写真表示(normal)かお顔検索結果の表示(okao)かを切り替え
  setMode({ commit }, mode) {
    commit('setMode', mode)
  },

  setOkaoChildId({ commit }, childId) {
    commit('setOkaoChildId', childId)
  },

  // ストアのcurrentPhotoIndexのみクリア(storageはそのまま)
  clearCurrentPhotoIndex({ commit }) {
    commit('setCurrentPhotoIndex', null)
  },

  clearAll({ commit }) {
    commit('setFromGuestSaleAuthPage', false)
    commit('setDisplayPhotoSize', 'large')
    commit('setCurrentPhotoIndex', null)
    commit('setMode', 'normal')
    commit('setOkaoChildId', null)
    commit('setShowFirstTimeAddOverdueToCartDialog', true)
    commit('setShowFirstTimeConfirmOverdueDialog', true)
  },

  // 閲覧する販売にに依存する情報のクリア
  clearSaleSpecifics({ commit }) {
    commit('setCurrentPhotoIndex', null)
    commit('setMode', 'normal')
    commit('setOkaoChildId', null)
  },
}

// ストレージに閲覧位置(閲覧中の写真のindex番号)を
// 保持する際に使用するキー。
// ユーザId + ルートのセールId + サブのセールId
function _getStorageKey(rootGetters) {
  // ローカルストレージに位置を保存する際のプレフィックス
  const prefix = 'browse'
  const userId = rootGetters['app/userId']
  const saleId = rootGetters['sale/id']
  const subSaleId = rootGetters['subSale/id']
  if (!userId || !saleId || !subSaleId) return null
  else return `${prefix}:${userId}:${saleId}:${subSaleId}`
}
