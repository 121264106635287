// ユーザの情報の取得

import apiFactory from '../api-factory'

export default apiFactory({
  path: '/my/infos',
  method: 'GET',
  needAuth: true,
  responseMapper: {
    id: 'user.id',
    name1: 'user.name1',
    name2: 'user.name2',
    name1_kana: 'user.name1Kana',
    name2_kana: 'user.name2Kana',
    email: 'user.email',
    location: {
      key: 'location',
      default: {},
    },
  },
})
