// トリミング時用のメニュー

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'TrimMenu',

  props: {
    orientation: String,
    size: String,
    // 複数の選択可能なトリミング条件をもっているか
    hasMultipleConditions: Boolean,
  },

  computed: {
    ...mapGetters({
      screenType: 'app/screenType',
    }),
  },

  methods: {
    showOptionsDialog() {
      if (this.hasMultipleConditions) this.$emit('showOptionsDialog')
    },
  },
}
</script>

<template lang="pug">
.trim-menu(:class='screenType')

  // モバイル用
  template(v-if='screenType === "mobile"')
    .first
      ps-button(
        color='purple',
        full-width,
        text-centered,
        size='large',
        @click='$emit("ok")'
      ) トリミング実行
    .second
      ps-row(:gutter='5')
        ps-col(:span='3')
          ps-button(
            color='pastel-green',
            full-width,
            text-centered,
            @click='showOptionsDialog'
          ) {{ orientation === 'landscape' ? '横向き' : '縦向き' }} / {{ size }}
        ps-col(:span='2')
          ps-button(
            full-width,
            text-centered,
            @click='$emit("cancel")'
          ) キャンセル

  // PC用
  template(v-else)

    .first

      div.trim-condition
        p 向き
        a(@click='showOptionsDialog')
          | {{ orientation === 'landscape' ? '横向き' : '縦向き' }}
          // 選択肢があれば下矢印を表示
          span(v-show='hasMultipleConditions')
            | &nbsp;
            ps-icon(icon='angle-down')

      div.trim-condition
        p サイズ
        a(@click='showOptionsDialog')
          | {{ size }}
          // 選択肢があれば下矢印を表示
          span(v-show='hasMultipleConditions')
            | &nbsp;
            ps-icon(icon='angle-down')

      div
        ps-icon-button(icon='check', color='purple',
          full-width, @click='$emit("ok")') 実行

    .second
       ps-button(full-width, text-centered, @click='$emit("cancel")') キャンセル

</template>

<style lang="sass" scoped>
@import '../../../../sass/mixins.sass'

.trim-menu
  &.mobile
    > .first
      // margin-topするのは実行ボタンを押してしまわないため
      margin-top: 5px
      margin-bottom: 5px

  &:not(.mobile)
    display: flex
    height: 100%
    flex-direction: column
    > .first
      flex-grow: 1
      > div.trim-condition
        margin-bottom: 1rem
        > p
          background-color: lighten($lime, 20%)
          padding: 0.2rem
          text-align: center
          margin-bottom: 0.3rem
        > a
          border: 2px solid lighten($blue, 25%)
          display: block
          border-radius: $radius
          padding: 0.5rem 0.2rem
          color: darken($blue, 10%)
          text-align: center
          cursor: pointer
          &:hover
            background-color: lighten($blue, 40%)
</style>
