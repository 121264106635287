// カートで使用するメニュー

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CartMenu',

  props: {
    // カート情報
    cartInfo: {
      type: Array,
    },
    // 選択可能なサイズ情報
    sizePrices: {
      type: Array,
    },
    // トリミング可能か
    trimmable: Boolean,
    // トリミングされているか
    trimmed: Boolean,
    // 日付入れ可能か
    datable: Boolean,
    // 日付入れされているか
    dated: Boolean,
    // 注文手続き可能か
    canGoToNextStep: Boolean,
    // 長辺のpx。トリミング時に選択可能なサイズのみ表示するために使用
    longEdge: Number,
    // 税別か
    isTaxExcluded: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      nums: _.range(51).map(i => {
        return { value: i, label: `${i}枚` }
      }),
      // データ販売は0 or 1
      numsDataSale: _.range(2).map(i => {
        return { value: i, label: `${i}枚` }
      }),
    }
  },

  computed: {
    ...mapGetters({
      screenType: 'app/screenType',
      role: 'app/role',
    }),
    isGuest() {
      return this.role === 'guest'
    },
    sizePricesAndCartNum() {
      const list = this.sizePrices.map(sp => {
        const cart = _.find(this.cartInfo, item => item.sizeId === sp.sizeId) || { num: 0 }
        return _.assign(cart, sp)
      })
      // トリミングされていたら、解像度を見て選択可能サイズを絞り込む
      if (this.trimmed) {
        // そのサイズに必要な最低解像度を満たしているか、またはカートに入っているか
        // 通常、最低解像度を満たしておらず、カートに入っているということは無いはずだが、念の為。
        return _.filter(list, item => this.longEdge >= item.minLongPx || item.num > 0)
      } else return list
    },
  },
}
</script>

<template lang="pug">
.cart-menu(:class='[screenType]')

  // モバイル用
  template(v-if='screenType === "mobile"')
    .first
      ps-row.price-and-num-list(:gutter='5')
        ps-col.col(
          v-for='item in sizePricesAndCartNum',
          :class='{ "active": item.num }',
          :key='item.sizeId'
        )
          p.title
            | {{ item.sizeName }}
            br
            | {{ item.price }}円
            small {{ isTaxExcluded ? '(税別)' : '' }}/枚
          ps-select.price-and-num(
            :items='item.dataSale ? numsDataSale : nums',
            :value='item.num',
            full-width,
            @input='$emit("changePhotoNum", { sizeId: item.sizeId, num: parseInt(arguments[0], 10) })'
          )

    .second(v-if='!isGuest')
      ps-row(:gutter='5')
        // トリミング
        ps-col(v-if='trimmable || trimmed')
          ps-button(
            v-if='!trimmed', color='purple', full-width, text-centered,
            @click='$emit("startTrimming")'
          ) トリミング
          ps-button(
            v-else, full-width, text-centered,
            @click='$emit("discardTrimming")'
          ) トリミング解除

        // 日付入れ
        ps-col(v-if='datable')
          ps-button(
            v-if='!dated', color='blue', full-width, text-centered,
            @click='$emit("startDating")'
          ) 日付入れ
          ps-button(
            v-else, full-width, text-centered,
            @click='$emit("discardDating")'
          ) 日付解除

    .third
      ps-row(:gutter='5')
        ps-col
          ps-button.close(full-width, text-centered, @click='$emit("close")') 閉じる
        ps-col
          ps-button(color='red', full-width, text-centered, :disabled='!canGoToNextStep', @click='$emit("goToOutro")') ご注文手続き

  // PC用
  template(v-else)
    .first

      //サイズと枚数
      .size-price
        p.title サイズと枚数
        ul.price-and-num-list
          li(v-for='item in sizePricesAndCartNum', :class='{ "active": item.num }')
            p
              | {{ item.sizeName }}
              br
              | {{ item.price }}円{{ isTaxExcluded ? '(税別)' : '' }}/枚
            ps-select.price-and-num(
              :items='item.dataSale ? numsDataSale : nums',
              :value='item.num',
              @input='$emit("changePhotoNum", { sizeId: item.sizeId, num: parseInt(arguments[0], 10) })'
            )
        .buttons
          ps-button(size='small', @click='$emit("removeFromCart")') カゴから削除

      // 画像編集関連
      .customs(v-if='!isGuest')
        // トリミング
        div(v-if='trimmable || trimmed')
          ps-button(
            v-if='!trimmed', color='purple', full-width, text-centered,
            @click='$emit("startTrimming")'
          )
            ps-icon(icon='cut')
            | &nbsp;トリミング
          ps-button(
            v-else, full-width, text-centered,
            @click='$emit("discardTrimming")'
          ) トリミング解除

        // 日付入れ
        div(v-if='datable')
          ps-button(
            v-if='!dated', color='blue', full-width, text-centered,
            @click='$emit("startDating")'
          ) 日付を入れる
          ps-button(
            v-else, full-width, text-centered,
            @click='$emit("discardDating")'
          ) 日付入れ解除

    .second
      div
        ps-button(color='red', full-width, text-centered, :disabled='!canGoToNextStep', @click='$emit("goToOutro")') ご注文手続きへ
      div
        ps-button.close(full-width, text-centered, @click='$emit("close")') 閉じる

</template>

<style lang="sass" scoped>
@import '../../../../sass/mixins.sass'

.cart-menu
  &.mobile
    .first
      margin-bottom: 5px
      .col
        background-color: $white-bis
        padding: 3px
        border-radius: $radius-small
        max-width: 33%
        &.active
          background-color: lighten($blue, 35%)
        .title
          line-height: 1
          font-size: $size-small
          text-align: center
          margin-bottom: 3px
    .second
      margin-bottom: 5px


  &:not(.mobile)
    display: flex
    height: 100%
    flex-direction: column
    > .first
      flex-grow: 1

      > .size-price
        margin-bottom: 0.5rem
        padding-bottom: 0.5rem
        border-bottom: 2px solid $white-ter
        > p
          background-color: lighten($lime, 20%)
          padding: 0.2rem
          text-align: center
          margin-bottom: 0.3rem
        > ul
          margin-bottom: 0.5rem
          > li
            background-color: $white-ter
            border-radius: $radius-small
            padding: 0.3rem
            text-align: center
            margin-bottom: 0.3rem
            &:last-child
              margin-bottom: 0
            > p
              font-size: $size-small
              margin-bottom: 0.2rem
            &.active
              background-color: lighten($blue, 35%)
        > .buttons
          text-align: center

      > .customs
        > *
          margin-bottom: 0.5rem
          &:last-child
            margin-bottom: 0

    > .second
      > :first-child
        margin-bottom: 0.5rem
</style>
