// トリミングの実行

import apiFactory from '../api-factory'

export default apiFactory({
  path: '/my/custom_photos/trim',
  method: 'POST',
  needAuth: true,
  bodyMapper: {
    saleId: 'sale_id',
    photoId: 'photo_id',
    'position.width': 'contents.w',
    'position.height': 'contents.h',
    'position.x': 'contents.x',
    'position.y': 'contents.y',
    async: {
      key: 'async',
      transform: value => (value ? 1 : 0),
      default: 0,
    },
  },
  responseMapper: {
    photo_custom_id: 'id',
    'photo_custom_data.data_checksum': {
      key: 'check',
      transform: value => value.slice(0, 5),
    },
  },
})
