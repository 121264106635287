// Bodyのスクロール関係のプラグイン

import platform from 'platform'

// iOS 10 かそれ以降であるか。
// iOS 10 からはピンチアウトが防げなくなったので、
// それに対応するためのフラグ
let isIOS10OrLater = platform.os.family === 'iOS' && parseFloat(platform.os.version) >= 10

export default {
  install(Vue) {
    // イベント
    Vue.prototype.$onBodyScroll = onScroll
    Vue.prototype.$onBodyScrollEnd = onScrollEnd
    Vue.prototype.$offBodyScrollAll = offAll
    // スクロールの状態
    Vue.prototype.$getBodyScrollInfo = getScrollInfo
    // スクロールの操作
    Vue.prototype.$scrollBodyToAsync = scrollToAsync
    Vue.prototype.$saveScrollBodyPosition = saveScrollPosition
    Vue.prototype.$restoreScrollBodyPositionAsync = restoreScrollPositionAsync
    // スクロールロック
    Vue.prototype.$lockBodyScroll = lockScroll
    Vue.prototype.$unlockScroll = unlockScroll
  },
}

// 初期化済みフラグ
let initialized = false

// イベントの発生を抑制
let cancelEvent = false

// イベントハンドラ
let scrollHandlers = []
let scrollEndHandlers = []

// 前回のscrollTopとscroll方向
let currentScroll = 0
// スクロールの方向。stay or bottom or top
let direction = 'stay'
// stay と判断するしきい値
const stayThreshold = 10

// スクロール位置の保存・復元に利用
let savedScrollInfo = null

// スクロールロック中に起こったスクロールを処理するハンドラ
let scrollHandlerOnLocking = null
// スクロールロック中の前回のClientY
let prevClientYOnLocking = null

// スクロールを間引いてトリガする
const throttledScroll = _.throttle(
  e => {
    if (cancelEvent) return
    const info = getScrollInfo()
    scrollHandlers.forEach(handler => handler(e, info))
  },
  100,
  { trailing: false }
)

// スクロールの終わりを検出する
const debouncedScroll = _.debounce(e => {
  if (cancelEvent) return
  const info = getScrollInfo()
  scrollEndHandlers.forEach(handler => handler(e, info))
}, 200)

function onScroll(handler) {
  if (!initialized) _init()
  scrollHandlers.push(handler)
}

function onScrollEnd(handler) {
  if (!initialized) _init()
  scrollEndHandlers.push(handler)
}

function offAll() {
  scrollHandlers = []
  scrollEndHandlers = []
  initialized = false
  window.removeEventListener('scroll', _scroll, { passive: true })
}

function _init() {
  window.addEventListener('scroll', _scroll, { passive: true })
  initialized = true
}

function _scroll(e) {
  // スクロールの位置と向きを更新
  const nextScroll = document.body.scrollTop || document.documentElement.scrollTop
  // スクロール量
  const delta = nextScroll - currentScroll
  if (delta > stayThreshold) direction = 'bottom'
  else if (delta < -stayThreshold) direction = 'top'
  else direction = 'stay'
  currentScroll = nextScroll

  throttledScroll(e)
  debouncedScroll(e)
}

// スクロール位置の保存
function saveScrollPosition() {
  savedScrollInfo = getScrollInfo()
}

// スクロール位置の復元
async function restoreScrollPositionAsync() {
  if (!savedScrollInfo) return
  const current = getScrollInfo()
  let scrollTop = current.scrollHeight - savedScrollInfo.scrollHeight + savedScrollInfo.scrollTop
  if (scrollTop < 0) scrollTop = 0
  await scrollToAsync(scrollTop)
}

function scrollToAsync(y) {
  return new Promise(resolve => {
    cancelEvent = true
    window.scrollTo(0, y)
    _.delay(
      () => {
        cancelEvent = false
        resolve()
      },
      300,
      'later'
    )
  })
}

function getScrollInfo() {
  const scrollHeight = document.body.scrollHeight || document.documentElement.scrollHeight
  const viewHeight = window.innerHeight
  return {
    direction,
    scrollTop: currentScroll,
    scrollHeight,
    scrollBottom: scrollHeight - currentScroll - viewHeight,
    viewHeight,
  }
}

function lockScroll(handler = null) {
  scrollHandlerOnLocking = handler
  prevClientYOnLocking = null
  cancelEvent = true
  window.addEventListener('mousewheel', _lockScrollHandler, { passive: false })
  window.addEventListener('touchmove', _lockScrollHandler, { passive: false })
  window.addEventListener('touchend', _clearPrevClientYOnLocking, { passive: false })

  // Windowsタブレット向け
  document.body.style.setProperty('-ms-touch-action', 'none')
}

function unlockScroll() {
  // IE11 においては オプションの部分も同一でないとイベントを削除できないのでオプションを付加
  window.removeEventListener('mousewheel', _lockScrollHandler, { passive: false })
  window.removeEventListener('touchmove', _lockScrollHandler, { passive: false })
  window.addEventListener('touchend', _clearPrevClientYOnLocking, { passive: false })
  scrollHandlerOnLocking = null
  prevClientYOnLocking = null
  cancelEvent = false

  // Windowsタブレット向け
  document.body.style.setProperty('-ms-touch-action', 'auto')
}

function _lockScrollHandler(e) {
  // ロック中に呼び出すハンドラが存在するか
  if (scrollHandlerOnLocking) {
    let deltaY
    if (e.type === 'touchmove') {
      if (prevClientYOnLocking) {
        deltaY = prevClientYOnLocking - e.changedTouches[0].clientY
      }
      prevClientYOnLocking = e.changedTouches[0].clientY
    } else {
      deltaY = e.deltaY
    }
    if (deltaY) scrollHandlerOnLocking(deltaY)
  }

  // iOS 10 以降かつ、2本指(ピンチイン・アウトを想定)ならば、
  // e.preventDefaultしない
  if (isIOS10OrLater && event.touches && event.touches.length === 2) return

  e.preventDefault()
}

// touchendでnullにしないと、
// 指を戻して再度スクロールしようとした時、
// 前の位置に戻ってしまう
function _clearPrevClientYOnLocking() {
  prevClientYOnLocking = null
}
