// お届けに関する情報を表示するダイアログ

<template lang="pug">
  .shipment-detail(:class='[ type ]')
    
    // 個別発送
    template(v-if='type === "individual"')

      p 商品はお支払いを確認後、通常14日以内にご自宅(または指定された住所)にお届けします。

      article
        h3 発送手数料について
        p
          | 発送手数料は購入枚数に関係なく、全国一律
          b {{ shipmentFeeNormal }}円 ({{ isTaxExcluded ? '税別' : '税込' }})
          | となります。

    // 写真館一括
    template(v-else)
      p 商品は学校・園からお渡しいたします。

</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ShipmentDetail',

  props: {
    // individual or lump
    type: {
      type: String,
      required: true,
    },
    isTaxExcluded: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters({
      shipmentFeeNormal: 'sale/shipmentFeeNormal',
    }),
  },
}
</script>

<style lang="sass" scoped>
@import '../../../sass/variables.sass'

.shipment-detail

  > *
    margin-bottom: 0.5rem

  p
    line-height: 1.4

  &.individual
    h3
      font-size: $size-normal
      font-weight: normal
      color: darken($blue, 10%)
      border-bottom: 1px dashed lighten($blue, 20%)
      padding-bottom: 0.2rem
      margin-bottom: 0.3rem
</style>
