// お子様情報

<script>
import { mapActions } from 'vuex'

import ChildInfo from '@/dialogs/contents/child-info'
import OkaoUploading from '@/dialogs/contents/okao-uploading'

import getChildrenApiAsync from '@/api/user/get-children'
import removeChildApiAsync from '@/api/user/remove-child'
import removeOkaoApiAsync from '@/api/user/remove-okao'

export default {
  name: 'MyPageChildren',

  data() {
    return {
      children: [],
    }
  },

  created() {
    this.updateChildrenAsync()
  },

  methods: {
    ...mapActions({
      setError: 'app/setError',
    }),

    // すべてのお子様情報の取得
    async updateChildrenAsync() {
      let response
      try {
        response = await getChildrenApiAsync()
      } catch (e) {
        this.setError(e)
        return
      }
      if (!response.ok) return
      this.children = response.payload.items
    },

    // お子様情報の削除
    async removeChildAsync(item) {
      if (!confirm('削除してもよろしいですか？')) return
      const params = { id: item.id }
      let response
      try {
        response = await removeChildApiAsync(params)
      } catch (e) {
        this.setError(e)
        return
      }
      if (!response.ok) return
      await this.updateChildrenAsync()
    },

    getFaceBg(item, i) {
      if (item.faces[i]) return `url(${item.faces[i].thumbnailUrl})`
      else return null
    },

    // お子様情報の追加または更新(itemが非nulなら更新)
    showChildInfoDialog(item = null) {
      let params = {
        title: 'お子様(参加者)情報の追加',
        component: ChildInfo,
        size: 'm',
        onClose: () => this.updateChildrenAsync(),
      }
      if (item) {
        _.assign(params, {
          title: 'お子様(参加者)情報の更新',
          props: {
            value: item,
            id: item.id,
          },
        })
      }
      this.$psdialog.open(params)
    },

    // お顔画像アップロードダイアログの表示
    showOkaoUploadDialog(child) {
      this.$psdialog.open({
        title: '写真の登録',
        component: OkaoUploading,
        props: {
          name: `${child.name1} ${child.name2}`,
          childId: child.id,
        },
        size: 'm',
        onClose: params => {
          if (params && params.ok) this.updateChildrenAsync()
        },
      })
    },

    // お顔画像の削除
    async removeOkaoAsync(child, i) {
      const face = child.faces[i]
      if (!face) return

      const params = { id: child.id, faceId: face.id }
      let response
      try {
        response = await removeOkaoApiAsync(params)
      } catch (e) {
        this.setError(e)
        return
      }
      if (!response.ok) return

      this.updateChildrenAsync()
    },
  },
}
</script>

<template lang="pug">
.my-page-children

  .inner

    ul.children

      li(v-for='item in children')
        
        ps-row(valign='center', break-point='tablet')

          ps-col.left
            .name
              h3 {{ item.name1 }} {{ item.name2 }} 様
              p.kana ({{ item.name1Kana }} {{ item.name2Kana }} 様)
            p.info クラス名: {{ item.attribute }} / ご関係: {{ item.relation }}

          ps-col.right(:span='0')
            ps-button(color='green', @click='showChildInfoDialog(item)') 情報の変更
            | &nbsp;
            ps-button(@click='removeChildAsync(item)') 削除

          //- ps-col.left(:span='1')
          //-   h3 {{ item.name1 }} {{ item.name2 }} 様
          //-   p.kana ({{ item.name1Kana }} {{ item.name2Kana }} 様)
          //-   p.info
          //-     | クラス名: {{ item.attribute }}
          //-     br
          //-     | 関係: {{ item.relation }}
          //-   .buttons
          //-     ps-button(color='green', @click='showChildInfoDialog(item)') 情報の変更
          //-     | &nbsp;
          //-     ps-button(@click='removeChildAsync(item)') 削除

          //- ps-col.right(:span='2')
          //-   p.caption-for-mobile お顔検索用お顔画像
          //-   ps-row(valign='center', :gutter='10')
          //-     ps-col.caption
          //-       p
          //-         | お顔検索用
          //-         br
          //-         | お顔画像
          //-     ps-col.image-outer(v-for='i in [0, 1, 2]', :class='{ active: item.faces[i] }', :key='i')
          //-       .image(:style='{ backgroundImage: getFaceBg(item, i) }')
          //-       ps-button.add(color='orange', size='small', @click='showOkaoUploadDialog(item)') 写真登録
          //-       ps-button.remove(@click='removeOkaoAsync(item, i)') 削除

    .operations(v-if='children.length < 3')
      ps-button(color='pink', large-width, @click='showChildInfoDialog()')
        ps-icon(icon='plus')
        | &nbsp;お子様情報を新しく追加

    .buttons
      ps-button(@click='$router.push({ name: "MyPageTop" })', large-width) 戻る
</template>

<style lang="sass" scoped>
@import '../../../../sass/variables.sass'
@import '../../../../sass/mixins.sass'

.my-page-children

  .inner
    background-color: $white
    padding: 1.5rem 1rem
    border-radius: $radius-small
    box-shadow: 0 1px 6px 2px rgba(0, 0, 0, .25)
    +mobile
      padding: 1rem 0.5rem
      box-shadow: 0 1px 1px 1px rgba(0, 0, 0, .3)


    > .children
      > li
        background-color: $white
        border: 1px solid $grey-lighter
        margin-bottom: 1.5rem
        padding: 1rem

        .left
          .name
            margin-bottom: 0.3rem
            h3
              display: inline-block
              margin-right: 0.5rem
              fotn-size: $size-large
              font-weight: bold
            p.kana
              display: inline-block
          p.info
            +mobile
              margin-bottom: 0.5rem

    > .operations
      text-align: center
      margin-bottom: 2rem

    > .buttons
      text-align: center
</style>
