// 規約の表示

<script>
export default {
  name: 'PsTerm',

  props: {
    html: String,
    loading: Boolean,
  },
}
</script>

<template lang="pug">
.ps-term
  .content(v-html='html')
  ps-loading(v-if='loading')
</template>

<style lang="sass" scoped>
@import '../../sass/variables.sass'
@import '../../sass/mixins.sass'

.ps-term
  position: relative
  border: 1px solid $white-ter
  border-radius: $radius-small
  background-color: $white
  padding: 0.5rem
  > .content
    overflow: auto
    height: 150px
    +mobile
      height: 100px
</style>

<style lang="sass">
@import '../../sass/variables.sass'

.ps-term
  > .content
    font-size: $size-small
    line-height: 1.2
    article
      margin-bottom: 1rem
    h2
      font-size: $size-normal
      font-weight: bold
      margin-bottom: 0.5rem

    p
      margin-bottom: 0.3rem
      &:last-child
        margin-bottom: 0

    ul
      margin-left: 1rem
      li
        list-style-type: disc
    ol
      margin-left: 1rem
      li
        list-style-type: decimal

    li, dd
      margin-bottom: 0.3rem
      &:last-child
        margin-bottom: 0
</style>
