// モバイルかタブレットかPCかの判定

export default function() {
  const mobileBreakPoint = parseInt(process.env.VUE_APP_MOBILE_BREAK_POINT || 768, 10)
  const tabletBreakPoint = parseInt(process.env.VUE_APP_TABLET_BREAK_POINT || 999, 10)

  // モバイルか
  let query = `(max-width:${mobileBreakPoint}px)`
  if (window.matchMedia(query).matches) return 'mobile'

  // タブレットか
  query = `(min-width:${mobileBreakPoint + 1}px) and (max-width:${tabletBreakPoint}px)`
  if (window.matchMedia(query).matches) return 'tablet'

  return 'pc'
}
