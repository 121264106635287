// 購入履歴

<script>
import { mapActions } from 'vuex'

// 注文詳細ダイアログ
import OrderDetail from '@/dialogs/contents/order-detail'
// キャンセル確認ダイアログ
import CancelConfirmation from '@/dialogs/contents/cancel-confirmation'
// 購入写真の一覧ダイアログ
import OrderItems from '@/dialogs/contents/order-items'

import getOrdersApiAsync from '@/api/user/get-orders'

const historyTerms = [
  { label: '過去6ヶ月間', value: 'default' },
  { label: 'すべて', value: 'all' },
]

const statusMap = {
  shipped: '発送済み',
  shipping: '発送待ち',
  commited: '入金待ち',
  accepted: '受付済み',
  canceled: 'キャンセル済み',
  unknown: '状態未確定',
}

export default {
  name: 'MyPageHistory',

  data() {
    return {
      historyTerms,
      term: 'default',
      orders: () => [],
      // クレジット決済の6時間以内キャンセルを無効にするかどうか(=ボタンを表示するかどうか)
      disabledCreditCancel: process.env.VUE_APP_DISABLED_CREDIT_CANCEL === 'true',
    }
  },

  created() {
    this.updateOrdersAsync()
  },

  methods: {
    ...mapActions({
      setError: 'app/setError',
    }),

    async updateOrdersAsync() {
      let response
      try {
        const params = { filter: this.term === 'default' ? 'last6mon' : '' }
        response = await getOrdersApiAsync(params)
      } catch (e) {
        this.setError(e)
        return
      }
      if (!response.ok) return

      this.orders = response.payload.items
    },

    getStatusName(code) {
      return statusMap[code] || statusMap.unknown
    },

    // キャンセル確認ダイアログの表示
    cancelOrder(order) {
      this.$psdialog.open({
        title: '確認',
        component: CancelConfirmation,
        props: { order },
        size: 'm',
        closeByClickBg: true,
        closeButton: true,
        onClose: params => {
          if (!params) return null
          else if (!params.result) alert('キャンセルに失敗しました。')
          else {
            alert('キャンセルを承りました。')
            this.updateOrdersAsync()
          }
        },
      })
    },

    // 詳細ダイアログの表示
    showDetail(order) {
      this.$psdialog.open({
        title: '注文の詳細',
        component: OrderDetail,
        props: { code: order.code },
        size: 'm',
        closeByClickBg: true,
        closeButton: true,
      })
    },

    // 購入写真の一覧表示
    showItems(order) {
      this.$psdialog.open({
        title: 'ご注文写真',
        component: OrderItems,
        props: { code: order.code },
        size: 'l',
        closeByClickBg: true,
        closeButton: true,
      })
    },
  },

  watch: {
    term() {
      this.updateOrdersAsync()
    },
  },
}
</script>

<template lang="pug">
.my-page-hisotry

  .inner

    .term
      ps-select(:items='historyTerms', v-model='term')
      span &nbsp;のご注文
      span(v-if='orders.length') ({{ orders.length }}件)

    ul.orders
      li(v-for='item in orders')

        // 状態や販売名など
        ps-row.first(valign='center', :gutter='15', break-point='mobile')
          ps-col(:span='0')
            p.tag(:class='item.status') {{ getStatusName(item.status) }}
          ps-col
            h3 {{ item.saleName }}
            h4 {{ item.orgName }}
          ps-col.cancel-button(:span='0')
            ps-button(v-if='item.cancellable && !disabledCreditCancel', size='small', @click='cancelOrder(item)') キャンセル

        // 注文の情報とボタン群
        ps-row.second(valign='center', break-point='tablet')

          ps-col.detail
            ps-row
              ps-col
                p
                  span 注文日:
                  | {{ item.orderedAt }}
                p
                  span 注文番号:
                  | {{ item.code }}
              ps-col
                p
                  span 金額:
                  | {{ item.price }}円(税込)
                p
                  span 支払い方法:
                  | {{ item.paymentTypeName }}
          
          ps-col(:span='0')
            .buttons
              // このキャンセルボタンはモバイル用
              ps-button.cancel(v-if='item.cancellable && !disabledCreditCancel', @click='cancelOrder(item)') キャンセル
              | &nbsp;
              ps-button(color='blue', @click='showItems(item)') 写真を見る
              | &nbsp;
              ps-button(color='green', @click='showDetail(item)') 注文詳細

    .buttons
      ps-button(@click='$router.push({ name: "MyPageTop" })', large-width) 戻る
</template>

<style lang="sass" scoped>
@import '../../../../sass/variables.sass'
@import '../../../../sass/mixins.sass'

.my-page-hisotry

  .inner

    background-color: $white
    padding: 1.5rem 1rem
    border-radius: $radius-small
    box-shadow: 0 1px 6px 2px rgba(0, 0, 0, .25)
    +mobile
      padding: 1rem 0.5rem
      box-shadow: 0 1px 1px 1px rgba(0, 0, 0, .3)

    > .term
      margin-bottom: 2rem

    > .orders
      margin-bottom: 5rem
      li
        background-color: $white
        border: 1px solid $grey-lighter
        margin-bottom: 1.5rem
        padding: 1rem

        .first
          border-bottom: 1px dashed $grey-lighter
          padding-bottom: 0.5rem
          margin-bottom: 0.5rem

          .tag
            display: inline-block
            padding: 0.3rem 1rem
            background-color: $grey
            color: $white
            border-radius: $radius-large
            &.commited
              background-color: $red
            &.shipping
              background-color: $orange
            &.shipped
              background-color: $blue

          h3
            font-size: $size-medium
            font-weight: normal
            margin-bottom: 0.3rem
          h4
            font-size: $size-normal
            font-weight: normal
            color: $grey-dark

          +mobile
            > *
              margin-bottom: 0.5rem
              .tag
                display: block
                text-align: center
            .cancel-button
              display: none

        .second
          .detail
            p
              line-height: 1.5
              span
                color: $grey-dark
                margin-right: 0.5rem
                +mobile
                  display: block
          .buttons
            text-align: center
            // タブレット以下用の設定
            +touch
              margin-top: 0.5rem
            +mobile
              > *
                margin: 0.1rem
            // タブレット以上は別の場所にキャンセルボタンを表示
            +tablet
              .cancel
                display: none
    > .buttons
      text-align: center
</style>
