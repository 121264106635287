// 特定のお子様情報の更新

import apiFactory from '../api-factory'

export default apiFactory({
  path: '/my/subs/<%= id %>',
  method: 'PUT',
  expectedParams: ['id'],
  needAuth: true,
  bodyMapper: {
    name1: 'name1?',
    name2: 'name2?',
    name1Kana: 'name1_kana?',
    name2Kana: 'name2_kana?',
    relation: 'relation?',
    attribute: 'attribute?',
    // 2020年〜の新しいお子様情報
    fiscalYear: 'fiscal_year',
    orgId: 'org_id',
    grade: 'grade_index',
  },
})
