// sentryにエラーリポートを送る

// ストア。ユーザ情報の取得のため
import store from '@/store'

// for Sentry
import Raven from 'raven-js'
// エラーの詳細
import errorDetails from '@/errors/details'
// エラーコード
import * as errorCodes from '@/errors/codes'

export default function(error) {
  if (!Raven.isSetup()) return

  // エラーコード取得
  let code
  if (errorDetails[error.message]) {
    code = error.message
  } else {
    code = errorCodes.UNKNOWN_ERROR
  }

  console.log('send error to sentry: ', code)

  const detail = errorDetails[code]
  const fingerprint = code

  // ユーザ情報をセットしてエラーを送信
  Raven.setUserContext({ id: store.getters['app/userId'], role: store.getters['app/role'] })
  Raven.captureException(error, {
    fingerprint,
    level: detail.type,
    extra: detail,
  })
}
