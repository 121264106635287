// 閲覧ページ

<template lang="pug">
.browse-page

  // ヘッダナビゲーション
  pc-header-nav(
    v-if='screenType !== "mobile"',
    @clickShowMenu='menuVisibility = true',
    @clickShowCategory='categoryMenuVisiblity = true'
  )
  mobile-header-nav(
    v-else,
    @clickShowMenu='menuVisibility = true',
    @clickShowCategory='categoryMenuVisiblity = true',
    @startFaceSearch='startFaceSearch',
    @endFaceSearch='endFaceSearch',
    @goToCart='$router.push({ name: "OutroPageCart", params: { id: saleId } })'
  )

  // 写真の一覧
  photo-list(v-if='!okaoNow', @clickPhoto='showBrowseZoomDialog(arguments[0].index)')
  okao-photo-list(v-else, @clickPhoto='showOkaoZoomDialog(arguments[0].index)')

  // サイドメニューはPCのみ
  side-menu(
    v-if='screenType !== "mobile"',
    @startOldSale='startOldSale',
    @startFaceSearch='startFaceSearch',
    @endFaceSearch='endFaceSearch'
  )

  // カテゴリメニュー(モバイル用)
  mobile-category-menu(
    v-if='screenType === "mobile" && categoryMenuVisiblity',
    @close='categoryMenuVisiblity = false',
    @startOldSale='startOldSale'
  )

  slide-in-menu(v-if='menuVisibility', @clickBg='menuVisibility = false')
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import getChildrenByOrgIdAndYearApiAsync from '@/api/user/get-children-by-org-id-and-year'

import PcHeaderNav from './component/pc-header-nav'
import MobileHeaderNav from './component/mobile-header-nav'
import PhotoList from './component/photo-list'
import SideMenu from './component/side-menu'
import MobileCategoryMenu from './component/mobile-category-menu'
import OkaoPhotoList from './component/okao-photo-list'

// 拡大ダイアログ
import BrowseZoom from '@/dialogs/contents/browse-zoom'
// お顔検索向け拡大ダイアログ
import OkaoZoom from '@/dialogs/contents/okao-zoom'
// パスワード登録ダイアログ
import PasswordRegister from '@/dialogs/contents/password-register'
// 過去販売用ダイアログ
import OldSales from '@/dialogs/contents/old-sales'
// お顔検索ダイアログ
import PrepareSearchingFaces from '@/dialogs/contents/prepare-searching-faces'
// 新しい(2020年度〜)お子様リストダイアログ
import NewChildrenList from '@/dialogs/contents/new-child-list'

// 販売に対する緊急のメッセージがある場合に表示
import ErrorMessage from '@/dialogs/contents/error-message'

import * as errorCodes from '@/errors/codes'

export default {
  name: 'BrowsePage',

  components: {
    PcHeaderNav,
    MobileHeaderNav,
    PhotoList,
    SideMenu,
    MobileCategoryMenu,
    OkaoPhotoList,
  },

  computed: {
    ...mapGetters({
      role: 'app/role',
      saleIsInTerm: 'sale/isInTerm',
      saleHasFatalAnnouncement: 'sale/hasFatalAnnouncement',
      saleHasInfoAnnouncement: 'sale/hasInfoAnnouncement',
      saleAnnouncement: 'sale/announcement',
      alreadyReadInfoAnnouncement: 'sale/alreadyReadInfoAnnouncement',
      screenType: 'app/screenType',
      // 新しいお子様情報管理を利用するか
      useNewChildrenInfoSystem: 'sale/useNewChildrenInfoSystem',
      // 新しいお子様情報管理を利用する場合のクラスのリスト
      newOrgAttributes: 'sale/newOrgAttributes',
      // 販売に紐づく年度
      fiscalYear: 'sale/fiscalYear',
      // 販売に紐づく園・学校のID
      orgId: 'sale/orgId',
      // 確認コードによる閲覧かどうか
      byConfirmCode: 'sale/byConfirmCode',
      // はじめての方ページ向けのページからの遷移か
      fromGuestSaleAuthPage: 'browsing/fromGuestSaleAuthPage',
      // お顔検索結果表示中かどうか
      okaoNow: 'browsing/okaoNow',
    }),
    saleId() {
      return this.$route.params.saleId
    },
    subSaleId() {
      return this.$route.query.subSaleId || this.saleId
    },
  },

  data() {
    return {
      menuVisibility: false,
      categoryMenuVisiblity: false,
    }
  },

  async created() {
    try {
      // ルートの販売情報と現在の販売情報
      await this.initSaleAsync({ id: this.saleId })
      // 販売が期間外ならエラー
      if (!this.saleIsInTerm) throw new Error(errorCodes.SALE_IS_OUT_OF_TERM)

      // 緊急メッセージがある
      if (this.saleHasFatalAnnouncement) {
        this.showSaleAnnouncement('error', this.saleAnnouncement.title, this.saleAnnouncement.body)
        return
      }

      await this.initSubSaleAsync({ id: this.subSaleId })

      // カートはsaleストアに依存しているのでinitSaleAsync完了後に呼ぶ
      await this.initSizePriceAsync()
      await this.initCartAsync()
    } catch (e) {
      this.setError(e)
    }

    this.initCurrentPhotoIndex()

    // はじめての方向けのページからの遷移の場合は
    // パスワード登録ダイアログを表示する
    if (this.fromGuestSaleAuthPage || storage.get('forceShowPasswordRegisterDialog')) {
      this.$psdialog.open({
        title: 'パスワードの登録',
        component: PasswordRegister,
        props: { type: 'first' },
        size: 'm',
      })
      // フラグはオフに
      this.$store.commit('browsing/setFromGuestSaleAuthPage', false)
      storage.remove('forceShowPasswordRegisterDialog')
    }

    // memberかつ、非確認コードかつ、新しいお子様情報管理を利用するなら、
    // お子様情報を取得
    if (this.role === 'member' && !this.byConfirmCode && this.useNewChildrenInfoSystem) {
      const response = await getChildrenByOrgIdAndYearApiAsync({
        orgId: this.orgId,
        fiscalYear: this.fiscalYear,
      })
      // 今年度の情報が不十分なお子様がいればダイアログを表示
      const childrenInsufficient = _.filter(
        response.payload.items,
        item => !item.hasCurrentFiscalYearInfo
      )
      if (childrenInsufficient.length > 0) {
        // ダイアログに渡すのはすべてのお子様の情報
        this.showNewChildrenList(response.payload.items)
      }
    }

    // 販売にお知らせメッセージがある場合に対応
    if (this.saleHasInfoAnnouncement && !this.alreadyReadInfoAnnouncement) {
      this.showSaleAnnouncement('info', this.saleAnnouncement.title, this.saleAnnouncement.body)
      // 閲覧済みのフラグを立てる
      this.setAlreadyReadInfoAnnouncementFlag()
    }
  },

  watch: {
    $route() {
      this.changeSubSaleAsync()
    },
  },

  methods: {
    ...mapActions({
      initSaleAsync: 'sale/initAsync',
      setAlreadyReadInfoAnnouncementFlag: 'sale/setAlreadyReadInfoAnnouncementFlag',
      initSizePriceAsync: 'sizePrice/initAsync',
      initSubSaleAsync: 'subSale/initAsync',
      initCartAsync: 'cart/initAsync',
      setCurrentPhotoIndex: 'browsing/setCurrentPhotoIndex',
      initCurrentPhotoIndex: 'browsing/initCurrentPhotoIndex',
      clearCurrentPhotoIndex: 'browsing/clearCurrentPhotoIndex',
      setBrowsingMode: 'browsing/setMode',
      setError: 'app/setError',
    }),

    // サブのセールの変更
    async changeSubSaleAsync() {
      // 今見ている位置をクリア
      this.clearCurrentPhotoIndex()

      try {
        const p1 = this.initSubSaleAsync({ id: this.subSaleId })
        const p2 = this.initSizePriceAsync()
        await Promise.all([p1, p2])
        this.initCurrentPhotoIndex()
      } catch (e) {
        this.setError(e)
      }
    },

    // 写真拡大画面の表示
    showBrowseZoomDialog(photoIndex) {
      this.$psdialog.open({
        component: BrowseZoom,
        props: { photoIndex },
        // 閉じた際、写真の位置が更新された場合、一覧を再読み込み
        onClose: params => {
          if (params && params.updated) this.setCurrentPhotoIndex({ index: params.photoIndex })
        },
      })
    },

    // 過去販売の開始
    startOldSale() {
      this.$psdialog.open({
        title: '以前の販売',
        component: OldSales,
        size: 'm',
        closeByClickBg: true,
        closeButton: true,
      })
    },

    // 販売に対するメッセージを表示
    showSaleAnnouncement(type, title, message) {
      this.$psdialog.open({
        title,
        props: {
          message,
          type,
        },
        component: ErrorMessage,
        size: 'm',
      })
    },

    startFaceSearch() {
      this.$psdialog.open({
        title: 'お顔検索',
        component: PrepareSearchingFaces,
        size: 'm',
        closeButton: true,
      })
    },

    endFaceSearch() {
      this.setBrowsingMode('normal')
    },

    // お顔検索向け拡大ダイアログ
    showOkaoZoomDialog(photoIndex) {
      this.$psdialog.open({
        component: OkaoZoom,
        props: { photoIndex },
      })
    },

    // 新しいお子様ダイアログの表示
    showNewChildrenList() {
      this.$psdialog.open({
        component: NewChildrenList,
        title: 'お子様情報の更新',
        props: {
          orgId: this.orgId,
          fiscalYear: this.fiscalYear,
          attributes: this.newOrgAttributes,
        },
        size: 'm',
        closeButton: true,
      })
    },
  },

  // 戻るボタン押下時を含めたページ遷移時の挙動
  beforeRouteLeave(to, from, next) {
    // ダイアログ表示時は遷移を防ぐ
    if (this.$psdialog.hasDialogs()) next(false)
    // はじめての方の販売認証ページへの遷移は防ぐ
    else if (to.name === 'IntroPageAuthorization') next(false)
    else next()
  },

  beforeDestroy() {
    // スクロール関連のイベントをすべてクリア
    this.$offBodyScrollAll()
  },
}
</script>

<style lang="sass" scoped>
@import '../../sass/variables.sass'
@import '../../sass/mixins.sass'

.browse-page
  padding: 50px 270px 0 0
  margin-bottom: 1rem
  +mobile
    padding: 45px 0 0

  // 支払いとお届けに関する情報
  .payment-and-shipping-info
    font-size: $size-small
    color: darken($green, 25%)
    background-color: lighten($green, 23%)
    text-align: center
    padding: 0.4rem
    border-radius: $radius
    margin-bottom: 0.8rem

    // 写真館一括
    &.online.lump
      color: darken($pink, 13%)
      background-color: lighten($pink, 17%)
    // 集金袋
    &.pouch
      color: darken($blue, 20%)
      background-color: lighten($blue, 38%)

    a
      margin-left: 0.5rem
      text-decoration: underline
      cursor: pointer

    +mobile
      .payment, .shipment
        display: none
</style>
