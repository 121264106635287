// お顔検索関連のストア

import searchFacesApi from '../../../api/sale/search-faces'

import complementPhotoItem from '@/helpers/complement-photo-item'

import * as errorCodes from '@/errors/codes'

const state = {
  // オリジナルの検索に使用した画像ファイル
  originalFile: null,
  // 実際に検索に使用した画像ファイルまたはBlob
  faceImageOrBlob: null,
  // お顔検索の結果
  photos: [],
  // 件数
  totalCount: null,
}

const getters = {
  originalFile(state) {
    return state.originalFile
  },
  faceImageOrBlob(state) {
    return state.faceImageOrBlob
  },
  photos(state) {
    return state.photos
  },
  totalCount(state) {
    return state.photos ? state.photos.length : 0
  },
}

const mutations = {
  setOriginalFile(state, value) {
    state.originalFile = value
  },
  setFaceImageOrBlob(state, value) {
    state.faceImageOrBlob = value
  },
  setPhotos(state, value) {
    state.photos = value
  },
  clear(state) {
    state.originalFile = null
    state.faceImageOrBlob = null
    state.photos = []
    state.totalCount = null
  },
}

const actions = {
  setOriginalFile({ commit }, value) {
    commit('setOriginalFile', value)
  },

  setFaceImageOrBlob({ commit }, value) {
    commit('setFaceImageOrBlob', value)
  },

  clearAll({ commit }) {
    commit('clear')
  },

  // お顔検索の実行
  async search({ state, commit, rootGetters }, saleId) {
    if (!state.faceImageOrBlob) {
      throw new Error(errorCodes.FACE_IMAGE_NOT_SET_ERROR)
    }
    const formData = new FormData()
    formData.append('image', state.faceImageOrBlob, 'okao.jpg')
    const response = await searchFacesApi(
      { id: saleId },
      formData,
      // silentモード
      true
    )
    if (!response.ok) {
      throw new Error(errorCodes.SEARCH_FACES_ERROR)
    }

    const payload = response.payload
    // 情報補完済みの写真のリストを保存
    commit('setPhotos', _complementPhotos(rootGetters, payload.token, payload.items))
  },
}

// APIから取得した写真情報にサイズ・価格情報等を付加して補完する
// また、カテゴリ境界用のタイトルを挿入する
function _complementPhotos(rootGetters, token, items) {
  // 補完に必要な情報
  const thumbnailBaseUrl = rootGetters['sale/thumbnailBaseUrl']
  const lupeBaseUrl = rootGetters['sale/lupeBaseUrl']
  const printType = rootGetters['sale/printType']
  const saleCustomizable = rootGetters['sale/customizable']
  const getSizePricesByEventIdNotSubSaleIdAndIndex =
    rootGetters['sizePrice/getSizePricesByEventIdNotSubSaleIdAndIndex']

  // 補完
  let complemented = []
  items.forEach(item => {
    // 価格設定を取得
    const sizePrices = getSizePricesByEventIdNotSubSaleIdAndIndex(item.eventIdNotSaleId, item.index)
    // 価格設定がないということは通常ないはずだが、ない場合はスキップ
    if (sizePrices.length === 0) return
    // 写真情報を補完
    complemented.push(
      complementPhotoItem(
        item,
        token,
        thumbnailBaseUrl,
        lupeBaseUrl,
        printType,
        sizePrices,
        saleCustomizable
      )
    )
  })

  return complemented
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
