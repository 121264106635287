// 注文の確定

import apiFactory from '../api-factory'

import bodyMapper from './order-body-mapper'

export default apiFactory({
  path: '/sales/<%= id %>/orders',
  method: 'POST',
  expectedParams: ['id'],
  needAuth: true,
  bodyMapper,
  responseMapper: {
    code: 'orderCode',
  },
})
