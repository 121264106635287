// かんたんガイド for PC

<script>
export default {
  name: 'BasicTutorialPc',

  data() {
    return {
      swiperOptions: {
        autoplay: false,
        autoHeight: true,
        prevButton: '.swiper-button-prev',
        nextButton: '.swiper-button-next',
      },
    }
  },
}
</script>

<template lang="pug">
.basic-tutorial-pc
  swiper(:options='swiperOptions')
    swiper-slide.slide
      img(src='../../../assets/pc-help-1.jpg')
    swiper-slide.slide
      img(src='../../../assets/pc-help-2.jpg')
    swiper-slide.slide
      img(src='../../../assets/pc-help-3.jpg')
    swiper-slide.slide
      img(src='../../../assets/pc-help-4.jpg')
    swiper-slide.slide
      img(src='../../../assets/pc-help-5.jpg')
    swiper-slide.slide
      img(src='../../../assets/pc-help-6.jpg')
    swiper-slide.slide.end
      .wrapper
        h3 さあ、閲覧を始めましょう！
        p
          ps-button(size='large', color='green', @click='$psdialog.close()') 閲覧開始
    .swiper-button-prev(slot='button-prev')
    .swiper-button-next(slot='button-next')
  .buttons
    ps-button(@click='$psdialog.close()', large-width) 閉じる
</template>

<style lang="sass"></style>

<style lang="sass" scoped>
@import '../../../sass/variables.sass'

.basic-tutorial-pc
  .slide
    img
      width: 100%
      height: auto
      vertical-align: top
      margin: 0.5rem auto
    &.end
      .wrapper
        background-color: #ffedd5
        padding: 6rem 2rem
        margin-bottom: 0.5rem
        h3
          margin: 0
          text-align: center
          font-size: 1.5rem
          letter-spacing: 0.1rem
          color: #512a11
        p
          margin-top: 1.5rem
          text-align: center

  .buttons
    text-align: center
</style>
