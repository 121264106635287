// お子様のリストの取得

import apiFactory from '../api-factory'

export default apiFactory({
  path: '/my/subs',
  method: 'GET',
  needAuth: true,
  responseMapper: {
    subs: {
      key: 'items',
      transform: (value, srcObj) => value.map(item => _transform(item, srcObj)),
    },
  },
})

function _transform(item, srcObj) {
  const token = srcObj.thumbnail_token
  return {
    id: item.id,
    name1: item.name1,
    name2: item.name2,
    name1Kana: item.name1_kana,
    name2Kana: item.name2_kana,
    relation: item.relation,
    attribute: item.attribute,
    faces: item.faces.map(face => {
      let status = face.detection.is_ok ? 'ok' : 'ng'
      if (!face.detection.is_done) status = 'doing'
      return {
        id: face.id,
        index: face.index,
        thumbnailUrl: `${face.thumbnail_url}&token=${token}`,
        status,
      }
    }),
  }
}
