// セキュリティコードについての説明ダイアログ

<template lang="pug">
  .security-code-detail
    h3 セキュリティコードとは
    p
      | クレジットカード番号の不正利用を防止するためにクレジットカードの裏面
      | または表面に記載された3桁もしくは4桁の番号です。
    ul
      li
        ps-row
          ps-col.first-col(:span='0')
            img(src='../../../assets/cvv-visa.png')
          ps-col
            ps-row(break-point='mobile', valign='center')
              ps-col
                p.description
                  | クレジットカード裏面の署名欄に記載されている番号になります。
                  | 右端3桁の番号を入力してください。
                  br
                  br
                  | 例)カード裏面の表記 1234 567
                  br
                  | ⇒実際の入力 567
              ps-col(:span='0')
                img(src='../../../assets/card-visa.png')
      li
        ps-row
          ps-col.first-col(:span='0')
            img(src='../../../assets/cvv-amex.png')
          ps-col
            ps-row(break-point='mobile', valign='center')
              ps-col
                p.description
                  | クレジットカード表面のクレジットカード番号の右上に記載されている番号になります。
                  br
                  | 4桁の番号を入力してください。
              ps-col(:span='0')
                img(src='../../../assets/card-amex.png')

    p ※セキュリティコードがわからない場合や印字されていない場合、カード発行会社にお問い合わせください。
</template>

<script>
export default {
  name: 'SecurityCodeDetail',
}
</script>

<style lang="sass" scoped>
@import '../../../sass/variables.sass'
@import '../../../sass/mixins.sass'

.security-code-detail
  > h3
    font-size: $size-normal
    font-weight: normal
    color: darken($blue, 10%)
    border-bottom: 1px dashed lighten($blue, 20%)
    padding-bottom: 0.2rem
    margin-bottom: 0.3rem
    +mobile
      display: none
  > p
    font-size: $size-small
    margin-bottom: 0.5rem
    line-height: 1.4

  > ul
    margin-bottom: 0.5rem
    li
      border-top: 1px solid $white-ter
      &:last-child
        border-bottom: 1px solid $white-ter
      .first-col
        background-color: rgba($green, 0.16)
        display: flex
        align-items: center
      .description
        font-size: $size-smallest
        margin: 0.5rem
      img
        margin: 0.5rem
</style>
