// PC用のサイドメニュー

<template lang="pug">
.side-menu
  .outer

    // カゴ情報
    ps-cart-info(
      :num='cartNum',
      :sum='cartSum',
      :remaining-message='remainingMessage',
      :benefit-message='benefitMessage',
      style='margin-bottom: 15px',
      :is-tax-excluded='isTaxExcluded',
      :longrun-info='longrunInfo'
    )
      ps-button(
        color='red',
        size='large',
        full-width,
        text-centered,
        @click='$router.push({ name: "OutroPageCart", params: { id: saleId } })',
        v-my-id='"go-to-cart"'
      )
        | 買い物カゴ&nbsp;
        ps-icon(icon='chevron-circle-right')

    //- お顔検索
    .faces-search(v-if='canSearchFaces')
      ps-button(
        v-if='!okaoNow',
        color='orange', full-width, text-centered,
        @click='$emit("startFaceSearch")'
      )
        ps-icon(icon='smile')
        | &nbsp;お顔検索
      ps-button(
        v-else,
        full-width, text-centered,
        @click='$emit("endFaceSearch")'
      )
        ps-icon(icon='reply')
        | &nbsp;お顔検索終了
    // 過去販売
    .old-sale(v-if='hasOldSale')

      ps-button(
        v-if='!isOldSale',
        color='dark-blue', full-width, text-centered,
        @click='$emit("startOldSale")'
      )
        ps-icon(icon='history')
        | &nbsp;以前の販売

      ps-button(
        v-else,
        color='dark-blue', full-width, text-centered,
        @click='$router.push({ name: "BrowsePage", params: { saleId: saleId } })'
      )
        ps-icon(icon='reply')
        | &nbsp;現在の販売に戻る

    // お顔検索中は表示しない
    template(v-if='!okaoNow')
      .category-title
        p
          span.clover
          | カテゴリー
          span.clover

      // カテゴリ
      .category
        ps-category(
          :category='categoryTree',
          :hidden-overdue-tag='!overdueCharge',
          v-model='currentPhotoIndex'
        )
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'SideMenu',

  computed: {
    ...mapGetters({
      role: 'app/role',
      saleId: 'sale/id',
      saleName: 'sale/name',
      saleEnd: 'sale/end',
      hasOldSale: 'sale/hasOldSale',
      // 税別か
      isTaxExcluded: 'sale/isTaxExcluded',
      // 年間販売の期間外販売手数料
      overdueCharge: 'sale/overdueCharge',
      cartNum: 'cart/num',
      cartSum: 'cart/sum',
      cartRemaining: 'cart/remainingToFree',
      currentShipmentFee: 'cart/currentShipmentFee',
      discontValue: 'cart/discontValue',
      // 写真枚数
      cartPhotoNum: 'cart/photoNum',
      // 期間外販売の写真の枚数
      cartOverdueNum: 'cart/overdueNum',
      // カート内の期間外販売手数料
      cartOverdueCharge: 'cart/overdueCharge',
      isOldSale: 'subSale/isOldSale',
      categoryTree: 'subSale/categoryTree',
      // お顔検索が利用可能か
      canSearchFaces: 'sale/canSearchFaces',
      // お顔検索利用中かどうか
      okaoNow: 'browsing/okaoNow',
    }),
    currentPhotoIndex: {
      get() {
        return this.$store.getters['browsing/currentPhotoIndex']
      },
      set(value) {
        this.$store.dispatch('browsing/setCurrentPhotoIndex', { index: value })
      },
    },
    // 後いくらで発送手数料無料となるかのメッセージ
    remainingMessage() {
      // カートが空なら表示しない
      if (!this.cartSum) return null
      // 残り1000円を切った段階で表示
      else if (!this.cartRemaining || this.cartRemaining > 1000) return null
      else return `あと<b>${this.cartRemaining}円</b>で<b>${this.currentShipmentFee}円</b>値引き`
    },
    benefitMessage() {
      // カートが空なら表示しない
      if (!this.cartSum) return null
      else if (!this.discontValue) return null
      else return `合計金額より<b>${this.discontValue}円</b>値引き!`
    },
    // 年間販売の概略
    longrunInfo() {
      if (!this.cartOverdueNum) return null
      return {
        normalPhotoNum: this.cartPhotoNum - this.cartOverdueNum,
        overduePhotoNum: this.cartOverdueNum,
        overdueCharge: this.cartOverdueCharge,
      }
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../../../sass/variables.sass'
@import '../../../sass/mixins.sass'

.side-menu
  position: fixed
  top: 50px
  right: 0
  bottom: 0
  width: 270px
  background-color: #fffec5
  > .outer
    position: absolute
    top: 10px
    right: 10px
    bottom: 10px
    left: 10px
    display: flex
    flex-direction: column

    > .faces-search, .old-sale
      margin-bottom: 1rem

    > .category-title
      text-align: center
      letter-spacing: 2px
      line-height: 1.5rem
      margin-bottom: 0.5rem
      .clover
        display: inline-block
        background-image: url('../../../assets/clover.png')
        background-repeat: no-repeat
        background-position: center center
        background-size: contain
        width: 1rem
        height: 1rem
        margin: 0 0.5rem
        vertical-align: text-top

    > .category
      flex-grow: 1
      // PCの時、スクロールバーとの隙間を少し開けるため
      padding-right: 5px
      overflow-y: auto
</style>
