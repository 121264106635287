// メールアドレス登録ページ

<script>
import { mapActions } from 'vuex'

import sendActivationMailApi from '../../../../api/public/send-activation-mail'
import term from '../../../../helpers/term'

import HelpDetail from '@/dialogs/contents/help-detail'
// メール設定についてのヘルプのId
const mailSettingHelpId = 4

export default {
  name: 'IntroPageRegister',

  data() {
    return {
      name1: null,
      name2: null,
      email: null,
      agreement: false,
      loading: false,
      errors: {},
    }
  },

  computed: {
    // 次へすすめる状態かどうか
    isValid() {
      return this.name1 && this.name2 && this.email && this.agreement
    },
    term() {
      return term
    },
  },

  methods: {
    ...mapActions({
      setError: 'app/setError',
    }),

    // メール設定の方法を表示
    showHelpDetailDialog() {
      this.$psdialog.open({
        title: 'メールの受信設定について',
        component: HelpDetail,
        props: { id: mailSettingHelpId },
        size: 'l',
        closeByClickBg: true,
        closeButton: true,
      })
    },

    goToPrev() {
      this.$router.push({ name: 'TopPage' })
    },

    async goToNextAsync() {
      if (!this.isValid) return

      this.errors = {}
      this.loading = true

      let response
      try {
        response = await sendActivationMailApi(null, {
          name1: this.name1,
          name2: this.name2,
          email: this.email,
        })
      } catch (e) {
        this.setError(e)
        return
      }
      this.loading = false

      if (response.ok) {
        this.$router.push({
          name: 'IntroPageActivation',
          params: { token: response.payload.token },
          query: { email: this.email },
        })
      } else {
        const message = _.get(response, 'payload.message')
        if (/registered/.test(message)) {
          this.errors.email =
            '登録済みのメールアドレスです。トップページの「ログイン」よりお進みください。'
        } else if (/unknown/.test(message)) {
          this.errors.email =
            '予期せぬエラーが発生しました。お手数ですが、しばらくしてから再度お試しください。'
        } else {
          this.errors.email = 'メールアドレスが正しくありません。'
        }
        this.$scrollBodyToAsync(0)
      }
    },
  },
}
</script>

<template lang="pug">
.intro-page-register

  .inner

    ps-dots-heading メールアドレスの確認

    article.user-info
      ps-row(:gutter='40', break-point='mobile')

        ps-col.first
          ps-medium-heading 情報の入力
          p
            | 確認に必要な情報を入力してください。
            | ご入力いただくメールアドレスに確認メールを送信します。
          .form
            // 姓・名            
            ps-form-item(label='お名前', required, :error='errors.name')
              ps-row(:gutter='10')
                ps-col(:span='0')
                  ps-input(placeholder='姓', full-width, ime-mode='active', v-model='name1')
                ps-col(:span='0')
                  ps-input(placeholder='名', full-width, ime-mode='active', v-model='name2')
            // メールアドレス
            ps-form-item(label='メールアドレス', required, :error='errors.email')
              ps-input(placeholder='(例)taro@example.com', type='email', full-width, ime-mode='disabled', v-model='email')

        ps-col.second(:span='0')
          .please-reading
            h3
              ps-icon(icon='info-circle')
              | &nbsp;お読みください
            p
              | 迷惑メールフィルターにより「確認メール」が届かない場合があります。
              | あらかじめ「ドメイン指定受信登録」に「11s.jp」を設定し、
              | このサイトからのメールを受信できるようにしておいてください。

    article.term
      ps-medium-heading ご利用規約
      p 利用規約をご確認のいただき、同意いただけましたらチェックボックスへチェックを入れてお進みください。
      .term-body
        h2 写真販売サイト「フォトいいね」利用規約
        ul
          li(v-for='item, i in term', :key='i')
            h3 {{ item.title }}
            template(v-for='desc in item.descriptions')
              p(v-if='desc.type === "text"') {{ desc.value }}
              ul(v-else-if='desc.type === "list"')
                li(v-for='listItem, k in desc.value', :key='k') {{ listItem }}

    article.buttons
      ps-agreement.agreement(v-model='agreement') 「ご利用規約」に同意します。
        p(slot='content')
          ps-button(size='large', color='orange', :disabled='!isValid', :loading='loading', min-width='16rem', text-centered, @click='goToNextAsync')
            | メールを送信する&nbsp;
            ps-icon(icon='chevron-circle-right')
      p      
        ps-button(size='large', @click='goToPrev')
          ps-icon(icon='chevron-circle-left')
          | &nbsp;戻る
  </template>

<style lang="sass" scoped>
@import '../../../../sass/variables'
@import '../../../../sass/mixins'

.intro-page-register

  .inner

    background-color: $white
    padding: 1.5rem 1rem
    border-radius: $radius-small
    box-shadow: 0 1px 6px 2px rgba(0, 0, 0, .25)
    +mobile
      padding: 1rem 0.5rem
      box-shadow: 0 1px 1px 1px rgba(0, 0, 0, .3)

    > article
      margin-bottom: 3rem
      &:last-child
        margin-bottom: 1.5rem

      &.user-info

        .first

          > p

            margin-bottom: 1rem
            line-height: 1.4

          +mobile
            .form
              margin-bottom: 1rem

        .second

          .please-reading
            border-radius: $radius-small
            background-color: lighten(#e4f7b7, 5%)
            padding: 0.5rem
            +tablet
              width: 350px
            h3
              font-weight: normal
              font-size: $size-normal
              text-align: center
              color: $black-bis
              letter-spacing: 1px
              margin-bottom: 0.4rem
            p
              font-size: $size-small
              line-height: 1.4
              color: $grey-darker
            a
              color: darken($blue, 10%)
              text-decoration: underline
              cursor: pointer

      &.term
        > p
          margin-bottom: 1rem
          line-height: 1.4
        .term-body
          margin-bottom: 1rem

      &.buttons
        > .agreement
          margin-bottom: 1.5rem
          p
            text-align: center
            padding: 0.5rem 0 1.5rem
        > p
          text-align: center

    .term-body
      max-height: 12rem
      overflow-y: auto
      border: 1px solid #ccc
      padding: 1rem
      h2
        font-size: 1.2rem
      ul
        > li
          margin-top: 2rem
          h3
            font-size: 1.1rem
            border-bottom: 1px dashed #ccc
            padding: 0.2rem 0
            letter-spacing: 0.05rem
          p
            margin-top: 0.5rem
            line-height: 1.3
          ul
            margin-top: 0.5rem
            li
              list-style-type: disc
              list-style-position: inside
              margin-left: 0.5rem
              margin-top: 0.5rem
              &:first-child
                margin-top: 0
</style>
