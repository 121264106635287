// トリミングの結果確認ダイアログ

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'TrimmingConfirmation',

  props: {
    orientation: String,
    zoom: Number,
    printLongEdge: Number,
    printShortEdge: Number,
    customId: Number,
    // この写真がすでにカゴに入っているか。
    // この値によってボタンの表記を切り替える
    inCart: Boolean,
    // 一部のサーバ環境で、トリミング画像がまだ出来ておらず、
    // 読み込み時404が表示されることがあるので、
    // その対策として、8秒後に再度トリミング画像を取りに行く
    loadImageDouble: Boolean,
  },

  data() {
    return {
      timestamp: Date.now(),
    }
  },

  created() {
    if (this.loadImageDouble) {
      _.delay(() => {
        this.timestamp = Date.now()
      }, 8000)
    }
  },

  computed: {
    ...mapGetters({
      token: 'cart/token',
      saleId: 'sale/id',
      baseUrl: 'sale/customThumbnailBaseUrl',
      screenType: 'app/screenType',
    }),

    url() {
      return `${this.baseUrl}${this.saleId}/${this.customId}/l.jpg?token=${this.token}&${this.timestamp}`
    },
  },

  methods: {
    close(type) {
      this.$psdialog.close({ type })
    },
  },
}
</script>

<template lang="pug">

.trimming-confirmation

  h1 トリミング完了

  // 写真表示部分
  .main
    .wrapper
      ps-fitting-photo(
        :long-edge='printLongEdge',
        :short-edge='printShortEdge',
        :zoom='zoom',
        :orientation='orientation',
        :url='url',
        adjust-before-update
      )

  // ボタン群
  .buttons

    // モバイル用のボタン
    template(v-if='screenType === "mobile"')
      div.first
        ps-button(color='orange', size='large', full-width, text-centered, @click='close("ok")')
          | {{ inCart ? '確定する' : 'カゴに追加' }}

      ps-row(:gutter='5', halign='center')
        ps-col(:span='0')
          ps-button(color='purple', @click='close("retry")') やり直し
        ps-col(:span='0')
          ps-button(@click='close("cancel")') 中止する

    // PC用のボタン
    template(v-else)  
      ps-row(:gutter='10', halign='center')
        ps-col(:span='0')
          ps-button(color='orange', large-width, @click='close("ok")')
            | {{ inCart ? '確定する' : 'カゴに追加' }}
        ps-col(:span='0')
          ps-button(color='purple', @click='close("retry")') やり直し
        ps-col(:span='0')
          ps-button(@click='close("cancel")') 中止する

</template>

<style lang="sass" scoped>
@import '../../../sass/variables.sass'
@import '../../../sass/mixins.sass'

.trimming-confirmation
  display: flex
  flex-direction: column
  width: 100%
  height: 100%

  > h1
    font-size: $size-normal
    background-color: $lime
    color: $white
    text-align: center
    padding: 0.3rem
    border-radius: $radius

  > .main
    flex-grow: 1
    position: relative
    margin: 10px 0
    .wrapper
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%

  > .buttons
    .first
      margin-bottom: 5px
</style>
