// 注文情報のレスポンスのマッパ

import dateHelper from '@/helpers/date-helper'
import normalizeSmbcOrderStatus from '@/helpers/normalize-smbc-order-status'

export default {
  code: 'code',
  'shop.name': 'shopName',
  'shop.id': 'shopId',
  'org.name': 'orgName',
  'org.id': 'orgId',
  'sale.name': 'saleName',
  'sale.id': 'saleId',
  'sale.options.photo.print_type': 'printType',
  'payment.name': 'paymentTypeName',
  'payment.price': 'price',
  'payment.code': 'paymentCode',
  'payment.options.codes[0]': 'paymentPouchCode',
  'payment_options.conveni.name': 'paymentConveniStores',
  'payment_options.conveni.type': 'paymentSubCode',
  'payment_options.conveni.payment.expires': {
    key: 'paymentExpiredAt',
    transform: value => (value ? dateHelper.toJpYYYYMMDD(value) : null),
  },
  'payment_options.conveni.payment.receiptNo': 'paymentConveniCode',
  'payment_options.conveni.payment.url': 'paymentConveniExternalUrl',
  'payment_options.conveni.payment.corpCode': 'paymentConveniCorpCode',
  details: [
    'orderDetails',
    {
      key: 'num',
      transform: value => _.sumBy(value, o => parseInt(o.memo, 10) || 0),
    },
  ],
  statuses: [
    {
      key: 'orderedAt',
      transform: value => {
        const datetime = _.last(value).datetime
        return dateHelper.toJpYYYYMMDD(datetime)
      },
    },
    {
      key: 'status',
      transform: (value, obj) => {
        const paymentTypeCode = obj.payment.code
        const statusCode = _.first(value).code
        return normalizeSmbcOrderStatus(paymentTypeCode, statusCode)
      },
    },
  ],
  // 注文完了画面のコンビニ決済選択時に表示する
  'customer.tel': 'customerTel',
  'dest.zipcode': 'zipcode',
  'dest.tel': 'tel',
  dest: [
    {
      key: 'name',
      transform: value => `${value.name1} ${value.name2}`,
    },
    {
      key: 'address1',
      transform: value => value.prefecture + value.address1,
    },
    {
      key: 'address2',
      transform: value => value.address2 + value.address3,
    },
    // この販売が一括か集金袋かといった情報がAPIにはないので、destの内容で無理やり判定
    {
      key: 'shippingType',
      transform: (value, obj) => {
        // 集金袋ならshippingTypeは2(一括)
        if (_.get(obj, 'payment.code') === 'pouch') return 2
        else if (!value || !value.name1) return 2
        else return 1
      },
    },
  ],
}
