// 日付周りのヘルパ関数群

export default {
  toJpYYYYMMDD,
  toJpMMDD,
  getCalendar,
  parseDateString,
}

const daysOfWeek = ['日', '月', '火', '水', '木', '金', '土']

// StringかNumberならDateに変換、それ以外ならそのまま返す
function _toDate(param) {
  // 2017-07-07T00:00:00 の形式は、
  // iOSおよびmacのsafariにて、その時刻にさらに+9:00されてしまうので、
  // 2017/07/07 00:00:00 の形式に変換
  if (_.isString(param)) return new Date(param.replace(/-/g, '/').replace('T', ' '))
  else if (_.isNumber(param)) return new Date(param)
  else return param
}

// 年 月 日 に変換
function toJpYYYYMMDD(param, withDayOfWeek = false) {
  const d = _toDate(param)
  const yyyymmdd = `${d.getFullYear()}年${d.getMonth() + 1}月${d.getDate()}日`
  if (withDayOfWeek) return `${yyyymmdd}(${daysOfWeek[d.getDay()]})`
  else return yyyymmdd
}

// 月 日 に変換
function toJpMMDD(param, withDayOfWeek = false) {
  const d = _toDate(param)
  const mmdd = `${d.getMonth() + 1}月${d.getDate()}日`
  if (withDayOfWeek) return `${mmdd}(${daysOfWeek[d.getDay()]})`
  else return mmdd
}

// カレンダーの配列の取得
function getCalendar(year, month) {
  const mm = ('0' + month).slice(-2)
  // iOS safari では `${year}-${mm}-01T00:00:00+0900` はパースできないので下記の様に変更
  const startStr = `${year}/${mm}/01 00:00:00`
  const d = new Date(startStr)

  // 月曜日始まりとした時の開始位置オフセット
  const offsetForward = (d.getDay() + 6) % 7
  // 月末の日付を求める。monthは翌月を表す。翌月の0日は今月の月末
  d.setMonth(month)
  d.setDate(0)
  const endDate = d.getDate()
  const offsetBackward = (7 - ((endDate + offsetForward) % 7)) % 7

  // 開始曜日 - 1 個分のパディング
  const paddingForward = _.fill(Array(offsetForward), null)
  const paddingBackward = _.fill(Array(offsetBackward), null)

  // 7日単位で配列に
  return _.chunk(_.concat(paddingForward, _.range(1, endDate + 1), paddingBackward), 7)
}

// '2017-4-20' のような形式をパース
function parseDateString(str, delimiter = '-') {
  if (!str) return { year: '----', month: '--', date: '--', jpYear: '令和--' }
  const [year, month, date] = str.split(delimiter).map(value => parseInt(value, 10))
  let jpYear
  // 2019/4 までは平成
  if (year <= 2018 || (year === 2019 && month <= 4)) {
    jpYear = `平成${year - 1988}`
  } else {
    const yearNum = year - 2018
    jpYear = `令和${yearNum === 1 ? '元' : yearNum}`
  }
  return { year, month, date, jpYear }
}
