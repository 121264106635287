// お子様の属性値を更新

import apiFactory from '../api-factory'

export default apiFactory({
  path: '/my/subs/<%= id %>/attributes',
  method: 'PUT',
  expectedParams: ['id'],
  needAuth: true,
  bodyMapper: {
    fiscalYear: 'fiscal_year?',
    orgId: 'org_id?',
    grade: 'grade_index?',
    attribute: 'attribute?',
  },
})
