// 販売に対して認可をもらう

import apiFactory from '../api-factory'

export default apiFactory({
  path: '/sales',
  method: 'POST',
  needAuth: true,
  bodyMapper: {
    code1: {
      key: 'code',
      transform: (value, srcObj) => `${value}::${srcObj.code2}`,
    },
  },
  responseMapper: {
    'sale.id': 'id',
    'sale.name': 'name',
    'sale.org_name': 'orgName',
    'sale.shop_name': 'shopName',
  },
})
