// お顔検索用写真一覧

<template lang="pug">
.okao-photo-list

  .heading
    h3 {{ orgName }}
    h2
      | {{ subSaleName }}
      span(v-if='isOldSale') (過去の販売)
    p(v-if='!isLongrun')
      | 公開期間
      span {{ saleEnd }}
      | まで

  .main
    .result
      p
        | お顔検索結果:&nbsp;
        b {{ totalCount }}
        | 件

    // 写真一覧本体
    // 複数の仮想的なページを持つ
    section.pages(ref='pages')
      ps-photo-page(
        :items='items',
        :size='displayPhotoSize',
        :cartMap='cartPhotosMap',
        :customsMap='customsMap',
        add-to-cart-button-color='orange',
        @clickPhoto='$emit("clickPhoto", arguments[0])',
        @addToCart='addToCart(arguments[0])',
        @removeFromCart='removeFromCart(arguments[0])',
        :overdue-charge='overdueCharge'
      )

    // 後ろを読み込むボタン
    footer(v-if='items.length > 0')
      a.last-photo
        ps-icon(icon='info-circle')
        | &nbsp;最後の写真です

</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'OkaoPhotoList',

  computed: {
    ...mapGetters({
      orgName: 'sale/orgName',
      saleEnd: 'sale/end',
      bannerUrl: 'sale/bannerUrl',
      subSaleName: 'subSale/name',
      isOldSale: 'subSale/isOldSale',
      displayPhotoSize: 'browsing/displayPhotoSize',
      cartPhotosMap: 'cart/photosMap',
      customsMap: 'cart/customsMap',
      totalCount: 'face/totalCount',
      items: 'face/photos',
      // 年間販売かどうか
      isLongrun: 'sale/isLongrun',
      // 年間販売の期間外販売手数料
      overdueCharge: 'sale/overdueCharge',
    }),
  },

  mounted() {
    // お顔検索前のスクロール位置になってしまうのでトップへ戻す。
    this.$scrollBodyToAsync(0)
  },

  methods: {
    ...mapActions({
      addPhotoToCartAsync: 'cart/addPhotoAsync',
      removePhotoFromCartAsync: 'cart/removePhotoAsync',
      setError: 'app/setError',
    }),

    async addToCart(item) {
      try {
        await this.addPhotoToCartAsync({
          photoId: item.id,
          num: 1,
          sizeId: item.sizePrices[0].sizeId,
          silent: true,
        })
      } catch (e) {
        this.setError(e)
      }
    },

    async removeFromCart(item) {
      try {
        this.removePhotoFromCartAsync({ photoId: item.id, silent: true })
      } catch (e) {
        this.setError(e)
      }
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../../../sass/variables.sass'
@import '../../../sass/mixins.sass'
$brown: darken($orange, 40%)

.okao-photo-list

  > .heading
    line-height: 1.5
    padding: 0.8rem 0.5rem
    background-color: rgba($white, .75)
    h3
      font-size: $size-normal
      font-weight: normal
    h2
      font-size: $size-medium
    p
      font-size: $size-small
      color: $red
      span
        display: inline-block
        margin: 0 5px
        padding: 0 2px
        background-color: $red
        color: $white
        border-radius: $radius-small

  > .main
    padding: 10px
    > .result
      p
        background: lighten($pink, 10%)
        padding: 0.3rem
        text-align: center
        border-radius: $radius
        margin-bottom: 0.3rem

    > header, > footer
      a
        display: block
        color: $black-ter
        border: 1px solid rgba(#3e342a, 0.2)
        background-color: rgba(#3e342a, 0.15)
        padding: 0.3rem
        border-radius: $radius-small
        text-align: center
        margin: 10px 0
        cursor: pointer

        // 最後の写真
        &.last-photo
          border: 1px solid rgba($pink, 0.6)
          background-color: rgba($pink, 0.15)
          color: $red
</style>
