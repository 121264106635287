// はじめての方用の一連のページ共通部分

<script>
import { mapGetters } from 'vuex'
import HelpDetail from '@/dialogs/contents/help-detail'

const steps = ['メールアドレス確認', '確認コード入力', '閲覧用コード入力']
const stepNums = { IntroPageRegister: 1, IntroPageActivation: 2, IntroPageAuthorization: 3 }
// ヘルプのドキュメントのIdのマップ
const helpDocIdMap = {
  IntroPageRegister: {
    id: 1,
    title: 'メールアドレス確認について',
  },
  IntroPageActivation: {
    id: 2,
    title: '確認コード入力について',
  },
  IntroPageAuthorization: {
    id: 3,
    title: '閲覧用コード入力について',
  },
}

export default {
  name: 'IntroPage',

  data() {
    return {
      steps,
    }
  },

  computed: {
    ...mapGetters({
      screenType: 'app/screenType',
    }),
    // 現在、何ステップ目かを返す(1スタート)
    currentStepNum() {
      return stepNums[this.$route.name]
    },
  },

  methods: {
    clickHelp() {
      const content = helpDocIdMap[this.$route.name]
      this.$psdialog.open({
        title: content.title,
        component: HelpDetail,
        props: { id: content.id },
        size: 'l',
        closeByClickBg: true,
        closeButton: true,
      })
    },
  },
}
</script>

<template lang="pug">
.intro-page

  // ヘッダ
  header
    common-header(:show-menu='false')

  // ステップの表示
  .steps
    ps-container
      ps-steps(:steps='steps', :current='currentStepNum')

  section
    ps-container
      // ここに実際のページ内容が表示される
      router-view

  common-footer.footer
</template>

<style lang="sass" scoped>
@import '../../sass/variables.sass'
@import '../../sass/mixins.sass'

.intro-page

  > .steps
    background-color: $white
    padding: 0.5rem 0
    margin-bottom: 1.5rem

  > section
    margin-bottom: 5rem
    min-height: 500px

  .footer
    position: absolute
    bottom: 0
    left: 0
    right: 0
</style>
