// パスワードの変更

import apiFactory from '../api-factory'

export default apiFactory({
  path: '/my/password',
  method: 'PUT',
  needAuth: true,
  bodyMapper: {
    old: 'passwd',
    new: 'new_passwd',
  },
})
