// お知らせの詳細表示ダイアログ

<script>
import getInfoDetailApiAsync from '@/api/public/get-info-detail'

export default {
  name: 'InfoDetail',

  props: {
    id: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      detail: null,
      loaded: false,
    }
  },

  created() {
    this.fetchDetailAsync()
  },

  methods: {
    async fetchDetailAsync() {
      this.loaded = false
      const response = await getInfoDetailApiAsync({ id: this.id }, null, true)

      if (!response.ok) {
        this.loaded = true
        return
      }

      this.detail = response.payload
      this.loaded = true
    },
  },
}
</script>

<template lang="pug">
.info-detail

  .loading(v-if='!loaded')
    ps-loading
    
  template(v-else)
    h1 {{ detail.title }}
    p.date {{ detail.date }}
    article.content(v-html='detail.html')

  .buttons
    ps-button(@click='$psdialog.close') 閉じる
</template>

<style lang="sass" scoped>
@import '../../../sass/variables.sass'

.info-detail
  > .loading
    height: 200px
    position: relative

  > h1
    border-bottom: 2px dotted lighten($blue, 20%)
    font-size: $size-large
    padding-bottom: 0.2rem
    font-weight: normal
    margin-bottom: 0.5rem

  > .date
    margin-bottom: 0.5rem

  > .content
    margin-bottom: 1rem

  > .buttons
    text-align: center
</style>
