// 登録のメリットを表示するダイアログ

<script>
import PasswordRegister from '../password-register'

export default {
  name: 'RegisterAdvantages',

  methods: {
    close(backToRegister) {
      this.$psdialog.close()
      // パスワード登録画面へ
      if (backToRegister) {
        this.$psdialog.open({
          title: 'パスワードの登録',
          component: PasswordRegister,
          size: 'm',
        })
      }
    },
  },
}
</script>

<template lang="pug">
.register-advantages
  p.desc パスワードを登録しない場合、次の機能がご利用いただけません。

  ul
    li
      ps-row(:gutter='10', valign='center')
        ps-col(:span='1')
          img(src='../../../assets/advantage-1.png')
        ps-col(:span='4')
          p ログアウトすると買い物カゴの中身がクリアされ、閲覧を再開することができません。
    li
      ps-row(:gutter='10', valign='center')
        ps-col(:span='1')
          img(src='../../../assets/advantage-2.png')
        ps-col(:span='4')
          p 別のスマートフォンやパソコンで続きを閲覧することができません。
    li
      ps-row(:gutter='10', valign='center')
        ps-col(:span='1')
          img(src='../../../assets/advantage-4.png')
        ps-col(:span='4')
          p マイページ(ご購入履歴等)がご利用になれません。

  .buttons
    ps-button(size='large', color='blue', full-width,
      text-centered, @click='close(true)') パスワードを登録する
    ps-button(size='small', full-width,
      text-centered, @click='close(false)') パスワードを登録しないで閲覧に進む
</template>

<style lang="sass" scoped>
@import '../../../sass/variables.sass'
@import '../../../sass/mixins.sass'

.register-advantages

  p.desc
    margin-bottom: 1rem

  ul
    margin-bottom: 1rem
    li
      border: 3px solid lighten(#ff8caf, 10%)
      border-radius: $radius-small
      background-color: $white
      padding: 10px
      margin-bottom: 0.5rem
      &:last-child
        margin-bottom: 0
      img
        width: 100%
        height: auto
      p
        line-height: 1.4

  .buttons
    max-width: 500px
    margin-left: auto
    margin-right: auto
    > :first-child
      margin-bottom: 0.5rem
</style>
