// 新しい(2020年度〜)のお子様リスト

<template lang="pug">
.new-chld-list

  p この園・学校に所属のお子様の情報を更新してください。

  h3 &lt;お子様情報の一覧&gt;

  ul

    li(v-for='child in children')

      .child
        ps-icon(icon='user-circle')
        p {{ child.name1 }} {{ child.name2 }} 様
        p
          a(
            @click='editChild(child)'
          ) [編集]
          a(
            @click='removeChildAsync(child)'
          ) [削除]

      .info
        div
          p 今年度の学年:
          ps-select(
            :items='grades',
            v-model='child.grade',
            @input='child.attribute = null'
            full-width
          )
        div
          p 今年度のクラス:
          ps-select(
            :items='getClasses(child)'
            v-model='child.attribute',
            full-width
          )

  .buttons
    .left
      ps-button(
        @click='showNewChildInfo',
        color='blue'
      ) お子様情報の追加
    .right
      ps-button(
        @click='updateChildren',
        color='green'
      ) 更新する
</template>

<script>
import { mapActions } from 'vuex'

import updateChildAttributesApiAsync from '@/api/user/update-child-attributes'
import getChildrenByOrgIdAndYearApiAsync from '@/api/user/get-children-by-org-id-and-year'
import removeChildApiAsync from '@/api/user/remove-child'

import NewChildInfo from '../new-child-info'

import attributesUtil from '@/helpers/new-attributes-util'

export default {
  name: 'NewChildList',

  props: {
    attributes: {
      type: Object,
    },
    fiscalYear: {
      type: Number,
    },
    orgId: {
      type: Number,
    },
  },

  data() {
    return {
      children: [],
    }
  },

  computed: {
    grades() {
      return [
        { label: '選択してください...', value: null },
        ...attributesUtil.getGrades(this.attributes),
      ]
    },
    allClasses() {
      return attributesUtil.getAllClasses(this.attributes)
    },
  },

  created() {
    this.initAsync()
  },

  methods: {
    ...mapActions({
      setError: 'app/setError',
    }),

    async initAsync() {
      // お子様情報の取得
      const res = await getChildrenByOrgIdAndYearApiAsync({
        orgId: this.orgId,
        fiscalYear: this.fiscalYear,
      })
      if (!res.ok) return

      const grades = _.map(attributesUtil.getGrades(this.attributes), item => item.value)

      this.children = _.map(res.payload.items, child => {
        const grade =
          grades.indexOf(child.grade.value) !== -1
            ? // 一旦文字列に
              `${child.grade.value}`
            : null
        const attribute =
          _.map(this.allClasses, item => item.value).indexOf(child.attribute) !== -1
            ? child.attribute
            : null
        return {
          ...child,
          grade,
          attribute,
        }
      })

      // [編集]でクラスを編集の際、UI側に反映されないので、無理やり情報を再挿入
      this.$nextTick(() => {
        this.children = _.map(this.children, child => child)
      })
    },

    // 学年でフィルタリングされたクラスの取得
    getClasses(child) {
      return [
        { label: '選択してください...', value: null },
        ...attributesUtil.getClasses(this.attributes, parseInt(child.grade, 10), [child.attribute]),
      ]
    },

    // すべてのお子様の情報を更新
    async updateChildren() {
      try {
        for (const child of this.children) {
          // 学年とクラスがないものはスキップ
          if (!child.grade || !child.attribute) continue
          await updateChildAttributesApiAsync(
            { id: child.id },
            {
              fiscalYear: this.fiscalYear,
              orgId: this.orgId,
              grade: parseInt(child.grade, 10),
              attribute: child.attribute,
            }
          )
        }
        this.$psdialog.close()
      } catch (e) {
        this.setError(e)
      }
    },

    // お子様情報の追加ダイアログの表示
    showNewChildInfo() {
      try {
        this.$psdialog.open({
          title: 'お子様(参加者)情報の追加',
          component: NewChildInfo,
          size: 'm',
          closeButton: true,
          props: {
            attributes: this.attributes,
            fiscalYear: this.fiscalYear,
            orgId: this.orgId,
          },
          onClose: params => {
            if (params && params.updated) {
              this.initAsync(true)
            }
          },
        })
      } catch (e) {
        this.setError(e)
      }
    },

    // お子様情報の更新
    editChild(child) {
      this.$psdialog.open({
        title: 'お子様(参加者)情報の編集',
        component: NewChildInfo,
        size: 'm',
        closeButton: true,
        props: {
          attributes: this.attributes,
          fiscalYear: this.fiscalYear,
          orgId: this.orgId,
          targetId: child.id,
          targetValues: {
            ...child,
            grade: child.grade ? parseInt(child.grade, 10) : null,
          },
        },
        onClose: params => {
          if (params && params.updated) {
            this.initAsync(true)
          }
        },
      })
    },

    // お子様情報の削除
    async removeChildAsync(child) {
      if (!confirm('削除してもよろしいですか？')) return
      const params = { id: child.id }
      let response
      try {
        response = await removeChildApiAsync(params)
      } catch (e) {
        this.setError(e)
        return
      }
      if (!response.ok) return
      await this.initAsync(true)
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../../../sass/variables.sass'

.new-chld-list

  > h3
    margin-top: 0.7rem
    font-size: $size-small
    text-align: center
    color: $blue

  > ul
    margin: 0.7rem auto 0
    max-width: 550px
    li
      margin-top: 1rem
      padding: 0.6rem 1rem
      &:first-child
        margin-top: 0
      border: 2px solid lighten($blue, 20%)
      border-radius: 5px
      .child
        display: flex
        align-items: center
        > :nth-child(1)
          color: $lime
          font-size: 1.8rem
        > :nth-child(2)
          flex: 1
          margin-left: 0.5rem
          font-size: $size-medium
          color: $grey-darker
          font-weight: bold
        > :nth-child(3)
          font-size: $size-small
          color: darken($blue, 10%)
          cursor: pointer
          text-decoration: underline
          > :nth-child(2)
            margin-left: 0.3rem
      .info
        margin-top: 0.5rem
        padding: 0.5rem
        border: 1px solid $orange
        border-radius: 5px
        background-color: lighten($yellow, 45%)
        p
          color: $black-bis
          font-size: $size-small
          margin-bottom: 0.2rem
        > :nth-child(2)
          margin-top: 0.3rem

  > .buttons
    margin-top: 1.5rem
    display: flex
    justify-content: center
    > :nth-child(2)
      margin-left: 1rem
</style>
