// 各コンポーネントをグローバルに登録するためのVue.jsプラグイン

import PsPaymentForm from './ps-payment-form'
import PsTerm from './ps-term'
import PsMobileMenuBase from './ps-mobile-menu-base'

// 以下追加分
import CommonHeader from './common-header'
import CommonFooter from './common-footer'
import FixedNav from './fixed-nav'
import SlideInMenu from './slide-in-menu'
import IinePaymentForm from './iine-payment-form'

const components = [
  PsPaymentForm,
  PsTerm,
  PsMobileMenuBase,
  // 以下追加分
  CommonHeader,
  CommonFooter,
  FixedNav,
  SlideInMenu,
  IinePaymentForm,
]

export default {
  // プラグインに必須のinstallメソッド
  install(Vue) {
    // グローバルに各コンポーネントを登録
    components.forEach(component => {
      Vue.component(component.name, component)
    })
  },
}
