// Swaggerが返すSCHEMA_VALIDATION_FAILED をパースする
// errorCodeMap に任意のオブジェクトを渡すことで、
// そのオブジェクトにエラー情報をマージすることもできる。

export default parseSwaggerSchemaError

const defaultMessage = '入力に誤りがあります。'

function parseSwaggerSchemaError(body, errorCodeMap = {}) {
  const errors = _.get(body, 'results.errors')
  if (!errors) return {}

  errors.forEach(error => {
    // すでにそのパスにエラーがあるならスキップ
    if (_.get(errorCodeMap, error.path)) return
    // エラーをセット
    _.set(errorCodeMap, error.path, {
      code: error.code,
      message: defaultMessage,
      fullPath: error.path.join('/'),
    })
  })

  return errorCodeMap
}
