// 閲覧画面で使用されるのメニュー

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'NormalMenu',

  props: {
    inCart: Boolean,
    trimmable: Boolean,
    trimmed: Boolean,
  },

  computed: {
    ...mapGetters({
      screenType: 'app/screenType',
      role: 'app/role',
    }),
    isGuest() {
      return this.role === 'guest'
    },
  },
}
</script>

<template lang="pug">
.normal-menu(:class='[screenType]')
  
  // モバイル用
  template(v-if='screenType === "mobile"')
    .first
      ps-button(v-if='!inCart', color='orange', full-width,
        text-centered, size='large', @click='$emit("addToCart")')
        ps-icon(icon='plus-circle')
        | &nbsp;カゴに追加
      ps-row.remove-item(v-else, :gutter='5', valign='stretch')
        ps-col.message
          p
            ps-icon(icon='check')
            | &nbsp;カゴに追加済み
        ps-col(:span='0')
          ps-button(
            size='large', @click='$emit("removeFromCart")')
            ps-icon(icon='minus-circle')
            | &nbsp;削除
    .second
      ps-row(:gutter='5')
        // すでにギリギリの解像度でトリミングされた場合、
        // trimmable = false かつ trimmed = true となることを考慮
        ps-col(v-if='(trimmable || trimmed) && !isGuest')
          ps-button(v-if='!trimmed', color='purple', full-width,
            text-centered, @click='$emit("startTrimming")'
          ) トリミング
          ps-button(v-else, full-width, text-centered,
            @click='$emit("discardTrimming")'
          ) トリミング解除
        ps-col
          ps-button(full-width, text-centered, @click='$emit("close")') 閉じる

  // PC用
  template(v-else)
    .first
      div
        ps-icon-button(v-if='!inCart', icon='plus-circle', color='orange', full-width,
          @click='$emit("addToCart")') カゴに追加
        ps-icon-button(v-else, icon='minus-circle', full-width,
          @click='$emit("removeFromCart")') カゴから削除

      // トリミング関係
      // すでにギリギリの解像度でトリミングされた場合、
      // trimmable = false かつ trimmed = true となることを考慮
      div(v-if='(trimmable || trimmed) && !isGuest')
        ps-icon-button(
          v-if='!trimmed',
          icon='cut',
          color='purple',
          full-width,
          @click='$emit("startTrimming")'
        ) トリミング
        ps-icon-button(
          v-else,
          icon='reply'
          full-width,
          @click='$emit("discardTrimming")'
        ) トリミング解除

    .second
      ps-button(full-width, text-centered, @click='$emit("close")') 閉じる

</template>

<style lang="sass" scoped>
@import '../../../../sass/variables.sass'

.normal-menu
  &.mobile
    > .first
      margin-bottom: 5px

      .remove-item
        .message
          display: flex
          border: 1px solid lighten($red, 32%)
          color: $red
          align-items: center
          justify-content: center
          border-radius: $radius

  &:not(.mobile)
    display: flex
    height: 100%
    flex-direction: column
    > .first
      flex-grow: 1
      > div
        margin-bottom: 1rem
</style>
