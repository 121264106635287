// not found ページ

<script>
export default {
  name: 'PublicPageNotFound',
}
</script>

<template lang="pug">
.not-found-page

  p
    ps-icon.icon(icon='warning')
    br
    | ページが見つかりません。

  .buttons
    ps-button(
      color='green',
      size='large',
      @click='$router.push({ name: "TopPage" })'
    ) トップページへ

</template>

<style lang="sass" scoped>
@import '../../../../sass/variables.sass'

.not-found-page
  > p
    font-size: 1.5rem
    line-height: 1.5
    text-align: center
    margin-bottom: 2rem
    .icon
      color: $blue
      font-size: 3rem
      text-shadow: 1px 1px 2px $grey
  > .buttons
    text-align: center
</style>
