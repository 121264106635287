// エラーコード

// 認証やAJAX関連

// APIとの通信で発生したエラー
export const API_ACCESS_ERROR = 'API_ACCESS_ERROR'

// 認証エラー。JWTのリフレッシュの失敗も含む
export const API_AUTH_ERROR = 'API_AUTH_ERROR'

// APIのパラメタ不備
export const API_PARAMS_ERROR = 'API_PARAMS_ERROR'

// ユーザ関連

// ユーザ情報の取得エラー
export const GET_USER_INFO_ERROR = 'GET_USER_INFO_ERROR'

// お子様のリストの取得エラー
export const GET_CHILDREN_ERROR = 'GET_CHILDREN_ERRO'

// すでにパスワード登録ずみ
export const ALREADY_REGISTERED_PASSWORD_ERROR = 'ALREADY_REGISTERED_PASSWORD_ERROR'

// パスワード新規登録エラー
export const UNKNOWN_REGISTERED_PASSWORD_ERROR = 'UNKNOWN_REGISTERED_PASSWORD_ERROR'

// すでに使用済みのメールアクティベーションコード
export const ALREADY_USED_ACTIVATION_CODE_ERROR = 'ALREADY_USED_ACTIVATION_CODE_ERROR'

// 販売関連

// 販売情報の取得エラー
export const GET_SALE_INFO_ERROR = 'GET_SALE_INFO_ERROR'

// 販売の期間外エラー
export const SALE_IS_OUT_OF_TERM = 'SALE_IS_OUT_OF_TERM'

// 表示中の販売の情報の取得エラー
export const GET_SUB_SALE_INFO_ERROR = 'GET_SUB_SALE_INFO_ERROR'

// サイズ・価格設定の取得エラー
export const GET_SIZE_PRICE_ERROR = 'GET_SIZE_PRICE_ERROR'

// 販売で利用可能な支払い方法の取得エラー
export const GET_PAYMENT_METHODS_ERROR = 'GET_PAYMENT_METHODS_ERROR'

// 画像編集関連

// トリミングに失敗
export const TRIM_ERROR = 'TRIM_ERROR'

// トリミングの解除に失敗
export const DISCARD_TRIM_ERROR = 'DISCARD_TRIM_ERROR'

// 日付入れに失敗
export const DATE_ERROR = 'DATE_ERROR'

// 日付入れの解除に失敗
export const DISCARD_DATE_ERROR = 'DISCARD_DATE_ERROR'

// カゴ関連

// カゴ情報の取得エラー
export const GET_CART_INFO_ERROR = 'GET_CART_INFO_ERROR'

// カゴの数値更新エラー
export const UPDATE_CART_ERROR = 'UPDATE_CART_ERROR'

// オプション販売の数値更新エラー
export const UPDATE_OPT_ERROR = 'UPDATE_OPT_ERROR'

// オプション販売柄上限エラー
export const ADD_OPT_LIMIT_ERROR = 'ADD_OPT_LIMIT_ERROR'

// 注文関連のエラー

// バリデーション時にバリデーションエラー以外のエラーが発生した
export const VALIDATE_ORDER_ERROR = 'VALIDATE_ORDER_ERROR'

// クレジットカードのトークン生成エラー
export const ORDER_GENERATE_CART_TOKEN_ERROR = 'ORDER_GENERATE_CART_TOKEN_ERROR'

// クレジットカード決済エラー
export const ORDER_RETRYABLE_CREDIT_ERROR = 'ORDER_RETRYABLE_CREDIT_ERROR'

// クレジットカードの有効期限切れエラー
export const ORDER_RETRYABLE_CREDIT_EXPIRY = 'ORDER_RETRYABLE_CREDIT_EXPIRY'

// クレジットカード以外での決済連携API側でのエラー
export const ORDER_RETRYABLE_ERROR = 'ORDER_RETRYABLE_ERROR'

// 注文の確定に失敗した
export const ORDER_ERROR = 'ORDER_ERROR'

// 注文履歴関連

// 注文履歴の取得エラー
export const GET_ORDER_DETAIL_ERROR = 'GET_ORDER_DETAIL_ERROR'

// お顔検索関連

// 検索ジョブをエンキューした時のエラー
export const ENQUE_OKAO_JOB_ERROR = 'ENQUE_OKAO_JOB_ERROR'

// 検索中のエラー
export const POLL_OKAO_JOB_ERROR = 'POLL_OKAO_JOB_ERROR'

// 検索終了後の結果表示の際のエラー
export const GET_OKAO_PHOTOS_ERROR = 'GET_OKAO_PHOTOS_ERROR'

// いいねのお顔検索関連

// 検索に使用するお顔画像がない
export const FACE_IMAGE_NOT_SET_ERROR = 'FACE_IMAGE_NOT_SET_ERROR'

// 検索に失敗した
export const SEARCH_FACES_ERROR = 'SEARCH_FACES_ERROR'

// その他

// ローカルストレージなどをサポートしていない(古いブラウザの可能性)
export const BROWSER_NOT_SUPPORT_ERROR = 'BROWSER_NOT_SUPPORT_ERROR'

// Safariのプライベートブラウズの可能性
export const SAFARI_PRIVATE_BROWSE_ERROR = 'SAFARI_PRIVATE_BROWSE_ERROR'

// 通信エラー等その他のエラー
export const UNKNOWN_ERROR = 'default'
