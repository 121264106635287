// 共通で利用するヘッダ

<template lang="pug">
  header.common-header
    ps-row.row(valign='center', :gutter='15')

      ps-col
        h1
          img(src='../../assets/logo-white.png')

      // ユーザ情報
      ps-col(v-if='showMenu', :span='0')
        a.menu-item(@click='$emit("clickShowMenu")')
          .icon.menu
          p メニュー
</template>

<script>
export default {
  name: 'CommonHeader',

  props: {
    showMenu: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../../sass/variables.sass'
@import '../../sass/mixins.sass'

.common-header
  background-color: $orange
  padding: 0 10px
  .row
    min-height: 50px

    h1
      max-width: 200px
      img
        display: block
        width: 100%
        height: auto

    .user
      color: white
      .links
        font-size: $size-small

      +mobile
        display: none

  a.menu-item
    display: block
    cursor: pointer
    transition: all 1s

    .icon
      width: 25px
      height: 25px
      background-repeat: no-repeat
      background-position: center center
      background-size: contain
      margin: 0 auto 1px
      transition: all 1s
      &.menu
        background-image: url('../../assets/menu-white.png')
    p
      font-size: $size-smallest
      color: #fff

    &:hover
      opacity: 0.6
      .icon
        transform: rotate(15deg)
</style>
