// 元画像の切り取り位置を算出
// positionはtop, left, width, heightをパーセントで与えられる

export default function(item, position) {
  const defaultSize = item.sizePrices[0]

  // プリントの横幅と縦幅
  let printWidth = defaultSize.printLongEdge
  let printHeight = defaultSize.printShortEdge
  if (item.orientation !== 'landscape') {
    ;[printWidth, printHeight] = [printHeight, printWidth]
  }

  // プリントサイズと同比率の矩形をデータ内にフィットさせる際のスケール
  const scale =
    item.dataWidth / printWidth > item.dataHeight / printHeight
      ? item.dataHeight / printHeight
      : item.dataWidth / printWidth

  // プリントされる領域のピクセル数
  const validDataWidth = printWidth * scale
  const validDataHeight = printHeight * scale

  // 元データからのオフセット
  const outerOffsetX = (item.dataWidth - validDataWidth) / 2
  const outerOffsetY = (item.dataHeight - validDataHeight) / 2

  // マスキング(ズーム)後の実際に表示されている領域の元データにおけるピクセル数
  const visibleDataWidth = validDataWidth / item.zoom
  const visibleDataHeight = validDataHeight / item.zoom

  // プリントされる領域のピクセル数からのオフセット
  const innerOffsetX = (validDataWidth - visibleDataWidth) / 2
  const innerOffsetY = (validDataHeight - visibleDataHeight) / 2

  const orgX = (visibleDataWidth * position.left) / 100 + outerOffsetX + innerOffsetX
  const orgY = (visibleDataHeight * position.top) / 100 + outerOffsetY + innerOffsetY
  const orgWidth = (visibleDataWidth * position.width) / 100
  const orgHeight = (visibleDataHeight * position.height) / 100

  // プリントによりカットされる分を考慮して一回り大きくしておく
  const width = orgWidth * item.zoom
  const height = orgHeight * item.zoom
  const x = orgX - (width - orgWidth) / 2
  const y = orgY - (height - orgHeight) / 2

  console.log('original print ratio: ', printWidth / printHeight)
  console.log('trim ratio: ', width / height)
  console.log(`x: ${x}, y: ${y}, width: ${width}, height: ${height}`)

  return { x, y, width, height }
}
