// 購入写真の一覧

<script>
import { mapActions } from 'vuex'

import getPhotoZoom from '@/helpers/get-photo-zoom'

import getOrderDetailApiAsync from '@/api/user/get-order-detail'
import getOrderItemsApiAsync from '@/api/user/get-order-items'

export default {
  name: 'OrderItems',

  props: {
    code: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      photos: [],
    }
  },

  async created() {
    try {
      const params = { code: this.code }
      const p1 = getOrderDetailApiAsync(params)
      const p2 = getOrderItemsApiAsync(params)
      const [response1, response2] = await Promise.all([p1, p2])
      if (!response1.ok || !response2.ok) return
      this.photos = this._complementPhotoItem(response1.payload, response2.payload)
    } catch (e) {
      this.setError(e)
    }
  },

  methods: {
    ...mapActions({
      setError: 'app/setError',
    }),

    // 写真の情報にサムネイルに関する情報を補完
    _complementPhotoItem(detail, items) {
      // filter しているのは、API側のバグで、購入していないitemが含まれることがあるので、それを除去している。
      const photos = items.photos.filter(value => value.details && value.details.length > 0)
      const token = items.token
      const thumbnailBase = `${items.thumbnailBaseUrl}thumbnail/${detail.shopId}/${detail.orgId}/`
      const customThumbnailBase = `${items.thumbnailBaseUrl}photo_custom/thumbnail/${detail.saleId}/`
      const printType = detail.printType
      return photos.map(item => {
        const zoom = getPhotoZoom(printType, item.details[0].sizeId)
        let url
        if (item.customId) {
          url = `${customThumbnailBase}${item.customId}/m.jpg?token=${token}`
        } else {
          url = item.thumbnailUrl
            ? item.thumbnailUrl.m
            : `${thumbnailBase}${item.eventIdNotSaleId}/${item.id}/m.jpg?token=${token}`
        }
        const longEdge = item.width >= item.height ? item.width : item.height
        const shortEdge = item.width < item.height ? item.width : item.height
        return _.assign({}, item, { zoom, url, longEdge, shortEdge })
      })
    },
  },
}
</script>

<template lang="pug">
.order-items
  ul.photos
    li(v-for='photo in photos')
      .wrapper
        ps-square-photo.photo(
          :shortEdge='photo.shortEdge',
          :longEdge='photo.longEdge',
          :orientation='photo.orientation',
          :zoom='photo.zoom',
          :url='photo.url'
        )
        span.index {{ photo.index }}
        ul.detail
          li(v-for='item in photo.details') {{ item.name }} : {{ item.num }}枚
        .tags
          // トリミング済みか
          ps-tag(v-if='photo.trimmed', color='purple') トリミング済み
          br
          // 日付入れ済み
          ps-tag(v-if='photo.dated', color='blue') 日付入れ済み
  
  .buttons
    ps-button(large-width, @click='$psdialog.close') 閉じる

</template>

<style lang="sass" scoped>
@import '../../../sass/variables.sass'
@import '../../../sass/mixins.sass'

.order-items
  > .photos
    overflow: hidden
    margin-bottom: 1rem
    > li
      width: 25%
      +tablet-only
        width: 33%
      +mobile
        width: 50%
      float: left
      > .wrapper
        position: relative
        margin: 5px
        border: 1px solid $grey-lighter
        .index
          position: absolute
          top: 5px
          left: 5px
          font-size: $size-small
          background-color: $white-ter
          padding: 3px
          border-radius: $radius-small
        .detail
          position: absolute
          right: 5px
          top: 5px
          padding: 3px
          border-radius: $radius-small
          font-size: $size-small
          background-color: rgba($white, 0.8)
          transition: all 0.5s ease
          > li
            text-align: center
        .tags
          position: absolute
          bottom: 10px
          left: 0
          right: 0
          text-align: center
  > .buttons
    text-align: center
</style>
