// パスワードの再設定

<script>
import { mapActions } from 'vuex'

import confirmChangePasswordApi from '@/api/user/confirm-change-password'

// トークンと仮パスワードの境界
const tokenCodeAlign = 40

export default {
  name: 'PublicPageRestPassword',

  data() {
    return {
      newPassword1: null,
      newPassword2: null,
      loading: false,
      error: null,
      completed: false,
    }
  },

  computed: {
    token() {
      return this.$route.params.token.slice(0, tokenCodeAlign)
    },
    // 再設定時に必要な8桁コード
    code() {
      return this.$route.params.token.slice(tokenCodeAlign, tokenCodeAlign + 8)
    },
    isValid() {
      return this.token && this.code && this.newPassword1 && this.newPassword2
    },
  },

  methods: {
    ...mapActions({
      setError: 'app/setError',
    }),

    async resetPasswordAsync() {
      this.error = null

      // 新しいパスワード２つが異なっていたらエラー
      if (this.newPassword1 !== this.newPassword2) {
        this.error = '2つのパスワードが異なります。'
        return
      }

      this.loading = true
      let response
      try {
        response = await confirmChangePasswordApi(
          {
            token: this.token,
          },
          {
            code: this.code,
            password: this.newPassword1,
          }
        )
      } catch (e) {
        this.setError(e)
        return
      }
      this.loading = false

      if (!response.ok) {
        this.error = 'パスワードが正しくありません'
        return
      }

      this.completed = true
    },
  },
}
</script>

<template lang="pug">
.reset-password-page

  .inner

    ps-medium-heading パスワードの再設定

    template(v-if='!completed')
      p 新しく設定したいパスワードを入力して「再設定する」を押してください。

      .form
        ps-form-item(label='新しいパスワード', hankaku, required, :error='error')
          ps-input(full-width, v-model='newPassword1', type='password')
          p.input-again 確認のため、もう一度同じパスワードを入力してください
          ps-input(full-width, v-model='newPassword2', type='password')

      .buttons
        ps-button(
          size='medium',
          color='green',
          large-width,
          :disabled='!isValid',
          :loading='loading',
          @click='resetPasswordAsync'
        ) 再設定する
        br
        br
        ps-button(size='small', @click='$router.push({ name: "TopPage" })') トップページへ

    template(v-else)
      p パスワードの再設定が完了しました。トップページの「ログイン」よりお進みください。

      .buttons
        ps-button(size='small', @click='$router.push({ name: "TopPage" })') トップページへ

</template>

<style lang="sass" scoped>
@import '../../../../sass/variables.sass'
@import '../../../../sass/mixins.sass'

.reset-password-page

  .inner
    background-color: $white
    padding: 1.5rem 1rem
    border-radius: $radius-small
    box-shadow: 0 1px 6px 2px rgba(0, 0, 0, .25)
    +mobile
      padding: 1rem 0.5rem
      box-shadow: 0 1px 1px 1px rgba(0, 0, 0, .3)

    > p
      margin-bottom: 1rem

    > .form
      max-width: 800px
      margin: 0 auto 2rem
      .input-again
        margin: 0.5rem 0

    > .buttons
      text-align: center
</style>
