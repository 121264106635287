// 右からスライドインするモバイル向けメニュー

<template lang="pug">
  ps-slide-in.ps-mobile-menu-base(
    from='right',
    :class='[ color ]'
  )
    .outer
      // ボタン領域
      .buttons(@click.self='$emit("clickBg")')
        ul
          li(v-for='item in buttons')
            a(@click='$emit(item.code)')
              ps-icon(:icon='item.icon')
              br
              span {{ item.label }}
      // メイン部分
      .main
        slot
</template>

<script>
const defaultButtons = [
  {
    code: 'close',
    label: '閉じる',
    icon: 'times',
  },
]

export default {
  name: 'PsMobileMenuBase',
  props: {
    buttons: {
      type: Array,
      default: () => defaultButtons,
    },
    color: {
      type: String,
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../../sass/variables.sass'

$default-color: darken(desaturate($orange, 50%), 40%)

.ps-mobile-menu-base
  .outer
    width: 100%
    height: 100%
    display: flex
    align-items: stretch
    justify-content: flex-end

    > .main
      position: relative
      flex-grow: 1
      max-width: 350px
      background-color: $white
      border-left: 6px solid $default-color
      overflow-y: auto

    > .buttons
      ul
        margin-top: 1rem
        margin-left: 0.5rem
        li
          a
            color: $white
            display: block
            background-color: $grey-dark
            text-align: center
            padding: 0.8rem
            border-top-left-radius: $radius
            border-bottom-left-radius: $radius
            margin-bottom: 5px
            i
              font-size: $size-large
            span
              font-size: $size-small
          &:first-child
            a
              background-color: $default-color

  &.green
    .outer
      > .main
        border-color: darken($green, 15%)
      > .buttons ul li:first-child a
        background-color: darken($green, 15%)
</style>
