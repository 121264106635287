// 写真のサイズ

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'PhotoSize',

  props: {
    // 現在の販売のサイズ表を表示するか
    // 例えば、過去販売閲覧中なら、その過去販売に設定されているサイズ表が表示される
    currentSale: Boolean,
  },

  computed: {
    ...mapGetters({
      eventIdNotSaleId: 'subSale/eventIdNotSaleId',
      // メインの販売のサイズ表取得のためのeventId
      rootEventIdNotSaleId: 'sale/eventIdNotSaleId',
      getSizeTableByEventIdNotSubSaleId: 'sizePrice/getSizeTableByEventIdNotSubSaleId',
    }),
    sizeTable() {
      const eventIdNotSaleId = this.currentSale ? this.eventIdNotSaleId : this.rootEventIdNotSaleId
      return this.getSizeTableByEventIdNotSubSaleId(eventIdNotSaleId)
    },
  },
}
</script>

<template lang="pug">
.photo-size
  ul
    li(v-for='size in sizeTable')
      .wrapper
        .figure(:class='`size-${size.code.toLowerCase()}`')
        p.mm
          span.for-mobile 長辺:
          | {{ size.long }}mm
          span.for-tablet &times;
          br.for-mobile
          span.for-mobile 短辺:
          | {{ size.short }}mm
        p.name {{ size.name }}
</template>

<style lang="sass" scoped>
@import '../../../sass/variables.sass'
@import '../../../sass/mixins.sass'

.photo-size
  > ul
    overflow: hidden
    padding: 0 0.5rem
    li
      width: 33%
      float: left
      padding: 0.5rem
      +mobile
        width: 50%
      > .wrapper
        border: 1px solid $orange
        border-radius: $radius-small
        padding: 5px
        > .figure
          +responsive-block(200, 160)
          background-size: contain
          position: relative
          &.size-lw
            background-image: url(../../../assets/size-lw.png)
          &.size-kg
            background-image: url(../../../assets/size-kg.png)
          &.size-2lw
            background-image: url(../../../assets/size-2lw.png)
          &.size-8pw
            background-image: url(../../../assets/size-8pw.png)
          &.size-6pw
            background-image: url(../../../assets/size-6pw.png)
          &.size-4pw
            background-image: url(../../../assets/size-4pw.png)
          &.size-l
            background-image: url(../../../assets/size-l.png)
          &.size-2l
            background-image: url(../../../assets/size-2l.png)
          &.size-2lg
            background-image: url(../../../assets/size-2lg.png)
          &.size-pa
            background-image: url(../../../assets/size-pa.png)
        > .mm
          text-align: center
          color: darken($orange, 15%)
          margin-bottom: 0.5rem
          .for-mobile
            display: none
          +mobile
            .for-mobile
              display: inline
            .for-tablet
              display: none
        > .name
          color: $white
          text-align: center
          background-color: lighten($orange, 10%)
          padding: 0.2rem
          border-radius: $radius-small
</style>
