// ストア

import Vue from 'vue'
import Vuex from 'vuex'

import app from './modules/app'
import sale from './modules/sale'
import cart from './modules/cart'
import subSale from './modules/sub-sale'
import browsing from './modules/browsing'
import sizePrice from './modules/size-price'
import order from './modules/order'
import face from './modules/face'

Vue.use(Vuex)

const actions = {
  // ほぼすべてのストアをクリア
  clearAll({ dispatch }) {
    dispatch('cart/clearAll')
    dispatch('sale/clearAll')
    dispatch('sizePrice/clearAll')
    dispatch('subSale/clearAll')
    dispatch('order/clearAll')
    dispatch('face/clearAll')
    dispatch('browsing/clearAll')
  },

  // 販売に依存する情報のみクリア
  clearSaleSpecifics({ dispatch }) {
    dispatch('cart/clearAll')
    dispatch('sale/clearAll')
    dispatch('sizePrice/clearAll')
    dispatch('subSale/clearAll')
    dispatch('order/clearAll')
    dispatch('face/clearAll')
    dispatch('browsing/clearSaleSpecifics')
  },
}

export default new Vuex.Store({
  modules: {
    app,
    sale,
    cart,
    subSale,
    browsing,
    sizePrice,
    order,
    face,
  },
  actions,
  strict: process.env.NODE_ENV !== 'production',
})
