// 汎用のアラート // お知らせやエラーなど

<script>
import { mapActions } from 'vuex'

const topPagePath = process.env.VUE_APP_TOP_PAGE_PATH || '/'

export default {
  name: 'ErrorMessage',

  props: {
    message: String,
    code: [Number, String],
    // クリティカルなエラーならトップへ飛ばす選択肢のみ
    type: {
      type: String,
      required: true,
    },
  },

  created() {
    // 現在のエラーをクリア
    this.clearError()
  },

  methods: {
    ...mapActions({
      clearError: 'app/clearError',
    }),

    reload() {
      this.$psdialog.close()
      window.location.reload()
    },

    goToTop() {
      this.$psdialog.close()
      // vue-routerでなく再読み込み
      window.location.href = topPagePath
    },
  },
}
</script>

<template lang="pug">
.error-message

  p.message(v-html='message')

  p.code(v-if='code') エラーコード: {{ code }}

  .buttons
    ps-button(
      v-show='type === "warning"',
      color='blue',
      full-width,
      text-centered,
      @click='reload'
    ) 再読み込み
    ps-button(
      v-show='type === "error" || type === "warning"'
      full-width,
      text-centered,
      @click='goToTop'
    ) トップページへ
    ps-button(
      v-show='type === "info"'
      full-width,
      text-centered,
      @click='$psdialog.close'
    ) 閉じる
</template>

<style lang="sass" scoped>
@import '../../../sass/variables.sass'

.error-message
  > .message
    margin-bottom: 1rem
    line-height: 1.3
  > .code
    text-align: right
    margin-bottom: 1rem
    font-size: $size-small
  > .buttons
    max-width: 250px
    margin: 0 auto
    > *
      margin-bottom: 0.5rem
      &:last-child
        margin-bottom: 0
</style>
