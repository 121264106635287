// お顔画像の検索

<script>
import addOkaoApiAsync from '@/api/user/add-okao'

export default {
  name: 'OkaoUploading',

  props: {
    name: String,
    childId: Number,
  },

  data() {
    return {
      file: null,
      rotate: 0,
      scale: 1.0,
    }
  },

  methods: {
    // ファイルを選択した
    selectFile(file) {
      this.reset()
      this.file = file
    },

    // ズームイン
    zoomInPhoto() {
      this.scale += 0.1
    },

    // ズームアウト
    zoomOutPhoto() {
      if (this.scale - 0.1 < 1.0) this.scale = 1.0
      else this.scale -= 0.1
    },

    rotate90Clockwise() {
      this.rotate = (this.rotate + 90) % 360
    },

    rotate90Anticlockwise() {
      this.rotate = (this.rotate - 90 + 360) % 360
    },

    // 調整をリセット
    reset() {
      this.rotate = 0
      this.scale = 1.0
      if (this.$refs.trimmer) this.$refs.trimmer.resetPosition()
    },

    // 決定
    async okAsync() {
      const blob = await this.$refs.trimmer.getImageAsync()
      const params = { id: this.childId }
      let formData = new FormData()
      formData.append('file', blob, 'okao.jpg')
      const response = await addOkaoApiAsync(params, formData)
      console.log(response)
      if (!response.ok) {
        this.$psdialog.close({ ok: false })
      } else {
        this.$psdialog.close({ ok: true })
      }
    },
  },
}
</script>

<template lang="pug">
.okao-uploading

  // ファイルのアップロード
  template(v-if='!file')

    p {{ name }}さんのお顔写真を登録(アップロード)します。

    p.alert
      ps-icon(icon='exclamation-triangle')
      | &nbsp;写真は正面を向いているものを使用してください。

    .drop-area
      ps-drop-area(@select='selectFile')

    .buttons
      ps-button(@click='$psdialog.close') 閉じる

  // 画像の位置等調整
  template(v-else)

    ps-row.main(:gutter='20', break-point='mobile')

      ps-col.trimmer(:span='0')
        .outer
          ps-okao-trimmer(:file='file', :rotate='rotate', :scale='scale', ref='trimmer')
          p.for-pc マウスで写真を移動できます
          p.for-mobile タッチで写真を移動できます

      ps-col
        p.info
          | 赤枠の太線の中にお顔が入るように
          | 「移動」「拡大」「回転」により調整することで、
          | お顔検索の精度がアップします。
        
        .zoom
          ps-row(:gutter='10', valign='center')
            ps-col
              p 拡大・縮小
            ps-col
              ps-button(color='blue', full-width, text-centered, @click='zoomOutPhoto')
                ps-icon(icon='minus')
            ps-col
              ps-button(color='blue', full-width, text-centered, @click='zoomInPhoto')
                ps-icon(icon='plus')

        .rotate
          ps-row(:gutter='10', valign='center')
            ps-col
              p 90度回転
            ps-col
              ps-button(color='pink', full-width, text-centered, @click='rotate90Anticlockwise')
                ps-icon(icon='undo')
            ps-col
              ps-button(color='pink', full-width, text-centered, @click='rotate90Clockwise')
                ps-icon(icon='repeat')

        .etc
          ps-button(size='small', @click='reset') 調整をリセット

    .buttons
      ps-button(@click='file = null') やり直す
      ps-button(@click='okAsync', color='green', large-width) 確定

</template>

<style lang="sass" scoped>
@import '../../../sass/variables.sass'
@import '../../../sass/mixins.sass'

.okao-uploading
  > p
    margin-bottom: 1rem
    line-height: 1.4

  > p.alert
    text-align: center
    border: 1px solid $pink
    color: darken($pink, 10%)
    background-color: lighten($yellow, 40%)
    border-radius: $radius-small
    padding: 0.2rem 0

  .trimmer
    > .outer
      width: 230px
      background-color: darken($orange, 10%)
      padding: 5px
      +mobile
        width: 180px
        margin: 0 auto 0.5rem
      p
        margin-top: 5px
        font-size: $size-small
        color: $white
        text-align: center
      .for-mobile
        display: none
      +mobile
        .for-pc
          display: none
        .for-mobile
          display: block

  .info
    background-color: lighten($blue, 40%)
    line-height: 1.4
    padding: 0.5rem
    border-radius: $radius
    margin-bottom: 1.5rem
    +mobile
      display: none


  .zoom, .rotate
    background-color: lighten($yellow, 38%)
    padding: 0.5rem
    margin-bottom: 0.5rem

  .etc
    text-align: center

  .drop-area
    margin-bottom: 0.5rem

  > .main
    margin-bottom: 1rem

  > .buttons
    text-align: center
    > :first-child
      margin-right: 10px
</style>
