// 注文の状態を正規化
// 支払い方法によってDBの状態が同じでも意味が変わってくる
//
// なお、決済会社との連携成功時点でsettle状態となるはずなので、
// 通常、commit状態では止まらないはず。

const statusMap = {
  conveni: {
    ship: 'shipped',
    receive: 'shipping',
    settle: 'commited',
    commit: 'commited',
    cancel: 'canceled',
  },
  card: {
    ship: 'shipped',
    receive: 'shipping',
    settle: 'shipping',
    commit: 'commited',
    cancel: 'canceled',
  },
  slip: {
    ship: 'shipped',
    receive: 'shipping',
    settle: 'commited',
    commit: 'commited',
    cancel: 'canceled',
  },
  pouch: {
    receive: 'shipping',
    settle: 'accepted',
    commit: 'accepted',
    cancel: 'canceled',
  },
}

export default function(paymentType, status) {
  return statusMap[paymentType][status] || 'unknown'
}
