// ゲスト(メールアクティベーションが済んだユーザ)向けの、 // 販売に対する認可を行うページ

<script>
import { mapActions } from 'vuex'

import authorizeSaleApiAsync from '@/api/sale/authorize-sale'

// 販売に対する緊急のメッセージがある場合に表示
import ErrorMessage from '@/dialogs/contents/error-message'

export default {
  name: 'IntroPageAuthorization',

  data() {
    return {
      code1: null,
      code2: null,
      error: null,
    }
  },

  computed: {
    // 最低限次へボタンを押せる状態にあるか
    isValid() {
      return this.code1 && this.code1.length === 5 && this.code2 && this.code2.length === 5
    },
  },

  methods: {
    ...mapActions({
      setError: 'app/setError',
    }),

    async authorizeSaleAsync() {
      this.error = null
      const body = { code1: this.code1, code2: this.code2 }
      let response
      try {
        response = await authorizeSaleApiAsync(null, body)
      } catch (e) {
        this.setError(e)
        return
      }
      if (!response.ok) {
        const message = _.get(response, 'payload.message')
        // 緊急のメッセージがあるかどうか。この場合だけダイアログを表示
        if (/announcement/.test(message)) {
          const announcement = _.get(response, 'payload.announcement', {})
          this.showSaleAnnouncement(announcement.title, announcement.body)
        } else if (/opened/.test(message) || /not ready/.test(message))
          this.error = 'こちらのイベントは公開準備中です。'
        else if (/closed/.test(message)) this.error = 'こちらのイベントは公開を終了しました。'
        else this.error = '入力されたコードが正しくありません。'
        return
      }

      // このページから閲覧ページに遷移した = ゲストによる初回閲覧
      // ということがわかるように、ストアのフラグを立てておく
      this.$store.commit('browsing/setFromGuestSaleAuthPage', true)

      // 閲覧ページへ
      this.$router.push({
        name: 'BrowsePage',
        params: { saleId: response.payload.id },
      })
    },

    // 販売に対する緊急のメッセージを表示
    showSaleAnnouncement(title, message) {
      this.$psdialog.open({
        title,
        props: {
          message,
          type: 'error',
        },
        component: ErrorMessage,
        size: 'm',
      })
    },

    goToTop() {
      this.$router.push({ name: 'TopPage' })
    },
  },
}
</script>

<template lang="pug">
.intro-page-authorization

  .inner

    ps-dots-heading 閲覧用のコードの入力

    article.sale-info
      ps-row(:gutter='40', break-point='mobile')

        ps-col.first
          .desc
            | 販売の案内等に記載されている、閲覧用のコード(コード1とコード2)を入力してください。
          .form
            ps-form-item(label='コード1', required, :error='error ? " " : null')
              ps-input(placeholder='(例)ab123', full-width, ime-mode='disabled', force-hankaku,
                :style='{ maxWidth: "8rem", textAlign: "center", letterSpacing: "2px" }', v-model='code1')
            ps-form-item(label='コード2', required, :error='error ? " " : null')
              ps-input(placeholder='(例)cd345', full-width, ime-mode='disabled', force-hankaku,
                :style='{ maxWidth: "8rem", textAlign: "center", letterSpacing: "2px" }', v-model='code2')
          
          p.error(v-if='error') {{ error }}

        ps-col.second(:span='0')
          .please-reading
            h3
              ps-icon(icon='info-circle')
              | &nbsp;お読みください
            ul
              li
                | 写真の閲覧・ご購入は公開期間のみ行なえます。

    article.buttons
      ps-button(size='large', @click='goToTop')
        ps-icon(icon='chevron-circle-left')
        | &nbsp;トップへ
      ps-button(size='large', color='orange', :disabled='!isValid', large-width, @click='authorizeSaleAsync')
        | 次へ&nbsp;
        ps-icon(icon='chevron-circle-right')

</template>

<style lang="sass" scoped>
@import '../../../../sass/variables'
@import '../../../../sass/mixins'

.intro-page-authorization

  .inner

    background-color: $white
    padding: 1.5rem 1rem
    border-radius: $radius-small
    box-shadow: 0 1px 6px 2px rgba(0, 0, 0, .25)
    +mobile
      padding: 1rem 0.5rem
      box-shadow: 0 1px 1px 1px rgba(0, 0, 0, .3)

    > article
      margin-bottom: 3rem

      &.sale-info
        .first
          .desc
            margin-bottom: 2rem

          p.error
            color: $red
            margin-top: 0.5rem
          margin-bottom: 1rem

        .second
          .please-reading
            border-radius: $radius-small
            background-color: lighten(#e4f7b7, 5%)
            padding: 0.5rem
            +tablet
              width: 350px
            h3
              font-weight: normal
              font-size: $size-normal
              text-align: center
              color: $black-bis
              letter-spacing: 1px
              margin-bottom: 0.4rem
            ul
              margin-left: 1rem
              li
                list-style-type: disc
                line-height: 1.4
                margin-bottom: 0.6rem
                color: $grey-darker
                &:last-child
                  margin-bottom: 0

      &.buttons
        text-align: center
        > :first-child
          margin-right: 1rem
</style>
