// 価格表ダイアログ

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'PhotoPrice',

  props: {
    // 現在の販売の価格表を表示するか
    // 例えば、過去販売閲覧中なら、その過去販売に設定されている価格表が表示される
    currentSale: Boolean,
    // 税別かどうか
    isTaxExcluded: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters({
      eventIdNotSaleId: 'subSale/eventIdNotSaleId',
      // メインの販売の価格表取得のためのeventId
      rootEventIdNotSaleId: 'sale/eventIdNotSaleId',
      getPriceTableByEventIdNotSubSaleId: 'sizePrice/getPriceTableByEventIdNotSubSaleId',
    }),
    priceTable() {
      const eventIdNotSaleId = this.currentSale ? this.eventIdNotSaleId : this.rootEventIdNotSaleId
      return this.getPriceTableByEventIdNotSubSaleId(eventIdNotSaleId)
    },
    // 集合写真があるかどうか
    hasGroup() {
      return (
        this.priceTable.hasGroupNormal ||
        this.priceTable.hasGroupGold ||
        this.priceTable.hasGroupPremium
      )
    },
    // スナップ写真があるかどうか
    hasSnap() {
      return (
        this.priceTable.hasSnapNormal ||
        this.priceTable.hasSnapGold ||
        this.priceTable.hasSnapPremium
      )
    },
  },

  methods: {
    getPrice(item, type, rank) {
      // 通常は価格設定は1つだが、年間販売の場合複数の可能性がある
      const prices = _.get(item, `${type}.${rank}`)
      if (!prices) return '-'
      const taxInfo = this.isTaxExcluded ? '(税別)' : ''
      return _.map(prices, price => `${price}円${taxInfo}`).join(' / ')
    },
  },
}
</script>

<template lang="pug">
.photo-price

  table.for-tablet

    thead
      tr
        th サイズ
        th(v-if='priceTable.hasGroupNormal')
          | 集合写真
        th(v-if='priceTable.hasGroupGold')
          | 集合写真
          .rank (ゴールド)
        th(v-if='priceTable.hasGroupPremium')
          | 集合写真
          .rank (プレミアム)
        th(v-if='priceTable.hasSnapNormal')
          | スナップ
        th(v-if='priceTable.hasSnapGold')
          | スナップ
          .rank (ゴールド)
        th(v-if='priceTable.hasSnapPremium')
          | スナップ
          .rank (プレミアム)

    tbody
      tr(v-for='item in priceTable.items')
        th
          | {{ item.name }}
          .size(v-show='item.long && item.short') {{ item.long }}mm &times; {{ item.short }}mm
        td.normal(v-if='priceTable.hasGroupNormal') {{ getPrice(item, 'group', 'normal') }}
        td.gold(v-if='priceTable.hasGroupGold') {{ getPrice(item, 'group', 'gold') }}
        td.premium(v-if='priceTable.hasGroupPremium') {{ getPrice(item, 'group', 'premium') }}
        td.normal(v-if='priceTable.hasSnapNormal') {{ getPrice(item, 'snap', 'normal') }}
        td.gold(v-if='priceTable.hasSnapGold') {{ getPrice(item, 'snap', 'gold') }}
        td.premium(v-if='priceTable.hasSnapPremium') {{ getPrice(item, 'snap', 'premium') }}

  // モバイルでは集合とスナップに価格表を分ける(スペースの都合から)

  // 集合写真の価格表
  table.for-mobile(v-if='hasGroup')

    thead
      tr
        th サイズ
        th(v-if='priceTable.hasGroupNormal')
          | 集合写真
        th(v-if='priceTable.hasGroupGold')
          | 集合写真
          .rank (ゴールド)
        th(v-if='priceTable.hasGroupPremium')
          | 集合写真
          .rank (プレミアム)

    tbody
      tr(v-for='item in priceTable.items')
        th
          | {{ item.name }}
          .size(v-show='item.long && item.short')
            | 長辺:{{ item.long }}mm
            br
            | 短辺:{{ item.short }}mm
        td.normal(v-if='priceTable.hasGroupNormal') {{ getPrice(item, 'group', 'normal') }}
        td.gold(v-if='priceTable.hasGroupGold') {{ getPrice(item, 'group', 'gold') }}
        td.premium(v-if='priceTable.hasGroupPremium') {{ getPrice(item, 'group', 'premium') }}

  table.for-mobile(v-if='hasSnap')

    thead
      tr
        th サイズ
        th(v-if='priceTable.hasSnapNormal')
          | スナップ
        th(v-if='priceTable.hasSnapGold')
          | スナップ
          .rank (ゴールド)
        th(v-if='priceTable.hasSnapPremium')
          | スナップ
          .rank (プレミアム)

    tbody
      tr(v-for='item in priceTable.items')
        th
          | {{ item.name }}
          .size(v-show='item.long && item.short')
            | 長辺:{{ item.long }}mm
            br
            | 短辺:{{ item.short }}mm
        td.normal(v-if='priceTable.hasSnapNormal') {{ getPrice(item, 'snap', 'normal') }}
        td.gold(v-if='priceTable.hasSnapGold') {{ getPrice(item, 'snap', 'gold') }}
        td.premium(v-if='priceTable.hasSnapPremium') {{ getPrice(item, 'snap', 'premium') }}

</template>

<style lang="sass" scoped>
@import '../../../sass/variables.sass'
@import '../../../sass/mixins.sass'

.photo-price

  padding: 0 1rem
  > table
    width: 100%
    // タブレット or PC のみ表を等幅に
    &.for-table
      table-layout: fixed
    &.for-mobile
      display: none

    thead
      th
        background-color: darken($orange, 10%)
        padding: 0.5rem 0
        color: $white
        font-weight: normal
        .rank
          font-size: $size-smallest
    tbody
      th
        background-color: lighten($orange, 25%)
        font-weight: normal
        padding: 1rem 0
        .size
          font-size: $size-smallest
      td
        vertical-align: center
        text-align: center
        background-color: $white-bis
        &.gold
          background: linear-gradient(to bottom right, #bfa67f 0%, #f4e3c9 50%, #bfa67f 100%)
        &.premium
          background: linear-gradient(to bottom right, #bbb 0%, #fff 50%, #aaa 100%)

    +mobile
      &.for-tablet
        display: none
      &.for-mobile
        display: table
</style>
