// 利用規約

export default [
  {
    title: '1. はじめに',
    descriptions: [
      {
        type: 'text',
        value:
          'この利用規約(以下、「本規約」といいます。)は、有限会社アイリス企画(屋号: 写真の伊里)(以下、「弊社」といいます。)が提供する' +
          '写真販売サイト「フォトいいね」(以下、「本サービス」といいます。)の利用条件を定めるものです。',
      },
      {
        type: 'text',
        value: '本サービスは、本規約にご同意の方に限り、利用することができます。',
      },
    ],
  },

  {
    title: '2. サービスの概要',
    descriptions: [
      {
        type: 'text',
        value:
          '1. 本サービスでは、弊社から事前に学校・団体を通じて、閲覧に必要な文字列(以下、「イベントコード」といいます。)を、販売するイベント(以下「販売イベント」といいます。)毎にお客様に配布します。',
      },
      {
        type: 'text',
        value:
          '2. お客様は本サービスにインターネットを介してアクセスし、イベントコードを入力することで、写真の閲覧や注文を行うことができます。',
      },
    ],
  },

  {
    title: '3. 商品の閲覧と注文',
    descriptions: [
      {
        type: 'text',
        value: '1. 写真の閲覧にはお客様のメールアドレスとお名前の登録が必要となります。',
      },
      {
        type: 'text',
        value:
          '2. お客様による写真の閲覧や注文は、弊社によって販売イベント毎に定められた期間内においてのみ可能となります。',
      },
      {
        type: 'text',
        value: '3. 本サービスの利用により生じる通信費は、お客様の負担となります。',
      },
    ],
  },

  {
    title: '4. 商品のお届け',
    descriptions: [
      {
        type: 'text',
        value:
          '1. 弊社は、お客様からのご注文に対して入金確認後、ご注文いただいた写真をプリントし納品いたします。',
      },
      {
        type: 'text',
        value:
          '2. 納品方法は販売イベント毎に定められており、注文時に入力した住所への発送、または学校・団体への納品となります。',
      },
      {
        type: 'text',
        value:
          '3. 写真プリントは受注生産となりますので、お客様のご都合によるキャンセル・返品・交換はできません。',
      },
      {
        type: 'text',
        value:
          '4. お客様がご使用になるスマートフォン・パソコンに表示される写真と、写真プリントとの間に色合いの違いが生じる場合がありますので、予めご了承ください。',
      },
      {
        type: 'text',
        value: '5. 商品の発送は日本国内に限らせていただきます。',
      },
    ],
  },

  {
    title: '5. 個人情報の取り扱い',
    descriptions: [
      {
        type: 'text',
        value:
          '弊社は、本サービスの提供に当たり、お客様のお名前、ご住所、お電話番号、ご購入履歴等の個人情報を収集し、適切に管理します。',
      },
      {
        type: 'text',
        value: '弊社は、お客様の個人情報を以下の目的に限り利用します。',
      },
      {
        type: 'text',
        value: '1. お客様にご注文いただいた商品を作成、発送するため。',
      },
      {
        type: 'text',
        value: '2. お客様に本サービスに関わる重要なお知らせを行うため。',
      },
      {
        type: 'text',
        value: '3. お客様にご購入履歴確認等の情報を提供するため。',
      },
      {
        type: 'text',
        value: '4. 本サービスの改善や新機能の開発に活用するため。',
      },
      {
        type: 'text',
        value: '5. お客様を特定できない形式で統計資料を作成するため。',
      },
      {
        type: 'text',
        value:
          '弊社は、商品の作成、発送等、個人情報の取り扱いを伴う業務の一部または全部を、外部の企業に委託することがあります。委託の際は、弊社と同様に個人情報を適切に管理できる業者を選定します。',
      },
    ],
  },

  {
    title: '6. 禁止事項',
    descriptions: [
      {
        type: 'text',
        value: 'お客様は、本サービスの利用にあたり、以下の行為を行ってはなりません。',
      },
      {
        type: 'text',
        value: '1. 本サービスのご利用時に、虚偽の情報を入力すること。',
      },
      {
        type: 'text',
        value: '2. 法令または公序良俗に反する行為をすること。',
      },
      {
        type: 'text',
        value:
          '3. 本サービスを提供するサーバー及びネットワークの機能を破壊したり、妨害したりする行為をすること。',
      },
      {
        type: 'text',
        value: '4. 本サービスの運営を妨害する恐れのある行為をすること。',
      },
      {
        type: 'text',
        value: '5. その他、弊社が不適切と判断する行為をすること。',
      },
    ],
  },

  {
    title: '7. 利用停止と登録抹消',
    descriptions: [
      {
        type: 'text',
        value:
          '弊社は、以下の場合には、事前の通知なくお客様に対して、本サービスの全部もしくは一部の利用を制限し、またはお客様の登録を抹消することができるものとします。',
      },
      {
        type: 'text',
        value: '1. お客様が本規約のいずれかの条項に違反した場合。',
      },
      {
        type: 'text',
        value: '2. お客様の登録した内容に、虚偽があることが判明した場合。',
      },
      {
        type: 'text',
        value: '3. その他、弊社が本サービスの利用を適当でないと判断した場合。',
      },
      {
        type: 'text',
        value:
          '弊社は、本条に基づき弊社が行った行為によりお客様に生じた損害について、一切の責任を負いません。',
      },
    ],
  },

  {
    title: '8. 利用規約の変更',
    descriptions: [
      {
        type: 'text',
        value:
          '弊社は、必要と判断した場合には、お客様に通知することなくいつでも本規約を変更することができるものとします。',
      },
    ],
  },

  {
    title: '9. 権利の帰属',
    descriptions: [
      {
        type: 'text',
        value:
          '本サービスに掲載されたすべての写真の著作権・知的財産権は、弊社に帰属するものとします。',
      },
    ],
  },

  {
    title: '10. サービスの中断と廃止',
    descriptions: [
      {
        type: 'text',
        value:
          '弊社は、以下のいずれかの事由によって、お客様に事前に通知することなく、本サービスの提供を中断または廃止することがあります。',
      },
      {
        type: 'text',
        value:
          '弊社は、本サービスの中断または廃止によってお客様に生じた損害について一切の責任を負いません。',
      },
      {
        type: 'text',
        value: '1. システムや設備の保守点検・変更・故障対応を行う場合。',
      },
      {
        type: 'text',
        value: '2. 火災・停電・天災等の不可抗力により、本サービスの提供が困難となった場合。',
      },
      {
        type: 'text',
        value: '3. 通信回線に不具合等が生じた場合。',
      },
      {
        type: 'text',
        value:
          '4. 運用上あるいは技術上の事由により、弊社が当サービスの中断が必要であると判断した場合。',
      },
      {
        type: 'text',
        value: '5. その他、やむを得ない事由が発生した場合。',
      },
    ],
  },

  {
    title: '11. 損害賠償と免責',
    descriptions: [
      {
        type: 'text',
        value:
          '1. 弊社は、何らかの理由によって損害賠償責任を負う場合にも、通常生じうる損害の範囲内かつ有料サービスにおいては代金額の範囲内においてのみ損害賠償の責任を負うものとします。',
      },
      {
        type: 'text',
        value:
          '2. 弊社は、本サービスに関して、お客様が他のお客様または第三者との間において生じた取引、連絡または紛争等について一切責任を負いません。',
      },
    ],
  },

  {
    title: '12. 準拠法',
    descriptions: [
      {
        type: 'text',
        value: '本規約の準拠法は日本国法とします。',
      },
      {
        type: 'text',
        value: '本サービスに関する一切の紛争は、名古屋地方裁判所を専属的合意管轄裁判所とします。',
      },
    ],
  },
]
