// お子様情報の削除

import apiFactory from '../api-factory'

export default apiFactory({
  path: '/my/subs/<%= id %>',
  expectedParams: ['id'],
  method: 'DELETE',
  needAuth: true,
})
