// 注文詳細ダイアログ

<script>
import { mapActions } from 'vuex'

import getOrderDetailApiAsync from '@/api/user/get-order-detail'
import conveniNativeInfo from '@/helpers/conveni-native-info'

const statusMap = {
  shipped: '発送済み',
  shipping: '発送待ち',
  commited: '入金待ち',
  accepted: '受付済み',
  canceled: 'キャンセル済み',
  unknown: '状態未確定',
}

export default {
  name: 'OrderDetail',

  props: {
    code: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      // 注文の詳細情報
      order: {},
    }
  },

  async created() {
    const params = { code: this.code }
    let response
    try {
      response = await getOrderDetailApiAsync(params)
    } catch (e) {
      this.setError(e)
      return
    }
    if (!response.ok) return
    this.order = response.payload

    // スクロールロック
    this.$psdialog.lockScroll()
  },

  computed: {
    statusName() {
      return statusMap[this.order.status] || statusMap.unknown
    },
    // コンビニ毎の固有の情報
    conveniInfo() {
      if (this.order.paymentCode !== 'conveni') return null
      return conveniNativeInfo[this.order.paymentSubCode] || {}
    },
  },

  methods: {
    ...mapActions({
      setError: 'app/setError',
    }),
  },

  beforeDestroy() {
    this.$psdialog.unlockScroll()
  },
}
</script>

<template lang="pug">

.order-detail

  ps-form-item(label='注文の状態')
    pre {{ statusName }}

  ps-form-item(label='販売イベント')
    pre
      | {{ order.saleName }}
      | {{ order.orgName }}
      | ({{ order.shopName }})

  ps-form-item(label='注文内容')
    pre
      | 注文日: {{ order.orderedAt }}
      | 注文番号: {{ order.code }}
      | {{ order.num ? `注文点数: ${order.num}点`: '' }}
      | 金額: {{ order.price }}円(税込)
    .details
      p &lt; 内訳 &gt;
      ul
        li(v-for='item in order.orderDetails')
          span {{ item.name }}
          span {{ item.memo }} {{ item.price }}円

  ps-form-item(label='支払い情報')
    // コンビニ払い
    template(v-if='order.paymentCode === "conveni"')
      pre
        | 支払い方法: コンビニ決済
        | 利用可能なコンビニ: {{ order.paymentConveniStores }}
        | {{ conveniInfo.numberName }}: {{ order.paymentConveniCode }}
        | 支払い期限: {{ order.paymentExpiredAt }}
        |
      pre(v-if='order.paymentConveniExternalUrl')
        | 印刷用の払込票は
        a(:href='order.paymentConveniExternalUrl', target='_blank') こちら
      pre
        | お支払い方法については
        a(:href='conveniInfo.howToUrl', target='_blank') こちら
    template(v-else)
      pre
        | 支払い方法: {{ order.paymentTypeName }}

  ps-form-item(v-if='order.shippingType === 1', label='お届け先')
    pre
      | {{ order.name }} 様
      |
      | &#12306;{{ order.zipcode }}
      | {{ order.address1 }}
      | {{ order.address2 }}
      |
      | TEL: {{ order.tel }}
  ps-form-item(v-else, label='お渡し')
    pre 学校・園からお渡し

  .buttons
    ps-button(@click='$psdialog.close()', large-width) 閉じる

</template>

<style lang="sass" scoped>
@import '../../../sass/variables.sass'

.order-detail
  pre
    line-height: 1.2
    padding: 0.5rem 0
    word-break: break-all
    white-space: pre-wrap
  .details
    font-size: $size-small
    border: 1px solid $grey-lighter
    line-height: 1.3
    padding: 0.2rem
    max-width: 300px
    overflow: hidden
    border-radius: $radius-small
    > p
      text-align: center
    > ul li
      clear: both
      span:last-child
        float: right
  > .buttons
    text-align: center
    margin-top: 1rem
</style>
