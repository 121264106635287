// 自分の情報

<script>
import { mapActions } from 'vuex'

import getInfoApiAsync from '@/api/user/get-info'

import ChangeEmail from '@/dialogs/contents/change-email'
import ChangePassword from '@/dialogs/contents/change-password'
// ユーザ情報変更ダイアログ
import UserInfo from '@/dialogs/contents/user-info'

export default {
  name: 'MyPageInfo',

  data() {
    return {
      // ユーザに関する詳細情報
      user: () => {
        return {}
      },
      // 住所に関する情報
      location: () => {
        return {}
      },
    }
  },

  created() {
    this.updateInfoAsync()
  },

  methods: {
    ...mapActions({
      setError: 'app/setError',
    }),

    async updateInfoAsync() {
      let response
      try {
        response = await getInfoApiAsync()
      } catch (e) {
        this.setError(e)
        return
      }
      if (!response.ok) return
      this.user = response.payload.user
      this.location = response.payload.location
    },

    // メールアドレスの変更
    showChangeEmailDialog() {
      this.$psdialog.open({
        title: 'メールアドレスの変更',
        component: ChangeEmail,
        size: 'm',
        closeButton: true,
      })
    },

    // パスワードの変更
    showChangePasswordDialog() {
      this.$psdialog.open({
        title: 'パスワードの変更',
        component: ChangePassword,
        size: 'm',
        closeByClickBg: true,
        closeButton: true,
      })
    },

    // ユーザ情報の変更
    showChangeUserInfoDialog() {
      this.$psdialog.open({
        title: '登録情報の更新',
        component: UserInfo,
        props: {
          value: _.assign({}, this.user, this.location),
        },
        size: 'm',
        closeByClickBg: true,
        closeButton: true,
        onClose: params => {
          if (params && params.updated) this.updateInfoAsync()
        },
      })
    },
  },
}
</script>

<template lang="pug">
.my-page-info

  .inner
    
    section.id-and-password
      ps-medium-heading.heading
        | メールアドレス
        span (ログインID)
        | とパスワード
      .table
        ps-form-item(label='メールアドレス')
          p.static {{ user.email }}
        ps-form-item(label='パスワード')
          p.static **********
      .buttons
        ps-button(color='green', @click='showChangeEmailDialog') メールアドレス変更
        | &nbsp;
        ps-button(color='green', @click='showChangePasswordDialog') パスワード変更

    section.name-and-location
      ps-medium-heading 登録情報
      .table
        ps-form-item(label='お名前')
          p.static
            span {{ user.name1 }} {{ user.name2 }} 様
            br
            span(v-if='user.name1Kana') {{ user.name1Kana }} {{ user.name2Kana }} 様
        ps-form-item(label='ご住所')
          p.static
            | &#12306;{{ location.zipcode }}
            br
            | {{ location.prefecture }}
            br
            | {{ location.address1 }}{{ location.address2 }}
            br
            | {{ location.address3 }}
        ps-form-item(label='電話番号')
          p.static {{ location.tel }}
      .buttons
        ps-button(color='green', @click='showChangeUserInfoDialog') 登録情報の更新

    .buttons
      ps-button(@click='$router.push({ name: "MyPageTop" })', large-width) 戻る

</template>

<style lang="sass" scoped>
@import '../../../../sass/variables.sass'
@import '../../../../sass/mixins.sass'

.my-page-info

  .inner

    background-color: $white
    padding: 1.5rem 1rem
    border-radius: $radius-small
    box-shadow: 0 1px 6px 2px rgba(0, 0, 0, .25)
    +mobile
      padding: 1rem 0.5rem
      box-shadow: 0 1px 1px 1px rgba(0, 0, 0, .3)

    > section
      margin-bottom: 3rem
      .table
        max-width: 600px
        margin: 0 auto 1rem
        p.static
          padding: 0.5rem
          line-height: 1.4
      .buttons
        text-align: center

    .id-and-password
      +mobile
        .heading span
          display: none

    > .buttons
      text-align: center
</style>
