// 情報入力画面

<template lang="pug">
.outro-page-register(v-if='ready')

  ps-row(:gutter='20')

    // メイン部分
    ps-col.main

      ps-cart-info.cart-info-mobile(
        v-if='screenType === "mobile"',
        :num='cartNum', :sum='cartTotal', :details='cartDetails',
        :photosSummary='cartPhotosSummary'
      )

      .form

        // エラーのかんたんな概要
        article.errors-summary(v-if='errorsSummary')
          ul
            li(v-for='item in errorsSummary') {{ item }}

        // 注文者情報
        article.user
          ps-medium-heading ご注文者の情報

          // ネット決済
          template(v-if='!isPouch')

            // 住所等を持っていないのでフォームの表示
            template(v-if='insufficientMemberOrOverrideOneTime')

              ps-user-form(v-model='user', :errors='getErrors("customer")', :remote-method='zipToAddressAsync')

            // メンバーかつ有効な住所を持っている
            template(v-else)

              ps-form-item(label='ご注文者')
                .wrapper
                  p
                    | {{ user.name1 }} {{ user.name2 }} 様
                    br
                    | ({{ user.name1Kana }} {{ user.name2Kana }} 様)
                  p
                    | &#12306;{{ user.zipcode }}
                    br
                    | {{ user.prefecture }}{{ user.address1 }}{{ user.address2 }}{{ user.address3 }}
                  p
                    | TEL {{ user.tel }}
                  .buttons
                    ps-button(color='green', size='smallest', @click='showChangeUserInfoDialog') 登録情報の更新
                    | &nbsp;
                    ps-button(color='turquoise', size='smallest', @click='changeUserInfoOneTime') 今回のみ変更

          // 集金袋
          template(v-else)

            ps-user-form(v-model='user', :errors='getErrors("customer")', pouch-mode)

        // お子様情報。一般向けイベントでは表示しない
        article.child(v-if='!isPublic')
          ps-medium-heading お子様(参加者)情報

          //- 2020年〜の新しいお子様情報システムを利用するケース
          div.new-child-list(v-if='role === "member" && useNewChildrenInfoSystem')
            p 参加者を選択してください(複数選択可)。
            ps-form-item(label='参加者氏名', required)
              ul(v-if='userChildren && userChildren.length > 0')
                li(
                  v-for='child in userChildren',
                  :class='{ "selected": selectedNewChildIds.indexOf(child.id) !== -1 }',
                  @click='clickNewChildListItem(child)'
                )
                  ps-icon(
                    :icon='selectedNewChildIds.indexOf(child.id) !== -1 ? "check-square" : "square"',
                    regular
                  )
                  p
                    | {{ child.name1 }} {{ child.name2 }} 様
                    span(
                      v-if='child.attribute',
                      style='margin-left: 0.2rem; font-size: 0.9rem;'
                    ) ({{ child.attribute }})
                  p
                    a(
                      @click.stop='showNewChildInfoDialogForUpdate(child)'
                    ) [編集]
                    a(
                      @click.stop='removeChildAsync(child)'
                    ) [削除]
              p.caution(v-else)
                b 「参加者を追加」
                | を押してお子様(参加者)情報を登録してください。

              div
                ps-button(
                  color='green',
                  @click='showNewChildInfoDialog'
                ) 参加者を追加

          template(v-else)
            // 候補があればセレクトボックスで表示
            template(v-if='childCandidates')
              ps-form-item(label='登録済み情報', required)
                ps-select(:items='childCandidates', v-model='selectedChildIndex')
              // 内容の表示
              template(v-if='isSelectedChild')
                ps-form-item(label='お子様とのご関係')
                  p {{ child.relation }}
                ps-form-item(label='お子様のお名前')
                  p
                    | {{ child.name1 }} {{ child.name2 }} 様
                    br
                    | ({{ child.name1Kana }} {{ child.name2Kana }} 様)
                ps-form-item(label='クラス・所属', required, :error='childAttributeError')
                  ps-select(v-if='orgAttributeCandidates', :items='orgAttributeCandidates', v-model='childAttribute')
                  ps-input(v-else, v-model='childAttribute')

            // 候補がない = 未登録者 or 登録して初回の購入、または新規に入力
            template(v-if='!childCandidates || selectedChildIndex === "new"')
              ps-child-form(
                v-model='child',
                :attributes='orgAttributeCandidates',
                :errors='getErrors("sub")',
                @selectMyself='selectMyself'
              )


        // 送り先。個別発送の場合のみ表示
        article.destination(v-if='isIndividual')
          ps-medium-heading お届け先の選択

          ps-form-item(label='お届け先', required)
            .dests
              ps-radio(radio-value='same', v-model='destType', full-width) ご注文者と同じ
              ps-radio(radio-value='other', v-model='destType', full-width) 別のお届け先
          // 別のお届け先入力欄
          ps-user-form(
            v-if='destType === "other"',
            v-model='destination',
            :errors='getErrors("dest")',
            :remote-method='zipToAddressAsync'
          )


        // 支払い方法 (集金袋以外)
        article.payment(v-if='!isPouch')
          ps-medium-heading お支払い方法の選択
          iine-payment-form(
            v-model='payment',
            :card-available='paymentMethods.indexOf("card") !== -1',
            :conveni-available='paymentMethods.indexOf("conveni") !== -1',
            :errors='getErrors("payment")',
            @clickWhatIsCvv='showSecurityCodeDetailDialog',
            ref='paymentForm'
          )

        //- 次前
        article.buttons
          //- iOS12のSafariにおいて、iframeのinput要素を利用すると、
          //- スクリーンキーボードが、ページ遷移後も出たままになる問題への対応。
          //- ページ遷移前にこの要素にfocus&blurすることで、でなくなる。
          //- なお、display:noneやvisibility:hiddenだとダメな模様
          div
            input.dummy(type='checkbox')
          ps-button(size='large', @click='goToPrev')
            ps-icon(icon='chevron-circle-left')
            | &nbsp;戻る
          ps-button.next-button(size='large', color='red', min-width='14rem',
            text-centered, :disabled='!canGoNext', @click='goToNextAsync', v-my-id='"go-to-next"')
            | 次へ&nbsp;
            ps-icon(icon='chevron-circle-right')

    //- サイドのカート情報
    ps-col.side(v-if='screenType !== "mobile"', :span='0')
      ps-fixed-region(:threshold='20')
        .cart-info-outer
          ps-cart-info(:num='cartNum', :sum='cartTotal', :details='cartDetails', :photosSummary='cartPhotosSummary', transparent)
            ps-button(
              full-width,
              size='small',
              text-centered,
              @click='goToPrev'
            )
              ps-icon(icon='chevron-circle-left')
              | &nbsp;戻る
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

// ユーザ情報変更ダイアログ
import UserInfo from '@/dialogs/contents/user-info'
// 2020年〜の新しいお子様情報のダイアログ
import NewChildInfo from '@/dialogs/contents/new-child-info'
// セキュリティコードの説明ダイアログ
import SecurityCodeDetail from '@/dialogs/contents/security-code-detail'
// 期間外販売についてのダイアログ
import ConfirmOverdue from '@/dialogs/contents/confirm-overdue'

// 郵便番号 -> 住所に
import zipToAddressAsync from '@/api/public/zip-to-address'
import getUserInfoApiAsync from '@/api/user/get-info'
import getChildrenApiAsync from '@/api/user/get-children'
// 支払方法の取得(ネット決済時のみ使用)
import getPaymentMethodsApiAsync from '@/api/sale/get-payment-methods'
import getChildrenByOrgIdAndYearApiAsync from '@/api/user/get-children-by-org-id-and-year'
// お子様情報の削除
import removeChildApiAsync from '@/api/user/remove-child'

import * as errorCodes from '@/errors/codes'

export default {
  name: 'OutroPageRegister',

  data() {
    return {
      userChildren: null,
      // 支払方法
      paymentMethods: [],
      // 準備ができたか
      ready: false,
      errors: {},
      // ps-user-form に渡すために
      zipToAddressAsync,
    }
  },

  computed: {
    ...mapGetters({
      cartNum: 'cart/num',
      photoNum: 'cart/photoNum',
      cartTotal: 'cart/total',
      cartDetails: 'cart/details',
      cartPhotosSummary: 'cart/photosSummary',
      cartOverdueNum: 'cart/overdueNum',
      saleIsInTerm: 'sale/isInTerm',
      saleIsInExtra: 'sale/isInExtra',
      isPouch: 'sale/isPouch',
      isPublic: 'sale/isPublic',
      isIndividual: 'sale/isIndividual',
      // 販売にセットされている組の情報
      orgAttributes: 'sale/orgAttributes',
      overdueCharge: 'sale/overdueCharge',
      // 新しいお子様情報管理を利用するか
      useNewChildrenInfoSystem: 'sale/useNewChildrenInfoSystem',
      // 販売に紐づく年度
      fiscalYear: 'sale/fiscalYear',
      // 販売に紐づく園・学校のID
      orgId: 'sale/orgId',
      // 新しいお子様情報管理を利用する場合のクラスのリスト
      newOrgAttributes: 'sale/newOrgAttributes',
      role: 'app/role',
      appUser: 'app/user',
      screenType: 'app/screenType',
      // ストアの情報が最低限送信するのに足るか
      isSatisfied: 'order/isSatisfied',
      // 年間販売向け
      showFirstTimeConfirmOverdueDialog: 'browsing/showFirstTimeConfirmOverdueDialog',
    }),
    // ストアのうち読み書きするもの
    user: {
      get() {
        return this.$store.getters['order/user']
      },
      set(value) {
        this.$store.commit('order/setUser', value)
      },
    },
    child: {
      get() {
        return this.$store.getters['order/child']
      },
      set(value) {
        this.$store.commit('order/setChild', value)
      },
    },
    // 既存のお子様を選択した際に使用
    childAttribute: {
      get() {
        return this.$store.getters['order/childAttribute']
      },
      set(value) {
        const newChild = _.cloneDeep(this.child)
        newChild.attribute = value
        this.child = newChild
      },
    },
    destination: {
      get() {
        return this.$store.getters['order/destination']
      },
      set(value) {
        this.$store.commit('order/setDestination', value)
      },
    },
    payment: {
      get() {
        return this.$store.getters['order/payment']
      },
      set(value) {
        this.$store.commit('order/setPayment', value)
      },
    },
    selectedChildIndex: {
      get() {
        return this.$store.getters['order/selectedChildIndex']
      },
      set(value) {
        this.$store.commit('order/setSelectedChildIndex', value)
      },
    },
    destType: {
      get() {
        return this.$store.getters['order/destType']
      },
      set(value) {
        this.$store.commit('order/setDestType', value)
      },
    },
    insufficientMemberOrOverrideOneTime: {
      get() {
        return this.$store.getters['order/insufficientMemberOrOverrideOneTime']
      },
      set(value) {
        this.$store.commit('order/setInsufficientMemberOrOverrideOneTime', value)
      },
    },
    // 新しいお子様情報利用時の選択されたお子様のID
    selectedNewChildIds: {
      get() {
        return this.$store.getters['order/selectedNewChildIds']
      },
      set(value) {
        this.$store.commit('order/setSelectedNewChildIds', value)
      },
    },
    saleId() {
      return this.$route.params.saleId
    },
    // お子様の候補
    childCandidates() {
      if (!this.userChildren || this.userChildren.length === 0) return null
      const children = this.userChildren.map((child, i) => {
        return { label: `${child.name1} ${child.name2} 様`, value: i }
      })
      return [
        { label: '選択してください', value: null },
        ...children,
        { label: '新たに入力する', value: 'new' },
      ]
    },
    // このお子様は選択されたお子様か = idを持っているか
    isSelectedChild() {
      return !!_.get(this.child, 'id')
    },
    orgAttributeCandidates() {
      if (!this.orgAttributes || this.orgAttributes.length === 0) return null
      return [{ label: '選択してください', value: null }, ...this.orgAttributes]
    },
    // 先頭に表示するエラーの概要
    errorsSummary() {
      if (!this.errors || _.isEmpty(this.errors)) return null
      console.log('errors', this.errors['payment'])
      const summaries = []
      if (this.errors['customer']) summaries.push('「ご注文者の情報」が正しくありません。')
      if (this.errors['sub']) summaries.push('「お子様(参加者)情報」が正しくありません。')
      if (this.errors['dest']) summaries.push('「お届け先情報」が正しくありません。')
      if (this.errors['payment']) summaries.push('「お支払情報」が正しくありません。')
      return summaries.length > 0 ? summaries : null
    },
    // 次のステップへ進めるかどうか(最低限のチェック)
    canGoNext() {
      // カード決済の場合で入力内容が不完全な場合
      const paymentId = parseInt(this.payment.id, 10)
      if (paymentId === 2 && !this.payment.cardFormIsOK) {
        return false
      }
      // コンビニ決済で決済方法が選択されていない場合
      if (paymentId === 3 && !this.payment.conveniCvs) {
        return false
      }
      return this.isSatisfied(
        this.isPouch,
        this.isPublic,
        this.isIndividual,
        this.role === 'member' && this.useNewChildrenInfoSystem
      )
    },
    // お子様情報のattributeのエラーのみ取得
    // 登録済みのお子様を選択時のエラー表示として使用
    childAttributeError() {
      return _.get(this.errors, 'sub.attribute.message')
    },
  },

  async created() {
    try {
      // 最初にsaleの情報を取得することで販売期間ないかどうかをチェック
      await this.initSaleAsync({ id: this.saleId })
      // 販売期間または販売猶予期間かどうか
      if (!this.saleIsInTerm && !this.saleIsInExtra) {
        throw new Error(errorCodes.SALE_IS_OUT_OF_TERM)
      }

      await Promise.all([
        this.getCartInfoAsync(),
        this.getLocationAsync(),
        this.getChildrenAsync(),
        this.getPaymentMethodsAsync(),
      ])
    } catch (e) {
      this.setError(e)
      return
    }

    // 写真の点数が0枚
    if (this.photoNum === 0) {
      alert(
        '買い物カゴに写真がありません。ご注文手続きを進めるには1枚以上の写真をカゴに追加してください。'
      )
      this.$router.push({ name: 'OutroPageCart', params: { id: this.saleId } })
      return
    }

    // 集金袋なら強制的に 1 に
    if (this.isPouch) this.payment = { id: 1 }
    // カード払いなら状態を初期化
    else if (parseInt(this.payment.id, 10) === 2) {
      this.payment = { id: 2 }
    }

    // 年間販売の期間外販売手数料の対象となる写真が存在する場合に確認ダイアログ表示
    if (this.cartOverdueNum > 0 && this.overdueCharge && this.showFirstTimeConfirmOverdueDialog) {
      this.$psdialog.open({
        title: '確認',
        component: ConfirmOverdue,
        props: {
          overdueNum: this.cartOverdueNum,
          overdueCharge: this.overdueCharge,
        },
        size: 's',
        closeByClickBg: true,
        closeButton: true,
      })
      this.$store.commit('browsing/setShowFirstTimeConfirmOverdueDialog', false)
    }

    this.ready = true
  },

  watch: {
    selectedChildIndex() {
      let child = this.userChildren[this.selectedChildIndex]
      if (!child) {
        this.child = null
        return
      }
      // クラスのプリセットがない
      if (!this.orgAttributes || this.orgAttributes.length === 0) {
        this.child = child
        return
      }
      // クラスのプリセットに存在しないクラス名なら、このお子様のクラス名をクリア
      const flattenOrgAttributes = _.flatMapDeep(this.orgAttributes, value =>
        value.items ? value.items : value
      )
      console.log(flattenOrgAttributes)
      if (_.intersection(flattenOrgAttributes, [child.attribute]).length !== 1) {
        child.attribute = null
      }
      this.child = child
    },
  },

  methods: {
    ...mapActions({
      initSaleAsync: 'sale/initAsync',
      initCartAsync: 'cart/initAsync',
      validateAsync: 'order/validateAsync',
      setError: 'app/setError',
    }),

    async getCartInfoAsync() {
      // 何らかの理由でカートの中身に変更があった場合に備え強制的に初期化
      await this.initCartAsync(true)
    },

    // 登録済みの住所等の情報を取得
    async getLocationAsync(force = false) {
      // すでにユーザ情報が入っている = 一度ここに来ている なら終了
      if (this.user && !force) return

      if (this.role !== 'member') {
        this.insufficientMemberOrOverrideOneTime = true
        this.user = _.pick(this.appUser, ['name1', 'name2'])
        return
      }

      const response = await getUserInfoApiAsync()
      if (!response.ok) throw new Error(errorCodes.GET_USER_INFO_ERROR)

      this.user = _.assign({}, response.payload.user, response.payload.location)
      this.insufficientMemberOrOverrideOneTime = !this._validateUser()
    },

    // お子様の一覧の取得
    async getChildrenAsync() {
      let response
      if (this.useNewChildrenInfoSystem) {
        response = await getChildrenByOrgIdAndYearApiAsync({
          orgId: this.orgId,
          fiscalYear: this.fiscalYear,
        })
      } else {
        response = await getChildrenApiAsync()
      }
      if (!response.ok) throw new Error(errorCodes.GET_CHILDREN_ERROR)
      this.userChildren = response.payload.items
      // すべて選択しておく
      if (this.useNewChildrenInfoSystem) {
        this.selectedNewChildIds = _.map(this.userChildren, child => child.id)
      }
    },

    // 支払方法の取得
    async getPaymentMethodsAsync() {
      // 集金袋方式なら終了
      if (this.isPouch) return
      const response = await getPaymentMethodsApiAsync({ id: this.saleId })
      if (!response.ok) throw new Error(errorCodes.GET_PAYMENT_METHODS_ERROR)
      this.paymentMethods = response.payload.items
    },

    // ユーザ情報変更ダイアログの表示
    showChangeUserInfoDialog() {
      this.$psdialog.open({
        title: '登録情報の更新',
        component: UserInfo,
        props: {
          value: this.user,
        },
        size: 'm',
        closeByClickBg: true,
        closeButton: true,
        onClose: async params => {
          try {
            if (params && params.updated) await this.getLocationAsync(true)
          } catch (e) {
            this.setError(e)
          }
        },
      })
    },

    // 今回のみユーザ情報変更
    changeUserInfoOneTime() {
      this.insufficientMemberOrOverrideOneTime = true
    },

    // 内容確認へ
    async goToNextAsync() {
      if (!this.canGoNext) return

      // ダミー要素へfocus&blurすることで、iOS Safari 12の
      // スクリーンキーボードが出たままになる問題に対応
      this.$el.querySelector('input.dummy').focus()
      this.$el.querySelector('input.dummy').blur()

      let errors
      try {
        // カード決済なら決済用のトークンを取得
        if (parseInt(this.payment.id, 10) === 2) {
          await this.getCardToken()
        }
        errors = await this.validateAsync({
          saleId: this.saleId,
          isPouch: this.isPouch,
          isPublic: this.isPublic,
          isIndividual: this.isIndividual,
          useNewChildrenInfoSystem: this.role === 'member' && this.useNewChildrenInfoSystem,
        })
      } catch (e) {
        this.setError(e)
        return
      }

      if (errors) {
        this.errors = errors
        this.$scrollBodyToAsync(0)
        return
      }
      // 成功なら確認ページヘ
      this.$router.push({ name: 'OutroPageConfirm', params: { saleId: this.saleId } })
    },

    // クレジットカードのトークンの取得
    async getCardToken() {
      if (!this.$refs.paymentForm) return Promise.reject()
      const cardToken = await this.$refs.paymentForm.getCardToken()
      this.payment = {
        ...this.payment,
        cardToken,
      }
    },

    showNewChildInfoDialog() {
      this.$psdialog.open({
        title: 'お子様(参加者)情報の追加',
        component: NewChildInfo,
        size: 'm',
        closeButton: true,
        props: {
          attributes: this.newOrgAttributes,
          fiscalYear: this.fiscalYear,
          orgId: this.orgId,
        },
        onClose: params => {
          // TODO: 適切なエラー処理
          if (params && params.updated) {
            this.getChildrenAsync()
          }
        },
      })
    },

    // 更新のためにお子様情報ダイアログを開く
    showNewChildInfoDialogForUpdate(child) {
      // 実際に存在するクラス名しか採用しない
      let attribute = null
      if (
        _.flattenDeep([
          _.map(this.newOrgAttributes.grade, item => item.items),
          _.map(this.newOrgAttributes.special, item => item.items),
        ]).indexOf(child.attribute) !== -1
      ) {
        attribute = child.attribute
      }
      // 学年が正しい値かどうか
      const grade = _.isFinite(_.get(child, 'grade.value')) ? parseInt(child.grade.value, 10) : null

      this.$psdialog.open({
        title: 'お子様(参加者)情報の編集',
        component: NewChildInfo,
        size: 'm',
        closeButton: true,
        props: {
          attributes: this.newOrgAttributes,
          fiscalYear: this.fiscalYear,
          orgId: this.orgId,
          targetId: child.id,
          targetValues: {
            ...child,
            attribute,
            grade,
          },
        },
        onClose: params => {
          // TODO: 適切なエラー処理
          if (params && params.updated) {
            this.getChildrenAsync()
          }
        },
      })
    },

    // お子様情報の削除
    async removeChildAsync(child) {
      if (!confirm('削除してもよろしいですか？')) return
      const params = { id: child.id }
      let response
      try {
        response = await removeChildApiAsync(params)
      } catch (e) {
        this.setError(e)
        return
      }
      if (!response.ok) return
      await this.getChildrenAsync()
    },

    showSecurityCodeDetailDialog() {
      this.$psdialog.open({
        title: 'セキュリティコードについて',
        component: SecurityCodeDetail,
        size: 's',
        closeByClickBg: true,
        closeButton: true,
      })
    },

    goToPrev() {
      this.$router.push({ name: 'OutroPageCart', params: { saleId: this.saleId } })
    },

    // ユーザ情報が十分かどうかチェック
    _validateUser() {
      const keys = [
        'name1',
        'name1Kana',
        'name2',
        'name2Kana',
        'zipcode',
        'prefecture',
        'address1',
        'address2',
        'tel',
      ]
      const picked = _.pick(this.user, keys)
      return _.compact(_.values(picked)).length === keys.length
    },

    // お子様情報入力で本人を選択した
    selectMyself() {
      this.child = _.assign({}, this.child, {
        name1: _.get(this.user, 'name1'),
        name2: _.get(this.user, 'name2'),
        name1Kana: _.get(this.user, 'name1Kana'),
        name2Kana: _.get(this.user, 'name2Kana'),
      })
    },

    // 新しいお子様管理のお子様がクリックされた
    clickNewChildListItem(child) {
      this.selectedNewChildIds = _.xor(this.selectedNewChildIds, [child.id])
    },

    // エラーの取得
    getErrors(key) {
      return _.get(this.errors, key, {})
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../../../../sass/variables.sass'
@import '../../../../sass/mixins.sass'

.outro-page-register

  .cart-info-mobile
    margin-bottom: 1.5rem

  .main

    .form
      background-color: $white
      padding: 1.5rem 1rem
      border-radius: $radius-small
      box-shadow: 0 1px 6px 2px rgba(0, 0, 0, .25)
      +mobile
        padding: 1rem 0.5rem
        box-shadow: 0 1px 1px 1px rgba(0, 0, 0, .3)

      > article
        margin-bottom: 3rem
        &:last-child
          margin-bottom: 2rem

        &.errors-summary
          margin-bottom: 1.5rem
          ul
            border: 2px solid $red
            border-radius: $radius
            background-color: lighten($pink, 18%)
            padding: 0.5rem
            li
              margin: 0 0 0.5rem 1.5rem
              list-style-type: disc
              color: $red
              &:last-child
                margin-bottom: 0

        .wrapper
          > p
            line-height: 1.4
            margin-bottom: 0.5rem
            &:last-child
              margin-bottom: 0

        &.child
          .new-child-list
            > p
              padding: 0
              line-height: 1
              margin-bottom: 1rem
            ul
              max-width: 500px
              margin-bottom: 0.5rem
              li
                display: flex
                align-items: center
                background-color: $white-bis
                border: 2px solid $grey-lighter
                border-radius: $radius
                cursor: pointer
                padding: 0.2rem 0.8rem
                > :nth-child(1)
                  color: $grey-dark
                > :nth-child(2)
                  margin-left: 0.5rem
                  flex: 1
                > :nth-child(3)
                  font-size: $size-small
                  color: darken($blue, 10%)
                  text-decoration: underline
                  > :nth-child(2)
                    margin-left: 0.3rem
                &:nth-child(n+2)
                  margin-top: 0.5rem
                &.selected
                  background-color: lighten($orange, 25%)
                  border: 2px solid lighten($orange, 10%)
                  > :nth-child(1)
                    color: $black-bis
            .caution
              color: $red
          p
            padding: 0.5rem 0
            line-height: 1.4

        &.destination
          .dests
            max-width: 400px
            > :first-child
              margin-bottom: 0.5rem

        &.buttons
          // ダミーチェックボックス
          input.dummy
            opacity: 0
          text-align: center
          > :nth-child(2)
            margin-right: 10px

  .side
    width: 260px
    .cart-info-outer
      background-color: #e4f7b7
</style>
