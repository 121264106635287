// 一般向けページの共通部分

<script>
export default {
  name: 'PublicPage',
}
</script>

<template lang="pug">
.public-page
  
  // ヘッダ
  header.header
    common-header(:show-menu='false')

  section
    ps-container
      router-view

  common-footer.footer

</template>

<style lang="sass" scoped>
.public-page
  .header
    margin-bottom: 2rem
  .footer
    position: absolute
    bottom: 0
    left: 0
    right: 0
</style>
