import { render, staticRenderFns } from "./mobile-category-menu.vue?vue&type=template&id=0fb0537a&scoped=true&lang=pug&"
import script from "./mobile-category-menu.vue?vue&type=script&lang=js&"
export * from "./mobile-category-menu.vue?vue&type=script&lang=js&"
import style0 from "./mobile-category-menu.vue?vue&type=style&index=0&lang=sass&"
import style1 from "./mobile-category-menu.vue?vue&type=style&index=1&id=0fb0537a&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0fb0537a",
  null
  
)

export default component.exports