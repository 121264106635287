// パスワード変更ダイアログ

<script>
import changePasswordApiAsync from '@/api/user/change-password'

export default {
  name: 'ChangePassword',

  data() {
    return {
      oldPassword: null,
      newPassword: null,
      error: null,
    }
  },

  computed: {
    validated() {
      return this.oldPassword && this.newPassword
    },
  },

  methods: {
    async changePasswordAsync() {
      this.error = null

      const body = {
        old: this.oldPassword,
        new: this.newPassword,
      }
      const response = await changePasswordApiAsync(null, body)

      if (!response.ok) {
        this.error = ' '
        return
      }

      this.$psdialog.close()

      alert('パスワードの変更が完了しました。')
    },
  },
}
</script>

<template lang="pug">
.change-password

  p.info 新しいパスワードは半角英数字8文字以上で入力してください。
  
  p.error-message(v-if='error') パスワードが正しくありません。

  .form
    ps-form-item(label='現在のパスワード', hankaku, required, :error='error')
      ps-input(full-width, type='password', v-model='oldPassword')
    ps-form-item(label='新しいパスワード', hankaku, required, :error='error')
      ps-input(full-width, type='password', v-model='newPassword')

  .buttons
    ps-button(@click='$psdialog.close') 閉じる
    ps-button(
      color='green',
      large-width,
      @click='changePasswordAsync'
    ) 変更

</template>

<style lang="sass" scoped>
@import '../../../sass/variables.sass'

.change-password
  > .info
    margin-bottom: 0.5rem
  > .error-message
    color: $red
    margin-bottom: 0.5rem
  > .form
    margin-bottom: 1rem
  > .buttons
    text-align: center
    > :first-child
      margin-right: 1rem
</style>
