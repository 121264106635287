// 認可取得済みの販売リストの取得

import apiFactory from '../api-factory'

import dateHelper from '../../helpers/date-helper'

// 7日分の秒数。カゴ決済の猶予期間が何日までかを算出するのに使用
const sevenDaysSec = 86400 * 7

export default apiFactory({
  path: '/my/sales',
  method: 'GET',
  needAuth: true,
  responseMapper: {
    sales: {
      key: 'items',
      transform: value => value.map(_transform),
    },
  },
})

function _transform(item) {
  return {
    id: item.id,
    name: item.name,
    orgName: item.org_name,
    daysLeft: item.days_left,
    start: dateHelper.toJpMMDD(item.term[0] * 1000, true),
    end: dateHelper.toJpMMDD(item.term[1] * 1000, true),
    endFull: dateHelper.toJpYYYYMMDD(item.term[1] * 1000, true),
    status: item.status,
    extraLimit: dateHelper.toJpMMDD((item.term[1] + sevenDaysSec) * 1000, true),
    // 年間販売かどうか
    isLongrun: !!item.is_longrun,
    // 年間販売に紐づく販売
    subSales: _transformSubSales(item.sub_sales),
    // 年間販売の1枚あたりの値段
    overdueCharge: item.settings.is_overdue_charge_disabled ? 0 : item.settings.overdue_charge,
  }
}

function _transformSubSales(subSales) {
  if (!subSales || subSales.length === 0) return null
  return _.map(subSales, sale => {
    return {
      id: sale.id,
      name: sale.name,
      isOverdue: !!sale.is_overdue,
      photoStartIndex: sale.photo_start_index,
      start: dateHelper.toJpYYYYMMDD(sale.t_start * 1000, true),
      due: dateHelper.toJpYYYYMMDD(sale.t_due * 1000, true),
    }
  })
}
