// 日付入れダイアログ

<script>
import dateHelper from '@/helpers/date-helper'

// 日付選択ダイアログ
import DateSelector from '../date-selector'

// 文字色。API側の仕様により大文字はダメらしい...
const colors = [
  { color: '#333333', borderColor: '#aaaaaa', borderSize: 1 },
  { color: '#e60012', borderColor: '#e86f78', borderSize: 1 },
  { color: '#f39800', borderColor: '#ffca72', borderSize: 1 },
  { color: '#009944', borderColor: '#75eaa9', borderSize: 1 },
  { color: '#0068b7', borderColor: '#71b1e2', borderSize: 1 },
  { color: '#920783', borderColor: '#d875cd', borderSize: 1 },
  { color: '#ffb2bf', borderColor: '#f2697f', borderSize: 1 },
  { color: '#fff570', borderColor: '#f7e71d', borderSize: 1 },
  { color: '#b3ebff', borderColor: '#69cff2', borderSize: 1 },
]

const verticals = [
  { code: 'top', name: '上' },
  { code: 'bottom', name: '下' },
]
const horizontals = [
  { code: 'left', name: '左' },
  { code: 'right', name: '右' },
]

// 前回選択した日付
let recentlyDate = null

export default {
  name: 'InsertingDate',

  props: {
    // カートに入っているか。入っているかによって、確定ボタンのテキストが変わる
    inCart: Boolean,
  },

  data() {
    return {
      date: recentlyDate,
      // 選択中のフォーマットのインデックス
      formatIndex: 0,
      // 選択可能な色
      colors,
      // 選択中の色のインデックス。デフォルトはオレンジに
      colorIndex: 2,
      // 選択可能な位置
      verticals,
      horizontals,
      // 選択中の位置
      position: 'bottom-right',
    }
  },

  computed: {
    // 日付の表記の選択肢
    formats() {
      const parsed = dateHelper.parseDateString(this.date)
      return [
        `${parsed.year}年${parsed.month}月`,
        `${parsed.jpYear}年${parsed.month}月`,
        `${parsed.year}/${parsed.month}/${parsed.date}`,
        `${parsed.jpYear}年${parsed.month}月${parsed.date}日`,
      ]
    },

    selectedDate() {
      const parsed = dateHelper.parseDateString(this.date)
      return `${parsed.year}/${parsed.month}/${parsed.date}`
    },
  },

  methods: {
    close(ok) {
      if (!ok || !this.date) {
        this.$psdialog.close()
        return
      }
      let params = {
        text: this.formats[this.formatIndex],
        vertical: this.position.split('-')[0],
        horizontal: this.position.split('-')[1],
      }
      _.assign(params, this.colors[this.colorIndex])
      this.$psdialog.close(params)
    },

    showDateSelectorDialog() {
      this.$psdialog.open({
        component: DateSelector,
        props: { date: this.date },
        size: 's',
        closeByClickBg: true,
        closeButton: true,
        onClose: date => {
          if (date) {
            this.date = date
            // 次にダイアログ表示した時ように保持
            recentlyDate = date
          }
        },
      })
    },
  },
}
</script>

<template lang="pug">
.inserting-date
  .option.date
    p.title 日付:
    a.value(@click='showDateSelectorDialog')
      span {{ selectedDate }}&nbsp;
      span [変更]

  .option.format
    p.title 形式:
    ul
      li(v-for='format, i in formats', :class='{ active: i === formatIndex }')
        a(@click='formatIndex = i') {{ format }}

  ps-row(:gutter='10')

    ps-col
      .option.color
        p.title カラー:
        ul
          li(v-for='color, i in colors', :class='{ active: i === colorIndex }')
            a(:style='{ backgroundColor: color.color }', @click='colorIndex = i')

    ps-col
      .option.position
        p.title 位置:
        .frame
          template(v-for='v in verticals')
            template(v-for='h in horizontals')
              a(
                :class='[ v.code, h.code, { active: position === `${v.code}-${h.code}` } ]',
                @click='position = `${v.code}-${h.code}`'
              ) {{ h.name }}{{ v.name }}
  .buttons
    ps-button(@click='close') 戻る
    ps-button(v-if='inCart', @click='close(true)', color='green', large-width, :disabled='!date') 実行
    ps-button(v-else, @click='close(true)', color='pink', :disabled='!date') 実行してカゴに追加
</template>

<style lang="sass" scoped>
@import '../../../sass/mixins.sass'

.inserting-date

  .option
    margin-bottom: 10px
    .title
      font-weight: bold
      margin-bottom: 0.2rem
      line-height: 1

    &.date
      .value
        display: block
        padding: 0.5rem 0
        text-align: center
        background-color: lighten($blue, 43%)
        border-radius: $radius-small
        cursor: pointer
        line-height: 1
        > span:first-child
          font-size: $size-large
          font-weight: bold
          letter-spacing: 1px
        > span:last-child
          color: darken($blue, 10%)
          text-decoration: underline

    // 日付フォーマットの選択
    &.format
      ul
        overflow: hidden
        li
          width: 50%
          float: left
          a
            margin: 0.2rem
            display: block
            border: 1px solid $grey-light
            padding: 0.5rem 0
            border-radius: $radius
            text-align: center
            cursor: pointer
            font-size: $size-small
          &.active
            > a
              background-color: lighten($blue, 40%)
              border: 1px solid $blue
              font-weight: bold
              color: darken($blue, 10%)

    // 色の選択
    &.color
      ul
        overflow: hidden
        li
          float: left
          padding: 3px
          width: 33%
          border: 1px solid $white
          &.active
            background-color: lighten($blue, 40%)
            border: 1px solid $blue
            border-radius: $radius-small
          a
            display: block
            height: 1.5rem
            border-radius: $radius-small
            cursor: pointer

    // 位置の選択
    &.position
      .frame
        position: relative
        border: 1px solid $grey-lighter
        height: 6rem
        background-color: $white-ter
        border-radius: $radius-small
        > a
          position: absolute
          display: block
          border: 1px solid $grey-lighter
          width: 40%
          text-align: center
          padding: 0.3rem 0
          cursor: pointer
          background-color: $white
          border-radius: $radius-small
          +mobile
            padding: 0.1rem 0

          &.left
            left: 3px
          &.right
            right: 3px
          &.top
            top: 3px
          &.bottom
            bottom: 3px

          &.active
            background-color: lighten($blue, 40%)
            border: 1px solid $blue
            font-weight: bold
            color: darken($blue, 10%)

  > .buttons
    text-align: center
    > :first-child
      margin-right: 1rem
</style>
