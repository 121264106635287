// モバイル向けの固定ヘッダナビゲーション

<template lang="pug">
ps-row.mobile-header-nav

  template(v-if='canSearchFaces')
    ps-col.col(v-if='!okaoNow')
      a(@touchend.prevent='throttledTap("startFaceSearch")', @mousedown.prevent='throttledTap("startFaceSearch")')
        p
          ps-icon.icon(icon='smile')
          br
          span お顔検索
    ps-col.col(v-else)
      a(@touchend.prevent='throttledTap("endFaceSearch")', @mousedown.prevent='throttledTap("startFaceSearch")')
        p
          ps-icon.icon(icon='reply')
          br
          span お顔検索終了

  ps-col.col
    a(@touchend.prevent='throttledTap("toggleThumbnailSize")', @mousedown.prevent='throttledTap("toggleThumbnailSize")')
      p
        ps-icon.icon(:icon='displayPhotoSize === "large" ? "compress" : "expand"')
        br
        span 表示サイズ
  
  //- お顔検索の結果表示中は表示しない
  ps-col.col(v-if='!okaoNow')
    a(@touchend.prevent='throttledTap("clickShowCategory")', @mousedown.prevent='throttledTap("clickShowCategory")')
      p
        ps-icon.icon(icon='book')
        br
        span カテゴリー

  ps-col.col
    a(@touchend.prevent='throttledTap("goToCart")', @mousedown.prevent='throttledTap("goToCart")')
      p
        ps-icon.icon(icon='shopping-cart')
        br
        span 買い物カゴ
    .cart-num(v-show='cartNum') {{ cartNum }}

  ps-col.col
    a(@touchend.prevent='throttledTap("clickShowMenu")', @mousedown.prevent='throttledTap("clickShowMenu")')
      p
        ps-icon.icon(icon='bars')
        br
        span メニュー

</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'MobileHeaderNav',

  data() {
    return {
      // メニューをタップした場合、イベント発火後、300msの間はwaitする
      throttledTap: _.throttle(this.tap, 300, { trailing: false }),
    }
  },

  computed: {
    ...mapGetters({
      // お顔検索使用中かどうか
      okaoNow: 'browsing/okaoNow',
      // お顔検索が利用可能か
      canSearchFaces: 'sale/canSearchFaces',
      // カゴに入っている商品点数
      cartNum: 'cart/num',
      // 表示サイズ(large or small)
      displayPhotoSize: 'browsing/displayPhotoSize',
    }),
  },

  methods: {
    tap(event) {
      if (event) {
        if (event === 'toggleThumbnailSize') this.toggleDisplaySize()
        else this.$emit(event)
      }
    },

    // 表示サイズのトグル
    toggleDisplaySize() {
      const size = this.displayPhotoSize === 'large' ? 'small' : 'large'
      this.$store.dispatch('browsing/setDisplayPhotoSize', size)
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../../../sass/variables.sass'
@import '../../../sass/mixins.sass'

.mobile-header-nav
  z-index: 80
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 50px
  background-color: $orange
  box-shadow: 0 0 4px 0 rgba(#000, .3)
  .col
    position: relative
    &:last-child a
      border-right: none !important
    a
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
      cursor: pointer
      display: flex
      border-right: 1px solid rgba($white, .3)
      justify-content: center
      align-items: center

      p
        text-align: center
        color: $white
        text-shadow: 0 0 3px darken($orange, 30%)
        .icon
          font-size: 20px
        span
          font-size: $size-small

    .cart-num
      position: absolute
      top: 10%
      right: 10%
      background-color: $red
      color: $white
      font-size: $size-smallest
      padding: 2px 4px
      border-radius: 6px
</style>
