// 自分のトークンの情報を取得

import apiFactory from '../api-factory'

// 判定すべきロール
const roles = ['member', 'guest']

export default apiFactory({
  path: '/token_infos',
  method: 'GET',
  needAuth: true,
  responseMapper: {
    id: 'id',
    email: 'email',
    name1: [
      {
        key: 'name',
        // roleがpublicの場合は名前がない
        transform: (value, srcObj) => {
          if (value && srcObj.name2) return `${value} ${srcObj.name2}`
          return null
        },
      },
      'name1',
    ],
    name2: 'name2',
    roles: {
      key: 'role',
      transform: values => {
        return _.get(_.intersection(values, roles), '0', 'public')
      },
    },
  },
})
