// ランダムにサムネイルを取得

import apiFactory from '../api-factory'

export default apiFactory({
  path: '/sales/<%= id %>/thumbnails',
  method: 'GET',
  expectedParams: ['id'],
  needAuth: true,
  responseMapper: {
    'thumbnails[].url': {
      key: 'items',
      transform: (values, srcObj) => {
        return values.map(value => {
          // URLにクエリパラメータが含まれているかどうか
          return /\?/.test(value)
            ? // U含まれている場合はそのまま返す
              value
            : // UR含まれていなければ `?token=...` を付加して返す
              `${value}?token=${srcObj.thumbnail_token}`
        })
      },
    },
  },
})
