// 注文情報のバリデーション

import apiFactory from '../api-factory'

import bodyMapper from './order-body-mapper'

export default apiFactory({
  path: '/sales/<%= id %>/orders',
  method: 'PUT',
  expectedParams: ['id'],
  needAuth: true,
  bodyMapper,
})
