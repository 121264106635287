// トリミングの解除

import apiFactory from '../api-factory'

export default apiFactory({
  path: '/my/custom_photos/trim/discard',
  method: 'POST',
  needAuth: true,
  bodyMapper: {
    saleId: 'sale_id',
    photoId: 'photo_id',
    async: {
      key: 'async',
      transform: value => (value ? 1 : 0),
      default: 0,
    },
  },
})
