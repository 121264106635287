// 注文情報ストア

import convenis from '@/helpers/convenis'

// ステート
const state = {
  // APIに送信する情報
  user: null,
  child: null,
  destination: null,
  // デフォルトはカード決済(id=2)
  payment: { id: '2' },
  // 注文情報入力ページのUI用。どのお子様を選んだか
  selectedChildIndex: null,
  // 新しいお子様情報利用時の選択されたお子様のID
  selectedNewChildIds: [],
  // 送り先の種類
  destType: 'same',
  // メンバーだけどlocation等の情報が足りないまたは、
  // メンバーだけど今回のみユーザ情報を上書きしたい
  insufficientMemberOrOverrideOneTime: true,
}

// ゲッタ
const getters = {
  user(state) {
    return state.user
  },
  child(state) {
    return state.child
  },
  destination(state) {
    return state.destination
  },
  payment(state) {
    return state.payment
  },
  selectedChildIndex(state) {
    return state.selectedChildIndex
  },
  selectedNewChildIds(state) {
    return state.selectedNewChildIds
  },
  destType(state) {
    return state.destType
  },
  // 注文情報に最低限必要な情報が含まれているか
  isSatisfied: state => (isPouch, isPublic, isIndividual, useNewChildrenInfoSystem = false) => {
    // ユーザ情報はどの場合でも必須
    if (!state.user) return false
    // 学校向けか
    if (!isPublic) {
      if (useNewChildrenInfoSystem) {
        // 新しいお子様情報を利用
        if (!state.selectedNewChildIds || state.selectedNewChildIds.length === 0) return false
      } else {
        // 旧来のお子様情報を利用
        if (!state.child) return false
      }
    }
    // 個別発送で発送先を選んでいないのはダメ
    if (isIndividual && !state.destType) return false
    // 集金以外で支払い方法が選択されていないのはダメ
    if (!isPouch && !state.payment) return false
    return true
  },
  insufficientMemberOrOverrideOneTime(state) {
    return state.insufficientMemberOrOverrideOneTime
  },
  // 確認画面用のゲッタ
  // ユーザ
  userName(state) {
    const name1 = _.get(state.user, 'name1') || ''
    const name2 = _.get(state.user, 'name2') || ''
    return `${name1} ${name2}`
  },
  userNameKana(state) {
    const name1 = _.get(state.user, 'name1Kana') || ''
    const name2 = _.get(state.user, 'name2Kana') || ''
    return `${name1} ${name2}`
  },
  userZipcode(state) {
    return _.get(state.user, 'zipcode') || ''
  },
  userAddress1(state) {
    const prefecture = _.get(state.user, 'prefecture') || ''
    const address1 = _.get(state.user, 'address1') || ''
    return `${prefecture}${address1}`
  },
  userAddress2(state) {
    const address2 = _.get(state.user, 'address2') || ''
    const address3 = _.get(state.user, 'address3') || ''
    return `${address2}${address3}`
  },
  userTel(state) {
    return _.get(state.user, 'tel') || ''
  },
  // お子様
  childRelation(state) {
    return _.get(state.child, 'relation') || ''
  },
  childName(state) {
    const name1 = _.get(state.child, 'name1') || ''
    const name2 = _.get(state.child, 'name2') || ''
    return `${name1} ${name2}`
  },
  childNameKana(state) {
    const name1 = _.get(state.child, 'name1Kana') || ''
    const name2 = _.get(state.child, 'name2Kana') || ''
    return `${name1} ${name2}`
  },
  childAttribute(state) {
    return _.get(state.child, 'attribute') || ''
  },
  // お届け先
  destinationName(state) {
    const name1 = _.get(state.destination, 'name1') || ''
    const name2 = _.get(state.destination, 'name2') || ''
    return `${name1} ${name2}`
  },
  destinationNameKana(state) {
    const name1 = _.get(state.destination, 'name1Kana') || ''
    const name2 = _.get(state.destination, 'name2Kana') || ''
    return `${name1} ${name2}`
  },
  destinationZipcode(state) {
    return _.get(state.destination, 'zipcode') || ''
  },
  destinationAddress1(state) {
    const prefecture = _.get(state.destination, 'prefecture') || ''
    const address1 = _.get(state.destination, 'address1') || ''
    return `${prefecture}${address1}`
  },
  destinationAddress2(state) {
    const address2 = _.get(state.destination, 'address2') || ''
    const address3 = _.get(state.destination, 'address3') || ''
    return `${address2}${address3}`
  },
  destinationTel(state) {
    return _.get(state.destination, 'tel') || ''
  },
  // 支払い方法の情報
  paymentMethod(state) {
    const type = parseInt(_.get(state.payment, 'id'), 10)
    if (type === 1) {
      return '集金袋'
    } else if (type === 2) {
      // カード払い
      return 'クレジットカード'
    } else if (type === 3) {
      const cvv = _.get(state.payment, 'conveniCvs')
      const conveni = _.find(convenis, item => item.code === cvv) || {}
      return `コンビニ払い (${conveni.name})`
    } else if (type === 4) {
      return '払込票 (郵便局) (手数料: 134円)'
    }
    return ''
  },
}

// ミューテーション
const mutations = {
  setUser(state, payload) {
    state.user = payload
  },
  setChild(state, payload) {
    state.child = payload
  },
  setDestination(state, payload) {
    state.destination = payload
  },
  setPayment(state, payload) {
    state.payment = payload
  },
  setSelectedChildIndex(state, payload) {
    state.selectedChildIndex = payload
  },
  setSelectedNewChildIds(state, payload) {
    state.selectedNewChildIds = payload
  },
  setDestType(state, payload) {
    state.destType = payload
  },
  setInsufficientMemberOrOverrideOneTime(state, payload) {
    state.insufficientMemberOrOverrideOneTime = payload
  },
}

export default { state, getters, mutations }
