// コンビニ固有の支払い番号の名称や、
// 支払い方法の説明

export default {
  seven: {
    numberName: '払込票番号',
    description:
      '払込票をプリントアウトする、または払込票番号を控えて、レジにて代金をお支払いください。',
    howToUrl: 'http://www.epsilon.jp/mb/conv/seven/index.html',
  },
  famima: {
    numberName: 'お支払用注文番号',
    description:
      '店内に設置されているFamiポート／ファミネットにて「企業コード」と「注文番号」を入力し、申込券を印字後、レジにて代金をお支払い下さい。',
    howToUrl: 'http://www.epsilon.jp/mb/conv/famima/index.html',
  },
  lawson: {
    numberName: '受付番号',
    description:
      '店内に設置されているLoppiにて「受付番号」を入力し、申込券を印字後、レジにて代金をお支払い下さい。',
    howToUrl: 'http://www.epsilon.jp/mb/conv/lawson/index.html',
  },
  mini: {
    numberName: '受付番号',
    description:
      '店内に設置されているLoppiにて「受付番号」を入力し、申込券を印字後、レジにて代金をお支払い下さい。',
    howToUrl: 'http://www.epsilon.jp/mb/conv/lawson/index.html',
  },
  circle: {
    numberName: '受付番号',
    description:
      '店内に設置されているKステーションにて「受付番号」を入力し、申込券を印字後、レジにて代金をお支払い下さい。',
    howToUrl: 'http://www.epsilon.jp/mb/conv/circlek_sunkus/index.html',
  },
  sunkus: {
    numberName: '受付番号',
    description:
      '店内に設置されているKステーションにて「受付番号」を入力し、申込券を印字後、レジにて代金をお支払い下さい。',
    howToUrl: 'http://www.epsilon.jp/mb/conv/circlek_sunkus/index.html',
  },
}
