// クレジットカード関連のユーティリティメソッド

// カードブランド
export const brands = {
  visa: 'visa',
  master: 'master',
  jcb: 'jcb',
  amex: 'amex',
  diners: 'diners',
}

// カード番号が正しいかをLuhnアルゴリズムを使ってチェック
export const checkNumbers = value => {
  if (!value) return false
  // ハイフン除去カード番号を1文字ずつに分割し並び順を反転
  const strDigits = value
    .replace(/[- ]/g, '')
    .split('')
    .reverse()
  // 桁数が14〜16かどうかをチェック
  if (strDigits.length < 14 || strDigits.length > 16) return false
  // 偶数番目を2倍にする
  const digits = strDigits.map((value, i) => (i % 2 ? parseInt(value, 10) * 2 : parseInt(value)))
  // 和を求める。ただし、n > 9 なら 9 引く
  const sum = digits.reduce((prev, curr) => prev + (curr > 9 ? curr - 9 : curr), 0)
  // mod 10 が 0 ならOK
  return sum % 10 === 0
}

// カードブランドの判定
export const getBrand = value => {
  if (!value) return null
  // ハイフンやスペースを除去
  const normalized = value.replace(/[- ]/g, '')
  // ビザは4から始まる
  if (/^4/.test(normalized)) return brands.visa
  // マスターは51-55から始まる
  else if (/^5[1-5]/.test(normalized)) return brands.master
  // JCBは35から始まるL
  else if (/^35/.test(normalized)) return brands.jcb
  // アメックスは34または37から始まる
  else if (/^3[47]/.test(normalized)) return brands.amex
  // ダイナースは30,36,38から始まる
  else if (/^3[068]/.test(normalized)) return brands.diners
  return null
}
