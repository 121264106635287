// 閲覧中の販売
// 過去販売を閲覧中であればその過去販売の情報

// ステート
const state = {
  id: null,
  // 基本情報
  basicInfo: null,
  // カテゴリツリーの情報
  categoryTree: null,
  // カテゴリの境界マップ
  categoryBoundMap: null,
}

// ゲッタ
const getters = {
  id(state) {
    return state.id
  },
  name(state) {
    return _.get(state, 'basicInfo.name')
  },
  minPhotoIndex(state) {
    return _.get(state, 'basicInfo.minPhotoIndex')
  },
  maxPhotoIndex(state) {
    return _.get(state, 'basicInfo.maxPhotoIndex')
  },
  eventIdNotSaleId(state) {
    return _.get(state, 'basicInfo.eventIdNotSaleId')
  },
  categoryTree(state) {
    return state.categoryTree
  },
  // 過去販売かどうか
  isOldSale(state, getters, rootState) {
    return state.id !== rootState.sale.id
  },
}

// ミューテーション
const mutations = {
  setId(state, payload) {
    state.id = parseInt(payload, 10)
  },
  setBasicInfo(state, payload) {
    state.basicInfo = payload
  },
  setCategoryTree(state, payload) {
    state.categoryTree = payload
  },
  setCategoryBoundMap(state, payload) {
    state.categoryBoundMap = payload
  },
}

export default { state, getters, mutations }
