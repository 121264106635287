// 実際に閲覧する販売の情報の取得

import apiFactory from '../api-factory'

export default apiFactory({
  path: '/sales/<%= id %>/<%= subId %>',
  method: 'GET',
  expectedParams: ['id', 'subId'],
  needAuth: true,
  responseMapper: {
    'detail.events[0].min_photo_index': 'minPhotoIndex',
    'detail.events[0].max_photo_index': 'maxPhotoIndex',
    name: 'name',
    // 価格表の取得にイベントIdが必要なため
    'detail.events[0].id': 'eventIdNotSaleId',
  },
})
