// お顔検索を実際に実行するダイアログ

<template lang="pug">
.search-faces
  template(v-if='loading')
    p.loading
      ps-icon(icon='spinner', spin)
      | &nbsp;お顔検索中...
  template(v-else)
    p.result
      ps-icon(icon='smile-o')
      | &nbsp;検索結果:&nbsp;
      span {{ totalCount }}枚
    ul
      li 横顔や小さく写っているお顔の写真は検索されない可能性があります。
      li 検索結果の枚数が少ない場合は、お顔画像の変更をお試しください。
    .buttons
      ps-button(@click='close', color='green', large-width)
        | {{ totalCount > 0 ? '閲覧する' : '閉じる' }}
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'SearchFaces',

  props: {
    saleId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      loading: true,
    }
  },

  async created() {
    try {
      await this.search(this.saleId)
      this.loading = false
    } catch (e) {
      this.setError(e)
    }
  },

  computed: {
    ...mapGetters({
      totalCount: 'face/totalCount',
    }),
  },

  methods: {
    ...mapActions({
      search: 'face/search',
      setMode: 'browsing/setMode',
      setError: 'app/setError',
    }),

    // ダイアログを閉じる。検索結果が 1以上 = 検索成功 なら
    // 閲覧の表示モードをお顔検索に切り替え
    close() {
      if (this.totalCount > 0) {
        this.setMode('okao')
        // ダイアログをすべて閉じる
        this.$psdialog.closeAll()
      } else {
        this.$psdialog.close()
      }
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../../../sass/variables.sass'

.search-faces
  p.loading
    font-size: $size-large
    text-align: center
    padding: 2rem 0
  p.result
    text-align: center
    line-height: 2rem
    font-size: $size-medium
    margin: 1rem 0
    color: darken($blue, 25%)
    > span
      font-size: $size-large
      font-weight: bold
      color: $red
      background-color: lighten($yellow, 40%)
      display: inline-block
      padding: 0.1rem 0.8rem
      border-radius: $radius-small
  > ul
    padding: 0 0.5rem 0 1.5rem
    border: 1px dashed $grey-lighter
    border-radius: $radius-small
    background-color: $white-bis
    margin-bottom: 1rem
    li
      font-size: $size-small
      list-style-type: disc
      margin: 0.5rem 0
  .buttons
    text-align: center
</style>
