// 写真のindex番号移動のためのスライダ

<template lang="pug">
  .header-slider
    ps-row(valign='center', :gutter='5')
      ps-col.title(:span='0')
        p 現在位置:
      ps-col.slider
        .outer
          vue-slider(
            v-if='currentPhotoIndex',
            class='browse-slider',
            :min='minPhotoIndex',
            :max='maxPhotoIndex',
            :height='8',
            :lazy='true',
            tooltip='hover',
            tooltip-dir='bottom',
            :value='currentPhotoIndex',
            formatter='No.{value}',
            :process-style='sliderProcessStyle',
            :tooltip-style='sliderTooltipStyle',
            @callback='changeValue'
          )
      ps-col.info(:span='0')
        .outer
          p {{ currentPhotoIndex }}枚目
          p {{ maxPhotoIndex }}枚中
</template>

<script>
import { mapGetters } from 'vuex'
import VueSlider from 'vue-slider-component'
export default {
  name: 'HeaderSlider',

  components: {
    VueSlider,
  },

  data() {
    return {
      sliderProcessStyle: {
        backgroundColor: 'rgba(255, 217, 17, .65)',
      },
      sliderTooltipStyle: {
        backgroundColor: '#eee',
        borderColor: '#ccc',
        color: '#666',
      },
    }
  },

  computed: {
    ...mapGetters({
      minPhotoIndex: 'subSale/minPhotoIndex',
      maxPhotoIndex: 'subSale/maxPhotoIndex',
    }),
    // 1以上を返さないと、VueSliderが例外を吐く
    currentPhotoIndex: {
      get() {
        return this.$store.getters['browsing/currentPhotoIndex'] || this.minPhotoIndex || 1
      },
      set(value) {
        this.$store.dispatch('browsing/setCurrentPhotoIndex', { index: value })
      },
    },
  },

  methods: {
    // スライダーの状態が変化した
    changeValue(index) {
      // 最初にvalueがnullの状態で呼ばれるのでそれをキャンセル
      // また、外部から currentPhotoIndex が変更された場合も呼ばれるが、
      // value と currentPhotoIndex が同じかどうかで判定可能
      if (!index || !this.currentPhotoIndex || index === this.currentPhotoIndex) return
      this.currentPhotoIndex = index
    },
  },
}
</script>

<style lang="sass">
.browse-slider .vue-slider
  background-image: url('../../../assets/slider-bg.png') !important
  background-repeat: repeat
  background-size: cover
  .vue-slider-dot
    width: 24px !important
    height: 24px !important
    top: -10px !important
    box-shadow: none
</style>

<style lang="sass" scoped>
@import '../../../sass/variables.sass'
@import '../../../sass/mixins.sass'

.header-slider
  .title p
    font-size: $size-small
    color: $white

  .slider
    cursor: pointer

  .info
    .outer
      font-size: $size-smallest
      background-color: $white
      padding: 0.2rem
      border-radius: $radius-small
      text-align: center

      // 吹き出し用
      position: relative
      p:first-child
        padding-bottom: 0.1rem
        border-bottom: 1px solid $grey
        margin-bottom: 0.1rem

      // 吹き出し
      &:before
        position: absolute
        display: block
        content: ''
        border-right: 10px solid $white
        border-top: 10px solid transparent
        border-bottom: 10px solid transparent
        left: -10px
        top: 50%
        margin-top: -10px
</style>
