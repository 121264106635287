// 重大なお知らせの取得

import apiFactory from '../api-factory'

export default apiFactory({
  path: '/news/highlighted',
  method: 'GET',
  responseMapper: {
    exists: 'exists',
    news: 'news',
  },
})
