// ダイアログプラグインプラグイン

export default {
  install(Vue) {
    Vue.prototype.$psdialog = psDialog
  },
}

let _component = null

const psDialog = {
  registerComponent(component) {
    _component = component
  },

  open(dialog) {
    _component.open(dialog)
  },

  close(params) {
    if (!_component) return
    const closed = _component.close()
    // onCloseコールバックが設定してあれば呼ぶ
    if (closed.onClose) closed.onClose(params)
    // iOSにてダイアログ内に入力要素があると、
    // キーボードが表示されっぱなしになる問題の対策
    if (document.activeElement) {
      document.activeElement.blur()
    }
  },

  closeAll() {
    _component.closeAll()
  },

  // 現在表示中のダイアログがあるかどうか
  hasDialogs() {
    if (!_component) return false
    return _component.hasDialogs
  },

  lockScroll() {
    _component.lockScroll()
  },

  unlockScroll() {
    _component.unlockScroll()
  },
}
