// はじめての方向けの3ケタコードによるアクティベーション

<script>
import activateMailApiAsync from '@/api/public/activate-mail'
import { mapActions } from 'vuex'

import HelpDetail from '@/dialogs/contents/help-detail'

import * as errorCodes from '@/errors/codes'

// メール設定についてのヘルプのId
const mailSettingHelpId = 4

// トークンと3ケタコードの境界
const tokenCodeAlign = 40

export default {
  name: 'IntroPageActivation',

  data() {
    return {
      code: null,
      error: null,
      loading: false,
    }
  },

  created() {
    // メール経由の場合はtokenに3ケタコードもくっついている
    this.code = this.$route.params.token.slice(tokenCodeAlign, tokenCodeAlign + 3)
  },

  computed: {
    email() {
      return this.$route.query.email
    },
    // 次へ進む準備ができたか
    isValid() {
      return this.code && this.code.length === 3
    },
  },

  methods: {
    ...mapActions({
      storeTokens: 'app/storeTokens',
      getMeAsync: 'app/getMeAsync',
      setError: 'app/setError',
    }),

    // メール設定の方法を表示
    showHelpDetailDialog() {
      this.$psdialog.open({
        title: 'メールの受信設定について',
        component: HelpDetail,
        props: { id: mailSettingHelpId },
        size: 'l',
        closeByClickBg: true,
        closeButton: true,
      })
    },

    goToPrev() {
      this.$router.push({ name: 'IntroPageRegister' })
    },

    async goToNextAsync() {
      if (!this.isValid) return

      this.loading = true

      // emailのリンク経由の場合、tokeに3ケタコードもくっついているので
      const token = this.$route.params.token.slice(0, tokenCodeAlign)
      // アクティベーションの実行
      let response
      try {
        response = await activateMailApiAsync({ token }, { code: this.code })
        if (!response.ok) {
          if (/not acceptable/.test(_.get(response, 'payload.message'))) {
            throw new Error(errorCodes.ALREADY_USED_ACTIVATION_CODE_ERROR)
          } else {
            this.error = '確認コードが正しくありません。'
            return
          }
        }
      } catch (e) {
        this.setError(e)
        return
      } finally {
        this.loading = false
      }

      // 認証情報をストア
      this.storeTokens({ token: response.payload.token, refresh: response.payload.refresh })

      // ストアの自分の情報を強制更新(public -> guest)
      await this.getMeAsync({ force: true })

      this.$router.push({ name: 'IntroPageAuthorization' })
    },
  },
}
</script>

<template lang="pug">
.intro-page-activation

  .inner

    ps-dots-heading 「確認メール」を送信しました。

    article.main
      ps-row(:gutter='40', break-point='mobile')

        ps-col.first

          p.email(v-if='email')
            ps-icon(icon='envelope')
            span メールアドレス:
            | &nbsp;{{ email }}

          p 確認メールに記載されている3ケタ(数字)の「確認コード」を入力してお進みください。

          .form
            ps-form-item(label='確認コード', required, :error='error')
              ps-input(placeholder='000', full-width, ime-mode='disabled', force-hankaku, :style='{ maxWidth: "5rem" }', type='tel', v-model='code')

        ps-col.second(:span='0')
          .please-reading
            h3
              ps-icon(icon='info-circle')
              | &nbsp;お読みください
            p
              | 迷惑メールフィルターにより「確認メール」が届かない場合があります。
              | あらかじめ「ドメイン指定受信登録」に「11s.jp」を設定し、
              | このサイトからのメールを受信できるようにしておいてください。

    article.buttons
      ps-button(size='large', @click='goToPrev')
        ps-icon(icon='chevron-circle-left')
        | &nbsp;やり直す
      ps-button(size='large', color='orange', :disabled='!isValid', :loading='loading', min-width='10rem', text-centered, @click='goToNextAsync')
        | 次へ&nbsp;
        ps-icon(icon='chevron-circle-right')
</template>

<style lang="sass" scoped>
@import '../../../../sass/variables'
@import '../../../../sass/mixins'

.intro-page-activation

  .inner

    background-color: $white
    padding: 1.5rem 1rem
    border-radius: $radius-small
    box-shadow: 0 1px 6px 2px rgba(0, 0, 0, .25)
    +mobile
      padding: 1rem 0.5rem
      box-shadow: 0 1px 1px 1px rgba(0, 0, 0, .3)

    > article
      margin-bottom: 3rem

      &.main
        .first

          > p

            &.email
              font-size: $size-large
              word-break: break-all
              +mobile
                span
                  display: none

            margin-bottom: 1rem
            line-height: 1.4

          +mobile
            .form
              margin-bottom: 1rem

        .second

          .please-reading
            border-radius: $radius-small
            background-color: lighten(#e4f7b7, 5%)
            padding: 0.5rem
            +tablet
              width: 350px
            h3
              font-weight: normal
              font-size: $size-normal
              text-align: center
              color: $black-bis
              letter-spacing: 1px
              margin-bottom: 0.4rem
            p
              font-size: $size-small
              line-height: 1.4
              color: $grey-darker
            a
              color: darken($blue, 10%)
              text-decoration: underline
              cursor: pointer

      &.buttons
        text-align: center
        > :first-child
          margin-right: 1rem
</style>
