// 閲覧画面で使用されるのメニュー

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'OkaoMenu',

  props: {
    inCart: Boolean,
    trimmable: Boolean,
    trimmed: Boolean,
    showOkaoIndicator: Boolean,
  },

  computed: {
    ...mapGetters({
      screenType: 'app/screenType',
      role: 'app/role',
    }),
  },
}
</script>

<template lang="pug">
.normal-menu(:class='[screenType]')
  
  // モバイル用
  template(v-if='screenType === "mobile"')
    .first
      ps-button(v-if='!inCart', color='orange', full-width,
        text-centered, size='large', @click='$emit("addToCart")')
        ps-icon(icon='plus-circle')
        | &nbsp;カゴに追加
      ps-button(v-else, full-width, text-centered,
        size='large', @click='$emit("removeFromCart")')
        ps-icon(icon='minus-circle')
        | &nbsp;カゴから削除
    .second
      ps-row(:gutter='5')
        // すでにギリギリの解像度でトリミングされた場合、
        // trimmable = false かつ trimmed = true となることを考慮
        ps-col(v-if='trimmable || trimmed')
          ps-button(v-if='!trimmed', color='purple', full-width,
            text-centered, @click='$emit("startTrimming")'
          ) トリミング
          ps-button(v-else, full-width, text-centered,
            @click='$emit("discardTrimming")'
          ) トリミング解除
        ps-col
          ps-button(full-width, text-centered, @click='$emit("close")') 閉じる

  // PC用
  template(v-else)
    .first
      div
        ps-icon-button(v-if='!inCart', icon='plus-circle', color='orange', full-width,
          @click='$emit("addToCart")') カゴに追加
        ps-icon-button(v-else, icon='minus-circle', full-width,
          @click='$emit("removeFromCart")') カゴから削除

      // トリミング関係
      // すでにギリギリの解像度でトリミングされた場合、
      // trimmable = false かつ trimmed = true となることを考慮
      div(v-if='trimmable || trimmed')
        ps-icon-button(
          v-if='!trimmed',
          icon='cut',
          color='purple',
          full-width,
          @click='$emit("startTrimming")'
        ) トリミング
        ps-icon-button(
          v-else,
          icon='reply'
          full-width,
          @click='$emit("discardTrimming")'
        ) トリミング解除

      // お顔枠の表示・非表示。ただしトリミング済みの場合は最初から表示しない
      div(v-if='!trimmed')
        ps-button(
          v-if='!showOkaoIndicator',
          color='pink',
          full-width,
          text-centered,
          @click='$emit("showOkaoInd")'
        ) お顔枠を表示
        ps-button(
          v-else,
          full-width,
          text-centered,
          @click='$emit("hideOkaoInd")'
        ) お顔枠を非表示

    .second
      ps-button(full-width, text-centered, @click='$emit("close")') 閉じる

</template>

<style lang="sass" scoped>
.normal-menu
  &.mobile
    > .first
      margin-bottom: 5px

  &:not(.mobile)
    display: flex
    height: 100%
    flex-direction: column
    > .first
      flex-grow: 1
      > div
        margin-bottom: 1rem
</style>
