// '1-10,15,30-50' のような文字列をパースして半開区間の配列を作成したり、
// 特定の番号がその半開区間の配列に含まれるかを判定する

export default {
  parseRangesString,
  isContain,
  getMin,
}

function parseRangesString(str) {
  if (!str) return []
  return str.split(',').map(interval => {
    // 半開区間を作成
    const splited = interval.trim().split('-')
    const start = parseInt(splited[0], 10)
    let end
    if (splited.length < 2) end = start + 1
    else end = parseInt(splited[1], 10) + 1
    return [start, end]
  })
}

function isContain(ranges, index) {
  for (let i = 0; i < ranges.length; ++i) {
    if (index >= ranges[i][0] && index < ranges[i][1]) return true
  }
  return false
}

function getMin(ranges) {
  if (!ranges || ranges.length === 0) return null
  return _.first(ranges)[0]
}
