// 支払い方法の説明ダイアログ

<template lang="pug">
  .payment-detail(:class='[type]')

    // ネット決済
    template(v-if='type !== "pouch"')
      p
         | こちらの販売は
         b 「ネット決済」
         | によるお支払いとなっております。
      p お支払い方法は次の2つからお選びいただけます(すべて先払い)。

      ul
        li
          p
            | クレジットカード決済
            span (手数料不要)
          img(src='../../../assets/card-brands.png')
        li
          p
            | コンビニ決済
            span (手数料不要)
          img(src='../../../assets/conveni-all.png')

    // 集金袋
    template(v-else)
      p
         | こちらの販売は
         b 集金袋
         | によるお支払いとなっております。

      img(src='../../../assets/envelope.png')

      p.note
        | ご注文確定後、太枠の欄に必要事項をご記入の上、
        | 代金を封筒に入れて、学校・園にご提出ください。
</template>

<script>
export default {
  name: 'PaymentDetail',

  props: {
    // 支払いのタイプ。 pouch or online
    type: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../../../sass/variables.sass'

.payment-detail

  > *
    margin-bottom: 0.5rem

  p
    line-height: 1.2

  &.online
    > ul
      li
        border: 1px solid lighten($orange, 20%)
        border-radius: $radius
        padding: 0.5rem
        margin-bottom: 0.5rem
        p
          color: darken($orange, 20%)
          margin-bottom: 0.3rem
        img
          display: block
          max-width: 300px
          height: auto
          width: 100%
          margin: 0 auto

  &.pouch
    > img
      display: block
      margin: 0 auto 1rem
      max-width: 300px
      width: 100%
      height: auto
    > .note
      font-size: $size-small
</style>
