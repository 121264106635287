// モバイル用のカテゴリ一覧

<template lang="pug">
  ps-mobile-menu-base.mobile-category-menu(
    color='green',    
    :buttons='buttons',
    :class='{ "highlight-ok-button": highlightOkButton }',
    @ok='moveCategory'
    @close='$emit("close")',
    @clickBg='$emit("close")'
  )
    .wrapper
      .body
        header
          h3 カテゴリー

        section(ref='category')
          ps-category(
            :category='categoryTree',
            :hidden-overdue-tag='!overdueCharge',
            v-model='photoIndex'
          )

        footer(v-if='hasOldSale')
          ps-button(
            v-if='!isOldSale'
            color='dark-blue', full-width, text-centered,
            @click='showOldSalesDialog'
          )
            ps-icon(icon='history')
            | &nbsp;以前の販売

          ps-button(
            v-else,
            color='dark-blue', full-width, text-centered,
            @click='goToCurrentSale'
          ) &nbsp;現在の販売に戻る

</template>

<script>
import { mapGetters } from 'vuex'

const buttons = [
  {
    code: 'ok',
    label: '決定',
    icon: 'check',
  },
  {
    code: 'close',
    label: '閉じる',
    icon: 'times',
  },
]

export default {
  name: 'MobileCategoryMenu',

  computed: {
    ...mapGetters({
      saleId: 'sale/id',
      hasOldSale: 'sale/hasOldSale',
      isOldSale: 'subSale/isOldSale',
      categoryTree: 'subSale/categoryTree',
      currentPhotoIndex: 'browsing/currentPhotoIndex',
      // 年間販売の期間外販売手数料
      overdueCharge: 'sale/overdueCharge',
    }),
  },

  data() {
    return {
      photoIndex: null,
      buttons,
      // OKボタンを目立たせる
      highlightOkButton: false,
    }
  },

  created() {
    this.photoIndex = this.currentPhotoIndex
    this.$lockBodyScroll(deltaY => {
      this.$refs.category.scrollTop += deltaY
    })
  },

  methods: {
    moveCategory() {
      this.$store.dispatch('browsing/setCurrentPhotoIndex', { index: this.photoIndex })
      this.$emit('close')
    },

    // 過去販売のダイアログを表示
    showOldSalesDialog() {
      this.$emit('startOldSale')
      this.$emit('close')
    },

    // 現在の販売に戻る
    goToCurrentSale() {
      this.$router.push({ name: 'BrowsePage', params: { saleId: this.saleId } })
      this.$emit('close')
    },
  },

  watch: {
    photoIndex() {
      if (this.photoIndex === this.currentPhotoIndex) return
      this.highlightOkButton = true
      _.delay(() => {
        this.highlightOkButton = false
      }, 2000)
    },
  },

  beforeDestroy() {
    this.$unlockScroll()
  },
}
</script>

<style lang="sass">
@import '../../../sass/variables.sass'

@keyframes highlight-ok-button
  0%
    transform: scale(1.0)
    background: darken($green, 15%)
  25%
    transform: scale(1.2)
    background: $green
  50%
    transform: scale(1.0)
    background: darken($green, 15%)
  75%
    transform: scale(1.2)
    background: $green
  100%
    transform: scale(1.0)
    background: darken($green, 15%)

.mobile-category-menu.highlight-ok-button
  .outer
    .buttons
      ul
        li:first-child
          a
            animation: highlight-ok-button 2s ease
</style>

<style lang="sass" scoped>
@import '../../../sass/variables.sass'
@import '../../../sass/mixins.sass'

.mobile-category-menu
  .wrapper
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    background-color: #e4f7b7

    > .body
      display: flex
      flex-direction: column
      height: 100%

      > header
        padding: 0.5rem 2px
        background-color: darken(#e4f7b7, 20%)
        h3
          text-align: center
          font-weight: normal
          font-size: $size-normal

      > section
        padding: 0 2px
        overflow: auto
        flex-grow: 1

      > footer
        padding: 0.5rem
</style>
