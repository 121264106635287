// お子様情報を新規追加

import apiFactory from '../api-factory'

export default apiFactory({
  path: '/my/subs/<%= id %>/faces',
  method: 'POST',
  expectedParams: ['id'],
  needAuth: true,
})
