// お知らせの詳細の取得

import apiFactory from '../api-factory'

import dateHelper from '../../helpers/date-helper'

export default apiFactory({
  path: '/news/<%= id %>',
  method: 'GET',
  expectedParams: ['id'],
  responseMapper: {
    id: 'id',
    title: 'title',
    content: 'html',
    date: {
      key: 'date',
      transform: value => dateHelper.toJpYYYYMMDD(value),
    },
  },
})
