// 現在の販売、過去販売にかかわらいない共通の販売情報の取得

import apiFactory from '../api-factory'

import dateHelper from '../../helpers/date-helper'

export default apiFactory({
  path: '/sales/<%= id %>',
  method: 'GET',
  expectedParams: ['id'],
  needAuth: true,
  responseMapper: {
    id: 'id',
    'detail.events[0].id': 'eventIdNotSaleId',
    name: 'name',
    collect_type: 'collectType',
    'authz.type': 'authorizationType',
    status: 'status',
    // この販売が属する年度
    fiscal_year: 'fiscalYear',
    'detail.sales[].id': 'oldSales[].id',
    'detail.sales[].name': 'oldSales[].name?',
    'options.is_customizable': 'customizable',
    'options.is_face_matching_available': 'canSearchFaces',
    'options.is_auto_section_title_enabled': 'insertSectionTitle',
    // 新しい(2020年度〜)お子様情報管理を利用するか
    'options.is_multiple_sub_users_available': 'useNewChildrenInfoSystem',
    // とりあえずバナーはv2しか表示されないので...
    'options.banner_url': {
      key: 'bannerUrl',
      transform: value => value.replace('v3', 'v2').replace('www3-img', 'www'),
    },
    // とりあえず最後のsaleIDは削除
    'options.thumbnail_base_url': [
      {
        key: 'thumbnailBaseUrl',
        transform: value => {
          return value.replace(/\d+\/$/, '')
        },
      },
      {
        key: 'lupeBaseUrl',
        transform: value => {
          return value.replace(/\d+\/$/, '').replace('thumbnail', 'thumbnail/rupetile')
        },
      },
      {
        key: 'customThumbnailBaseUrl',
        transform: value => {
          return value.replace(/\/thumbnail.+/, '/photo_custom/thumbnail/')
        },
      },
      {
        key: 'customLupeBaseUrl',
        transform: value => {
          return value.replace(/\/thumbnail.+/, '/photo_custom/thumbnail/rupetile/')
        },
      },
    ],
    'org_attributes.values': {
      key: 'orgAttributes',
      transform: classes => {
        if (!classes || classes.length === 0) return null
        // 特殊な記号 '___' で分離
        const splitted = classes.map(value => value.split('___'))
        // すべてが '___' に対応していなければそのまま返す
        if (!_.every(splitted, value => value.length === 2)) return classes
        // ここ来るということは、配列中の各値は['区分名', 'クラス名']となっているはず
        // それを { '区分名1' [ 'クラス名1', 'クラス名2' ] } のような形に変換
        else {
          // 順序を保った区分名のリスト
          const groups = _.uniq(_.unzip(splitted)[0])
          return groups.map(group => {
            return {
              label: group,
              items: _.map(
                _.filter(splitted, value => value[0] === group),
                value => value[1]
              ),
            }
          })
        }
      },
    },
    // 新しい(2020年度〜)属性情報
    org_attribute_definitions: 'newOrgAttributes',
    org_id: 'orgId',
    org_name: 'orgName',
    print_type: 'printType',
    shipping_type: 'shippingType',
    shop_name: 'shopName',
    type: 'saleType',
    'term[0]': {
      key: 'start',
      transform: value => dateHelper.toJpMMDD(value, true),
    },
    'term[1]': {
      key: 'end',
      transform: value => dateHelper.toJpMMDD(value, true),
    },
    // 過去販売の手数料
    'options.resale.charge': 'oldSaleCharge',
    // 緊急のお知らせ
    announcement: 'announcement',
    // 税別かどうか
    'tax.is_excluded': 'isTaxExcluded',
    // 年間販売かどうか
    is_longrun: {
      key: 'isLongrun',
      transform: value => !!value,
    },
    // 年間販売の手数料
    'settings.overdue_charge': 'overdueCharge',
    // 年間販売の手数料無効フラグ
    'settings.is_overdue_charge_disabled': {
      key: 'isOverdueChargeDisabled',
      transform: value => !!value,
    },
    // 年間販売を構成する販売
    sub_sales: {
      key: 'subSales',
      transform: values => {
        return _.map(values, sale => {
          return {
            id: sale.id,
            name: sale.name,
            isOverdue: !!sale.is_overdue,
            photoStartIndex: sale.photo_start_index,
            start: dateHelper.toJpYYYYMMDD(sale.t_start * 1000, true),
            due: dateHelper.toJpYYYYMMDD(sale.t_due * 1000, true),
            // お顔検索の可否
            canSearchFacesInSubSale: sale.is_faces_searchable,
          }
        })
      },
    },
    charge: [
      // 通常の発送手数料
      {
        key: 'shipmentFeeNormal',
        transform: value => _.get(value, '["photo.delivery"].base', 0),
      },
      // ４つ切含む発送手数料
      {
        key: 'shipmentFeeLarge',
        transform: value => _.get(value, '["photo.delivery"].includes_4PW', 0),
      },
      // 発送手数料の一律値引き
      {
        key: 'shippingDiscountBase',
        transform: value => {
          const discount = _.get(value, 'photo.delivery.discount')
          return !discount || !discount.enabled ? 0 : discount.base
        },
      },
      // 発送手数料無料の境界
      {
        key: 'shippingFreeThreshold',
        transform: value => {
          const discount = _.get(value, 'photo.delivery.discount')
          return !discount || !discount.enabled ? 0 : discount.threshold
        },
      },
    ],
  },
})
