// 写真をマスクするための拡大率の取得

// マスキング用のズームの割合
const zoomMap = {
  // 銀塩
  '1': {
    L: 1.024,
    LW: 1.026,
    KG: 1.026,
    '2L': 1.022,
    '2L:FR': 1.022,
    '2LW': 1.019,
    '2LG': 1.021,
    PA: 1.027,
    '8PW': 1.019,
    '6PW': 1.016,
    '4PW': 1.014,
  },
  // PSプリントサーバ
  // 現時点では通常の銀塩と同じ値を使用
  '2': {
    L: 1.024,
    LW: 1.026,
    KG: 1.026,
    '2L': 1.022,
    '2L:FR': 1.022,
    '2LW': 1.019,
    '2LG': 1.021,
    PA: 1.027,
    '8PW': 1.019,
    '6PW': 1.016,
    '4PW': 1.014,
  },
  // ドリームラボ
  '3': {
    LW: 1.04,
    KG: 1.037,
    '2L': 1.032,
    '2L:FR': 1.032,
    '2LW': 1.032,
    '2LG': 1.032,
    '8PW': 1.024,
    '6PW': 1.019,
    '4PW': 1.015,
  },
}

export default function(printType, sizeCode) {
  return zoomMap[printType][sizeCode] || 1.0
}
