// 要素にIdを付与するためのカスタムディレクティブ

export default {
  // ページ名を伴ったIdの付与
  install(Vue) {
    Vue.directive('my-id', {
      inserted: (el, binding, vnode) => {
        el.id = _.kebabCase(vnode.context.$route.name) + '-' + binding.value
      },
    })
    // ページ名を伴ったクラス名の付与
    Vue.directive('my-class', {
      inserted: (el, binding, vnode) => {
        if (!el.classList) return
        const classname = _.kebabCase(vnode.context.$route.name) + '-' + binding.value
        el.classList.add(classname)
      },
    })
  },
}
