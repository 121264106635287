// パスワードの変更確定
// このAPIは認証不要である点に注意

import apiFactory from '../api-factory'

export default apiFactory({
  path: '/my/password/reset/<%= token %>',
  method: 'POST',
  expectedParams: ['token'],
  bodyMapper: {
    code: 'code',
    password: 'passwd',
  },
})
