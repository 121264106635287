// APIアクセス用の関数ファクトリ

// ajax用モジュール
import axios from 'axios'

// レスポンスやリクエストをマッピングするためのモジュール
import mapper from 'object-mapper'

// ストア。トークンとリフレッシュトークン取得のため
import store from '@/store'

// WindowsのIE or Edgeの判定に使用
// 401レスポンスが例外として扱われることがあるので、
// それへの対応に必要
import platform from 'platform'

// エラーコード
import * as errorCodes from '@/errors/codes'

import sendErrorReport from '@/helpers/send-error-report'

// トークンのリフレッシュ
const refreshTokenAsync = apiFactory({
  path: '/tokens',
  method: 'POST',
  needAuth: true,
  bodyMapper: {
    type: 'grant_type',
    refresh: 'refresh_token',
  },
  responseMapper: {
    id_token: 'token',
  },
})

// APIのファクトリ
function apiFactory({
  path,
  method,
  headers,
  needAuth,
  expectedParams,
  bodyMapper,
  responseMapper,
}) {
  // 最低限必要なパラメタは揃っているか
  if (!path || !method) throw new Error(errorCodes.API_PARAMS_ERROR)

  const pathGenerator = _.template(path)
  let config = {
    // 500 未満はvalidなステータスとする
    validateStatus: status => status < 500,
    timeout: 80000,
  }
  const axiosInstance = axios.create(config)

  // bodyのマッピングがあるか
  if (bodyMapper) {
    axiosInstance.interceptors.request.use(config => {
      console.log('intercept request.', config)
      if (config.data) config.data = mapper(config.data, {}, bodyMapper)
      return config
    })
  }

  // レスポンスのマッピングがある
  if (responseMapper) {
    axiosInstance.interceptors.response.use(
      response => {
        console.log('intercept response.')
        const ok = _.get(response, 'status') === 200 || _.get(response, 'statusText') === 'OK'
        if (ok && response.data) response.data = mapper(response.data, {}, responseMapper)
        return response
      },
      error => {
        // Windows IE において、401であるにもかかわらず例外(Network Error)が発生してしまう不具合があるため、
        // WindowsかつNetwork ErrorかつJWTをもっているなら401扱いとする。
        if (
          /windows/i.test(platform.os.family) &&
          /Network Error/.test(error.description) &&
          store.getters['app/token']
        ) {
          console.log('Windows Network Error exception.')
          return Promise.resolve({ status: 401 })
        } else {
          return Promise.reject(error)
        }
      }
    )
  }

  const api = async (params, body, silent, retry) => {
    // APIレスポンス待ち数をインクリメント
    if (!silent) store.dispatch('app/incrementPendings')

    // 追加のヘッダ
    const additionalHeaders =
      needAuth && store.getters['app/token']
        ? { Authorization: `Bearer ${store.getters['app/token']}` }
        : {}

    const url = _getUrl(params, expectedParams, pathGenerator)
    let response
    try {
      response = await axiosInstance({
        method,
        url,
        headers: _.assign(additionalHeaders, headers),
        data: body,
      })
    } catch (e) {
      console.log('rethrow exception in ajax.', e)
      // この時点でsentryに通知。よって、再スローした分と合わせて2回sentryに通知が行く
      sendErrorReport(e)
      // 再スロー
      throw new Error(errorCodes.API_ACCESS_ERROR)
    } finally {
      if (!silent) store.dispatch('app/decrementPendings')
    }

    // 401ならリフレッシュが必要。ただし、2回目の場合は例外を伝播
    if (response.status === 401) {
      // 1回目かどうか
      if (!retry) {
        // リフレッシュに成功ならもう一度APIたたく
        if (await _refreshAsync()) {
          const ret = await api(params, body, silent, true)
          return ret
        }
      }
      throw new Error(errorCodes.API_AUTH_ERROR)
    } else if (response.status === 403) {
      // 現状、ユーザ認証での403とアプリケーション由来の403を区別する決まった方法がないので、
      // statusCode というプロパティがあるかどうかで、ユーザ認証に失敗したと判断
      if (_.get(response, 'data.statusCode')) throw new Error(errorCodes.API_AUTH_ERROR)
    }

    console.log(url, _createResponse(response))

    return _createResponse(response)
  }

  return api
}

function _getUrl(params, expectedParams, pathGenerator) {
  const _params = _.pick(params, expectedParams)
  const _path = pathGenerator(_params)
  if (/^https?:\/\//.test(_path)) return _path
  else return (process.env.VUE_APP_API_BASE || '') + _path
}

function _createResponse(response) {
  return {
    ok: _.get(response, 'status') === 200 || _.get(response, 'statusText') === 'OK',
    status: _.get(response, 'status'),
    payload: _.get(response, 'data'),
  }
}

// トークンのリフレッシュ
async function _refreshAsync() {
  console.log('try refresh token...')
  const body = {
    type: 'refresh_token',
    refresh: store.getters['app/refresh'],
  }

  // リフレッシュに失敗した場合は強制ログアウト
  try {
    const response = await refreshTokenAsync(null, body)
    if (!response.ok) throw new Error()
    store.dispatch('app/storeTokens', { token: response.payload.token })
    return true
  } catch (e) {
    console.log('failed to refresh token.')
    store.dispatch('app/logout')
    return false
  }
}

export default apiFactory
