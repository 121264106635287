// アプリケーションのエントリポイント

<template lang="pug">
  #app
    router-view

    .dialog(ref='dialog')

    ps-dialog()
    ps-loading(v-show='pending', icon='hourglass-start', full-screen)

    ps-app-info.version(v-if='showVersion', :app-version='version')

</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import PsDialog from './dialogs/main'

// エラーメッセージ用ダイアログ
import ErrorMessage from './dialogs/contents/error-message'
// エラーの詳細
import errorDetails from './errors/details'

// 旧 -> 新 に遷移する際にcookieクリアする
// 並列して運用する際の暫定措置
import removeAllCookies from './helpers/remove-all-cookies'

export default {
  name: 'App',

  components: {
    PsDialog,
  },

  data() {
    return {
      debouncedResize: null,
    }
  },

  computed: {
    ...mapGetters({
      pending: 'app/pending',
      error: 'app/error',
    }),
    // デバッグ用のバージョン情報を表示するか
    showVersion() {
      return process.env.VUE_APP_SHOW_VERSION === 'true'
    },
    // バージョン
    version() {
      return process.env.VUE_APP_VERSION
    },
  },

  mounted() {
    // 画面タイプを更新
    this.$store.dispatch('app/updateScreenType')
    // 画面サイズ変更をトリガにして画面のタイプを更新するように
    this.debouncedResize = _.debounce(this._resize, 300)
    window.addEventListener('resize', this.debouncedResize)

    // すべてのcoookieをクリア
    if (process.env.VUE_APP_REMOVE_ALL_COOKIES === 'true') removeAllCookies('.photospot.jp', '/')
  },

  methods: {
    ...mapActions({
      clearPendings: 'app/clearPendings',
      setError: 'app/setError',
    }),

    // ウィンドウのリサイズ
    _resize() {
      this.$store.dispatch('app/updateScreenType')
    },
  },

  watch: {
    error() {
      if (!this.error) return

      console.trace()

      // スピナーを消す
      this.clearPendings()
      // ダイアログをすべて消す
      this.$psdialog.closeAll()

      const code = this.error.message
      const err = _.assign({}, errorDetails.default, errorDetails[code])
      this.$psdialog.open({
        title: err.title,
        props: {
          code: err.code,
          message: err.message,
          type: err.type,
        },
        component: ErrorMessage,
        size: 's',
        color: 'yellow',
      })
    },
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.debouncedResize)
  },
}
</script>

<style lang="sass">
@import './sass/mixins.sass'
@import './sass/variables.sass'

html
  body
    margin: 0
    // iPhone等の長押しでズームの吹き出しが出るのを防ぐ
    -webkit-touch-callout: none
    user-select: none

  // 壁紙
  +bg-image('./assets/browse-yellow-light-bg.png', null, repeat)

  // 基本のカラー
  color: $black-ter
  // 基本のフォント
  font-family: sans-serif

  // フォントサイズはPCで100%(通常16px)
  // モバイルで14pxを標準とする
  font-size: 100%
  +mobile
    font-size: 14px

  li
    list-style-type: none

  #app
    position: relative
    height: 100%
    min-height: 100vh
    // フッタ用にざっくり50px
    padding-bottom: 50px

    // デバッグ用のバージョン情報
    > .version
      position: absolute
      bottom: 10px
      right: 10px
      padding: 0.3rem
      color: white
      background-color: rgba(0, 0, 0, 0.3)
      &:hover
        visibility: hidden
</style>
