<template lang="pug">
.top-page

  h1
    img(src='../../assets/logo.png')

  // 緊急のお知らせ
  .emergency(v-if='seriousInfo')
    ps-container
      ps-serious-info(:title='seriousInfo.title', :html='seriousInfo.content')

  // ゲストでまだ閲覧中(間違ってトップに来た可能性あり)
  .guest-and-seeing(v-if='currentSaleForGuest')
    ps-container
      .body
        p
          | 現在
          b 「{{ currentSaleForGuest.orgName }} {{ currentSaleForGuest.name }}」
          | を閲覧中です。
        .buttons
          ps-button(color='green', size-large, @click='resume') 閲覧を再開する

  .form
    h2 写真の閲覧はこちらから
    ps-row(break-point='tablet')
      ps-col
        .start
          h3 初めての方
          a.start-button(@click='$router.push({ name: "IntroPageRegister" })')
            // パソコン用のボタン画像
            img(v-if='screenType === "pc"', src='../../assets/top-start-button.png', class='for-pc')
            // タブレット・モバイル用のボタン画像
            img(v-else, src='../../assets/top-start-button-mobile.png', class='for-mobile')
      ps-col.right-col
        .login
          h3 登録済みの方
          form(v-on:submit.prevent.stop='login')
            p.error-message(v-if='error') {{ error }}
            ul(:class='{ error: error }')
              li
                ps-input(full-width, placeholder='メールアドレス', v-model='email', type='email')
              li
                ps-input(full-width, placeholder='パスワード', v-model='password', type='password')
                p
                  a(@click='$router.push({ name: "PublicPageReminder" })') パスワードをお忘れの方

            button.login-button(type='submit')
              img(src='../../assets/top-login-button.png')

  ul.links
    li
      a(href='/public/term') ご利用規約
    li
      a(href='/public/privacy') 個人情報保護方針
    li
      a(href='/public/legal') 特定商取引法に基づく表記

</template>

<script>
import { mapGetters, mapActions } from 'vuex'

// 認可済みの販売一覧の取
import getAuthorizedSalesApiAsync from '@/api/user/get-authorized-sales'
// 重要なお知らせの取得
import getSeriousInfoApiAsync from '@/api/public/get-serious-info'

export default {
  name: 'TopPage',

  data() {
    return {
      email: null,
      password: null,
      loading: false,
      error: null,
      // ゲストの場合の現在閲覧中の販売
      currentSaleForGuest: null,
      // 重要なお知らせ
      seriousInfo: null,
    }
  },

  created() {
    this.getSeriousInfoAsync()
    // ゲストの場合とpublicの場合があり、ゲストなら閲覧中の販売がある可能性がある
    if (this.role === 'guest') this.getAuthorizedSalesAsync()
  },

  computed: {
    ...mapGetters({
      screenType: 'app/screenType',
      role: 'app/role',
    }),
    loginEnabled() {
      return this.email && this.email.length > 0 && this.password && this.password.length > 0
    },
  },

  methods: {
    ...mapActions({
      setError: 'app/setError',
    }),

    // ゲストで閲覧中のイベントがあるかどうか
    async getAuthorizedSalesAsync() {
      console.log('getAuthorizedSalesAsync')
      try {
        const response = await getAuthorizedSalesApiAsync(null, null, true)
        if (!response.ok) return
        this.currentSaleForGuest = _.get(response, 'payload.items[0]', null)
      } catch (e) {
        this.setError(e)
      }
    },

    // 緊急のお知らせの取得
    async getSeriousInfoAsync() {
      try {
        const response = await getSeriousInfoApiAsync(null, null, true)
        // 重要なお知らせが存在するかどうか
        if (response.ok && response.payload.exists) {
          this.seriousInfo = response.payload.news
        }
      } catch (e) {
        // 何もしない
      }
    },

    // ゲストが閲覧中だった販売を再開
    resume() {
      this.$router.push({ name: 'BrowsePage', params: { saleId: this.currentSaleForGuest.id } })
    },

    async login() {
      this.error = null
      this.loading = true
      const params = { email: this.email, password: this.password }
      const response = await this.$store.dispatch('app/loginAsync', params)
      this.loading = false

      // ログイン失敗
      if (!response.ok) {
        const message = _.get(response, 'payload.message')
        if (/password/.test(message)) this.error = 'パスワードが正しくありません。'
        else this.error = '登録されていないメールアドレスです。'
        return
      }

      this.$router.push({ name: 'MyPageTop' })
    },

    submit() {
      this.$refs.form.submit()
      return false
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../../sass/variables.sass'
@import '../../sass/mixins.sass'

.top-page
  position: fixed
  top: 0
  right: 0
  bottom: 0
  left: 0

  overflow-y: auto

  border-top: 3px solid #ff983c

  background-image: url('../../assets/top-bg.jpg')
  background-size: cover
  background-position: top center

  padding: 5rem 1rem 1rem
  +mobile
    padding: 2rem 0.5rem 1rem

  text-align: center

  h1
    display: inline-block
    max-width: 300px
    img
      display: block
      width: 100%
      height: auto

  .emergency
    margin: 3rem auto 0
    max-width: 640px
    +mobile
      margin-top: 2rem

  .guest-and-seeing
    max-width: 640px
    margin: 3rem auto 0
    border-radius: $radius-large
    padding: 1rem
    background-color: rgba($white, .7)
    box-shadow: 0px 0px 15px 0px rgba($black, .2)
    +mobile
      margin-top: 2rem
    .buttons
      margin-top: 0.5rem

  .form
    max-width: 640px
    margin: 3rem auto 2rem
    border-radius: $radius-large
    padding: 1rem
    background-color: rgba($white, .7)
    box-shadow: 0px 0px 15px 0px rgba($black, .2)
    +mobile
      margin-top: 2rem

    .right-col
      border-left: 1px solid $grey-lighter
      +mobile
        border-left: none
      +tablet-only
        border-left: none

    h2
      margin-bottom: 1rem
      font-size: 1.2rem
      color: $grey-darker
      letter-spacing: 1px

    h3
      letter-spacing: 2px
      margin-bottom: 1.5rem
      +mobile
        margin-bottom: 1rem
      +tablet-only
        margin-bottom: 1rem

    .start, .login
      +mobile
        border: 1px solid #000
        border-radius: $radius
        margin-bottom: 1rem
        padding: 1rem
      +tablet-only
        border: 1px solid #000
        border-radius: $radius
        margin-bottom: 1rem
        padding: 1rem

    .start
      border-color: rgba(#5db92d, .5)
      h3
        color: #5db92d
      .start-button
        cursor: pointer
        display: inline-block
        transition: all 1s
        &:hover
          opacity: .8
          transform: rotate(10deg)
          +touch
            transform: none

    .login
      border-color: rgba(#ff5f90, .5)
      h3
        color: #ff5f90

      form
        padding: 0 1rem
        .error-message
          font-size: $size-small
          text-align: left
          color: $red
          margin-bottom: 0.5rem
        ul
          li
            margin-bottom: 1.5rem
            p
              text-align: right
              margin-top: 0.3rem
              font-size: $size-small
              a
                text-decoration: underline
                cursor: pointer

        .login-button
          // クリック時に枠線が出るので
          outline: none
          &:hover
            opacity: .8

  .links
    margin-top: 4rem
    margin-bottom: 1rem
    li
      margin: 0.5rem
    a
      text-decoration: none
      color: #444
    +tablet
      position: absolute
      bottom: 0
      right: 0
      li
        display: inline-block
</style>
