// 特定のアイテムの状態を更新

import apiFactory from '../api-factory'

import responseMapper from './response-mapper'

export default apiFactory({
  path: '/my/carts/<%= saleId %>',
  method: 'PUT',
  expectedParams: ['saleId'],
  needAuth: true,
  bodyMapper: {
    photoId: 'opt.photo_id',
    sizeId: 'opt.size_id',
    num: 'opt.num',
    photoCustomId: 'opt.photo_custom_id',
    goodsId: [
      'opt.daicolo_goods_id',
      {
        key: 'service_type',
        transform: value => (value ? 'daicolo_goods' : 'photo'),
      },
    ],
  },
  responseMapper,
})
