// 注文の詳細の取得

import apiFactory from '../api-factory'

import responseMapper from './order-response-mapper'

export default apiFactory({
  path: '/my/orders/<%= code %>',
  method: 'GET',
  expectedParams: ['code'],
  needAuth: true,
  responseMapper,
})
