// お子様情報を新規追加

import apiFactory from '../api-factory'

export default apiFactory({
  path: '/my/subs',
  method: 'POST',
  needAuth: true,
  bodyMapper: {
    name1: 'name1?',
    name2: 'name2?',
    name1Kana: 'name1_kana?',
    name2Kana: 'name2_kana?',
    relation: 'relation?',
    attribute: 'attribute?',
    // 2020年度〜の新しいお子様情報用
    // 末尾に '?' を付加すると、旧来の呼び出し時にも、
    // nullで下記プロパティを付与してしまうので '?' なしで
    grade: 'grade_index',
    fiscalYear: 'fiscal_year',
    orgId: 'org_id',
  },
})
