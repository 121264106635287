// カートの概要の取得
// update=1にすることで常に内容を更新

import apiFactory from '../api-factory'

import responseMapper from './response-mapper'

export default apiFactory({
  path: '/my/carts/<%= saleId %>?sort_items=1&update=1',
  method: 'GET',
  expectedParams: ['saleId'],
  needAuth: true,
  responseMapper,
})
