// 元となる販売の情報(共通の情報)を取得

import getSaleDetailApiAsync from '@/api/sale/get-detail'

import * as errorCodes from '@/errors/codes'

// アクション

export default {
  async initAsync({ commit, rootGetters }, { id }) {
    commit('setId', id)

    const params = { id }
    const response = await getSaleDetailApiAsync(params)

    if (!response.ok) {
      throw new Error(errorCodes.GET_SALE_INFO_ERROR)
    }

    commit('setDetail', response.payload)

    // Infoレベルのお知らせの既読フラグ
    const key = _getStorageKey(id, rootGetters)
    const flag = !!storage.get(key)
    commit('setAlreadyReadInfoAnnouncement', flag)
  },

  // ローカルストレージにこの販売に関わるinfoレベルのお知らせが既読であることをセット
  setAlreadyReadInfoAnnouncementFlag({ getters, rootGetters }) {
    // ローカルストレージにもセット
    const key = _getStorageKey(getters['id'], rootGetters)
    if (!key) return
    storage.set(key, 'true')
  },

  // ストアの情報をクリア
  clearAll({ commit }) {
    commit('setId', 0)
    commit('setDetail', null)
    commit('setAlreadyReadInfoAnnouncement', false)
  },
}

// ローカルストレージ利用のためのキーを取得
// ユーザId + ルートのセールId + サブのセールId
function _getStorageKey(saleId, rootGetters) {
  // ローカルストレージに位置を保存する際のプレフィックス
  const prefix = 'announcement'
  const userId = rootGetters['app/userId']
  if (!userId || !saleId) return null
  else return `${prefix}:${userId}:${saleId}`
}
