// 販売の共通情報とカートの状態

// ステート
const state = {
  // 販売のId
  id: null,
  // 販売の詳細
  detail: null,
  // Infoレベルの販売に対するお知らせが既読かどうか
  alreadyReadInfoAnnouncement: false,
}

// ゲッタ
const getters = {
  // 販売関連の情報
  id(state) {
    return state.id
  },
  status(state) {
    return _.get(state, 'detail.status')
  },
  name(state) {
    return _.get(state, 'detail.name')
  },
  fiscalYear(state) {
    return _.get(state, 'detail.fiscalYear')
  },
  orgId(state) {
    return _.get(state, 'detail.orgId')
  },
  orgName(state) {
    return _.get(state, 'detail.orgName')
  },
  shopName(state) {
    return _.get(state, 'detail.shopName')
  },
  end(state) {
    return _.get(state, 'detail.end')
  },
  bannerUrl(state) {
    return _.get(state, 'detail.bannerUrl')
  },
  thumbnailBaseUrl(state) {
    return _.get(state, 'detail.thumbnailBaseUrl')
  },
  lupeBaseUrl(state) {
    return _.get(state, 'detail.lupeBaseUrl')
  },
  customThumbnailBaseUrl(state) {
    return _.get(state, 'detail.customThumbnailBaseUrl')
  },
  customLupeBaseUrl(state) {
    return _.get(state, 'detail.customLupeBaseUrl')
  },
  printType(state) {
    return _.get(state, 'detail.printType')
  },
  shippingType(state) {
    return _.get(state, 'detail.shippingType')
  },
  customizable(state) {
    return !!_.get(state, 'detail.customizable')
  },
  // 税別かどうか
  isTaxExcluded(state) {
    return _.get(state, 'detail.isTaxExcluded')
  },
  // お顔検索可能か
  canSearchFaces(state, getters) {
    // 年間販売か
    if (getters.isLongrun) {
      // 1つはtrueyなものがあるか
      return _.some(_.map(getters.subSales, item => item.canSearchFacesInSubSale))
    } else {
      return !!_.get(state, 'detail.canSearchFaces')
    }
  },
  // タイトル挿入するか
  insertSectionTitle(state) {
    return !!_.get(state, 'detail.insertSectionTitle')
  },
  // 過去の販売リスト
  oldSales(state) {
    return _.get(state, 'detail.oldSales')
  },
  // 年間販売の販売リスト
  subSales(state) {
    return _.get(state, 'detail.subSales')
  },
  // 過去販売の有無
  hasOldSale(state) {
    const oldSales = _.get(state, 'detail.oldSales')
    if (!oldSales) return false
    else return oldSales.length > 0
  },
  // 過去販売における期間外販売手数料
  oldSaleCharge(state) {
    return _.get(state, 'detail.oldSaleCharge')
  },
  // オプション販売をスキップするかどうか
  skippingOption(state) {
    const shippingType = _.get(state, 'detail.shippingType')
    const collectType = _.get(state, 'detail.collectType')
    // shippingType が 写真館一括(=2) または collectTypeが集金袋(=2)ならオプション販売はスキップ
    return shippingType === 2 || collectType === 2
  },
  // 注文手続きに必要な各種情報
  // 集金袋かどうか
  isPouch(state) {
    return _.get(state, 'detail.collectType') === 2
  },
  // 一般向けか
  isPublic(state) {
    return _.get(state, 'detail.saleType') === 1
  },
  // 個別発送か
  isIndividual(state) {
    return _.get(state, 'detail.shippingType') === 1
  },
  // PSサーバか
  isPsServer(state) {
    return parseInt(_.get(state, 'detail.printType'), 10) === 2
  },
  // 新しい(2020年度〜)お子様情報管理を使用するか
  useNewChildrenInfoSystem(state) {
    return _.get(state, 'detail.useNewChildrenInfoSystem') === 1
  },
  // 組情報
  orgAttributes(state) {
    return _.get(state, 'detail.orgAttributes')
  },
  // 2020年度〜一部で使用する新しい学年・組情報
  newOrgAttributes(state) {
    return _.get(state, 'detail.newOrgAttributes')
  },
  // 確認コードによる閲覧か
  byConfirmCode(state) {
    return _.get(state, 'detail.authorizationType') === 2
  },
  // 写真館管理画面からの閲覧か
  byShopConsole(state) {
    return _.get(state, 'detail.authorizationType') === 9
  },
  // 閲覧期間内かどうか。純粋に期間内の場合と、期間前で確認コードの場合とがある
  // また写真館管理画面からの閲覧の場合常に許可
  isInTerm(state, getters) {
    return (
      getters.byShopConsole ||
      getters.status === 'open' ||
      (getters.status === 'early' && getters.byConfirmCode)
    )
  },
  // 猶予期間内かどうか。そもそもguestには猶予期間がないのでfalse
  isInExtra(state, getters, rootState, rootGetters) {
    if (rootGetters['app/role'] !== 'member') return false
    else return getters.status === 'extra'
  },
  // この販売のメインのeventId。カゴページ以降で価格表やサイズ表を表示するときに必要
  eventIdNotSaleId(state) {
    return _.get(state, 'detail.eventIdNotSaleId')
  },
  // この販売に緊急のお知らせが存在するか。なおこの場合は閲覧は中断される
  hasFatalAnnouncement(state) {
    const announcement = _.get(state, 'detail.announcement')
    if (announcement && announcement.type === 4) return true
    return false
  },
  // この販売にInfoレベルのお知らせが存在するか。
  hasInfoAnnouncement(state) {
    const announcement = _.get(state, 'detail.announcement')
    if (announcement && announcement.type === 1) return true
    return false
  },
  // 販売のお知らせの取得
  announcement(state) {
    return _.get(state, 'detail.announcement')
  },
  // 販売のInfoレベルのお知らせが既読かどうか
  alreadyReadInfoAnnouncement(state) {
    return state.alreadyReadInfoAnnouncement
  },
  // 年間販売の手数料が無効化されているか
  isOverdueChargeDisabled(state) {
    return _.get(state, 'detail.isOverdueChargeDisabled', false)
  },
  // 年間販売かどうか
  isLongrun(state) {
    return _.get(state, 'detail.isLongrun')
  },
  // 年間販売の手数料
  overdueCharge(state, getters) {
    if (getters.isOverdueChargeDisabled) return 0
    return _.get(state, 'detail.overdueCharge', 0)
  },
  // 年間販売として紐付けられている販売を
  // 販売の最初の写真インデックス番号をもって、取得する
  getSubSaleByPhotoStartIndex: (state, getters) => photoIndex => {
    return _.find(getters.subSales, item => item.photoStartIndex === photoIndex)
  },
  // 発送手数料(通常)
  shipmentFeeNormal(state) {
    return _.get(state, 'detail.shipmentFeeNormal', 0)
  },
  // 発送手数料(4つ切含む)
  shipmentFeeLarge(state) {
    return _.get(state, 'detail.shipmentFeeLarge', 0)
  },
  // 送料一律値引き
  shippingDiscountBase(state) {
    return _.get(state, 'detail.shippingDiscountBase', 0)
  },
  // 送料無料のしきい値
  shippingFreeThreshold(state) {
    return _.get(state, 'detail.shippingFreeThreshold', 0)
  },
}

// ミューテーション
const mutations = {
  setId(state, payload) {
    state.id = parseInt(payload, 10)
  },
  setDetail(state, payload) {
    state.detail = payload
  },
  setAlreadyReadInfoAnnouncement(state, payload) {
    state.alreadyReadInfoAnnouncement = payload
  },
}

export default { state, getters, mutations }
