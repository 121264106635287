// ユーザ情報の更新

import apiFactory from '../api-factory'

export default apiFactory({
  path: '/my/infos',
  method: 'PUT',
  needAuth: true,
  bodyMapper: {
    name1: 'name1',
    name2: 'name2',
    name1Kana: 'name1_kana',
    name2Kana: 'name2_kana',
    tel: {
      key: 'location.tel',
      transform: value => (value ? value.replace(/\D/g, '') : ''),
    },
    zipcode: {
      key: 'location.zipcode',
      transform: value => (value ? value.replace(/\D/g, '') : ''),
    },
    prefecture: 'location.prefecture',
    address1: 'location.address1',
    address2: 'location.address2',
    address3: 'location.address3',
  },
})
