// 注文のキャンセル

import apiFactory from '../api-factory'

export default apiFactory({
  path: '/my/orders/<%= code %>/cancel',
  expectedParams: ['code'],
  method: 'PUT',
  needAuth: true,
  bodyMapper: {
    reasonCode: 'reason_code',
  },
})
