// 年間販売の期間外販売手数料が取られる写真を購入する際に表示するダイアログ

<template lang="pug">
.add-overdue-to-cart
  p
    | 標準販売期間を過ぎているため、1点につき
    span {{ overdueCharge }}円(税別)
    | の期間外販売手数料がかかります。
  .buttons
    ps-button(@click='$psdialog.close', color='orange') カゴに追加
</template>

<script>
export default {
  name: 'AddOverdueToCart',

  props: {
    overdueCharge: {
      type: Number,
      required: true,
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../../../sass/mixins.sass'

.add-overdue-to-cart
  p
    span
      color: $red
      font-weight: bold
  .buttons
    text-align: center
    margin-top: 1rem
</style>
