// 拡大画面のベース

<script>
import { mapGetters, mapActions } from 'vuex'

// 通常メニュー
import NormalMenu from './components/normal-menu'
// トリミング用メニュー
import TrimMenu from './components/trim-menu'
// カートページ用メニュー
import CartMenu from './components/cart-menu'
// お顔検索用メニュー
import OkaoMenu from './components/okao-menu'
// タイトルメニュー
import TitleMenu from './components/title-menu'

// 特定のアイテムのトリミング可能な条件を算出
import getTrimmableConditions from '@/helpers/get-trimmable-conditions'
// トリミングの位置情報の算出
import calcTrimmingPosition from '@/helpers/calc-trimming-position'

// トリミング内容確認ダイアログ
import TrimmingConfirmation from '@/dialogs/contents/trimming-confirmation'
// トリミングオプションダイアログ
import TrimmingOptions from '@/dialogs/contents/trimming-options'
// 日付入れダイアログ
import InsertingDate from '@/dialogs/contents/inserting-date'

// 年間販売についての説明ダイアログ
import AddOverdueToCartDialog from '@/dialogs/contents/add-overdue-to-cart'

import trimApiAsync from '@/api/sale/trim'
import discardTrimApiAsync from '@/api/sale/discard-trim'
import dateApiAsync from '@/api/sale/date'
import discardDateApiAsync from '@/api/sale/discard-date'

import * as errorCodes from '@/errors/codes'

export default {
  name: 'ZoomBase',

  components: {
    NormalMenu,
    TrimMenu,
    CartMenu,
    OkaoMenu,
    TitleMenu,
  },

  props: {
    // メニューの種類。 normal or cart or okao
    menuType: String,
    // 写真オブジェクト
    item: {
      required: true,
    },
    hasNext: Boolean,
    hasPrev: Boolean,
    // 最小サイズ(デフォルトサイズ)の価格を表示するか
    showPrice: {
      type: Boolean,
      default: true,
    },
    // 次(購入手続き)にすすめるか。カートページ用
    canGoToNextStep: {
      type: Boolean,
      default: false,
    },
    // お顔検索のお顔位置
    face: {},
  },

  data() {
    return {
      // トリミングモードかどうか
      trimMode: false,
      // 日付入れ中か。日付入れ時にルーペが動作しないようにするため
      dateMode: false,
      // Androidの標準ブラウザの一部でtouchendとclickがpreventdefaultしても発生する件への対策
      // 500ms 未満だと2回発生してしまう
      throttledNext: _.throttle(
        () => {
          this.$emit('goNext')
          // ルーペを消す
          this.lupePos = null
        },
        500,
        { trailing: false }
      ),
      throttledPrev: _.throttle(
        () => {
          this.$emit('goPrev')
          // ルーペを消す
          this.lupePos = null
        },
        500,
        { trailing: false }
      ),
      // ルーペの位置
      lupePos: null,
      // 1.7秒後にルーペを消す
      debouncedHideLupe: _.debounce(() => {
        this.lupePos = null
      }, 1700),
      // トリミングの向き
      trimOrientation: null,
      // トリミングのサイズと価格
      trimSizePrice: null,
      // トリミングの位置情報
      trimPos: null,
      // トリミングのアラート(これ以上小さくできない)を表示するかどうかのフラグ
      trimShowAlert: false,
      // 2秒後にトリミングアラートを消す
      debouncedHideTrimAlert: _.debounce(() => {
        this.trimShowAlert = false
      }, 2000),
      // マウント完了のフラグ
      domIsMounted: false,
      // お顔位置を表示するかどうかのフラグ
      showOkaoIndicator: true,
    }
  },

  created() {
    // スクロールロックをかける
    this.$lockBodyScroll()
    console.log(this.current)
  },

  // マウント完了のフラグ
  mounted() {
    this.domIsMounted = true
  },

  computed: {
    ...mapGetters({
      role: 'app/role',
      // ルーペのサイズ切り替えに使用
      screenType: 'app/screenType',
      saleId: 'sale/id',
      // 税別か
      isTaxExcluded: 'sale/isTaxExcluded',
      cartPhotosMap: 'cart/photosMap',
      customsMap: 'cart/customsMap',
      // 年間販売かどうか
      isLongrun: 'sale/isLongrun',
      // 年間販売の期間外販売手数料
      overdueCharge: 'sale/overdueCharge',
      // 年間販売の説明ダイアログを表示するか
      showFirstTimeAddOverdueToCartDialog: 'browsing/showFirstTimeAddOverdueToCartDialog',
    }),

    // カートに入っているか
    inCart() {
      return !!this.cartPhotosMap[this.item.id]
    },

    // カスタム情報を補完済みのアイテム
    current() {
      if (!this.customsMap[this.item.id]) return this.item
      else return _.assign({}, this.item, this.customsMap[this.item.id])
    },

    // 新しい仕様のサムネイル配信では、this.current.urlはオブジェクト
    thumbnailUrl() {
      if (_.isString(this.current.url)) {
        return `${this.current.url}l.jpg?token=${this.current.token}&${this.current.check}`
      } else {
        return this.current.url['l']
      }
    },

    // プリントの比率。トリミング時に必要
    printRatio() {
      return this.item.orientation === 'landscape'
        ? this.item.sizePrices[0].printLongEdge / this.item.sizePrices[0].printShortEdge
        : this.item.sizePrices[0].printShortEdge / this.item.sizePrices[0].printLongEdge
    },

    // トリミング可能か = 写真がカスタム可能で解像度が足りているか
    trimmable() {
      return !!(this.item.customizable && this.trimConditions)
    },

    // トリミング条件(許可されたものすべてを返す)
    trimConditions() {
      if (this.item.type !== 'photo') return null
      else return getTrimmableConditions(this.item)
    },

    // 複数のトリミング条件の選択肢があるか
    hasMultipleTrimConditions() {
      if (_.keys(this.trimConditions).length > 1) return true
      else if (
        this.trimConditions[this.item.orientation] &&
        this.trimConditions[this.item.orientation].length > 1
      )
        return true
      else return false
    },

    facePositionStyle() {
      if (!this.face || !this.item || !this.domIsMounted) return null
      const clientWidth = this.$refs.wrap.clientWidth
      const clientHeight = this.$refs.wrap.clientHeight
      const dataWidth = this.item.dataWidth
      const dataHeight = this.item.dataHeight
      const scale = Math.max(dataWidth / clientWidth, dataHeight / clientHeight)
      console.log(scale)
      const scaledClientWidth = clientWidth * scale
      const scaledClientHeight = clientHeight * scale

      const offsetX = (scaledClientWidth - dataWidth) / 2
      const offsetY = (scaledClientHeight - dataHeight) / 2

      return {
        top: Math.floor(((this.face.top + offsetY) / scaledClientHeight) * 100) + '%',
        left: Math.floor(((this.face.left + offsetX) / scaledClientWidth) * 100) + '%',
        bottom: Math.floor(((this.face.bottom + offsetY) / scaledClientHeight) * 100) + '%',
        right: Math.floor(((this.face.right + offsetX) / scaledClientWidth) * 100) + '%',
      }
    },
  },

  methods: {
    ...mapActions({
      initCartAsync: 'cart/initAsync',
      addPhotoToCartAsync: 'cart/addPhotoAsync',
      removePhotoFromCartAsync: 'cart/removePhotoAsync',
      setError: 'app/setError',
    }),

    moveInPhoto(pos) {
      this.lupePos = pos
      this.debouncedHideLupe()
      // モバイルに限り写真エリア内で移動(タップ)されたらお顔の位置表示消す
      if (this.screenType === 'mobile') this.showOkaoIndicator = false
    },

    // デフォルトのサイズをカゴに追加
    async addToCartDefaultSizeAsync() {
      // 期間外か
      if (this.isLongrun && this.overdueCharge && this.item.isOverdue) {
        if (this.showFirstTimeAddOverdueToCartDialog) {
          await this.showDialogForLongrun()
          // 一度表示したのでフラグをfalseに
          this.$store.commit('browsing/setShowFirstTimeAddOverdueToCartDialog', false)
        }
      }
      try {
        await this.addPhotoToCartAsync({
          photoId: this.item.id,
          num: 1,
          sizeId: this.item.sizePrices[0].sizeId,
          silent: true,
        })
      } catch (e) {
        this.setError(e)
      }
    },

    // 写真枚数の変更
    async changePhotoNumAsync(params) {
      try {
        await this.addPhotoToCartAsync({
          photoId: this.current.id,
          photoCustomId: this.current.customId,
          num: params.num,
          sizeId: params.sizeId,
        })
        // iPadでカゴページにて写真枚数変更時、マスクの計算がずれるのを防ぐために
        _.delay(() => this.$refs.photo.adjust(), 300)
      } catch (e) {
        this.setError(e)
      }
    },

    // カゴからすべてのアイテムを削除
    removeFromCartAll() {
      this.removePhotoFromCartAsync({ photoId: this.item.id, silent: true })
    },

    // トリミングの開始
    startTrimming() {
      // 元画像の向きの取得
      this.trimOrientation = this.item.orientation
      this.trimSizePrice = this.trimConditions[this.item.orientation][0]
      this.trimPos = null
      this.trimMode = true
      // 特にスマホにおいて、メニューが切り替わった際に、
      // 写真領域のサイズが変わる場合、比率の計算が狂うので、
      // 強制的に再アジャスト
      this.$nextTick(() => {
        this.$refs.photo.adjust()
      })
    },

    // トリミング作業のキャンセル
    cancelTrimming() {
      this.trimMode = false
      this.trimOrientation = null
      this.trimSizePrice = null
      this.trimPos = null
      // 特にスマホにおいて、メニューが切り替わった際に、
      // 写真領域のサイズが変わる場合、比率の計算が狂うので、
      // 強制的に再アジャスト
      this.$nextTick(() => {
        this.$refs.photo.adjust()
      })
    },

    // トリミングされた状態を解除
    async discardTrimmingAsync() {
      // confirm('トリミング解除を行うとカゴからも削除されます。')
      const body = { saleId: this.saleId, photoId: this.item.id }
      const response = await discardTrimApiAsync(null, body)
      if (!response.ok) throw new Error(errorCodes.DISCARD_TRIM_ERROR)

      // カートの状態を更新
      await this.initCartAsync(true)
    },

    // トリミング実行
    async doTrimmingAsync() {
      // トリミング情報がない または ほぼ元画像と同じ場合(99.99%)は何もしない。
      if (!this.trimPos || (this.trimPos.width > 99.99 && this.trimPos.height > 99.99)) {
        this.cancelTrimming()
        return
      }

      const position = calcTrimmingPosition(this.item, this.trimPos)
      const body = { saleId: this.saleId, photoId: this.item.id, position }
      const response = await trimApiAsync(null, body)
      if (!response.ok) throw new Error(errorCodes.TRIM_ERROR)

      this._showTrimmingConfirmationDialog(response.payload.id)
    },

    // トリミング内容の確認ダイアログ
    _showTrimmingConfirmationDialog(customId) {
      this.$psdialog.open({
        component: TrimmingConfirmation,
        props: {
          orientation: this.trimOrientation,
          printLongEdge: this.trimSizePrice.printLongEdge,
          printShortEdge: this.trimSizePrice.printShortEdge,
          zoom: this.item.zoom,
          inCart: this.inCart,
          customId,
          // トリミング画像がglusterFs上にある場合など
          // 画像取得を2回試行するかどうか
          loadImageDouble: process.env.VUE_APP_LOAD_TRIM_IMAGE_DOUBLE === 'true',
        },
        // トリミング内容の確認ウィンドウが大きすぎるので幅を制限
        maxWidth: '800px',
        onClose: params => {
          this.onCloseTrimmingConfirmationDialogAsync(
            params.type,
            this.trimSizePrice.sizeId,
            customId
          )
        },
      })
    },

    async onCloseTrimmingConfirmationDialogAsync(type, sizeId, photoCustomId) {
      try {
        // トリミング確定
        if (type === 'ok') {
          // 一度オールクリア
          const photoId = this.item.id
          await this.removePhotoFromCartAsync({ photoId })
          // カゴに追加
          await this.addPhotoToCartAsync({ photoId, sizeId, photoCustomId, num: 1 })
          // トリミングモードも終了
          this.cancelTrimming()
        } else {
          // トリミングし直し、または、トリミング自体キャンセル
          // トリミング周りのバグのため、discardしないと処理が残る
          await this.discardTrimmingAsync()
          if (type === 'cancel') this.cancelTrimming()
        }
      } catch (e) {
        this.setError(e)
      }
    },

    showTrimmingOptionsDialog() {
      this.$psdialog.open({
        title: 'トリミングの設定',
        component: TrimmingOptions,
        props: {
          orientation: this.trimOrientation,
          sizePrice: this.trimSizePrice,
          conditions: this.trimConditions,
          // 税別か
          isTaxExcluded: this.isTaxExcluded,
        },
        size: 's',
        closeByClickBg: true,
        closeButton: true,
        onClose: this.onCloseTrimmingOptionsDialog,
      })
    },

    onCloseTrimmingOptionsDialog(params) {
      if (!params || !params.update) return
      this.trimOrientation = params.orientation
      this.trimSizePrice = params.sizePrice
    },

    // これ以上小さくできない時(トリミング時)のアラートを表示
    showTrimAlert() {
      this.trimShowAlert = true
      this.debouncedHideTrimAlert()
    },

    // 日付入れダイアログの表示
    showInsertDateDialog() {
      this.dateMode = true
      this.$psdialog.open({
        title: '日付入れの設定',
        component: InsertingDate,
        props: { inCart: this.inCart },
        size: 's',
        closeByClickBg: true,
        closeButton: true,
        onClose: params => {
          this.dateMode = false
          if (params) this.doDateAsync(params)
        },
      })
    },

    // 文字入れ処理を実際に実行
    async doDateAsync(textParams) {
      try {
        const body = _.assign(
          {
            saleId: this.saleId,
            photoId: this.item.id,
            printLongEdge: this.item.sizePrices[0].printLongEdge,
            printShortEdge: this.item.sizePrices[0].printShortEdge,
          },
          textParams
        )
        const response = await dateApiAsync(null, body)
        if (!response.ok) throw new Error(errorCodes.DATE_ERROR)

        // カートに入っているならカート情報の更新のみ
        if (this.inCart) {
          // 成功したらカート情報を再取得
          this.initCartAsync(true)
        } else {
          // カートに入っていないならデフォルトサイズをカートへ
          this.addPhotoToCartAsync({
            photoCustomId: response.payload.id,
            photoId: this.item.id,
            num: 1,
            sizeId: this.item.sizePrices[0].sizeId,
          })
        }
      } catch (e) {
        this.setError(e)
      }
    },

    // 日付入れの解除
    async discardDatingAsync() {
      const body = { saleId: this.saleId, photoId: this.item.id }
      const response = await discardDateApiAsync(null, body)
      if (!response.ok) throw new Error(errorCodes.DISCARD_DATE_ERROR)

      // カートの状態を更新
      await this.initCartAsync(true)
    },

    // 年間販売の説明のためのダイアログを表示
    showDialogForLongrun() {
      return new Promise(resolve => {
        this.$psdialog.open({
          title: '期間外販売について',
          component: AddOverdueToCartDialog,
          props: {
            overdueCharge: this.overdueCharge,
          },
          size: 's',
          closeByClickBg: true,
          closeButton: true,
          onClose: () => {
            resolve()
          },
        })
      })
    },
  },

  watch: {
    item() {
      // 写真アイテムが変わるたびに、お顔の位置表示フラグをクリア
      this.showOkaoIndicator = true
    },
  },

  beforeDestroy() {
    // スクロールロックの解除
    this.$unlockScroll()
  },
}
</script>

<template lang="pug">
.zoom-base

  // 写真
  .main
    .wrapper(ref='wrap', :class='[current.rank, { "in-cart": inCart }]')

      // 写真
      // long-edge, short-edge のフォールバックはデータ販売用
      template(v-if='current.type === "photo"')
        ps-fitting-photo(
          ref='photo',
          :long-edge='current.sizePrices[0].printLongEdge || (current.dataWidth >= current.dataHeight ? current.dataWidth : current.dataHeight)',
          :short-edge='current.sizePrices[0].printShortEdge || (current.dataWidth < current.dataHeight ? current.dataWidth : current.dataHeight)',
          :orientation='current.orientation',
          :zoom='current.zoom || 1.0',
          :url='thumbnailUrl',
          @moveInPhoto='moveInPhoto',
          @moveOutPhoto='lupePos = null'
        )
          template(v-if='trimMode')
            ps-trimmer.trimmer(
              :trim-ratio='trimSizePrice.ratio',
              :image-ratio='printRatio',
              :min-scale='trimSizePrice.minScalePercent',
              @update='trimPos = arguments[0]',
              @reachMinScale='showTrimAlert'
            )

            .trim-alert(v-show='trimShowAlert')
              p これ以上トリミングできません。
      
      // タイトル
      .title(v-else-if='current.type === "title"')
        p(v-html='current.titles.join("<br>")')

      //- 年間販売における販売境界
      .sale-title(v-else-if='current.type === "sale"')
        .wrapper
          h3
            span(v-if='current.isOverdue && overdueCharge') 期間外
            | {{ current.name }}
          .term(v-if='!current.isOverdue && overdueCharge')
            | 標準販売期間: {{ current.due }}まで
          .note.caution(v-if='current.isOverdue && overdueCharge')
            | ※ 1枚あたり
            b {{ overdueCharge }}円(税別)
            | の期間外販売手数料がかかります。
          .note(v-else-if='overdueCharge')
            | ※ 標準販売期間を過ぎると1枚あたり
            b {{ overdueCharge }}円(税別)
            | の期間外販売手数料がかかります。

      // お顔の位置表示インジケータ。トリミング済みの場合は最初から表示しない。
      transition(name='okao')
        .face(v-if='facePositionStyle && !current.trimmed', v-show='showOkaoIndicator', :style='facePositionStyle')

      // トリミングモードでないときに情報や次前ボタンを表示
      template(v-if='!trimMode && !dateMode')

        // この写真に関する各種情報(タイトルの場合は表示しない)
        template(v-if='current.type === "photo"')
          // 価格と写真番号
          .photo-info
            // 基本情報
            .basic
              p.index {{ item.index }}
              // 閲覧画面でのみ表示
              p.price(v-show='showPrice') {{ item.sizePrices[0].price }}円{{ isTaxExcluded ? '(税別)' : '' }}

            // ゴールド・プレミアム
            p.badge(v-if='current.rank === "gold"') ゴールド
            p.badge(v-else-if='current.rank === "premium"') プレミアム

          // 写真加工情報
          .custom-info
            //- 期間外販売
            template(v-if='current.isOverdue && overdueCharge')
              span.overdue 期間外
              br
            template(v-if='current.trimmed')
              span.trimmed トリミング済み
              br
            span.dated(v-if='current.dated') 日付入れ済み

          // ルーペ。モバイルの場合はひと回り小さく
          // 新サムネイル配信の場合はlupeTokenをトークンとして利用
          ps-balloon-lupe(
            v-if='lupePos',
            :small='screenType === "mobile"',
            :x='lupePos.outer.x', :y='lupePos.outer.y',
            :dom-x='lupePos.photo.x', :dom-y='lupePos.photo.y',
            :dom-width='lupePos.photo.width', :dom-height='lupePos.photo.height',
            :data-width='current.dataWidth', :data-height='current.dataHeight',
            :base-url='current.lupeUrl', :id='current.id', :token='current.lupeToken || current.token'
          )

        // 前へ
        a.prev-button(
          v-show='hasPrev',
          @click.prevent.stop='throttledPrev',
          @touchend.prevent.stop='throttledPrev',
          title='前の写真'
        )
          ps-icon(icon='arrow-left')

        // 次へ
        a.next-button(
          v-show='hasNext',
          @click.prevent.stop='throttledNext',
          @touchend.prevent.stop='throttledNext',
          title='次の写真'
        )
          ps-icon(icon='arrow-right')

  // メニュー
  .sub
    // タイトル用のメニュー
    title-menu(
      v-if='current.type === "title"',
      @close='$emit("close")'
    )
    // 閲覧画面でのメニュー
    normal-menu(
      v-else-if='menuType === "normal" && !trimMode',
      :in-cart='inCart',
      :trimmable='trimmable',
      :trimmed='current.trimmed',
      @addToCart='addToCartDefaultSizeAsync()',
      @removeFromCart='removeFromCartAll()',
      @startTrimming='startTrimming',
      @discardTrimming='discardTrimmingAsync',
      @close='$emit("close")'
    )
    // カートページ用メニュー
    cart-menu(
      v-else-if='menuType === "cart" && !trimMode',
      :cart-info='cartPhotosMap[current.id]',
      :size-prices='current.sizePrices',
      :trimmable='trimmable',
      :trimmed='current.trimmed',
      :dated='current.dated',
      :datable='current.customizable && !current.oldTrim',
      :can-go-to-next-step='canGoToNextStep',
      :long-edge='current.dataWidth >= current.dataHeight ? current.dataWidth: current.dataHeight',
      :is-tax-excluded='isTaxExcluded',
      @removeFromCart='removeFromCartAll()',
      @changePhotoNum='changePhotoNumAsync',
      @startTrimming='startTrimming',
      @discardTrimming='discardTrimmingAsync',
      @startDating='showInsertDateDialog',
      @discardDating='discardDatingAsync',
      @goToOutro='$emit("goToNextStep")',
      @close='$emit("close")'
    )
    // トリミングメニュー
    trim-menu(
      v-if='trimMode',
      :orientation='trimOrientation',
      :size='trimSizePrice.sizeName',
      :has-multiple-conditions='hasMultipleTrimConditions',
      @ok='doTrimmingAsync',
      @cancel='cancelTrimming',
      @showOptionsDialog='showTrimmingOptionsDialog'
    )
    // お顔検索用メニュー
    okao-menu(
      v-if='menuType === "okao" && !trimMode',
      :in-cart='inCart',
      :trimmable='trimmable',
      :trimmed='current.trimmed',
      :showOkaoIndicator='showOkaoIndicator',
      @addToCart='addToCartDefaultSizeAsync()',
      @removeFromCart='removeFromCartAll()',
      @startTrimming='startTrimming',
      @discardTrimming='discardTrimmingAsync',
      @showOkaoInd='showOkaoIndicator = true',
      @hideOkaoInd='showOkaoIndicator = false',
      @close='$emit("close")'
    )

</template>

<style lang="sass" scoped>
@import '../../../sass/variables.sass'
@import '../../../sass/mixins.sass'

.okao-leave-active
  transition: opacity 1s
.okao-enter-active
  transition: opacity 1s

.okao-enter, .okao-leave-to
  opacity: 0

.zoom-base
  display: flex
  width: 100%
  height: 100%
  +mobile
    flex-direction: column

  // 写真
  > .main
    flex-grow: 1
    position: relative
    > .wrapper
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
      background-color: #eee
      border-radius: $radius-small

      // トリミング時のアラート
      .trim-alert
        position: absolute
        bottom: 15px
        width: 100%
        text-align: center
        > p
          display: inline-block
          background-color: rgba(lighten($pink, 20%), 0.7)
          padding: 0.3rem
          border-radius: $radius-small
          color: $red

      // 価格等の情報
      > .photo-info
        position: absolute
        top: 5px
        right: 5px
        +mobile
          left: 5px
          right: auto
        .basic
          background-color: $white
          padding: 0.2rem
          min-width: 4rem
          text-align: center
          border-radius: $radius-small
          font-size: 1.1rem
          > .price
            margin-top: 0.2rem
            border-top: 1px solid $grey
            padding-top: 0.2rem
            font-size: 0.9rem
        .badge
          margin-top: 0.5rem
          background-color: $grey
          padding: 0.2rem
          text-align: center
          border-radius: $radius-small
          border: 1px solid $grey

      > .custom-info
        position: absolute
        top: 5px
        left: 5px
        +mobile
          left: auto
          right: 5px
        text-align: right
        > span
          display: inline-block
          line-height: 1
          color: $white
          padding: 0.4rem
          border-radius: $radius-small
          margin-bottom: 0.4rem
          &.overdue
            background: darken($pink, 15%)
          &.trimmed
            background-color: $purple
          &.dated
            background-color: $blue

      > .face
        border: 3px solid rgba(red, 0.9)
        border-radius: $radius-small
        position: absolute
        transform: scale(1.2)

      // 次前ボタン
      > .prev-button, > .next-button
        position: absolute
        display: flex
        align-items: center
        justify-content: center
        bottom: 0.5rem
        font-size: 3rem
        background-color: rgba(#9fd52c, 0.7)
        width: 4rem
        height: 4rem
        color: $white
        cursor: pointer
        border-radius: $radius-small
        &:hover
          transform: scale(1.1)
          background-color: rgba(#9fd52c, 0.85)
      > .prev-button
        left: 10px
      > .next-button
        right: 10px

      // タイトル
      > .title
        +bg-stripe(#ff8caf, lighten(#ff5f90, 10%))
        width: 100%
        height: 100%
        border-radius: $radius-small
        display: flex
        align-items: center
        justify-content: center
        > p
          color: $white
          font-size: $size-largest
          font-weight: bold
          text-shadow: 2px 2px 2px darken(#ff5f90, 20%)
          text-align: center
          line-height: 1.5

      // 年間販売における販売境界
      > .sale-title
        border-radius: $radius-small
        display: flex
        height: 100%
        align-items: center
        justify-content: center
        background: #fffec5
        .wrapper
          padding: 0 0.5rem
          h3
            text-align: center
            span
              background: $red
              color: $white
              font-weight: bold
              padding: 0.1rem 0.3rem
              margin-right: 0.3rem
          .term
            text-align: center
            margin-top: 0.5rem
          .note
            text-align: center
            margin-top: 0.5rem
            &.caution
              color: $red

      // ゴールド・プレミアム
      &.gold
        background: linear-gradient(to bottom right, #d0c395 0%, #f9edda 50%, #d0c395 100%)
        .badge
          background: linear-gradient(to bottom right, #c7be9a 0%, #f3ebd1 50%, #c7be9a 100%)
          color: #826e22
          box-shadow: 0 0 3px 0 rgba(#826e22, .8)
          border: none
      &.premium
        background: linear-gradient(to bottom, #ccc 0%, #fff 50%, #ccc 100%)
        .badge
          background: linear-gradient(to bottom right, #ccc 0%, #fff 50%, #ccc 100%)
          border: none
          color: #999
          box-shadow: 0 0 3px 0 rgba(#999, .8)
      // カートに入っている場合
      &.in-cart
        background: #ffcda5

  // メニュー表示領域
  > .sub
    +tablet
      // iOS Safariにおいて、 メニューに対して親である.subに
      // heightが指定してないと、子要素のheight: 100%が効かないので明示的に設定
      height: 100%
      width: 160px
      margin-left: 10px
    +mobile
      margin-top: 5px
</style>
