// 過去販売のリストの表示

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'OldSales',

  computed: {
    ...mapGetters({
      saleId: 'sale/id',
      oldSales: 'sale/oldSales',
      oldSaleCharge: 'sale/oldSaleCharge',
    }),
  },

  methods: {
    moveToOldSale(subSaleId) {
      this.$router.push({
        name: 'BrowsePage',
        query: { subSaleId },
        params: { saleId: this.saleId },
      })
      this.$psdialog.close()
    },
  },
}
</script>

<template lang="pug">
.old-sales

  .intro

    h3 お読みください

    p
      | 「以前の販売」での写真のご注文時には、ご注文される写真枚数にかかわらず、
      b 各販売毎
      | に
      span {{ oldSaleCharge }}円(税込)
      | の「期間外販売手数料」が加算されます。

    p
      | 例えば、「以前の販売」2件で写真をご注文される場合は次のようになります。
        
    p.example
      | (例) {{ oldSaleCharge }}円(期間外販売手数料) &times; 2件(販売の件数) = {{ oldSaleCharge * 2 }}円

  h3 以前の販売の一覧

  ul
    li(v-for='sale in oldSales')
      a(@click='moveToOldSale(sale.id)')
        | {{ sale.name }} &nbsp;
        ps-icon(icon='chevron-circle-right')

  .buttons
    ps-button(@click='$psdialog.close()', large-width) 閉じる

</template>

<style lang="sass" scoped>
@import '../../../sass/mixins.sass'

.old-sales
  > .intro
    border-radius: $radius
    padding: 0.5rem
    background-color: lighten($pink, 18%)
    margin-bottom: 1rem
    h3
      font-size: $size-normal
      text-align: center
      margin-bottom: 0.5rem
    p
      margin-bottom: 0.5rem
      line-height: 1.4
      font-size: $size-small
      span
        color: $red
        font-weight: bold
      &.example
        background-color: $white-ter
        padding: 0.2rem 0
        text-align: center

        font-weight: normal
        font-size: $size-medium
        border-bottom: 1px dotted lighten($blue, 30%)
        padding: 0 0 0.2rem 0.1rem
        color: darken($blue, 20%)
        margin-bottom: 0.5rem
        text-align: center

  > ul
    margin-bottom: 1rem
    li
      a
        display: block
        background-color: lighten($blue, 38%)
        color: darken($blue, 20%)
        padding: 0.5rem 0
        border-radius: $radius
        text-align: center
        margin: 3px 0
        cursor: pointer

  > .buttons
    text-align: center
</style>
