import Vue from 'vue'
import Router from 'vue-router'
import { beforeEach, afterEach, createRoleChecker } from './handlers'

import TopPage from '@/pages/top-page'

import PublicPage from '@/pages/public-page'
import PublicPageInfo from '@/pages/public-page/children/info'
import PublicPageReminder from '@/pages/public-page/children/reminder'
import PublicPageRestPassword from '@/pages/public-page/children/reset-password'
import PublicPageChangeEmail from '@/pages/public-page/children/change-email'
import PublicPageNotFound from '@/pages/public-page/children/not-found'
import PublicPageLegal from '@/pages/public-page/children/legal'
import PublicPagePrivacy from '@/pages/public-page/children/privacy'
import PublicPageTerm from '@/pages/public-page/children/term'

import IntroPage from '@/pages/intro-page'
import IntroPageRegister from '@/pages/intro-page/children/register'
import IntroPageActivation from '@/pages/intro-page/children/activation'
import IntroPageAuthorization from '@/pages/intro-page/children/authorization'

import MyPage from '@/pages/my-page'
import MyPageTop from '@/pages/my-page/children/top'
import MyPageHistory from '@/pages/my-page/children/history'
import MyPageInfo from '@/pages/my-page/children/info'
import MyPageChildren from '@/pages/my-page/children/children'

import BrowsePage from '@/pages/browse-page'

import OutroPage from '@/pages/outro-page'
import OutroPageCart from '@/pages/outro-page/children/cart'
import OutroPageRegister from '@/pages/outro-page/children/register'
import OutroPageConfirm from '@/pages/outro-page/children/confirm'
import OutroPageCompleted from '@/pages/outro-page/children/completed'

Vue.use(Router)

// env.TOP_PAGE_URLがある場合、
// トップページは使用せず、指定したURLにリダイレクト
const topPageRouting = process.env.VUE_APP_TOP_PAGE_URL
  ? {
      path: '/',
      name: TopPage.name,
      redirect: () => {
        location.href = process.env.VUE_APP_TOP_PAGE_URL
      },
    }
  : {
      path: '/',
      name: TopPage.name,
      component: TopPage,
      meta: { title: '写真販売サイト フォトいいね!' },
      beforeEnter: createRoleChecker(['public', 'guest'], { name: MyPageTop.name }),
    }

let routes = [
  // トップページ
  topPageRouting,
  // 一般向けページ
  {
    path: '/public',
    component: PublicPage,
    children: [
      {
        path: 'info',
        name: PublicPageInfo.name,
        component: PublicPageInfo,
        meta: { title: 'お知らせ' },
      },
      {
        path: 'reminder',
        name: PublicPageReminder.name,
        component: PublicPageReminder,
        meta: { title: 'パスワードをお忘れの方' },
      },
      {
        path: 'reset-password/:token',
        name: PublicPageRestPassword.name,
        component: PublicPageRestPassword,
        meta: { title: 'パスワードのリセット' },
      },
      {
        path: 'change-email/:token',
        name: PublicPageChangeEmail.name,
        component: PublicPageChangeEmail,
        meta: { title: 'メールアドレスの変更' },
      },
      {
        path: 'not-found',
        name: PublicPageNotFound.name,
        component: PublicPageNotFound,
        meta: { title: 'ページが見つかりません' },
      },
      {
        path: 'legal',
        name: PublicPageLegal.name,
        component: PublicPageLegal,
        meta: { title: '特定商取引法に基づく表記 ' },
      },
      {
        path: 'privacy',
        name: PublicPagePrivacy.name,
        component: PublicPagePrivacy,
        meta: { title: '個人情報保護方針' },
      },
      {
        path: 'term',
        name: PublicPageTerm.name,
        component: PublicPageTerm,
        meta: { title: '利用規約' },
      },
    ],
  },
  // はじめての方向けのページ群
  {
    path: '/intro',
    component: IntroPage,
    children: [
      {
        path: 'register',
        name: IntroPageRegister.name,
        component: IntroPageRegister,
        meta: { title: 'メールアドレスの確認' },
        beforeEnter: createRoleChecker(['public', 'guest'], { name: TopPage.name }),
      },
      {
        path: 'activation/:token',
        name: IntroPageActivation.name,
        component: IntroPageActivation,
        meta: { title: '確認コードの入力' },
        beforeEnter: createRoleChecker(['public', 'guest'], { name: TopPage.name }),
      },
      // このページはゲストのみ
      {
        path: 'authorization',
        name: IntroPageAuthorization.name,
        component: IntroPageAuthorization,
        meta: { title: '閲覧用のコードの入力' },
        beforeEnter: createRoleChecker(['guest'], { name: TopPage.name }),
      },
    ],
  },
  // マイページ関連
  {
    path: '/my',
    component: MyPage,
    meta: { title: 'マイページ' },
    beforeEnter: createRoleChecker(['member'], { name: TopPage.name }),
    children: [
      {
        path: '',
        name: MyPageTop.name,
        component: MyPageTop,
        meta: { title: 'マイページトップ' },
      },
      {
        path: 'history',
        name: MyPageHistory.name,
        component: MyPageHistory,
        meta: { title: 'ご購入履歴' },
      },
      {
        path: 'info',
        name: MyPageInfo.name,
        component: MyPageInfo,
        meta: { title: 'お客様情報' },
      },
      {
        path: 'children',
        name: MyPageChildren.name,
        component: MyPageChildren,
        meta: { title: 'お子様情報' },
      },
    ],
  },
  // 閲覧ページ
  {
    path: '/browse/:saleId',
    name: BrowsePage.name,
    component: BrowsePage,
    meta: { title: '写真の閲覧' },
    beforeEnter: createRoleChecker(['guest', 'member'], { name: TopPage.name }),
  },
  // カートページ
  {
    path: '/outro/:saleId',
    component: OutroPage,
    beforeEnter: createRoleChecker(['guest', 'member'], { name: TopPage.name }),
    children: [
      {
        path: 'cart',
        name: OutroPageCart.name,
        component: OutroPageCart,
        meta: { title: '買い物カゴ' },
      },
      {
        path: 'register',
        name: OutroPageRegister.name,
        component: OutroPageRegister,
        meta: { title: 'ご注文情報の入力' },
      },
      {
        path: 'confirm',
        name: OutroPageConfirm.name,
        component: OutroPageConfirm,
        meta: { title: 'ご注文内容の確認' },
      },
      {
        path: 'completed/:code',
        name: OutroPageCompleted.name,
        component: OutroPageCompleted,
        meta: { title: 'ご注文完了' },
      },
    ],
  },
  // 404
  {
    path: '*',
    redirect: { name: PublicPageNotFound.name },
  },
]

const router = new Router({
  mode: 'history',
  base: process.env.VUE_APP_ROUTER_BASE || '/',
  routes,
})

router.beforeEach(beforeEach)
router.afterEach(afterEach)

export default router
