// 特定商取引法

<template lang="pug">
.legal

  .outer

    ps-medium-heading 特定商取引法に基づく表記

    ul.contents
      li(v-for='item, i in contents', :key='i')
        h3 {{ item.title }}
        template(v-for='desc in item.descriptions')
          p(v-if='desc.type === "text"') {{ desc.value }}
          ul(v-else-if='desc.type === "list"')
            li(v-for='listItem, k in desc.value', :key='k') {{ listItem }}

  .buttons
    ps-button(@click='$router.back()') 戻る
</template>

<script>
const contents = [
  {
    title: '屋号',
    descriptions: [
      {
        type: 'text',
        value: '写真の伊里',
      },
    ],
  },
  {
    title: ' 事業者名',
    descriptions: [
      {
        type: 'text',
        value: '有限会社アイリス企画',
      },
    ],
  },
  {
    title: '運営責任者名',
    descriptions: [
      {
        type: 'text',
        value: '作道 有哉',
      },
    ],
  },
  {
    title: '住所',
    descriptions: [
      {
        type: 'text',
        value: '〒488-0002  愛知県尾張旭市根の鼻町1-2-12',
      },
    ],
  },
  {
    title: '電話番号',
    descriptions: [
      {
        type: 'text',
        value: '0561-55-4051',
      },
      {
        type: 'text',
        value: '(FAX：0561-55-4052)',
      },
    ],
  },
  {
    title: 'メールアドレス',
    descriptions: [
      {
        type: 'text',
        value: 'info@syashinnoiri.co.jp',
      },
    ],
  },
  {
    title: '商品代金以外の料金の説明',
    descriptions: [
      {
        type: 'text',
        value:
          'ご購入の際の送料は、全国一律税込286円または税別260円。ただし、幼稚園・学校より商品を配布する場合は送料無料',
      },
    ],
  },
  {
    title: 'お支払い方法',
    descriptions: [
      {
        type: 'list',
        value: [
          'クレジットカード決済',
          'コンビニ決済',
          ' 園・学校でのお支払い (注文袋) (幼稚園・学校より商品を配布する販売は、注文袋に代金を入れて、園・学校にお渡しください。)',
        ],
      },
    ],
  },
  {
    title: 'お支払い時期',
    descriptions: [
      {
        type: 'list',
        value: [
          'クレジットカード: 購入時',
          'コンビニ決済: 注文より10日以内',
          '園・学校でのお支払い(注文袋): 注文袋の提出期限までに園・学校にお渡しください',
        ],
      },
    ],
  },
  {
    title: '商品引渡し時期',
    descriptions: [
      {
        type: 'text',
        value: '注文確定から15日以内に発送いたします。',
      },
      {
        type: 'text',
        value:
          'ただし、幼稚園・学校より商品を配布する場合は、注文袋の提出期限から2週間以内に幼稚園・学校に納品いたします',
      },
    ],
  },
  {
    title: '返品・キャンセル・交換について',
    descriptions: [
      '商品は受注生産のため、お客様都合による返品・キャンセル・交換はお受けできません。 ',
    ],
  },
  {
    title: 'サイズ違い・品番違い・不良品 ',
    descriptions: [
      {
        type: 'text',
        value:
          '商品の品質には万全を期しておりますが、サイズ違い・品番違い・品質不良などがあった場合は、商品到着日より14日以内に当社に到着したもののみ返品及び交換を受け付けるものとします。',
      },
      {
        type: 'text',
        value:
          '当社までお電話をいただいた後、商品の入った封筒と納品書を同封の上、当社まで料金着払いでご返送ください。再プリント料金を含み当社負担で良品を発送致します。',
      },
      {
        type: 'text',
        value:
          'なお、期間経過後は、商品にサイズ違い・品番違い・品質不良があっても、お客様がご了承の上、受領されたものとします。',
      },
    ],
  },
  {
    title: '個人情報の取り扱い',
    descriptions: [
      {
        type: 'text',
        value: '「個人情報保護方針」をご参照ください。 ',
      },
    ],
  },
]

export default {
  name: 'PublicPageLegal',

  computed: {
    contents() {
      return contents
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../../../../sass/variables.sass'
@import '../../../../sass/mixins.sass'

.legal
  margin-bottom: 2rem

  .outer

    background-color: $white
    padding: 1.5rem 1rem 4rem
    border-radius: $radius-small
    box-shadow: 0 1px 6px 2px rgba(0, 0, 0, .25)
    +mobile
      padding: 1rem 0.5rem
      box-shadow: 0 1px 1px 1px rgba(0, 0, 0, .3)

    .contents
      > li
        margin-top: 2rem
        h3
          font-size: 1.1rem
          border-bottom: 1px dashed #ccc
          padding: 0.2rem 0
          letter-spacing: 0.05rem
        p
          margin-top: 0.5rem
        ul
          margin-top: 0.5rem
          li
            list-style-type: disc
            list-style-position: inside
            margin-left: 0.5rem
            margin-top: 0.5rem
            &:first-child
              margin-top: 0

  .buttons
    text-align: center
    margin: 3rem 0
</style>
