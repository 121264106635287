// お顔検索の実行

<template lang="pug">
.prepare-searching-faces

  .step-1(v-if='step === 1')
    p
      b 「お顔検索」
      | とは、お手持ちのお子様の写真を利用して、お子様が写っている写真を検索する便利な機能です。

    .photo
      div
        div
          .preview(
            v-if='imageDataUrl',
            :style='{ "background-image": `url(${imageDataUrl})` }'
          )
            p.buttons
              ps-button(size='smallest', color='red', @click='removePhoto') 削除
              ps-button(size='smallest', color='blue', @click='adjustPhoto') 位置調整
            
          .operation(v-else)
            label.ps-button.ps-button--green
              .ps-button__inner
                ps-icon(icon='image')
                | &nbsp;写真を登録する
              input(
                type='file',
                accept='image/jpeg',
                @change='changePhoto'
              )
      p
        ps-icon(icon='exclamation-triangle')
        | &nbsp;正面を向いている写真を使用してください。

    .buttons
      ps-button(
        v-if='isLongrun',
        size='orange', full-width, text-centered,
        :disabled='!this.originalFile || !this.faceImageOrBlob',
        @click='goNext'
      )
        | 次へ&nbsp;
        ps-icon(icon='arrow-right')
      ps-button(
        v-else,
        size='orange', full-width, text-centered,
        :disabled='!this.originalFile || !this.faceImageOrBlob',
        @click='searchFaces(saleId)'
      )
        ps-icon(icon='search')
        | &nbsp;検索開始

  .step-2(v-else)
    p 検索したい販売を選んでください。

    ul
      li(v-for='item in subSales', :key='item.id')
        a(@click='searchFaces(item.id)')
          span(v-if='item.isOverdue') 期間外
          | &nbsp;{{ item.name }}

    .buttons
      ps-button(@click='goPrev')
        ps-icon(icon='arrow-left')
        | &nbsp;戻る

</template>

<script>
import { mapActions, mapGetters } from 'vuex'

// お顔写真トリミングダイアログ
import FacePhotoAdjuster from '../face-photo-adjuster'
// 検索ダイアログ
import SearchFaces from '../search-faces'

export default {
  name: 'PrepareSearchingFaces',

  data() {
    return {
      imageDataUrl: null,
      // いくつめのステップか
      // 初期値は1。また、非年間販売の場合1で終わり
      step: 1,
    }
  },

  computed: {
    ...mapGetters({
      // 現在の販売のId(年間販売以外で利用)
      saleId: 'sale/id',
      // 年間販売かどうか
      isLongrun: 'sale/isLongrun',
      subSales: 'sale/subSales',
    }),
    originalFile: {
      get() {
        return this.$store.getters['face/originalFile']
      },
      set(value) {
        this.$store.dispatch('face/setOriginalFile', value)
      },
    },
    faceImageOrBlob: {
      get() {
        return this.$store.getters['face/faceImageOrBlob']
      },
      set(value) {
        this.$store.dispatch('face/setFaceImageOrBlob', value)
      },
    },
  },

  created() {
    if (this.originalFile && this.faceImageOrBlob) {
      this._loadImageData()
    }
  },

  methods: {
    ...mapActions({
      clearStore: 'face/clearAll',
    }),

    removePhoto() {
      this.clearStore()
      this.imageDataUrl = null
    },

    adjustPhoto() {
      this.$psdialog.open({
        title: '位置調整',
        component: FacePhotoAdjuster,
        props: {
          file: this.originalFile,
        },
        size: 'm',
        closeButton: true,
        onClose: obj => {
          if (obj && obj.image) {
            this.faceImageOrBlob = obj.image
            this._loadImageData()
          }
        },
      })
    },

    changePhoto(e) {
      const file = (e.target.files || e.dataTransfer)[0]
      this.originalFile = file
      this.faceImageOrBlob = file
      this._loadImageData()
    },

    goNext() {
      ++this.step
    },

    goPrev() {
      --this.step
    },

    searchFaces(saleId) {
      this.$psdialog.open({
        title: 'お顔検索中',
        component: SearchFaces,
        props: {
          saleId,
        },
        size: 'xs',
      })
    },

    async _loadImageData() {
      return new Promise(resolve => {
        const reader = new FileReader()
        reader.addEventListener(
          'load',
          () => {
            this.imageDataUrl = reader.result
            resolve()
          },
          false
        )
        reader.readAsDataURL(this.faceImageOrBlob)
      })
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../../../sass/variables.sass'

.prepare-searching-faces

  .step-1
    > p:first-child
      font-size: $size-small
      b
        color: darken($blue, 15%)
    > .photo
      margin-top: 1rem
      > div
        width: 90%
        margin: 0 auto
        max-width: 240px
        border: 4px solid #8ed6d4
        background: #fffee5
        > div
          position: relative
          padding-top: 120%
          background-image: url(../../../assets/face-searching-avatar.png)
          background-size: contain
          background-position: bottom center
          > .operation
            position: absolute
            left: 0
            right: 0
            bottom: 15px
            text-align: center
            input
              display: none
          > .preview
            position: absolute
            left: 0
            right: 0
            bottom: 0
            top: 0
            background-size: contain
            background-position: center center
            background-color: $white
            p.buttons
              position: absolute
              left: 0
              right: 0
              bottom: 0
              text-align: center
              > *
                margin: 0.5rem
      > p
        margin-top: 0.5rem
        color: $red
        text-align: center
        font-size: $size-small
    > .buttons
      margin-top: 1rem
      > *
        margin: 0 auto
        max-width: 240px

  .step-2
    > ul
      max-width: 400px
      margin: 1.5rem auto 0
      li
        a
          display: block
          padding: 0.8rem
          background-color: lighten($blue, 42%)
          color: darken($blue, 20%)
          border-radius: $radius
          text-align: center
          margin: 0.8rem 0
          cursor: pointer
          span
            display: inline-block
            margin-left: 0.5rem
            background-color: $red
            color: white
            font-weight: bold
            font-size: $size-small
            line-height: 1.0
            padding: 0.2rem
        &:first-child
          a
            padding: 1rem
            background-color: lighten($blue, 38%)
        &:hover
          a
            background-color: lighten($blue, 30)
    > .buttons
      margin-top: 1.5rem
      text-align: center
</style>
