// パスワードを忘れた方用ページ

<script>
import { mapActions } from 'vuex'

import sendReminderMailApi from '@/api/public/send-reminder-mail'

export default {
  name: 'PublicPageReminder',

  data() {
    return {
      email: null,
      loading: false,
      error: null,
      // リマインダメールの送信が完了したかどうか
      sent: false,
    }
  },

  methods: {
    ...mapActions({
      setError: 'app/setError',
    }),

    async goNextAsync() {
      if (!this.email) return

      this.loading = true
      this.error = null

      let response
      try {
        response = await sendReminderMailApi(null, { email: this.email })
      } catch (e) {
        this.setError(e)
        return
      }

      this.loading = false

      if (response.ok) {
        this.sent = true
      } else {
        this.error = '登録されていないメールアドレスです。'
      }
    },
  },
}
</script>

<template lang="pug">
.reminder-page

  .inner
    
    ps-medium-heading パスワードをお忘れの方

    // メール送信前。フォーム等を表示する
    template(v-if='!sent')

      ul
        li ご登録されたメールアドレスを入力のうえ、「送信する」を押してください。
        li メールが届きましたら、メール本文のリンクを開き、新しいパスワードを設定してください。

      .form
        ps-form-item(label='メールアドレス', hankaku, required, :error='error')
          ps-input(full-width, type='email', v-model='email')

      .buttons
        ps-button(
          size='medium',
          color='green',
          large-width,
          :disabled='!email',
          :loading='loading',
          @click='goNextAsync'
        ) 送信する
        br
        br
        ps-button(size='small', @click='$router.push({ name: "TopPage" })') トップページへ

    // メール送信後
    template(v-else)
      ul
        li 「{{ email }}」宛にメールを送信しました。
        li メールが届きましたら、メール本文のリンクを開き、新しいパスワードを設定してください。

      .buttons
        ps-button(size='small', @click='$router.push({ name: "TopPage" })') トップページへ


</template>

<style lang="sass" scoped>
@import '../../../../sass/variables'
@import '../../../../sass/mixins'

.reminder-page
  .inner
    background-color: $white
    padding: 1.5rem 1rem
    border-radius: $radius-small
    box-shadow: 0 1px 6px 2px rgba(0, 0, 0, .25)
    +mobile
      padding: 1rem 0.5rem
      box-shadow: 0 1px 1px 1px rgba(0, 0, 0, .3)

    > *
      margin-bottom: 1rem
    > ul
      margin-left: 1.5rem
      li
        list-style-type: disc
        line-height: 1.2
        margin-bottom: 0.5rem

    > .form
      max-width: 800px
      margin: 0 auto 2rem

    > .buttons
      text-align: center
</style>
