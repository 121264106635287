<template lang="pug">
  ps-slide-in.slide-in-menu(from='right')
    ps-row.row

      ps-col.left-col
        .left(
          @click.self.prevent.stop='$emit("clickBg")',
          @touchstart.self.prevent.stop='$emit("clickBg")',
        )

      ps-col.right-col(:span='0')
        .right

          header
            p こんにちは {{ name }} さん
            .buttons
              ps-button(v-if='role === "member"', size='small', color='green', @click='goToMypage')
                ps-icon(icon='user-circle')
                | &nbsp;マイページ
              | &nbsp;
              ps-button(size='small', @click='showLogoutDialog') ログアウト

          // 通常画面(閲覧等)でのメニュー
          section(v-if='!myPageMode')
            // 販売の情報
            article.info
              h3 {{ orgName }}
              h2 {{ saleName }}
              p
                | 公開期間
                span {{ saleEnd }}
                | まで

            article.menu
              ul
                li(v-for='menu in menuItems', :key='menu.code')
                  a(@click='clickItem(menu.code)')
                    ps-icon(:icon='menu.icon')
                    | &nbsp;{{ menu.label }}
          // マイページ用のメニュー
          section.my-page-menu(v-else)
            article.menu
              ul
                li(v-for='menu in myMenuItems', :key='menu.title', :class='`menu-${menu.class}`')
                  router-link(
                    style='text-decoration: none',
                    :to='{ name: menu.to }'
                  ) {{ menu.title }}

          footer
            ul
              li(v-for='link in links', :key='link.to')
                a(:href='link.to', :target='link.target') {{ link.label }}
</template>

<script>
import { mapGetters } from 'vuex'

// ログアウトダイアログ
import UserLogout from '@/dialogs/contents/user-logout'

// お支払い方法ダイアログ
import PaymentDetail from '@/dialogs/contents/payment-detail'
// お届けに関するダイアログ
import ShipmentDetail from '@/dialogs/contents/shipment-detail'
// 写真サイズについてのダイアログ
import PhotoSize from '@/dialogs/contents/photo-size'
// 写真価格についてのダイアログ
import PhotoPrice from '@/dialogs/contents/photo-price'
// 中断・再開方法 についてのダイアログ用
import HelpDetail from '@/dialogs/contents/help-detail'
// 使い方についてのダイアログ(モバイル用とPC用)
import BasicTutorialMobile from '@/dialogs/contents/basic-tutorial-mobile'
import BasicTutorialPc from '@/dialogs/contents/basic-tutorial-pc'

import externalLinks from '@/helpers/external-links'

// マイページのメニュー項目
import myMenuItems from '@/helpers/my-menu-items'

// メニュー項目
const menuItems = [
  { label: 'お支払い方法', icon: 'credit-card', code: 'payment' },
  { label: 'お届けについて', icon: 'truck', code: 'shipment' },
  { label: 'サイズ表', icon: 'table', code: 'size' },
  { label: '価格表', icon: 'yen-sign', code: 'price' },
  { label: '中断・再開方法', icon: 'play', code: 'resume' },
  { label: '操作方法', icon: 'hand-point-up', code: 'op' },
]

// フッタのリンク
const links = [externalLinks.term, externalLinks.legal, externalLinks.privacy]

const resumeHelpId = 6

export default {
  name: 'SlideInMenu',

  data() {
    return {
      menuItems,
      myMenuItems,
      links,
    }
  },

  props: {
    // マイページ用のモード
    myPageMode: Boolean,
  },

  computed: {
    ...mapGetters({
      role: 'app/role',
      name: 'app/name',
      screenType: 'app/screenType',
      saleName: 'sale/name',
      saleEnd: 'sale/end',
      orgName: 'sale/orgName',
      isPouch: 'sale/isPouch',
      isIndividual: 'sale/isIndividual',
      // 税別か
      isTaxExcluded: 'sale/isTaxExcluded',
    }),
  },

  methods: {
    goToMypage() {
      this.$router.push({ name: 'MyPageTop' })
    },

    showLogoutDialog() {
      this.$psdialog.open({
        title: 'ログアウト',
        component: UserLogout,
        size: 's',
        closeByClickBg: true,
        closeButton: true,
      })
    },

    // 支払い方法についてのダイアログ表示
    showPaymentDetailDialog() {
      this.$psdialog.open({
        title: 'お支払い方法',
        component: PaymentDetail,
        props: {
          type: this.isPouch ? 'pouch' : 'online',
        },
        size: 's',
        closeByClickBg: true,
        closeButton: true,
      })
    },

    // お届けについてのダイアログを表示
    showShipmentDetailDialog() {
      this.$psdialog.open({
        title: 'お届けについて',
        component: ShipmentDetail,
        props: {
          type: this.isIndividual ? 'individual' : 'lump',
          isTaxExcluded: this.isTaxExcluded,
        },
        size: 's',
        closeByClickBg: true,
        closeButton: true,
      })
    },

    // 写真サイズ表
    showPhotoSizeDialog() {
      this.$psdialog.open({
        title: 'サイズ表',
        component: PhotoSize,
        // 現在表示中のの販売のサイズ表を表示するかどうか
        // trueなら過去販売閲覧中は過去販売のサイズ表が表示される
        // この挙動は閲覧ページのみ
        props: {
          currentSale: this.$route.name === 'BrowsePage',
        },
        size: 'm',
        closeByClickBg: true,
        closeButton: true,
      })
    },

    // 写真価格表
    showPhotoPriceDialog() {
      this.$psdialog.open({
        title: '価格表',
        component: PhotoPrice,
        // 現在表示中のの販売の価格表を表示するかどうか
        // trueなら過去販売閲覧中は過去販売の価格表が表示される
        // この挙動は閲覧ページのみ
        props: {
          currentSale: this.$route.name === 'BrowsePage',
          isTaxExcluded: this.isTaxExcluded,
        },
        size: 'm',
        closeByClickBg: true,
        closeButton: true,
      })
    },

    // お顔検索、中断・再開についてのヘルプ
    showHelpDetailDialog(id, title) {
      this.$psdialog.open({
        title,
        component: HelpDetail,
        props: { id },
        size: 'l',
        closeByClickBg: true,
        closeButton: true,
      })
    },

    // 使い方のダイアログの表示
    showTutorialDialog() {
      const component = this.screenType === 'mobile' ? BasicTutorialMobile : BasicTutorialPc
      this.$psdialog.open({
        component,
        size: 'm',
      })
    },

    clickItem(code) {
      if (code === 'payment') {
        this.showPaymentDetailDialog()
      } else if (code === 'shipment') {
        this.showShipmentDetailDialog()
      } else if (code === 'size') {
        // サイズ表の表示
        this.showPhotoSizeDialog()
      } else if (code === 'price') {
        // 価格表の表示
        this.showPhotoPriceDialog()
      } else if (code === 'op') {
        this.showTutorialDialog()
      } else if (code === 'resume') {
        this.showHelpDetailDialog(resumeHelpId, '中断・再開方法')
      }
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../../sass/variables.sass'

.slide-in-menu
  .row
    height: 100%

    // Android 標準ブラウザのflex boxの不具合への対応
    .left-col, .right-col
      position: relative
      .right, .left
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%

    .right-col
      width: 80%
      max-width: 320px
      box-shadow: 0 0 8px 0 rgba(#000, .5)
      .right
        background-color: red
        display: flex
        flex-direction: column

        header
          background: darken($orange, 5%)
          padding: 0.5rem
          color: $white
          text-align: center
          p
            margin-bottom: 0.5rem
        section
          background: white
          flex: 1 1 0%
          overflow-y: auto

          line-height: 1.5
          padding: 0.5rem
          background-color: $white

          .info
            padding-bottom: 0.5rem
            border-bottom: 1px dotted $grey-light
            h3
              font-size: $size-normal
              font-weight: normal
            h2
              font-size: $size-medium
            p
              font-size: $size-small
              color: $red
              span
                display: inline-block
                margin: 0 5px
                padding: 0 2px
                background-color: $red
                color: $white
                border-radius: $radius-small

          .menu
            ul
              padding: 0.5rem 0
              li
                margin-bottom: 0.5rem
                &:last-child
                  margin-bottom: 0
                a
                  display: block
                  cursor: pointer
                  border-radius: $radius-small
                  background-color: lighten(#86bccb, 20%)
                  text-align: center
                  padding: 0.5rem
                  &:hover
                    text-decoration: underline
                    background-color: lighten(#86bccb, 25%)

          &.my-page-menu
            ul
              li
                a
                  background-position: center left 10px
                &.menu-top > a
                  background-image: url('../../assets/icon-my-top.png')
                &.menu-history > a
                  background-image: url('../../assets/icon-my-history.png')
                &.menu-info > a
                  background-image: url('../../assets/icon-my-info.png')
                &.menu-child > a
                  background-image: url('../../assets/icon-my-child.png')

        footer
          background: $orange
          padding: 0.5rem
          li
            margin-bottom: 0.5rem
            &:last-child
              margin-bottom: 0
            a
              display: block
              text-decoration: none
              color: $white
              border: 1px solid rgba($white, .5)
              border-radius: $radius-small
              text-align: center
              padding: 3px
              font-size: $size-small
</style>
