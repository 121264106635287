// はじめての方向けの3ケタコードによるメールアクティベーション
// 成功すると認証用JWTとリフレッシュトークンがもらえる

import apiFactory from '../api-factory'

export default apiFactory({
  path: '/register/email_verifications/<%= token %>',
  method: 'POST',
  expectedParams: ['token'],
  bodyMapper: {
    code: 'code',
  },
  responseMapper: {
    id_token: 'token',
    refresh_token: 'refresh',
  },
})
