// ログアウトダイアログ

<script>
import { mapActions, mapGetters } from 'vuex'

import PasswordRegister from '../password-register'

// トップページのパス
// ログアウト時、メモリの内容もクリアしたいので、
// this.$router.push({ name: 'TopPage' })
// でなくページ遷移するために必要
const topPagePath = process.env.VUE_APP_TOP_PAGE_PATH || '/'

export default {
  name: 'UserLogout',

  computed: {
    ...mapGetters({
      role: 'app/role',
    }),
  },

  methods: {
    ...mapActions({
      logout: 'app/logout',
    }),

    showPaswordRegisterDialog() {
      // 一旦ログアウトダイアログは閉じてしまう
      this.$psdialog.close()
      // パスワード登録ダイアログを表示
      this.$psdialog.open({
        title: 'パスワードの登録',
        component: PasswordRegister,
        // ログアウトダイアログから来たことを示すtypeをセット
        // これが設定されていると、登録完了後もう一度このログアウトダイアログを出す。
        props: {
          type: 'logout',
        },
        size: 'm',
      })
    },

    // ログアウトしてトップページへ
    doLogout() {
      this.logout()
      this.$psdialog.close()
      // vue-routerでなく再読み込み
      window.location.href = topPagePath
    },

    // 閉じる
    close() {
      this.$psdialog.close()
    },
  },
}
</script>

<template lang="pug">
.user-logout(:class='{ "is-guest": role === "guest" }')
  
  p ログアウトしてもよろしいですか？

  .alert
    h3
      ps-icon(icon='exclamation-triangle')
      | &nbsp;パスワード登録が完了していません
    p
      | パスワード登録を行わずにログアウトすると、
      | 再度閲覧する際に、もう一度「はじめての方」向けのお手続きが必要となります。
      | また、現在のカゴの状態も保存されません。
    .buttons
      ps-button(
        color='orange',
        large-width,
        @click='showPaswordRegisterDialog'
      ) パスワード登録する

  footer

    ps-button(
      @click='close'
    ) 閲覧を続ける
  
    | &nbsp;

    ps-button(
      @click='doLogout'
    ) ログアウト
</template>

<style lang="sass" scoped>
@import '../../../sass/mixins.sass'

.user-logout
  > *
    margin-bottom: 0.5rem
    &:last-child
      margin-bottom: 0

  > p
    margin-bottom: 2rem

  > .alert
    display: none

  &.is-guest
    > p
      margin-bottom: 0.5rem
    > .alert
      display: block
      background-color: lighten($pink, 18%)
      border: 1px solid $pink
      border-radius: $radius
      padding: 0.5rem
      margin-bottom: 1rem
      h3
        font-weight: normal
        font-size: $size-medium
        text-align: center
        color: $red
        margin-bottom: 0.5rem
      p
        line-height: 1.2
        margin-bottom: 1rem
      .buttons
        text-align: center

  footer
    text-align: center
</style>
