// 実際に閲覧する販売のサイズと価格

import apiFactory from '../api-factory'

import rangesUtil from '../../helpers/ranges-util'

// プリントに必要な最低解像度
// px / mm で指定
const pxPerMmLowerBound = 10

const rankMap = {
  '1': 'normal',
  '2': 'gold',
  '0': 'premium',
}

export default apiFactory({
  path: '/sales/<%= id %>/<%= subId %>/size_prices',
  method: 'GET',
  expectedParams: ['id', 'subId'],
  needAuth: true,
  responseMapper: {
    // イベントId(SaleIdではない)
    'events[0].id': 'eventIdNotSaleId',
    'events[0].size_prices[].photo_idxs': {
      key: 'items[].ranges',
      transform: values => {
        if (!values) return []
        return values.map(value => rangesUtil.parseRangesString(value))
      },
    },
    'events[0].size_prices[].price.type': [
      // ノーマル・ゴールド・プレミアム
      {
        key: 'items[].info.rank',
        transform: values => {
          if (!values) return []
          return values.map(value => rankMap[value % 3])
        },
      },
      // 集合写真かどうか
      {
        key: 'items[].info.group',
        transform: values => {
          if (!values) return []
          return values.map(value => value > 3)
        },
      },
    ],
    'events[0].size_prices[].price.value': 'items[].info.price',
    'events[0].size_prices[].size.code': [
      'items[].info.sizeCode',
      // データ販売かどうかの判定 = 'PTDL'という文字が含まれるかどうか
      {
        key: 'items[].info.dataSale',
        transform: values => {
          if (!values) return []
          return values.map(value => /PTDL/.test(value))
        },
      },
    ],
    'events[0].size_prices[].size.id': 'items[].info.sizeId',
    'events[0].size_prices[].size.mm_longer': [
      'items[].info.printLongEdge',
      // トリミング時に最低限必要なピクセル数を算出
      {
        key: 'items[].info.minLongPx',
        transform: values => {
          if (!values) return []
          return values.map(value => value * pxPerMmLowerBound)
        },
      },
    ],
    'events[0].size_prices[].size.mm_shorter': 'items[].info.printShortEdge',
    'events[0].size_prices[].size.name': 'items[].info.sizeName',
  },
})
