// カートページ

<template lang="pug">
.outro-page-cart

  ps-cart-info.cart-info-mobile(
    v-if='screenType === "mobile"',
    :num='cartNum',
    :sum='cartSum',
    :remaining-message='remainingMessage',
    :benefit-message='benefitMessage',
    :longrun-info='longrunInfo',
    :is-tax-excluded='isTaxExcluded'
  )

  ps-row(:gutter='20')
    // メイン部分
    ps-col.main

      ps-box.message-for-confirm-code(v-if='byConfirmCode', type='danger')
        | 確認用のコードをご利用の場合、写真をご購入いただけません。ご了承ください。
      ps-box.message-for-confirm-code(v-else-if='byShopConsole', type='danger')
        | 「公開内容確認」は内容確認用のため、購入はできません。ご了承ください。

      .desc

        ps-medium-heading 買い物カゴ

        p(v-if='!photos || photos.length === 0') カゴに写真がありません。
        template(v-else)
          p.description
            | 写真を
            span.pc クリック
            span.mobile タップ
            span(v-if='saleIsCustomizable')
              | して、購入する写真サイズや枚数を変更したり、トリミングや日付を入れたりすることができます(集合写真は不可)。
            span(v-else)
              | して、購入する写真サイズや枚数を変更できます。

          // 一度かごに追加して削除した写真はあるが、有効な写真が0枚
          p.alert(v-if='cartPhotoNum === 0')
            | 「ご注文手続き」に進むには1枚以上写真をカゴに追加してください。

      ps-photo-page(
        :items='photos',
        :customs-map='customsMap',
        :cart-map='cartPhotosMap',
        :is-tax-excluded='isTaxExcluded',
        add-to-cart-button-color='orange',
        change-size-and-num-button-color='green',
        cart-page-mode,
        @clickPhoto='showCartZoomDialog(arguments[0].index)',
        @addToCart='addToCart(arguments[0])',
        @removeFromCart='removeFromCart(arguments[0])',
        :overdue-charge='overdueCharge'
      )

    // サイドのカート情報
    ps-col.side(v-if='screenType !== "mobile"', :span='0')
      ps-fixed-region(:threshold='20')

        .cart-info-outer
          ps-cart-info.cart-info(
            :num='cartNum',
            :sum='cartSum',
            :remaining-message='remainingMessage',
            :benefit-message='benefitMessage',
            :is-tax-excluded='isTaxExcluded',
            :longrun-info='longrunInfo',
            transparent
          )
            ps-button.go-to-next(
              color='red',
              size='large',
              full-width,
              text-centered,
              :disabled='!canGoToNext',
              @click='goToNext',
              v-my-id='"go-to-next"'
            )
              | ご注文手続きへ&nbsp;
              ps-icon(icon='chevron-circle-right')
            // 販売猶予期間なら写真一覧へボタンは表示しない
            ps-button(
              v-if='!saleIsInExtra',
              full-width,
              size='small',
              text-centered,
              @click='$router.push({ name: "BrowsePage", params: { id: saleId } })'
            )
              ps-icon(icon='chevron-circle-left')
              | &nbsp;写真一覧へ
        
          .functions

            ps-button(
              v-if='saleIsCustomizable',
              color='blue',
              :disabled='!photos || photos.length === 0',
              @click='showCartZoomDialog(photos[0].index)',
              full-width,
              text-centered
            ) 日付を入れる

            ps-button(
              color='lime', full-width, text-centered,
              @click='showPhotoSizeDialog'
            )
              ps-icon(icon='table')
              | &nbsp;サイズ表

            ps-button(
              color='lime', full-width, text-centered,
              @click='showPhotoPriceDialog'
            )
              ps-icon(icon='jpy')
              | &nbsp;価格表

  // モバイル用のボタン領域
  .fixed-buttons-area(v-if='screenType === "mobile"')
    ps-row(:gutter='20', valign='center')
      // 販売猶予期間なら戻るボタンは表示しない
      ps-col(v-if='!saleIsInExtra', :span='0')
        ps-button(
          size='large',
          @click='$router.push({ name: "BrowsePage", params: { id: saleId } })'
        ) 戻る
      ps-col
        ps-button(
          size='large',
          color='red',
          full-width,
          text-centered,
          :disabled='!canGoToNext',
          @click='goToNext',
          v-my-id='"go-to-next"'
        ) ご注文手続きへ
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
// 写真サイズについてのダイアログ
import PhotoSize from '@/dialogs/contents/photo-size'
// 写真価格についてのダイアログ
import PhotoPrice from '@/dialogs/contents/photo-price'
// 拡大ダイアログ
import CartZoom from '@/dialogs/contents/cart-zoom'

import * as errorCodes from '@/errors/codes'

export default {
  name: 'OutroPageCart',

  async created() {
    try {
      // ルートの販売情報と現在の販売情報
      await this.initSaleAsync({ id: this.saleId })
      // 販売期間または販売猶予期間かどうか
      if (!this.saleIsInTerm && !this.saleIsInExtra) {
        throw new Error(errorCodes.SALE_IS_OUT_OF_TERM)
      }

      await this.initSizePriceAsync()
      await this.initCartAsync(true)
    } catch (e) {
      this.setError(e)
    }
  },

  computed: {
    ...mapGetters({
      saleIsInTerm: 'sale/isInTerm',
      saleIsInExtra: 'sale/isInExtra',
      saleIsCustomizable: 'sale/customizable',
      // 税別か
      isTaxExcluded: 'sale/isTaxExcluded',
      cartNum: 'cart/num',
      cartSum: 'cart/sum',
      cartPhotoNum: 'cart/photoNum',
      cartRemaining: 'cart/remainingToFree',
      currentShipmentFee: 'cart/currentShipmentFee',
      discontValue: 'cart/discontValue',
      // 期間外販売の写真の枚数
      cartOverdueNum: 'cart/overdueNum',
      // カート内の期間外販売手数料
      cartOverdueCharge: 'cart/overdueCharge',
      photos: 'cart/photos',
      cartPhotosMap: 'cart/photosMap',
      customsMap: 'cart/customsMap',
      byConfirmCode: 'sale/byConfirmCode',
      byShopConsole: 'sale/byShopConsole',
      screenType: 'app/screenType',
      // 年間販売の期間外販売手数料
      overdueCharge: 'sale/overdueCharge',
    }),
    saleId() {
      return this.$route.params.saleId
    },
    // 次の画面に進めるかどうか = 写真を1点も買っていないまたは、確認用コードを使用または、写真館管理から来た という条件にあてはまらないか
    canGoToNext() {
      return !this.byConfirmCode && !this.byShopConsole && this.cartPhotoNum > 0
    },
    // 後いくらで発送手数料無料となるかのメッセージ
    remainingMessage() {
      // カートが空なら表示しない
      if (!this.cartSum) return null
      // 残り1000円を切った段階で表示
      else if (!this.cartRemaining || this.cartRemaining > 1000) return null
      else return `あと<b>${this.cartRemaining}円</b>で<b>${this.currentShipmentFee}円</b>値引き`
    },
    benefitMessage() {
      // カートが空なら表示しない
      if (!this.cartSum) return null
      else if (!this.discontValue) return null
      else return `合計金額より<b>${this.discontValue}円</b>値引き!`
    },
    // 年間販売の概略
    longrunInfo() {
      if (!this.cartOverdueNum) return null
      return {
        normalPhotoNum: this.cartPhotoNum - this.cartOverdueNum,
        overduePhotoNum: this.cartOverdueNum,
        overdueCharge: this.cartOverdueCharge,
      }
    },
  },

  methods: {
    ...mapActions({
      initSaleAsync: 'sale/initAsync',
      initSizePriceAsync: 'sizePrice/initAsync',
      initCartAsync: 'cart/initAsync',
      addPhotoToCartAsync: 'cart/addPhotoAsync',
      removePhotoFromCartAsync: 'cart/removePhotoAsync',
      setError: 'app/setError',
    }),

    addToCart(item) {
      try {
        this.addPhotoToCartAsync({
          photoId: item.id,
          num: 1,
          sizeId: item.sizePrices[0].sizeId,
        })
      } catch (e) {
        this.setError(e)
      }
    },

    removeFromCart(item) {
      try {
        this.removePhotoFromCartAsync({ photoId: item.id })
      } catch (e) {
        this.setError(e)
      }
    },

    goToNext() {
      this.$router.push({ name: 'OutroPageRegister', params: { saleId: this.saleId } })
    },

    showCartZoomDialog(photoIndex) {
      this.$psdialog.open({
        component: CartZoom,
        props: { photoIndex },
      })
    },

    showPhotoSizeDialog() {
      this.$psdialog.open({
        title: 'サイズ表',
        component: PhotoSize,
        size: 'm',
        closeByClickBg: true,
        closeButton: true,
      })
    },

    showPhotoPriceDialog() {
      this.$psdialog.open({
        title: '価格表',
        component: PhotoPrice,
        props: {
          // 税別かどうか
          isTaxExcluded: this.isTaxExcluded,
        },
        size: 'm',
        closeByClickBg: true,
        closeButton: true,
      })
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../../../../sass/variables.sass'
@import '../../../../sass/mixins.sass'

.outro-page-cart

  +tablet
    margin-top: 2rem

  .message-for-confirm-code
    margin-bottom: 1rem

  .cart-info-mobile
    margin-top: 1.5rem
    margin-bottom: 1.5rem

  .main
    .description
      margin-bottom: 0.5rem
      line-height: 1.2
      > span
        +tablet
          &.pc
            display: inline
          &.mobile
            display: none
        +mobile
          &.pc
            display: none
          &.mobile
            display: inline
    .alert
      color: $red
      margin-bottom: 0.5rem

  .side
    width: 260px

    .cart-info-outer
      border-radius: $radius-small
      background-color: #e4f7b7
      .cart-info
        margin-bottom: 0
        .go-to-next
          margin-bottom: 0.5rem

      .functions
        margin: 0 0.5rem
        border-top: 1px solid $grey-lighter
        padding: 0.5rem 0
        > *
          margin-bottom: 0.5rem
          &:last-child
            margin-bottom: 0

  .fixed-buttons-area
    position: fixed
    bottom: 0
    left: 0
    right: 0
    background-color: rgba($white, 0.9)
    border-top: 1px solid $grey-lighter
    padding: 10px 20px 20px
    > *
      height: 100%
</style>
