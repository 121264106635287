// パスワードのセット

import apiFactory from '../api-factory'

export default apiFactory({
  path: '/my/password',
  method: 'POST',
  needAuth: true,
  bodyMapper: {
    password: 'passwd',
  },
  responseMapper: {
    id_token: 'token',
    refresh_token: 'refresh',
  },
})
