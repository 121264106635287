// パスワード登録ダイアログ

<script>
import { mapGetters, mapActions } from 'vuex'

// 登録することによるメリットを表示するダイアログ
import RegisterAdvantages from '../register-advantages'
// パスワード登録完了ダイアログ
import PasswordRegsisterCompleted from '@/dialogs/contents/password-register-completed'

import setPasswordApiAsync from '@/api/user/set-password'

import * as errorCodes from '@/errors/codes'

export default {
  name: 'PasswordRegister',

  props: {
    // 'first': 初回
    // 'normal': 通常
    // 'okao': お顔検索時
    // ログアウト時にパスワード登録に遷移
    // 'logout': ログアウト
    type: {
      type: String,
      default: 'normal',
    },
  },

  data() {
    return {
      password: null,
      passwordVisible: false,
      error: null,
    }
  },

  computed: {
    ...mapGetters({
      userName: 'app/name',
      userEmail: 'app/email',
    }),

    // 登録ボタン。typeによって文言が変化する
    legisterButtonText() {
      if (this.type === 'first') return '登録して閲覧開始'
      else return 'パスワード登録する'
    },
  },

  mounted() {
    // Google Analyticsが有効であれば、パスワード登録ダイアログ(初回)の表示を通知
    if (this.type === 'first' && this.$ga) {
      this.$ga.event('register', 'show dialog')
    }
  },

  methods: {
    ...mapActions({
      storeTokens: 'app/storeTokens',
      getMeAsync: 'app/getMeAsync',
      setError: 'app/setError',
    }),

    // パスワードの表示非表示
    togglePasswordVisibility() {
      this.passwordVisible = !this.passwordVisible
    },

    async setPasswordAsync() {
      let response
      try {
        const body = { password: this.password }
        response = await setPasswordApiAsync(null, body)
        if (!response.ok) {
          // 通常は起こらないが、何らかの理由で登録済みなのに再度パスワードを登録しようとした
          if (/already/.test(_.get(response, 'payload.message'))) {
            this.setError(new Error(errorCodes.ALREADY_REGISTERED_PASSWORD_ERROR))
          } else {
            this.error = 'パスワードに誤りがあります。'
          }
          return
        }
      } catch (e) {
        this.setError(new Error(errorCodes.UNKNOWN_REGISTERED_PASSWORD_ERROR))
        return
      }

      // memberとしての認証情報をストア
      this.storeTokens({ token: response.payload.token, refresh: response.payload.refresh })

      // ストアの自分の情報を強制更新(guest -> member)
      await this.getMeAsync({ force: true })

      this.$psdialog.close()

      this.showPasswordRegisterCompletedDialog(this.type)

      // Google Analyticsが有効であれば、パスワード登録完了を通知
      if (this.type === 'first' && this.$ga) {
        this.$ga.event('register', 'register password')
      }
    },

    // パスワード登録完了を知らせるダイアログ
    showPasswordRegisterCompletedDialog(type) {
      this.$psdialog.open({
        component: PasswordRegsisterCompleted,
        props: { type },
        size: 'xs',
        closeByClickBg: true,
      })
    },

    close() {
      this.$psdialog.close()
      // もし初回の表示なら引き止めダイアログを表示する
      if (this.type === 'first') {
        this.$psdialog.open({
          title: '確認',
          component: RegisterAdvantages,
          size: 'm',
        })
      }
    },
  },
}
</script>

<template lang="pug">
.password-register
  
  p.advantages-for-mobile(v-if='type === "normal" || type === "first"')
    | パスワードを登録することで、閲覧の中断/再開・マイページなどの便利な機能が利用可能になります。
  p(v-else-if='type === "okao"') お顔検索機能の利用にはパスワード登録が必要となります。

  .form
    ps-form-item.name(label='お名前')
      p.value {{ userName }} 様
    ps-form-item.email(label='メールアドレス')
      p.value {{ userEmail }}
    ps-form-item(label='パスワード', hankaku, :error='error')
      ps-row.password(:gutter='10')
        ps-col
          ps-input(:type='passwordVisible ? "text" : "password"', force-hankaku, full-width, ime-mode='disabled', v-model='password')
        ps-col(:span='0')
          a(@click='togglePasswordVisibility') {{ passwordVisible ? '隠す' : '表示' }}
      p.info お好きな半角英数字8文字以上
      p.note ※次回以降のログインに必要となりますので、お忘れにならないようご注意ください。

  .advantages-for-pc(v-if='type === "normal" || type === "first"')
    p &lt;パスワードを登録するとできること&gt;
    ul
      li 閲覧の中断・再開を行うことができます。
      li 別のパソコンやスマートフォンで続きを見ることができます。
      // li 「お顔検索」の利用が可能になります。
      li マイページでの購入履歴の確認が可能になります。

  .buttons
    ps-row(valign='center', halign='center', :gutter='20')
      ps-col(:span='0')
        a.close(@click='close') 閉じる
      ps-col(:span='0')
        ps-button(size='large', color='orange', @click='setPasswordAsync') {{ legisterButtonText }}

</template>

<style lang="sass" scoped>
@import '../../../sass/variables.sass'
@import '../../../sass/mixins.sass'

.password-register

  > *
    margin-bottom: 0.5rem
    &:last-child
      margin-bottom: 0

  > .form
    a
      line-height: 2.5rem
      text-decoration: underline
      cursor: pointer
    p.value
      padding: 0.5rem 0
    p.info
      font-size: $size-small
      color: darken($blue, 10%)
      margin: 0.3rem 0
    p.note
      font-size: $size-small
      color: darken($green, 20%)
    .email
      word-wrap: break-word
    +mobile
      .name
        display: none

  .advantages-for-pc
    margin-bottom: 1rem
    p
      color: darken($blue, 10%)
    ul
      li
        font-size: $size-small
        list-style-type: disc
        margin: 0.2rem 0 0.2rem 1rem
    +mobile
      display: none

  .advantages-for-mobile
    font-size: $size-small
    line-height: 1.2
    margin-bottom: 1rem
    +tablet
      display: none

  .buttons
    a.close
      cursor: pointer
      text-decoration: underline
</style>
