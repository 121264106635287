// 注文履歴の取得

import apiFactory from '../api-factory'

import dateHelper from '@/helpers/date-helper'
import normalizeSmbcOrderStatus from '@/helpers/normalize-smbc-order-status'

export default apiFactory({
  path: '/my/orders?filter=<%= filter %>',
  method: 'GET',
  needAuth: true,
  expectedParams: ['filter'],
  responseMapper: {
    orders: {
      key: 'items',
      transform: value => value.map(_transform),
    },
  },
})

function _transform(value) {
  const statusCode = (_.first(value.statuses) || {}).code
  const paymentTypeCode = value.payment.code
  const status = normalizeSmbcOrderStatus(paymentTypeCode, statusCode)
  const datetime = (_.last(value.statuses) || {}).datetime
  const orderedAt = datetime ? dateHelper.toJpYYYYMMDD(datetime) : null
  return {
    code: value.code,
    id: value.id,
    cancellable: value.is_cancellable,
    orgName: value.org.name,
    saleName: value.sale.name,
    paymentTypeName: value.payment.name,
    price: value.payment.price,
    status,
    orderedAt,
  }
}
