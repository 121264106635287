// お顔検索の拡大画面

<template lang="pug">
.okao-zoom
  zoom-base(
    v-if='current',
    menu-type='okao',
    :item='current',
    :face='current.face',
    :has-next='hasNext',
    :has-prev='hasPrev',
    @goPrev='goPrev',
    @goNext='goNext',
    ref='zoomBase',
    @close='this.$psdialog.close'
  )
</template>

<script>
import { mapGetters } from 'vuex'

import ZoomBase from '../zoom-base'

export default {
  name: 'OkaoZoom',

  components: {
    ZoomBase,
  },

  props: {
    photoIndex: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      // 配列中の位置
      pos: null,
    }
  },

  created() {
    this.pos = _.findIndex(this.photos, item => item.index === this.photoIndex)
  },

  computed: {
    ...mapGetters({
      photos: 'face/photos',
    }),
    current() {
      return this.photos[this.pos]
    },
    hasNext() {
      return this.photos.length - 1 > this.pos
    },
    hasPrev() {
      return this.pos > 0
    },
  },

  methods: {
    // 次の写真へ移動
    goNext() {
      if (!this.hasNext) return
      ++this.pos
    },
    // 前の写真へ移動
    goPrev() {
      if (!this.hasPrev) return
      --this.pos
    },

    clickBg() {
      // 子コンポーネントがトリミングモードかどうかをチェック
      if (!_.get(this.$refs.zoomBase, 'trimMode')) this.$psdialog.close()
    },
  },
}
</script>

<style lang="sass" scoped>
.okao-zoom
  width: 100%
  height: 100%
</style>
