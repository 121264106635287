// 注文情報コミット用のbodyマッパ

export default {
  user: {
    key: 'customer',
    transform: _transformUser,
  },
  child: {
    key: 'sub',
    transform: _transformChild,
  },
  childIds: 'sub_ids',
  destination: {
    key: 'dest',
    transform: _transformUser,
  },
  payment: {
    key: 'payment',
    transform: _transformPayment,
  },
  options: {
    key: 'options',
    default: { version: '20170724-1800' },
  },
}

function _transformUser(value, a, b) {
  console.log(value, a, b)
  if (!value) return null
  const base = {
    name1: value.name1 || '',
    name2: value.name2 || '',
    name1_kana: value.name1Kana || '',
    name2_kana: value.name2Kana || '',
  }
  // location 情報があるかどうかを、zipcodeで判断
  // zipcode がない = 集金袋
  if (!_.has(value, 'zipcode')) return { base }

  const location = {
    zipcode: (value.zipcode || '').replace(/\D/g, ''),
    prefecture: value.prefecture || '',
    address1: value.address1 || '',
    address2: value.address2 || '',
    address3: value.address3 || '',
    tel: (value.tel || '').replace(/\D/g, ''),
  }
  return { base, location }
}

function _transformChild(value) {
  if (!value) return null
  return {
    relation: value.relation || '',
    name1: value.name1 || '',
    name2: value.name2 || '',
    name1_kana: value.name1Kana || '',
    name2_kana: value.name2Kana || '',
    attribute: value.attribute || '',
  }
}

function _transformPayment(value) {
  if (!value) return null
  const type = parseInt(value.id, 10)
  let payment = { type }
  // カード払い
  // 2018年よりカード番号非保持のため、
  // カード番号は送らず、トークンのみ送信
  if (type === 2) {
    payment.card = {
      token: value.cardToken,
    }
    // 2021/3よりカード決済会社の指定を付与
    payment.agent_code = value.agentCode
  } else if (type === 3) {
    // コンビニ払い
    payment.conveni = { cvs: value.conveniCvs || '' }
  }
  return payment
}
