// 特定の写真オブジェクトのトリミング可能な条件を取得する
// 適合するものがなければnullを返す

// 90% 縮小までしかできないものは除去
const cutOffThresholdPercent = 100

export default function(item) {
  // pickByでnullのものは除去
  const result = _.pickBy({
    landscape: _getTrimmableConditions(item, 'landscape'),
    portrait: _getTrimmableConditions(item, 'portrait'),
  })
  return _.isEmpty(result) ? null : result
}

function _getTrimmableConditions(item, orientation) {
  // 元画像の基準となる長さを算出
  // 元画像とトリミングの向きが同じなら、長辺を基準に、違うなら短辺を基準に
  const basisEdgePx =
    item.orientation === orientation
      ? Math.max(item.dataWidth, item.dataHeight)
      : Math.min(item.dataWidth, item.dataHeight)

  let trimmable = []
  item.sizePrices.forEach(sizePrice => {
    // データ販売なら終了
    if (sizePrice.dataSale) return
    // 解像度不足なら終了
    const minScalePercent = (sizePrice.minLongPx / basisEdgePx) * 100
    if (minScalePercent >= cutOffThresholdPercent) return
    const ratio =
      orientation === 'landscape'
        ? sizePrice.printLongEdge / sizePrice.printShortEdge
        : sizePrice.printShortEdge / sizePrice.printLongEdge
    // サイズと価格情報に縮小率の下限を付加
    trimmable.push(_.assign({ minScalePercent, ratio }, sizePrice))
  })
  return trimmable.length > 0 ? trimmable : null
}
