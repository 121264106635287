// ユーザ情報(名前や住所など)の更新ダイアログ

<script>
import parseSwaggerSchemaError from '@/helpers/parse-swagger-schema-error'

// 郵便番号 -> 住所に
import zipToAddressAsync from '@/api/public/zip-to-address'

import updateInfoApiAsync from '@/api/user/update-info'

export default {
  name: 'UserInfo',

  props: {
    value: {},
  },

  data() {
    return {
      newValue: this.value ? _.cloneDeep(this.value) : null,
      errors: {},
      // ps-user-form に渡すために
      zipToAddressAsync,
    }
  },

  created() {
    this.$psdialog.lockScroll()
  },

  methods: {
    // 内容の更新
    async updateAsync() {
      this.errors = {}

      const body = _.assign(
        {
          name1: '',
          name2: '',
          name1Kana: '',
          name2Kana: '',
          tel: '',
          zipcode: '',
          prefecture: '',
          address1: '',
          address2: '',
          address3: '',
        },
        _.pickBy(this.newValue)
      )

      const response = await updateInfoApiAsync(null, body)

      if (!response.ok) {
        if (_.get(response, 'payload.code') === 'SCHEMA_VALIDATION_FAILED') {
          this.errors = parseSwaggerSchemaError(response.payload)
        }
        alert('入力に誤りがあります。')
        return
      }

      this.$psdialog.close({ updated: true })

      alert('更新完了')
    },

    // ダイアログを閉じる
    close() {
      this.$psdialog.close()
    },
  },

  beforeDestroy() {
    this.$psdialog.unlockScroll()
  },
}
</script>

<template lang="pug">
.user-info

  .form
    ps-user-form(v-model='newValue', :errors='errors', :remoteMethod='zipToAddressAsync')

  .buttons
    ps-button(@click='close') 戻る
    ps-button(color='green', large-width, @click='updateAsync') 内容更新

</template>

<style lang="sass" scoped>
.user-info
  .form
    margin-bottom: 1rem
  .buttons
    text-align: center
    > :first-child
      margin-right: 1rem
</style>
