// メールアドレスの変更

<script>
import { mapActions } from 'vuex'

import confirmChangeEmailApiAsync from '@/api/user/confirm-change-email'

// トークンと3ケタコードの境界
const tokenCodeAlign = 40

export default {
  name: 'PublicPageChangeEmail',

  data() {
    return {
      password: null,
      error: null,
      // メールアドレス変更結果
      result: null,
      loading: false,
    }
  },

  computed: {
    email() {
      return this.$route.query.email
    },
  },

  methods: {
    ...mapActions({
      setError: 'app/setError',
    }),

    // emailの変更を実行
    async confirmChangeEmailAsync() {
      if (!this.password) return

      this.error = null
      this.loading = true
      const code = this.$route.params.token.slice(tokenCodeAlign, tokenCodeAlign + 3)
      const token = this.$route.params.token.slice(0, tokenCodeAlign)

      // 変更の確定
      let response
      try {
        const body = { code, password: this.password }
        response = await confirmChangeEmailApiAsync({ token }, body, true)
      } catch (e) {
        this.setError(e)
        return
      } finally {
        this.loading = false
      }

      if (!response.ok) {
        // エラーの種類を判別
        if (response.status === 406) this.error = 'パスワードが正しくありません。'
        else this.result = 'fail'
      } else {
        this.result = 'success'
      }
    },
  },
}
</script>

<template lang="pug">
.change-email-page

  .inner

    template(v-if='result')
      ps-medium-heading {{ result === 'success' ? 'メールアドレスを変更しました。' : 'メールアドレスの変更に失敗しました。' }}
      .buttons
        ps-button(color='green', large-width, @click='$router.push({ name: "TopPage" })') トップページへ

    template(v-else)
      ps-medium-heading メールアドレスの変更
      
      p(v-if='email') メールアドレスをご確認の上、パスワードを入力して「変更」を押してください。
      p(v-else) パスワードを入力して「変更」を押してください。

      .form
        ps-form-item(v-if='email', label='変更後のメールアドレス')
          p.email {{ email }}
        ps-form-item(label='パスワード', hankaku, required, :error='error')
          ps-input(full-width, type='password', v-model='password')
      
      .buttons
        ps-button(@click='$router.push({ name: "TopPage" })') 戻る
        ps-button(
          color='green',
          large-width,
          :disabled='!password',
          :loading='loading',
          @click='confirmChangeEmailAsync'
        ) 変更

</template>

<style lang="sass" scoped>
@import '../../../../sass/variables.sass'
@import '../../../../sass/mixins.sass'

.change-email-page

  .inner

    background-color: $white
    padding: 1.5rem 1rem
    border-radius: $radius-small
    box-shadow: 0 1px 6px 2px rgba(0, 0, 0, .25)
    +mobile
      padding: 1rem 0.5rem
      box-shadow: 0 1px 1px 1px rgba(0, 0, 0, .3)

    > *
      margin-bottom: 1rem

    .form
      margin-bottom: 3rem
      max-width: 650px
      margin-left: auto
      margin-right: auto
      .email
        font-size: $size-medium
        padding: 0.3rem 0

    .buttons
      text-align: center
      > *
        margin-right: 1rem
        &:last-child
          margin-right: 0
</style>
