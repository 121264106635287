// ヘルプの取得

import apiFactory from '../api-factory'

export default apiFactory({
  path: '/helps/<%= id %>',
  method: 'GET',
  expectedParams: ['id'],
  responseMapper: {
    id: 'id',
    title: 'title',
    content: 'html',
  },
})
