// マイページ

<template lang="pug">
.my-page

  header
    common-header(@clickShowMenu='menuVisibility = true')

  section
    ps-container
      ps-row(:gutter='30', break-point='mobile')

        // メイン部分
        ps-col.main

          h2.page-title
            img(v-if='pageName === "MyPageTop"', src='../../assets/icon-my-top-white.png')
            span {{ pageTitle }}

          router-view

        // サイドメニュー
        ps-col.side(:span='0')
          .menus
            h3 マイページメニュー
            ul
              li(
                v-for='item in menuItems',
                :key='item.title',
                :class='`menu-${item.class}`'
              )
                router-link(
                  style='text-decoration: none',
                  :to='{ name: item.to }'
                ) {{ item.title }}

  common-footer.footer

  // メニュー
  slide-in-menu(v-if='menuVisibility', @clickBg='menuVisibility = false', my-page-mode)
</template>

<script>
import UserLogout from '@/dialogs/contents/user-logout'
import menuItems from '@/helpers/my-menu-items'

export default {
  name: 'MyPage',

  data() {
    return {
      menuItems,
      menuVisibility: false,
    }
  },

  computed: {
    name() {
      return this.$store.getters['app/name']
    },
    pageTitle() {
      return this.$route.meta.title
    },
    pageName() {
      return this.$route.name
    },
  },

  methods: {
    showLogoutDialog() {
      this.$psdialog.open({
        title: 'ログアウト',
        component: UserLogout,
        size: 's',
        closeByClickBg: true,
        closeButton: true,
      })
    },
  },

  watch: {
    // ページ遷移したら、メニューは隠す
    $route() {
      this.menuVisibility = false
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../../sass/variables.sass'
@import '../../sass/mixins.sass'

.my-page

  > header
    margin-bottom: 2rem
    +mobile
      margin-bottom: 1.2rem

  > section
    margin-bottom: 4rem

    .main
      h2.page-title
        background-image: url('../../assets/green-dot.png')
        background-repeat: repeat
        padding: 0.5rem
        text-align: center
        font-size: $size-largest
        letter-spacing: 1px
        color: $white
        font-weight: normal
        border-radius: $radius-large
        margin-bottom: 2rem
        display: flex
        justify-content: center
        align-items: center
        box-shadow: 0 1px 5px 1px rgba(0, 0, 0, .2)
        +mobile
          box-shadow: 0 1px 1px 1px rgba(0, 0, 0, .1)
          margin-bottom: 1.2rem
        > img
          margin-right: 0.5rem
          width: 34px
          height: 30px

    .side
      .menus
        width: 280px
        background-color: #e4f7b7
        border-radius: $radius-small
        padding: 1rem
        > h3
          color: $white
          font-weight: normal
          text-align: center
          font-size: $size-medium
          padding: 0.5rem 0.5rem 1rem
          background-image: url('../../assets/my-menu-title-bg.png')
          background-size: 100% 100%
          margin-bottom: 0.5rem
        // メニュー
        > ul
          > li
            margin-bottom: 0.6rem
            &:last-child
              margin-bottom: 0
            > a
              position: relative
              display: block
              background-color: $white
              padding: 1rem
              text-align: center
              border-radius: $radius-small
              box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .2)
              color: #fd5f8f
              background-position: center left 20px
              cursor: pointer
              &:before
                position: absolute
                content: ''
                top: 5px
                left: 5px
                bottom: 5px
                right: 5px
                border: 1px dashed $grey-lighter
                border-radius: $radius-small
              &:hover
                opacity: 0.9
            &.menu-top > a
              background-image: url('../../assets/icon-my-top.png')
            &.menu-history > a
              background-image: url('../../assets/icon-my-history.png')
            &.menu-info > a
              background-image: url('../../assets/icon-my-info.png')
            &.menu-child > a
              background-image: url('../../assets/icon-my-child.png')

      // モバイルではサイドメニューは出さない
      +mobile
        display: none

  .footer
    position: absolute
    bottom: 0
    left: 0
    right: 0
</style>
