import getScreenType from '@/helpers/get-screen-type'
import sendErrorReport from '@/helpers/send-error-report'

// APIアクセス
import loginApiAsync from '@/api/user/login'
import getTotokenInfoApiAsync from '@/api/user/get-token-info'

// エラーコード
import * as errorCodes from '@/errors/codes'

// ローカルストレージのキー名
// アクセストークン
const tokenKey = 'token'
// リフレッシュトーク
const refreshKey = 'refresh'

// アクション

export default {
  // 自分の情報を取得。
  // force = trueで強制更新。roleが変化した際にこのフラグを利用。
  async getMeAsync({ commit, state }, { force, silent }) {
    // すでに情報があるなら終了
    if (state.user && !force) return true

    const response = await getTotokenInfoApiAsync(null, null, !!silent)

    if (response.ok) commit('setUser', response.payload)
  },

  async loginAsync({ dispatch }, { email, password }) {
    const response = await loginApiAsync(null, { email, password })

    if (response.ok) {
      dispatch('storeTokens', response.payload)
      await dispatch('getMeAsync', { force: true })
    }

    return response
  },

  // ストアとローカルストレージにトークンを保存
  storeTokens({ commit }, { token, refresh }) {
    if (token) {
      storage.remove(tokenKey)
      commit('setToken', token)
      storage.set(tokenKey, token)
    }
    if (refresh) {
      storage.remove(refreshKey)
      commit('setRefresh', refresh)
      storage.set(refreshKey, refresh)
    }
  },

  // ローカルストレージからトークンを復元
  retrieveTokens({ commit, state }) {
    // すでにストアにロード済みならなにもしない
    if (state.token) return
    const token = storage.get(tokenKey)
    const refresh = storage.get(refreshKey)
    if (token) commit('setToken', token)
    if (refresh) commit('setRefresh', refresh)
  },

  logout({ commit, dispatch }) {
    commit('setToken', null)
    commit('setRefresh', null)
    commit('setUser', null)
    commit('setShowNewRelease', false)
    dispatch('clearAll', null, { root: true })
    storage.remove(tokenKey)
    storage.remove(refreshKey)
  },

  // 画面サイズの更新
  async updateScreenType({ commit }) {
    const type = getScreenType()
    commit('setScreenType', type)
  },

  // チュートリアルのクリア状況の取得
  getTutorialStatus({ getters }, code) {
    const key = _getLocalStrageKey(getters, code)
    return !!storage.get(key)
  },

  // code で指定したチュートリアルを完了に
  completeTutorial({ getters }, code) {
    const key = _getLocalStrageKey(getters, code)
    storage.set(key, 'ok')
  },

  // エラーコードをセット
  setError({ commit }, error) {
    commit('setError', error)
    // エラーを外部サービスに通知。
    // ただしAPI_ACCESS_ERRORの場合はすでに通知済みなので通知しない。
    if (error.message !== errorCodes.API_ACCESS_ERROR) sendErrorReport(error)
  },

  // ニューリリースの表示の切り替え
  setShowNewRelease({ commit }, flag) {
    commit('setShowNewRelease', flag)
  },

  // エラーをクリア
  clearError({ commit }) {
    commit('setError', null)
  },

  // API待ちの数をインクリメント
  incrementPendings({ state, commit }) {
    commit('setPendings', state.pendings + 1)
  },

  // API待ちの数をデクリメント
  decrementPendings({ state, commit }) {
    if (state.pendings === 0) return
    commit('setPendings', state.pendings - 1)
  },

  // API待ちの数をクリア
  clearPendings({ commit }) {
    commit('setPendings', 0)
  },
}

function _getLocalStrageKey(getters, code) {
  // ローカルストレージにチュートリアルの終了状態を保存する際のプレフィックス
  const prefix = 'tutorial'
  const userId = getters['userId']
  return `${prefix}:${userId}:${code}`
}
