// マイページのメニューに表示するアイテム

export default [
  {
    title: 'マイページトップ',
    icon: 'user-circle',
    isTop: true,
    to: 'MyPageTop',
    class: 'top',
  },
  {
    title: 'ご購入履歴',
    icon: 'shopping-cart',
    to: 'MyPageHistory',
    class: 'history',
  },
  {
    title: 'お客様情報',
    icon: 'address-card-o',
    to: 'MyPageInfo',
    class: 'info',
  },
  {
    title: 'お子様情報',
    icon: 'child',
    to: 'MyPageChildren',
    class: 'child',
  },
]
