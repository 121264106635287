<template lang="pug">
.privacy

  .outer

    ps-medium-heading 個人情報保護方針

    p 写真の伊里では、以下に掲げる方針に基づいてお客様の個人情報を取り扱っております。

    ul.contents
      li(v-for='item, i in contents', :key='i')
        h3 {{ item.title }}
        template(v-for='desc in item.descriptions')
          p(v-if='desc.type === "text"') {{ desc.value }}
          ul(v-else-if='desc.type === "list"')
            li(v-for='listItem, k in desc.value', :key='k') {{ listItem }}

  .buttons
    ps-button(@click='$router.back()') 戻る
</template>

<script>
const contents = [
  {
    title: '個人情報保護の適用範囲',
    descriptions: [
      {
        type: 'text',
        value:
          '各団体に所属する方が、有限会社アイリス企画(屋号: 写真の伊里)(以下、「弊社」とします)上にあるサービスを利用する上で、弊社が取得した個人情報は、本ページに掲げる個人情報保護方針に従って管理されます。',
      },
      {
        type: 'text',
        value: 'なお、個人情報とは、以下のような内容を指します。',
      },
      {
        type: 'list',
        value: [
          '団体名',
          '行事名',
          '会員番号・学生番号など識別可能な番号',
          '氏名',
          '住所',
          '電話番号',
          '商品の注文コード',
          'メールアドレス',
        ],
      },
    ],
  },
  {
    title: '個人情報の利用目的',
    descriptions: [
      {
        type: 'text',
        value:
          '各団体に所属する方が、団体コード、行事コードなどを入力し写真を購入することを目的としています。',
      },
    ],
  },
  {
    title: '個人情報の安全対策',
    descriptions: [
      {
        type: 'text',
        value:
          '弊社の各サービスでは、お客様の個人情報を、正確、最新のものとするよう適切な処置を講じます。',
      },
      {
        type: 'text',
        value:
          'また、お客様の情報への不当なアクセスなどを防止するための措置万全の体制及び個人情報の漏洩、滅失またはき損の防止その他の個人情報の安全管理のために必要かつ適切な措置を整え、個人情報の管理体制に関して継続的に改善に努めてまいります。',
      },
    ],
  },
  {
    title: 'お客様の個人情報の第三者提供',
    descriptions: [
      {
        type: 'text',
        value:
          '弊社は、商品配送を外部の企業へ委託しており、商品配送に必要な最小限の情報(住所、氏名、電話番号)を提供しておりますが、その提供に当たっては、情報の安全管理が図られるよう、当該事業者に対する必要かつ適切な管理を行うこととしております。',
      },
      {
        type: 'text',
        value:
          '上記以外につきましては、弊社は、お客様の事前承認なく、お客様の個人情報を第三者に開示・提供することはありません。第三者との間での共同利用や業務委託先に個人情報を提供する場合は、別途、提供先について調査し、個人情報の適切な処理・管理を行います。',
      },
    ],
  },
  {
    title: '個人情報の開示',
    descriptions: [
      {
        type: 'text',
        value:
          '弊社では、下記の場合を除き個人情報を開示することはありません。但し、弊社のサービスを運営する上で、必須であり、必要かつ相当な範囲での開示は除かれます。',
      },
      {
        type: 'list',
        value: [
          '集計および分析等により得られたものを、個人を識別または特定できない態様にて提携先等第三者に開示または提供する場合',
          '任意に「利用者」等の同意を得た上で個人情報を開示または利用する場合',
          '裁判所の発する令状その他裁判所の決定、命令または法令に基づき開示する場合',
          '検察・警察・監督官庁からの適法・適式な手順により情報の照会があった場合',
        ],
      },
    ],
  },
]

export default {
  name: 'PublicPagePrivacy',

  computed: {
    contents() {
      return contents
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../../../../sass/variables.sass'
@import '../../../../sass/mixins.sass'

.privacy
  margin-bottom: 2rem

  .outer

    background-color: $white
    padding: 1.5rem 1rem 4rem
    border-radius: $radius-small
    box-shadow: 0 1px 6px 2px rgba(0, 0, 0, .25)
    +mobile
      padding: 1rem 0.5rem
      box-shadow: 0 1px 1px 1px rgba(0, 0, 0, .3)

    .contents
      > li
        margin-top: 2rem
        h3
          font-size: 1.1rem
          border-bottom: 1px dashed #ccc
          padding: 0.2rem 0
          letter-spacing: 0.05rem
        p
          margin-top: 0.5rem
          line-height: 1.3
        ul
          margin-top: 0.5rem
          li
            list-style-type: disc
            list-style-position: inside
            margin-left: 0.5rem
            margin-top: 0.5rem
            &:first-child
              margin-top: 0

  .buttons
    text-align: center
    margin: 3rem 0
</style>
