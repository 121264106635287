// 共通で利用するフッタ

<template lang="pug">
  ul.common-footer
    li(v-for='link in links', :key='link.label')
      a(:href='link.to', :target='link.target') {{ link.label }}
</template>

<script>
import externalLinks from '@/helpers/external-links'

const links = [externalLinks.term, externalLinks.legal, externalLinks.privacy]

export default {
  name: 'CommonFooter',

  data() {
    return {
      links,
    }
  },
}
</script>

<style lang="sass" scoped>
@import '../../sass/variables.sass'
@import '../../sass/mixins.sass'

.common-footer
  text-align: right
  background-color: #edefe7
  padding: 1.2rem
  font-size: $size-small
  li
    display: inline-block
    margin: 0 0.5rem

    // for-mobile
    +mobile
      overflow: hidden
      float: right
      width: 50%
      line-height: 1.5
      margin: 0

    a
      color: #1c464c
      cursor: pointer
      text-decoration: none
      &:hover
        text-decoration: underline
</style>
