// 実際に閲覧する販売の目次情報

import apiFactory from '../api-factory'

import rangesUtil from '../../helpers/ranges-util'

export default apiFactory({
  path: '/sales/<%= id %>/<%= subId %>/sections',
  method: 'GET',
  expectedParams: ['id', 'subId'],
  needAuth: true,
  responseMapper: {
    'sections[0].sections': {
      key: 'items',
      transform: (values, srcObj) => values.map(value => _transform(value, srcObj.thumbnail_token)),
    },
  },
})

function _transform(value, token) {
  let item = {
    name: value.name,
    localRanges: rangesUtil.parseRangesString(value.sub_idxs),
    globalRanges: rangesUtil.parseRangesString(value.sub_idxs_descent),
    thumbnailUrl: value.thumbnail_url,
  }

  // 年間販売向け
  if (value.depth === 1 && value.is_overdue) item.isOverdue = true

  // 古いタイプのサムネイルストレージの場合、旧来通りトークンを別途付与する
  if (value.thumbnail_storage_type !== 's3') item.thumbnailUrl += `?token=${token}`

  if (value.sections && value.sections.length > 0) {
    item.items = value.sections.map(section => _transform(section, token))
  }
  return item
}
