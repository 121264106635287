// はじめての方向けのアクティベーションメールの送信

import apiFactory from '../api-factory'

export default apiFactory({
  path: '/register/email_verifications',
  method: 'POST',
  bodyMapper: {
    name1: 'name1',
    name2: 'name2',
    email: 'email',
  },
  responseMapper: {
    token: 'token',
  },
})
