// ダイアログの表示のためのエントリポイント // 通常はルートのコンポーネントに1配置する。 //
また付属のプラグインを読み込ませて使用する

<template lang="pug">
  transition(name='ps-dialog-view')
    // 背景
    .ps-dialog-view(
      v-show='dialogs.length',
      ref='outer'
    )
      // ダイアログ本体
      ps-dialog(
        ref='entity',
        v-for='dialog, i in dialogs',
        v-bind='dialog',
        :key='i',
        @close='close'
      )
</template>

<script>
export default {
  name: 'DialogMain',

  data() {
    return {
      dialogs: [],
    }
  },

  mounted() {
    // プラグインと一緒に使う必要がある
    if (!this.$psdialog) {
      throw new Error('you have to load the plugin before use this.')
    }
    this.$psdialog.registerComponent(this)
  },

  computed: {
    hasDialogs() {
      return this.dialogs.length > 0
    },
  },

  methods: {
    // ダイアログの表示
    open(dialog) {
      this.dialogs.push(dialog)
    },

    // ダイアログを閉じる。
    close() {
      return this.dialogs.pop()
    },

    // すべてのダイアログを閉じる
    closeAll() {
      this.dialogs = []
    },

    // スクロールロックの実行
    lockScroll() {
      this.$lockBodyScroll(deltaY => {
        this.$refs.outer.scrollTop += deltaY
      })
    },

    // スクロールアンロックの実行
    unlockScroll() {
      this.$unlockScroll()
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../../sass/variables.sass'

.ps-dialog-view
  position: fixed
  background-color: rgba(20, 37, 49, 0.8)
  top: 0
  left: 0
  width: 100%
  height: 100%
  transition: opacity .4s ease
  overflow-y: auto
  z-index: 1000
  > *
    opacity: 0.7
    &:last-child
      opacity: 1

// アニメーション
.ps-dialog-view-enter
  opacity: 0
  .ps-dialog-view
    opacity: 0

.ps-dialog-view-leave-active
  opacity: 0
  .ps-dialog-view
    opacity: 0
    transofrom: scale(0.9)
</style>
