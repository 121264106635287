// ログイン

import apiFactory from '../api-factory'

export default apiFactory({
  path: '/my/authns',
  method: 'POST',
  bodyMapper: {
    email: 'email',
    password: 'passwd',
  },
  responseMapper: {
    id_token: 'token',
    refresh_token: 'refresh',
    message: 'message',
  },
})
