// お知らせページ

<script>
import PsInfoContainer from '@/containers/ps-info-container'

export default {
  name: 'PublicPageInfo',

  components: {
    PsInfoContainer,
  },

  props: {},
}
</script>

<template lang="pug">
.info-page

  .inner

    p クリックすると詳細が表示されます。

    ps-info-container(large)

    .buttons
      //- リダイレクト時にvue-routerがエラーを出すので対策
      ps-button(
        color='green',
        @click='$router.push({ name: "TopPage" }).catch(() => {})'
      ) トップページへ

</template>

<style lang="sass" scoped>
@import '../../../../sass/variables.sass'
@import '../../../../sass/mixins.sass'

.info-page

  .inner

    background-color: $white
    padding: 1.5rem 1rem
    border-radius: $radius-small
    box-shadow: 0 1px 6px 2px rgba(0, 0, 0, .25)
    +mobile
      padding: 1rem 0.5rem
      box-shadow: 0 1px 1px 1px rgba(0, 0, 0, .3)

    > *
      margin-bottom: 1rem
    > .buttons
      text-align: center
</style>
