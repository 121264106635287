// タイトル表示時用のメニュー

<script>
export default {
  name: 'TitleMenu',
}
</script>

<template lang="pug">
.title-menu
  .first
  .second
    ps-button(full-width, text-centered, @click='$emit("close")') 閉じる

</template>

<style lang="sass" scoped>
@import '../../../../sass/variables.sass'

.title-menu
  display: flex
  height: 100%
  flex-direction: column
  > .first
    flex-grow: 1
    > div
      margin-bottom: 1rem
</style>
