// 年間販売の期間外販売手数料が取られる写真が含まれている状態で //
注文手続きへ進んだ際に表示されるダイアログ

<template lang="pug">
.confirm-overdue
  .description
    | 標準販売期間を過ぎた写真が
    span {{ overdueNum }}点
    | カゴに入っているため、
    span 1点につき {{ overdueCharge }}円 / 計{{ overdueSum }}円(税別)
    | の期間外販売手数料がかかります。
  .buttons
    ps-button(@click='$psdialog.close') OK
</template>

<script>
export default {
  name: 'ConfirmOverdue',

  props: {
    // 1枚あたりの期間外販売手数料
    overdueCharge: {
      type: Number,
      required: true,
    },
    // 期間外の対象の枚数
    overdueNum: {
      type: Number,
      required: true,
    },
  },

  computed: {
    overdueSum() {
      return this.overdueCharge * this.overdueNum
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../../../sass/variables.sass'

.confirm-overdue
  .description
    span
      margin: 0 0.3rem
      text-align: center
      font-weight: bold
      color: $red
  .buttons
    text-align: center
</style>
