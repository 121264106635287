// カート

// 払込票作成手数料
const makeSlipCost = 134

// ステート
const state = {
  num: null,
  sum: null,
  total: null,
  photoNum: null,
  photoSum: null,
  optionNum: null,
  optionSum: null,
  // 詳細 (金額の内訳)
  details: null,
  // サムネイルトークン
  token: null,
  photos: [],
  // 購入写真の判定用マップ(キーはphotoId)
  photosMap: {},
  // カスタム写真の判定用マップ(キーはphotoId)
  customsMap: {},
  // オプション販売の判定用マップ(キーはId)
  optionsMap: {},
  // このカート情報はどのsaleIdのものかを識別するために必要
  // この情報には通常、外からはアクセスしない
  saleId: null,
  // 写真の購入内訳
  photosSummary: [],
  // 年間販売における期間外販売の対象となる写真の枚数
  overdueNum: 0,
}

// ゲッタ
const getters = {
  num(state) {
    return state.num
  },
  sum(state) {
    return state.sum
  },
  // 支払い方法として払込票を選択した場合その金額が追加される
  total(state, getters, rootState, rootGetters) {
    const paymentId = _.get(rootGetters['order/payment'], 'id')
    return _.toNumber(paymentId) === 4 ? state.total + makeSlipCost : state.total
  },
  photoNum(state) {
    return state.photoNum
  },
  photoSum(state) {
    return state.photoSum
  },
  optionNum(state) {
    return state.optionNum
  },
  optionSum(state) {
    return state.optionSum
  },
  // 現在のカートの状況から算出される送料はいくらか
  currentShipmentFee(state) {
    const item = _.find(state.details, item => item.code === 'PHOTO_DELIVERY')
    return item ? item.sum : 0
  },
  // 発送手数料の値引き金額がいくらか
  discontValue(state) {
    const item = _.find(state.details, item => item.code === 'PHOTO_DELIVERY_DISCOUNT')
    return item ? Math.abs(item.sum) : 0
  },
  // 後いくらで送料無料か
  remainingToFree(state, rootGetters) {
    const threshold = rootGetters['sale/shippingFreeThreshold']
    // 値引きの設定自体が無い
    if (!threshold) return 0
    return threshold > state.photoSum ? threshold - state.photoSum : 0
  },
  // 支払い方法として払込票が選択されている場合、その項目が末尾に追加される
  details(state) {
    return state.details
  },
  token(state) {
    return state.token
  },
  photos(state) {
    return state.photos
  },
  photosMap(state) {
    return state.photosMap
  },
  customsMap(state) {
    return state.customsMap
  },
  optionsMap(state) {
    return state.optionsMap
  },
  photosSummary(state) {
    return state.photosSummary
  },
  // 期間外販売の枚数
  overdueNum(state) {
    return state.overdueNum
  },
  // 期間外販売手数料
  overdueCharge(state, getters, rootState, rootGetters) {
    const num = getters['overdueNum']
    const charge = rootGetters['sale/overdueCharge']
    return num && charge ? charge * num : 0
  },
}

// ミューテーション
const mutations = {
  setNum(state, payload) {
    state.num = payload
  },
  setSum(state, payload) {
    state.sum = payload
  },
  setTotal(state, payload) {
    state.total = payload
  },
  setPhotoNum(state, payload) {
    state.photoNum = payload
  },
  setPhotoSum(state, payload) {
    state.photoSum = payload
  },
  setOptionNum(state, payload) {
    state.optionNum = payload
  },
  setOptionSum(state, payload) {
    state.optionSum = payload
  },
  setDetails(state, payload) {
    state.details = payload
  },
  setPhotos(state, payload) {
    state.photos = payload
  },
  setToken(state, payload) {
    state.token = payload
  },
  setPhotosMap(state, payload) {
    state.photosMap = payload
  },
  setCustomsMap(state, payload) {
    state.customsMap = payload
  },
  setOptionsMap(state, payload) {
    state.optionsMap = payload
  },
  setSaleId(state, payload) {
    state.saleId = payload
  },
  setPhotosSummary(state, payload) {
    state.photosSummary = payload
  },
  setOverdueNum(state, payload) {
    state.overdueNum = payload
  },
}

export default { state, getters, mutations }
