// 注文完了ページ

<script>
import { mapGetters, mapActions } from 'vuex'

import getOrderDetailApiAsync from '@/api/user/get-order-detail'

import conveniNativeInfo from '@/helpers/conveni-native-info'

import * as errorCodes from '@/errors/codes'

export default {
  name: 'OutroPageCompleted',

  beforeRouteEnter(to, from, next) {
    // 通常は注文内容確認ページから遷移してくる
    // そうでない場合はトップページへ
    // ただしリロード(from.name=null)は許容
    const name = 'OutroPageConfirm'
    if (from.name === name || !from.name) {
      next()
    } else {
      next({ name: 'TopPage' })
    }
  },

  data() {
    return {
      // 注文の詳細情報
      order: {},
    }
  },

  async created() {
    let response
    try {
      const params = { code: this.code }
      response = await getOrderDetailApiAsync(params)
      if (!response.ok) throw new Error(errorCodes.GET_ORDER_DETAIL_ERROR)
    } catch (e) {
      this.setError(e)
      return
    }

    this.order = response.payload
  },

  computed: {
    ...mapGetters({
      user: 'app/user',
      role: 'app/role',
    }),
    // 注文コード
    code() {
      return this.$route.params.code
    },
    // コンビニ毎の固有の情報
    conveniInfo() {
      if (this.order.paymentCode !== 'conveni') return null
      return conveniNativeInfo[this.order.paymentSubCode] || {}
    },
  },

  methods: {
    ...mapActions({
      logout: 'app/logout',
      setError: 'app/setError',
    }),

    goToMyPageTop() {
      this.$router.push({ name: 'MyPageTop' })
    },

    goToMyPageHistory() {
      this.$router.push({ name: 'MyPageHistory' })
    },

    // Guest向け。ログアウトしてからトップページへ
    goToTopPage() {
      this.logout()
      this.$router.push({ name: 'TopPage' })
    },
  },

  // 戻るボタン押下時を含めたページ遷移時の挙動
  beforeRouteLeave(to, from, next) {
    // 確定後は、前のページ(内容確認ページ)には戻れないように
    if (to.name === 'OutroPageConfirm') next(false)
    else next()
  },
}
</script>

<template lang="pug">
.outro-page-completed

  .inner

    ps-medium-heading.heading 注文手続きが完了しました。

    article.main
      p
        | ご注文番号は
        b 「{{ code }}」
        | です。
        br
        | ご注文内容確認メールを
        // guestの場合、トップページへの遷移までの僅かな間に、ログアウトによって、user.emailにアクセスできなくなるので、v-onceに
        b(v-once) 「{{ user.email }}」
        | に送信しました。
        template(v-if='order.paymentCode === "conveni"')
          br
          | また、決済代行事業者のGMOイプシロン株式会社より、お支払い方法についてのメールが届きます。

    // 支払いについて

    // コンビニ決済
    article.about-payment.conveni(v-if='order.paymentCode === "conveni"')
      h3 コンビニ決済でのお支払いについて

      ul
        li
          span お支払い可能なコンビニ
          span {{ order.paymentConveniStores }}
        li
          span {{ conveniInfo.numberName }}
          span {{ order.paymentConveniCode }}
        li(v-if='order.paymentSubCode !== "seven"')
          span 登録お電話番号
          span {{ order.customerTel }}
        li
          span ご注文金額（合計）
          span {{ order.price }}円
        li
          span お支払い期限
          span {{ order.paymentExpiredAt }}
      
      h4 お支払い手順

      p {{ conveniInfo.description }}
      p(v-if='order.paymentConveniExternalUrl')
        | ※ プリンターをお持ちの方は
        a(:href='order.paymentConveniExternalUrl', target='_blank') こちら
        | をクリックすると「払込票」が表示されますので、印刷して店頭にお持ちください。

    // 払込票
    article.about-payment.slip(v-if='order.paymentCode === "slip"')
      h3 払込票でのお支払いについて

      ul
        li
          span ご注文金額（合計）
          span {{ order.price }}円
        li
          span お支払い期限
          span {{ order.paymentExpiredAt }}

      h4 お支払い手順

      ol
        li お支払いに必要な払込票が通常2～4日後に郵送されます。
        li 最寄りのコンビニもしくはゆうちょへ上記封筒に入っている払込票をもって、お支払いください。

    // 集金袋
    article.about-payment.pouch(v-else-if='order.paymentCode === "pouch"')
      h3 集金袋でのお支払いについて

      img(src='../../../../assets/envelope-large.png')

      ul
        li
          span 合計金額
          span.large {{ order.price }}円
        li
          span 注文コード
          span.large {{ order.paymentPouchCode }}

      h4 お支払い手順

      ol
        li
          span 赤ワク
          | の中の「年・組」、「お名前(お子様)」、「合計金額」、「注文コード(5文字)」を集金袋に記入し、お金を入れて封をしてください。
        li 学校・園に期日までに提出してください。


    article.buttons
      template(v-if='role === "member"')
        ps-button(size='large', color='green', @click='goToMyPageTop')
          ps-icon(icon='user-circle')
          | &nbsp;マイページトップへ
        ps-button(size='large', color='orange', @click='goToMyPageHistory')
          ps-icon(icon='shopping-cart')
          | &nbsp;ご注文履歴へ
      ps-button(v-else, size='large', color='green', @click='goToTopPage')
        ps-icon(icon='home')
        | &nbsp;トップページへ

</template>

<style lang="sass" scoped>
@import '../../../../sass/variables.sass'
@import '../../../../sass/mixins.sass'

.outro-page-completed

  .inner
    background-color: $white
    padding: 1.5rem 1rem
    border-radius: $radius-small
    box-shadow: 0 1px 6px 2px rgba(0, 0, 0, .25)
    +mobile
      padding: 1rem 0.5rem
      box-shadow: 0 1px 1px 1px rgba(0, 0, 0, .3)

    // 通常よりフォントを大きく目立つように
    > .heading
      font-size: $size-largest

    > article
      margin-bottom: 2rem

      &.main
        > *
          margin-bottom: 1rem

        > p
          line-height: 1.4

          &.note
            font-size: $size-small
            color: darken($green, 20%)

      &.about-payment
        background-color: lighten($orange, 37%)
        border: 1px solid lighten($orange, 28%)
        border-radius: $radius-small
        padding: 0.8rem
        max-width: 800px
        margin-left: auto
        margin-right: auto

        > h3
          background-color: lighten($orange, 5%)
          color: $white
          border-radius: $radius-small
          font-weight: normal
          font-size: $size-medium
          padding: 0.3rem
          text-align: center
          margin-bottom: 1rem

        > ul
          margin-bottom: 1rem
          > li
            margin-bottom: 0.5rem
            > :first-child
              color: darken($orange, 10%)
              &:after
                content: ': '
            > .large
              font-size: $size-large
              letter-spacing: 1px

        > h4
          font-size: $size-normal
          font-weight: bold
          margin-bottom: 0.5rem

        > ol
          > li
            list-style-type: decimal
            margin-left: 1.5rem
            margin-bottom: 0.3rem
            line-height: 1.2
            span
              color: $red

        > img
          margin: 0 auto 1rem
          width: 100%
          max-width: 400px
          height: auto
          display: block

        &.conveni
          ol.group2 li
            list-style-type: disc
            a
              cursor: pointer
          > p
            margin-bottom: 0.5rem

      &.buttons
        text-align: center
        > :nth-child(2)
          +mobile
            margin-top: 0.5rem
          +tablet
            margin-left: 0.5rem
</style>
