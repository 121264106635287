// 日付入れの実行

import apiFactory from '../api-factory'

export default apiFactory({
  path: '/my/custom_photos/date',
  method: 'POST',
  needAuth: true,
  bodyMapper: {
    saleId: 'sale_id',
    photoId: 'photo_id',
    vertical: {
      key: 'contents.pos_v',
      transform: value => value.slice(0, 1),
    },
    horizontal: {
      key: 'contents.pos_h',
      transform: value => value.slice(0, 1),
    },
    text: 'contents.text',
    color: 'contents.color',
    borderColor: 'contents.border_color',
    borderSize: 'contents.border_size',
    printLongEdge: 'contents.mm_longer',
    printShortEdge: 'contents.mm_shorter',
    async: {
      key: 'async',
      transform: value => (value ? 1 : 0),
      default: 0,
    },
  },
  responseMapper: {
    photo_custom_id: 'id',
    'photo_custom_data.data_checksum': {
      key: 'check',
      transform: value => value.slice(0, 5),
    },
  },
})
