// メールアドレスの変更
// 実際には確認メールが送られるのみで、この時点では変更は確定されない

import apiFactory from '../api-factory'

export default apiFactory({
  path: '/my/email_verifications',
  method: 'POST',
  needAuth: true,
  bodyMapper: {
    email: 'email',
    password: 'passwd',
  },
})
