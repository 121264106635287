// お知らせの一覧の取得

import apiFactory from '../api-factory'

export default apiFactory({
  path: '/news?type=1',
  method: 'GET',
  responseMapper: {
    news: 'items',
  },
})
