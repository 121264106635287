// SPA外へのリンク

const linkBaseUrl = process.env.VUE_APP_LINK_BASE || ''

export default {
  term: createLinkObject('利用規約', '/public/term'),
  privacy: createLinkObject('個人情報保護方針', '/public/privacy'),
  legal: createLinkObject('特定商取引法に基づく表記', '/public/legal'),
}

function createLinkObject(label, to, fullpath = false, targetBlank = false) {
  // pickByでnull or undefined のものは除去
  return _.pickBy({
    label,
    to: fullpath ? to : linkBaseUrl + to,
    target: targetBlank ? '_blank' : null,
  })
}
