// カートページでの拡大画面

<script>
import ZoomBase from '../zoom-base'

import { mapGetters } from 'vuex'

export default {
  name: 'CartZoom',

  components: {
    ZoomBase,
  },

  props: {
    photoIndex: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      // 配列中の位置
      pos: null,
    }
  },

  created() {
    this.pos = _.findIndex(this.photos, item => item.index === this.photoIndex)
  },

  computed: {
    ...mapGetters({
      photos: 'cart/photos',
      cartPhotoNum: 'cart/photoNum',
      skippingOption: 'sale/skippingOption',
      byConfirmCode: 'sale/byConfirmCode',
    }),
    hasNext() {
      return this.photos.length - 1 > this.pos
    },
    hasPrev() {
      return this.pos > 0
    },
    current() {
      return this.photos[this.pos]
    },
    saleId() {
      return this.$route.params.saleId
    },
    // 次の画面に進めるかどうか = 写真を1点も買っていないまたは、確認用コードを使用 という条件にあてはまらないか
    canGoToNext() {
      return !this.byConfirmCode && this.cartPhotoNum > 0
    },
  },

  methods: {
    // 次の写真へ移動
    goNext() {
      if (!this.hasNext) return
      ++this.pos
    },
    // 前の写真へ移動
    goPrev() {
      if (!this.hasPrev) return
      --this.pos
    },

    clickBg() {
      // 子コンポーネントがトリミングモードかどうかをチェック
      if (!_.get(this.$refs.zoomBase, 'trimMode')) this.close()
    },

    // 購入手続きへ
    goToNextStep() {
      this.$psdialog.close()
      this.$router.push({ name: 'OutroPageRegister', params: { saleId: this.saleId } })
    },

    close() {
      this.$psdialog.close({
        photoIndex: this.current.index,
        updated: this.current.index !== this.photoIndex,
      })
    },
  },
}
</script>

<template lang="pug">
  .cart-zoom
    zoom-base(
      v-if='current',
      menu-type='cart',
      :item='current',
      :has-next='hasNext',
      :has-prev='hasPrev',
      :can-go-to-next-step='canGoToNext',
      :show-price='false',
      @goNext='goNext',
      @goPrev='goPrev',
      @goToNextStep='goToNextStep',
      @close='close',
      ref='zoomBase'
    )
</template>

<style lang="sass" scoped>
.cart-zoom
  width: 100%
  height: 100%
</style>
