// 支払い方法入力フォーム

<template lang="pug">
  .ps-payment-form

    ps-form-item(label='お支払い方法', required)

      .payment-types

        ps-radio(v-if='cardAvailable', radio-value='2', v-model='payment.id', full-width, @input='update')
          | クレジットカード (手数料不要)

          // カード払い用のフォーム
          article.type-card(slot='description')
            .brands
              .logo(
                v-for='brand in cardBrands',
                :class='[ brand ,{ deactive: currentCardBrand && brand !== currentCardBrand }]',
                :key='brand'
              )

            // カード番号
            .card-num(:class='{ error: getError("card.number") }')
              p.caption
                | カード番号&nbsp;
                ps-tag(color='blue') 半角
              ps-input(
                v-model='payment.cardNumber',
                placeholder='0000000000000000',
                ime-mode='disabled',
                force-hankaku,
                full-width,
                @input='update',
                style='max-width: 14rem'
              )
              p.error-message(v-if='getError("card.number")') {{ getError("card.number") }}
            
            // カード有効期限
            .card-exp(:class='{ error: getError("card.exp") }')
              p.caption 有効期限

              ps-select(v-model='payment.cardExpMonth', :items='cardExpMonths', @input='update')
              span 月&nbsp;/&nbsp;
              ps-select(v-model='payment.cardExpYear', :items='cardExpYears', @input='update')
              span 年
              p.error-message(v-if='getError("card.exp")') {{ getError("card.exp") }}

            // セキュリティコード
            .card-cvv(:class='{ error: getError("card.cvv") }')
              p.caption
                | セキュリティコード
                span(v-if='currentCardBrand') {{ currentCardBrand !== 'amex' ? '(3ケタ)' : '(4ケタ)' }}
                | &nbsp;
                ps-tag(color='blue') 半角
              ps-input(v-model='payment.cardCvv', ime-mode='disabled', force-hankaku, @input='update', style='width: 6rem')
              p.error-message(v-if='getError("card.cvv")') {{ getError("card.cvv") }}
              p.what-is-cvv
                a(@click='$emit("clickWhatIsCvv")')
                  ps-icon(icon='question-circle')
                  | セキュリティコードとは

        ps-radio(v-if='conveniAvailable', radio-value='3', v-model='payment.id', full-width, @input='update')
          | コンビニ決済 (手数料不要)

          // コンビニ払いのフォーム
          article.type-conveni(slot='description')
            p.caption お支払いするコンビニを選択してください。

            .conveni-group(:class='{ error: getError("conveni.cvs") }')

              ps-radio(
                v-for='item in convenis', :key='item.code',
                v-model='payment.conveniCvs', :radio-value='item.code',
                color='blue', full-width,
                @input='update'
              )
                span.conveni-icon(:class='item.code')
                | {{ item.name }}

              // ps-radio(v-model='payment.conveniCvs', radio-value='0301', color='blue', open-description-always, full-width, @input='update')
              //   img(src='../../assets/conveni-group-1.png')
              //   span &nbsp;セブンイレブン
              //   div.desc(slot='description')
              //     p お手続き完了後に画面に表示される「払込票番号」のメモまたは「払込票」を印刷して店頭レジに持参し、お支払いをします。
              //     p.note ※「払込票番号」でお支払いをされる場合、店頭レジで「インターネットショッピングの支払い」とお申し出ください。

              // ps-radio(v-model='payment.conveniCvs', radio-value='0302', color='blue', open-description-always, full-width, @input='update')
              //   div
              //     img.group2(src='../../assets/conveni-group-2.png')
              //     p ローソン、ミニストップ、ファミリーマート、セイコーマート、サークルＫ・サンクス
              //   div.desc(slot='description')
              //     p お手続き完了後に画面に表示される「お支払い受付番号」のメモを店頭に持参し、店頭設置端末を操作してお支払いをします。
              //     p.note ※店頭設置端末を操作の際、お申込時にご登録頂いたお客様のお電話番号の入力が必要となります。



              p.error-message(v-if='getError("conveni.cvs")') コンビニを選択してください。


        ps-radio(v-if='slipAvailable', radio-value='4', v-model='payment.id', full-width, @input='update')
          | 払込票 (郵便局) (手数料: 134円)

          article.type-slip(slot='description')
            img(src='../../assets/payment-slip.png')
            p お支払い手続き完了後 4日以内に、以下の封書がお手元に届きます。受領されましたら、発行日から14日以内 にお支払いください。
            p.note 封書が届かない場合は、弊社までご連絡下さい。
        
        
</template>

<script>
import * as creditCardUtil from '@/helpers/credit-card-util'
import convenis from '@/helpers/convenis'

export default {
  name: 'PsPaymentForm',

  data() {
    return {
      payment: {
        id: null,
        cardNumber: null,
        cardExpMonth: null,
        cardExpYear: null,
        cardCvv: null,
        conveniCvs: null,
      },
      convenis,
    }
  },

  props: {
    value: {},
    cardAvailable: {
      type: Boolean,
      default: true,
    },
    conveniAvailable: {
      type: Boolean,
      default: true,
    },
    slipAvailable: {
      type: Boolean,
      default: true,
    },
    errors: {
      default: () => {
        return {}
      },
    },
  },

  created() {
    if (!this.value) return
    this.payment.id = this.value.id
    this.payment.cardNumber = this.value.cardNumber
    this.payment.cardExpMonth = this.value.cardExpMonth
    this.payment.cardExpYear = this.value.cardExpYear
    this.payment.cardCvv = this.value.cardCvv
    this.payment.conveniCvs = this.value.conveniCvs
  },

  computed: {
    // 入力されたカード番号からカードブランドを算出
    // 推定できない場合はnullが返る
    currentCardBrand() {
      return creditCardUtil.getBrand(this.payment.cardNumber)
    },
    // すべてのカードブランド
    cardBrands() {
      return _.keys(creditCardUtil.brands)
    },
    cardExpMonths() {
      return [{ label: '--', value: null }, ..._.range(1, 13).map(i => ('0' + i).slice(-2))]
    },
    cardExpYears() {
      const year = new Date().getFullYear()
      return [{ label: '----', value: null }, ..._.range(year, year + 21).map(i => '' + i)]
    },
  },

  methods: {
    update() {
      this.$emit('input', _.cloneDeep(this.payment))
    },
    getError(key) {
      let error
      if (key === 'exp') {
        error = _.get(this.errors, 'card.exp_month') || _.get(this.errors, 'card.exp_year')
      } else {
        error = _.get(this.errors, key)
      }
      return error ? error.message : null
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../../sass/variables.sass'
@import '../../sass/mixins.sass'

.ps-payment-form

  .payment-types

    > *
      margin-bottom: 0.5rem
      &:last-child
        margin-bottom: 0

    article
      margin: 0 0.5rem 0.5rem

      p.caption
        margin-bottom: 0.3rem

      // エラーメッセージ
      p.error-message
        font-size: $size-small
        color: $red
        margin-top: 0.3rem

      // カード払い
      &.type-card
        > *
          margin-bottom: 0.5rem
        .brands
          // max-width: 250px
          // width: 100%
          // height: auto
          .logo
            display: inline-block
            width: 2rem
            height: 2rem
            background-size: cover
            margin-left: 0.3rem
            &.deactive
              opacity: 0.3
            &.visa
              background-image: url(../../assets/visa.png)
            &.master
              background-image: url(../../assets/master.png)
            &.jcb
              background-image: url(../../assets/jcb.png)
            &.amex
              background-image: url(../../assets/amex.png)
            &.diners
              background-image: url(../../assets/diners.png)
        .card-num
          input
            width: 13rem
        .card-cvv
          input
            width: 6rem
        .what-is-cvv
          margin-top: 0.3rem
          a
            font-size: $size-small
            color: darken($blue, 20%)
            border-bottom: 1px solid darken($blue, 20%)
            cursor: pointer

      // コンビニ払い
      &.type-conveni
        .conveni-group
          > *
            margin-bottom: 0.1rem
            .conveni-icon
              display: inline-block
              width: 1.8rem
              height: 1.8rem
              margin-right: 0.5rem
              background-size: cover
              &.seven
                background-image: url(../../assets/seven.gif)
              &.famima
                background-image: url(../../assets/famima.gif)
              &.lawson
                background-image: url(../../assets/lawson.gif)
              &.mini
                background-image: url(../../assets/mini.gif)
              &.circle
                background-image: url(../../assets/circle.gif)
              &.sunkus
                background-image: url(../../assets/sunkus.gif)

      // 払込表
      &.type-slip
        p
          font-size: $size-small
          line-height: 1.4
          &.note
            color: darken($green, 30%)
</style>
