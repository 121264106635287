// 閲覧の状態に関するストア

// ステート
const state = {
  // はじめての方向けの販売認可画面から遷移してきたか
  fromGuestSaleAuthPage: false,
  // パスワード登録ダイアログの表示モード。nullなら表示しない
  showFirstTimePasswordRegisterDialog: false,
  // はじめて年間販売の期間外の写真を購入する際に表示するダイアログのフラグ
  showFirstTimeAddOverdueToCartDialog: true,
  // はじめて年間販売の期間外の写真をカートに入れた状態で購入手続きする際に表示するダイアログのフラグ
  showFirstTimeConfirmOverdueDialog: true,
  // 現在閲覧中の写真index
  currentPhotoIndex: null,
  // 写真の表示サイズ
  displayPhotoSize: 'large',
  // 通常の表示(normal)かお顔検索結果(okao)の表示か
  mode: 'normal',
  // お顔検索対象の子のId
  okaoChildId: null,
}

// ゲッタ
const getters = {
  // 初回のパスワード登録ダイアログを表示するかどうか
  fromGuestSaleAuthPage(state) {
    return state.fromGuestSaleAuthPage
  },
  currentPhotoIndex(state) {
    return state.currentPhotoIndex
  },
  displayPhotoSize(state) {
    return state.displayPhotoSize
  },
  okaoChildId(state) {
    return state.okaoChildId
  },
  // お顔検索中使用中かどうか
  okaoNow(state) {
    return state.mode === 'okao'
  },
  // はじめて年間販売の期間外の写真を購入する際に表示するダイアログを表示するか
  showFirstTimeAddOverdueToCartDialog(state) {
    // 期間外販売手数料がかからない販売なら表示しない
    return state.showFirstTimeAddOverdueToCartDialog
  },
  // はじめて年間販売の期間外の写真をカートに入れた状態で購入手続きする際に表示するダイアログのフラグ
  showFirstTimeConfirmOverdueDialog(state) {
    // 期間外販売手数料がかからない販売なら表示しない
    return state.showFirstTimeConfirmOverdueDialog
  },
}

// ミューテーション
const mutations = {
  setFromGuestSaleAuthPage(state, payload) {
    state.fromGuestSaleAuthPage = payload
  },
  setDisplayPhotoSize(state, payload) {
    state.displayPhotoSize = payload
  },
  setCurrentPhotoIndex(state, payload) {
    state.currentPhotoIndex = payload
  },
  // モード
  setMode(state, payload) {
    state.mode = payload
  },
  // 写真一覧の閲覧によって現在のindexが変更された
  setCurrentPhotoIndexSilent(state, payload) {
    state.currentPhotoIndex = payload
  },
  setOkaoChildId(state, payload) {
    state.okaoChildId = payload
  },
  // 年間販売向け
  setShowFirstTimeAddOverdueToCartDialog(state, payload) {
    state.showFirstTimeAddOverdueToCartDialog = payload
  },
  setShowFirstTimeConfirmOverdueDialog(state, payload) {
    state.showFirstTimeConfirmOverdueDialog = payload
  },
}

export default { state, getters, mutations }
