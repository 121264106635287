// お顔検索の実行

import apiFactory from '../api-factory'

export default apiFactory({
  path: '/sales/<%= id %>/photos_by_faces',
  method: 'POST',
  expectedParams: ['id'],
  needAuth: true,
  responseMapper: {
    thumbnail_token: 'token',
    photos: {
      key: 'items',
      transform: values => {
        return values.map(item => {
          const dataWidth = item.data_width
          const dataHeight = item.data_height
          const bbox = item.face.bounding_box
          return {
            id: item.id,
            dataWidth,
            dataHeight,
            index: item.index,
            eventIdNotSaleId: item.event_id,
            check: item.data_checksum.slice(0, 5),
            // PSのお顔検索のバウンディングボックスと同じフォーマットに
            face: {
              top: dataHeight * bbox.top,
              bottom: dataHeight * (1.0 - bbox.top - bbox.height),
              left: dataWidth * bbox.left,
              right: dataWidth * (1.0 - bbox.left - bbox.width),
            },
            thumbnailUrls: item.thumbnail_url,
            isOverdue: item.is_overdue,
          }
        })
      },
    },
  },
})
