// パスワードの登録完了を通知するダイアログ

<script>
import { mapGetters } from 'vuex'

// ログアウトダイアログ
import UserLogout from '../user-logout'
// 初めての方向けチュートリアル
import BasicTutorialMobile from '../basic-tutorial-mobile'
import BasicTutorialPc from '../basic-tutorial-pc'

export default {
  name: 'PasswordRegisterCompleted',

  props: {
    // 'first': 初回
    // 'normal': 通常
    // ログアウト時にパスワード登録に遷移
    // 'logout': ログアウト
    type: String,
  },

  computed: {
    ...mapGetters({
      screenType: 'app/screenType',
    }),
  },

  methods: {
    showBasicTutorial() {
      this.$psdialog.close()
      const component = this.screenType === 'mobile' ? BasicTutorialMobile : BasicTutorialPc
      this.$psdialog.open({
        component,
        size: 'm',
      })
    },

    showLogoutDialog() {
      this.$psdialog.open({
        title: 'ログアウト',
        component: UserLogout,
        size: 's',
        closeByClickBg: true,
        closeButton: true,
      })
    },

    close() {
      this.$psdialog.close()
      if (this.type === 'logout') {
        // ログアウトダイアログの表示
        this.showLogoutDialog()
      }
    },
  },
}
</script>

<template lang="pug">
.password-register-completed
  .icon
    ps-icon(icon='check-circle')
  p
    | パスワードの登録が完了しました。
  .buttons
    ps-button(v-if='type === "first"', color='green', @click='showBasicTutorial')
      | 操作方法の説明を見る
    ps-button(large-width, @click='close') 閉じる
</template>

<style lang="sass" scoped>
@import '../../../sass/variables.sass'

.password-register-completed
  > .icon
    font-size: 3rem
    text-align: center
    color: $orange
    margin-bottom: 0.5rem
  > p
    text-align: center
    margin-bottom: 1rem
  > .buttons
    text-align: center
    > *
      margin-bottom: 0.5rem
      &:last-child
        margin-bottom: 0
</style>
