// マイページトップ

<script>
import { mapActions } from 'vuex'

import mobileMenuItems from '@/helpers/my-menu-items'
import PsInfoContainer from '@/containers/ps-info-container'

// 認可済みの販売一覧の取
import getAuthorizedSalesApiAsync from '@/api/user/get-authorized-sales'
// ランダムにサムネイルを取得
import getRandomThumbnailsApiAsync from '@/api/sale/get-random-thumbnails'
// 販売の閲覧認可を取得する
import authorizeSale from '@/api/sale/authorize-sale'
// 重要なお知らせの取得
import getSeriousInfoApiAsync from '@/api/public/get-serious-info'

// 販売に対する緊急のメッセージがある場合に表示
import ErrorMessage from '@/dialogs/contents/error-message'

export default {
  name: 'MyPageTop',

  components: {
    PsInfoContainer,
  },

  data() {
    return {
      code1: null,
      code2: null,
      error: null,
      loading: false,
      authorizedSales: [],
      thumbnailsList: {},
      // for mobile
      mobileMenuItems: _.filter(mobileMenuItems, item => !item.isTop),
      // 重要なお知らせ
      seriousInfo: null,
    }
  },

  created() {
    this.fetchSalesAsync()
    this.getSeriousInfoAsync()
  },

  computed: {
    validated() {
      return this.code1 && this.code1.length === 5 && this.code2 && this.code2.length === 5
    },
  },

  methods: {
    ...mapActions({
      setError: 'app/setError',
      // 特定の販売に依存する情報のクリア
      // 販売の閲覧等を再開する前に呼ぶ
      clearSaleSpecifics: 'clearSaleSpecifics',
      // 特定の販売の閲覧位置を強制上書き(年間販売用)
      setPhotoIndexManual: 'browsing/setPhotoIndexManual',
    }),

    // 認可済みの販売一覧の取得
    async fetchSalesAsync() {
      // まず販売一覧の取得
      try {
        let response
        response = await getAuthorizedSalesApiAsync()
        if (!response.ok) return
        this.authorizedSales = response.payload.items
        // サムネイルも取得
        const pList = this.authorizedSales.map(sale => this._getRandomThumbnailsAsync(sale.id))
        this.thumbnailsList = await Promise.all(pList)
      } catch (e) {
        this.setError(e)
      }
    },

    // ランダムなサムネイルを取得(サイレント実行 = スピナーは表示しない)
    async _getRandomThumbnailsAsync(saleId) {
      const params = { id: saleId }
      const response = await getRandomThumbnailsApiAsync(params, null, true)
      if (!response.ok) return null
      else return response.payload.items
    },

    // 緊急のお知らせの取得
    async getSeriousInfoAsync() {
      try {
        const response = await getSeriousInfoApiAsync(null, null, true)
        // 重要なお知らせが存在するかどうか
        if (response.ok && response.payload.exists) {
          this.seriousInfo = response.payload.news
        }
      } catch (e) {
        // 何もしない
      }
    },

    async authorizeSale() {
      this.error = null
      this.loading = true

      const body = { code1: this.code1, code2: this.code2 }

      // ボタンのローディング状態を制御するために例外処理
      let response
      try {
        response = await authorizeSale(null, body, true)
      } catch (e) {
        // 再スロー
        this.setError(e)
        return
      }
      this.loading = false

      if (!response.ok) {
        const message = _.get(response, 'payload.message')
        // 緊急のメッセージがあるかどうか。この場合だけダイアログを表示
        if (/announcement/.test(message)) {
          const announcement = _.get(response, 'payload.announcement', {})
          this.showSaleAnnouncement(announcement.title, announcement.body)
        } else if (/opened/.test(message) || /not ready/.test(message))
          this.error = 'こちらのイベントは公開準備中です。'
        else if (/closed/.test(message)) this.error = 'こちらのイベントは公開を終了しました。'
        else this.error = '入力されたコードが正しくありません。'
      } else {
        // 成功したら閲覧ページへ
        this.$router.push({
          name: 'BrowsePage',
          params: { saleId: response.payload.id },
        })
      }
    },

    // 閲覧を再開
    // overridePhotoIndexを設定しておくと、
    resumeBrowsing(item, overridePhotoIndex = null) {
      // 年間販売向けの閲覧位置の強制上書き
      if (overridePhotoIndex) {
        console.log('overriede!', overridePhotoIndex)
        this.setPhotoIndexManual({
          saleId: item.id,
          subSaleId: item.id,
          index: overridePhotoIndex,
        })
      }
      this.clearSaleSpecifics()
      this.$router.push({
        name: 'BrowsePage',
        params: { saleId: item.id },
      })
    },

    // 買い物カゴを再開
    resumeCart(item) {
      this.clearSaleSpecifics()
      this.$router.push({
        name: 'OutroPageCart',
        params: { saleId: item.id },
      })
    },

    // 販売に対する緊急のメッセージを表示
    showSaleAnnouncement(title, message) {
      this.$psdialog.open({
        title,
        props: {
          message,
          type: 'error',
        },
        component: ErrorMessage,
        size: 'm',
      })
    },
  },
}
</script>

<template lang="pug">
.my-page-top

  .mobile-menu
    // モバイルのためのトップページ用メニュー
    section
      ps-row(:gutter='5')
        ps-col(v-for='item, i in mobileMenuItems', :key='i', :class='`menu-${item.class}`')
          router-link(:to='{ name: item.to }')
            .icon
            span {{ item.title }}

  .inner

    // 緊急のお知らせ
    section.emergency(v-if='seriousInfo')
      ps-serious-info(:title='seriousInfo.title', :html='seriousInfo.content')

    // 新しいイベントの閲覧
    section.new-sale
      ps-medium-heading 新しいイベントの閲覧

      p.info 販売のご案内等に記載された閲覧用のコード(コード1とコード2)をご入力ください。

      .form
        ps-form-item(label='コード1', hankaku, :error='error ? " " : null')
          ps-input(placeholder='例:abab1', v-model='code1', v-my-id='"code1"')
        ps-form-item(label='コード2', hankaku, :error='error ? " " : null')
          ps-input(placeholder='例:cdcd2' v-model='code2', v-my-id='"code2"')

      p.error(v-if='error') {{ error }}

      p.buttons
        ps-button(color='orange', size='medium', text-centered, min-width='8rem',
          :loading='loading', @click='authorizeSale', :disabled='!validated', v-my-id='"do-auth"')
          | 次へ &nbsp;
          ps-icon(icon='chevron-circle-right')

    // 認可済みのイベント
    section.authorized-sales
      ps-medium-heading 閲覧中のイベント

      ul.sales
        li(v-for='item, i in authorizedSales', :key='item.key', :class='[`status-${item.status}`, { "is-longrun": item.isLongrun }]')

          //- 通常の販売かどうか
          template(v-if='!item.isLongrun')
            ps-row(:gutter='15', valign='center', break-point='tablet')
              
              ps-col.left(:span='0')
                .circle
                  p(v-if='item.daysLeft > 0')
                    small 残り
                    br
                    b {{ item.daysLeft }}
                    br
                    small 日
                  p(v-else-if='item.daysLeft === 0 && item.status === "open"')
                    | 本日
                    br
                    | まで
                  p(v-else)
                    | 公開
                    br
                    | 終了

              ps-col.main
                h3 {{ item.name }}
                h4 {{ item.orgName }}
                p.term 公開期間 {{ item.end }} まで
                ul.thumbs(v-if='thumbnailsList[i]')
                  li(
                    v-for='url in thumbnailsList[i]',
                    :key='url',
                    :style='{ backgroundImage: `url(${url})` }'
                  )
                p.status-extra-info(v-if='item.status === "extra"')
                  | ※ {{ item.extraLimit }}までは買い物カゴに追加済みの写真を購入することができます。
              
              ps-col.right(:span='0')
                .buttons
                  // 厳密には、'early'の場合は、'authorizationType' が 2 or 9 の場合のみ表示とすべき
                  ps-button(v-if='item.status === "open" || item.status === "early"', color='orange', @click='resumeBrowsing(item)', v-my-id='`restart-${i}`')
                    | 閲覧を再開 &nbsp;
                    ps-icon(icon='chevron-circle-right')
                  ps-button(v-else, color='purple', @click='resumeCart(item)')
                    | 買い物カゴへ &nbsp;
                    ps-icon(icon='chevron-circle-right')

          //- 年間販売
          template(v-else)
            h3 {{ item.name }}
            h4 {{ item.orgName }}
            p 公開期間 {{ item.endFull }} まで
            ul.sub-sales
              li(v-for='sale in item.subSales', :key='sale.id')
                ps-row(:gutter='15', valign='center', break-point='tablet')
                  ps-col
                    .title
                      span.tag(v-if='sale.isOverdue && item.overdueCharge' color='red') 期間外
                      span.name {{ sale.name }}
                    .due(v-if='item.overdueCharge')
                      | {{ sale.isOverdue ? '標準販売期間は終了しました。' : `標準販売期間 ${sale.due} まで` }}
                    .alert(v-if='sale.isOverdue && item.overdueCharge')
                      | ※ 1枚あたり{{item.overdueCharge}}円(税別)の期間外販売手数料がかかります。
                    .notification(v-else-if='item.overdueCharge')
                      | ※ 標準販売期間を過ぎると1枚あたり{{ item.overdueCharge }}円(税別)の期間外販売手数料がかかります。

                  ps-col.buttons(:span='0')
                    ps-button(
                      color='orange',
                      @click='resumeBrowsing(item, sale.photoStartIndex)'
                    )
                      | 閲覧できます&nbsp;
                      ps-icon(icon='chevron-circle-right')

    // お知らせ
    section.info
      ps-info-container
</template>

<style lang="sass" scoped>
@import '../../../../sass/variables.sass'
@import '../../../../sass/mixins.sass'
.my-page-top

  .mobile-menu
    display: none
    margin-bottom: 1rem
    background-color: $white
    box-shadow: 0 1px 1px 1px rgba(0, 0, 0, .3)
    padding: 5px
    border-radius: $radius-small
    +mobile
      display: block
    a
      display: block
      background-color: lighten(#86bccb, 20%)
      text-align: center
      padding: 5px
      border-radius: $radius-small
      color: $black-bis
      text-decoration: none
      font-size: $size-small
      .icon
        width: 35px
        height: 35px
        margin: 0 auto 0.3rem
        background-position: center center
        background-color: $white
        border-radius: 50%
    .menu-history .icon
      background-image: url('../../../../assets/icon-my-history.png')
    .menu-info .icon
      background-image: url('../../../../assets/icon-my-info.png')
    .menu-child .icon
      background-image: url('../../../../assets/icon-my-child.png')


  .inner

    background-color: $white
    padding: 1.5rem 1rem
    border-radius: $radius-small
    box-shadow: 0 1px 5px 1px rgba(0, 0, 0, .2)
    +mobile
      padding: 1rem 0.5rem
      box-shadow: 0 1px 1px 1px rgba(0, 0, 0, .3)

    > section
      margin-bottom: 3rem

    > .new-sale
      > .info
        text-align: center
        margin-bottom: 1rem
      > .error
        color: $red
        text-align: center
        margin-bottom: 1rem
      > .form
        max-width: 500px
        margin: 0 auto 1rem
      > .buttons
        text-align: center

    > .authorized-sales

      .sales
        > li
          background-color: #fffec5
          border-radius: $radius-small
          padding: 0.5rem
          margin-bottom: 1rem

          .left
            .circle
              min-width: 5rem
              min-height: 5rem
              border-radius: 50%
              background-color: $white
              display: flex
              align-items: center
              justify-content: center
              p
                text-align: center
                color: $red
                line-height: 1.1
                b
                  font-size: $size-large
                  letter-spacing: 2px
              +touch
                min-width: 0
                min-height: 0
                border-radius: $radius
                margin-bottom: 0.4rem
                padding: 0.2rem
                p
                  b
                    font-size: $size-normal
                br
                  display: none

          .main
            h3
              font-weight: normal
              font-size: $size-large
              margin-bottom: 0.4rem
            h4
              font-weight: normal
              font-size: $size-small
              margin-bottom: 0.4rem
            p.term
              color: $red
              font-size: $size-small
              margin-bottom: 0.4rem
            p.status-extra-info
              color: darken($green, 25%)
              font-size: $size-small
            ul.thumbs
              margin-bottom: 0.4rem
              li
                display: inline-block
                width: 2.5rem
                height: 2.5rem
                background-position: top center
                background-size: cover
                border-radius: 3px
                margin-right: 0.3rem
                &:last-child
                  margin-right: 0
                +mobile
                  &:nth-child(n+9)
                    display: none

          .right
            .buttons
              text-align: center
              margin-right: 1rem
              +touch
                margin-right: 0

          &.status-extra
            background-color: $white-bis
            .left
              .circle
                background-color: $white
                p
                  color: $grey-darker

          &.is-longrun
            > h3
              font-weight: normal
              font-size: $size-large
              margin-bottom: 0.2rem
            > h4
              font-weight: normal
              font-size: $size-small
              margin-bottom: 0.2rem
            > p
              font-size: $size-small
              margin-bottom: 0.4rem
            > ul
              background: $white
              border-radius: 3px
              padding: 0.5rem
              > li
                padding: 0.6rem
                &:nth-child(n+2)
                  border-top: 1px dashed brown
                .title
                  font-size: $size-normal
                  .tag
                    display: inline-block
                    background: $red
                    color: $white
                    padding: 0 0.3rem
                    margin-right: 0.3rem
                    border-radius: 3px
                .due
                  font-size: $size-small
                  color: darken($green, 30%)
                .alert
                  font-size: $size-small
                  color: $red
                .notification
                  font-size: $size-small
                  color: darken($blue, 20%)
            .buttons
              text-align: center
              margin-right: 1rem
              +touch
                margin-right: 0
                margin-top: 0.5rem
      // .sales
      //   li
      //     margin-bottom: 1.5rem
      //     background-color: $white
      //     border-radius: $radius
      //     padding: 1rem

      //     // 期間
      //     .term
      //       > p:first-child
      //         background-color: $pink
      //         font-size: $size-small
      //         padding: 0.2rem
      //         text-align: center
      //         border-top-left-radius: $radius
      //         border-top-right-radius: $radius
      //         color: $white
      //         font-size: $size-small
      //         > span
      //           display: block
      //           background-color: $white
      //           padding: 0.2rem
      //           margin: 0.2rem 0
      //           border-radius: $radius-small
      //           color: $red
      //       > p:nth-child(2)
      //         line-height: 2rem
      //         font-size: $size-small
      //         text-align: center
      //         background-color: lighten($yellow, 35%)
      //         color: $red
      //         > span
      //           font-size: $size-large
      //       > p:last-child
      //         border-bottom-left-radius: $radius
      //         border-bottom-right-radius: $radius

      //     &.status-extra
      //       background-color: $white-bis
      //       .term
      //         > p:first-child
      //           background-color: $grey-dark
      //           > span
      //             color: $grey-darker
      //         > p:nth-child(2)
      //           background-color: $grey-lighter
      //           color: $grey-darker


      //     // 詳細
      //     .detail
      //       h3
      //         font-weight: normal
      //         font-size: $size-medium
      //         margin-bottom: 0.3rem
      //       h4
      //         font-weight: normal
      //         color: $grey-darker
      //         margin-bottom: 0.5rem
      //       .thumbs
      //         overflow: hidden
      //         .thumb
      //           float: left
      //           width: 3rem
      //           height: 3rem
      //           margin: 0.1rem
      //           background-color: lighten($blue, 35%)
      //           background-size: cover
      //           background-position: center center
      //           +mobile
      //             &:nth-child(n+9)
      //               display: none
      //       .status-extra-info
      //         margin-top: 0.5rem
      //         font-size: $size-small
      //         color: $red
      //     .restart
      //       padding: 0.5rem 0
</style>
