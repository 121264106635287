// キャンセル確認ダイアログ

<script>
import cancelOrderApiAsync from '@/api/user/cancel-order'

const cancelReasons = [
  { label: '間違った写真を購入したため', value: '1' },
  { label: '追加で欲しい写真が見つかったため', value: '2' },
  { label: '買いすぎてしまったため', value: '3' },
]

export default {
  name: 'CancelConfirmation',

  props: {
    order: {
      required: true,
    },
  },

  data() {
    return {
      // キャンセル理由
      reason: null,
    }
  },

  computed: {
    reasons() {
      return [{ label: '理由を選択してください', value: null }, ...cancelReasons]
    },
  },

  methods: {
    async doCancelAsync() {
      const params = { code: this.order.code }
      const body = { reasonCode: this.reason }
      const response = await cancelOrderApiAsync(params, body)
      this.$psdialog.close({ result: response.ok })
    },
  },
}
</script>

<template lang="pug">

.cancel-confirmation

  p 下記の注文をキャンセルしてよろしいですか。

  .details
    ps-form-item(label='販売イベント')
      pre
        | {{ order.saleName }}
        | {{ order.orgName }}

    ps-form-item(label='注文内容')
      pre
        | 注文日: {{ order.orderedAt }}
        | 注文番号: {{ order.code }}
        | 金額: {{ order.price }}円(税込)
  
  .reasons
    ps-select(:items='reasons', v-model='reason', full-width)

  .buttons
    ps-button(@click='$psdialog.close') 閉じる
    | &nbsp;
    ps-button(color='red', @click='doCancelAsync', :disabled='!reason') キャンセル実行

</template>

<style lang="sass" scoped>

.cancel-confirmation

  > *
    margin-bottom: 0.5rem
    &:last-child
      margin-bottom: 0

  > .details
    margin-bottom: 1rem
    pre
      line-height: 1.2
      padding: 0.5rem 0
      word-break: break-all
      white-space: pre-wrap

  > .buttons
    text-align: center
</style>
