// ルータに呼ばれるハンドラ群

import store from '../store'

// ページ遷移毎に呼ばれるハンドラ
export function beforeEach(route, redirect, next) {
  // ページ遷移毎に先頭にスクロール。scrollBehavior でも可能だが、
  // history モードでのみ動作するので以下の方法を利用
  window.scrollTo(0, 0)
  // iOSにてキーボードが表示されっぱなしになる問題の対策
  if (document.activeElement) {
    document.activeElement.blur()
  }
  // ストアにトークンをロード
  store.dispatch('app/retrieveTokens')
  // 自分の情報を取得
  store
    .dispatch('app/getMeAsync', { silent: true })
    .then(() => next())
    .catch(e => {
      console.log('an exception occured in router before each.', e)
      next(false)
      store.dispatch('app/setError', e)
    })
}

// 遷移確定後に呼ばれるハンドラ。タイトルを付与する
export function afterEach(to) {
  const defaultTitle = '11s.jp'
  if (to.meta.title) document.title = `${to.meta.title} : ${defaultTitle}`
  else document.title = defaultTitle
}

// ロールチェックする関数を返す
export function createRoleChecker(roles, redirectTo) {
  return (to, from, next) => {
    // 自分のロールの取得
    const role = store.getters['app/role']
    // rolesに含まれていなければリダイレクト
    if (roles.indexOf(role) === -1) next(redirectTo)
    else next()
  }
}
