// カート情報用のレスポンスマッパオブジェクト

function _transformItemsToPhotos(items) {
  return _.filter(items, item => item.service_type === 'photo').map(item => {
    return {
      id: item.data.id,
      dataWidth: item.data.width,
      dataHeight: item.data.height,
      index: item.data.index,
      eventIdNotSaleId: item.data.event_id,
      check: item.data.checksum.slice(0, 5),
      // 各サイズのサムネイルのURL(新仕様)
      thumbnailUrls: item.data.thumbnail_url,
      // ルーペ画像取得に使うトークン
      lupeToken: item.data.thumbnail_token,
      // 期間外かどうか
      isOverdue: !!_.get(item, 'opts[0].is_overdue'),
    }
  })
}

function _transformItemsToPhotosMap(items) {
  let map = {}
  _.filter(items, item => item.service_type === 'photo').forEach(item => {
    // 1度カゴに入れて購入していないものも存在するので、それらを除去
    const purchased = _.filter(item.opts, opt => opt.num > 0).map(opt => {
      return { sizeId: opt.size_id, num: opt.num }
    })
    // 空でないなら追加
    if (purchased.length > 0) map[item.data.id] = purchased
  })
  return map
}

function _transformItemsToCustomsMap(items) {
  let map = {}
  // すべての写真アイテムに対して
  _.filter(items, item => item.service_type === 'photo').forEach(item => {
    // カスタム写真のみ取り出し
    const custom = _.filter(item.opts, opt => opt.num > 0 && opt.photo_custom_id)
    if (custom.length > 0) {
      map[item.data.id] = {
        id: item.data.id,
        dataWidth: item.data.width,
        dataHeight: item.data.height,
        index: item.data.index,
        check: item.data.checksum.slice(0, 5),
        customId: _.first(custom).photo_custom_id,
        // トリミングされているか
        trimmed: !!_.get(item, 'data.custom_params.trim'),
        // 日付入れされているか
        dated: !!_.get(item, 'data.custom_params.date'),
        // 旧PhotoSpotでのtrimか
        oldTrim: !!_.get(item, 'data.custom_params.trim.area'),
      }
    }
  })
  return map
}

function _transformItemsToOptionsMap(items) {
  let map = {}
  _.filter(items, item => item.service_type === 'daicolo_goods').forEach(item => {
    const num = _.get(item, 'opts[0].num', 0)
    if (num > 0) map[item.data.id] = num
  })
  return map
}

export default {
  thumbnail_token: 'token',
  items: [
    // 写真表示用の写真リスト
    {
      key: 'photos',
      transform: _transformItemsToPhotos,
    },
    // 購入写真マップ(PhotoIdで検索用)
    {
      key: 'photosMap',
      transform: _transformItemsToPhotosMap,
    },
    // 購入済みオプション販売マップ(PhotoIdで検索用)
    {
      key: 'optionsMap',
      transform: _transformItemsToOptionsMap,
    },
    // カスタム写真マップ(PhotoIdで検索用)
    {
      key: 'customsMap',
      transform: _transformItemsToCustomsMap,
    },
    // 期間外販売の枚数
    {
      key: 'overdueNum',
      transform: items => {
        let overdueNum = 0
        _.forEach(items, item => {
          // そもそも期間外でない場合は除去
          if (!_.get(item, 'opts[0].is_overdue', false)) return
          _.forEach(item.opts, opt => {
            overdueNum += opt.num || 0
          })
        })
        return overdueNum
      },
    },
  ],
  'details[].name': 'details[].name',
  'details[].group': 'details[].subName',
  'details[].num': 'details[].num',
  'details[].sum': 'details[].sum',
  'details[].code': {
    key: 'details[].code',
    transform: values =>
      values.map(value => (value ? value.toUpperCase().replace(/\./g, '_') : '')),
  },
  'details[0].details': {
    key: 'photosSummary',
    transform: items => _.filter(items, item => item.num > 0),
  },
  'stats[0].num': 'num',
  // sumは手数料を含まず、totalは含む
  'stats[0].sum': 'sum',
  'stats[0].total': 'total',
  'stats[1].num': 'photoNum',
  'stats[1].sum': 'photoSum',
  'stats[2].num': 'optionNum',
  'stats[2].sum': 'optionSum',
}
