// PAY.JP用のカード決済フォーム

<template lang="pug">
.payjp-card-form
  //- カードブランド
  ul.card-brands
    li(
      v-for='item in cardBrands',
      :class='[item.code, { deactive: !item.isActive }]',
      :key='item.code'
    )
  
  //- カード番号
  .card-number
    p.caption
      | カード番号&nbsp;
      ps-tag(color='blue') 半角
    //- 入れ子にするのは、iPhone Safari12にて、
    //- focusした際にページ先頭に勝手にスクロールされることへの対策
    .input-outer
      div(:id='cardNumber.id')
    p.error-message(v-if='cardNumber.error') {{ cardNumber.error }}

  //- 有効期限
  .card-expiry
    p.caption
      | 有効期限
      ps-tag(color='blue') 半角
    .input-outer
      div(:id='cardExpiry.id')
    p.error-message(v-if='cardExpiry.error') {{ cardExpiry.error }}

  //- セキュリティコード
  .card-cvc
    p.caption
      | セキュリティコード
      span(v-if='currentCardBrand') {{ currentCardBrand !== 'American Express' ? '(3ケタ)' : '(4ケタ)' }}
      | &nbsp;
      ps-tag(color='blue') 半角
    .input-outer
      div(:id='cardCvc.id')
    p.error-message(v-if='cardCvc.error') {{ cardCvc.error }}
    p.what-is-cvv
      a(@click='$emit("clickWhatIsCvv")')
        ps-icon(icon='question-circle')
        | セキュリティコードとは
</template>

<script>
import * as errorCodes from '@/errors/codes'

const cardBrands = [
  {
    name: 'Visa',
    code: 'visa',
  },
  {
    name: 'MasterCard',
    code: 'master',
  },
  {
    name: 'JCB',
    code: 'jcb',
  },
  {
    name: 'American Express',
    code: 'amex',
  },
  {
    name: 'Diners Club',
    code: 'diners',
  },
  {
    name: 'Discover',
    code: 'discover',
  },
]

// カード番号入力欄
// ID
const cardNumberId = 'payjp-card-form-card-number'
// スタイル
const cardNumberOptions = {
  placeholder: '0000 0000 0000 0000',
  style: {
    base: {
      '::placeholder': {
        color: '#aaa',
      },
    },
  },
}
// カード有効期限入力欄
// ID
const cardExpiryId = 'payjp-card-form-card-expiry'
// スタイル
const cardExpiryOptions = {
  style: {
    base: {
      '::placeholder': {
        color: '#aaa',
      },
    },
  },
}
// カードのセキュリティコード欄
// ID
const cardCvcId = 'payjp-card-form-card-cvc'
// スタイル
const cardCvcOptions = {
  // 空文字にしておかないと、デフォルトで'CVC'と表示されてしまう
  placeholder: '',
  style: {
    base: {
      '::placeholder': {
        color: '#aaa',
      },
    },
  },
}

export default {
  name: 'PayjpCardForm',

  data() {
    return {
      // カード番号
      cardNumber: {
        id: cardNumberId,
        elem: null,
        isOk: false,
        error: null,
      },
      cardExpiry: {
        id: cardExpiryId,
        elem: null,
        isOk: false,
        error: null,
      },
      cardCvc: {
        id: cardCvcId,
        elem: null,
        isOk: false,
        error: null,
      },
      // カード番号柄推測される現在のカードブランド
      currentCardBrand: 'unknown',
    }
  },

  computed: {
    cardBrands() {
      return cardBrands.map(item => ({
        ...item,
        // カード番号から推測されていない状態ならすべてisActive=trueに
        isActive: this.currentCardBrand === 'unknown' ? true : item.name === this.currentCardBrand,
      }))
    },
    isOk() {
      return this.cardNumber.isOk && this.cardExpiry.isOk && this.cardCvc.isOk
    },
  },

  mounted() {
    const elements = payjp.elements()

    // カード番号
    this.cardNumber.elem = elements.create('cardNumber', cardNumberOptions)
    this.cardNumber.elem.mount('#' + this.cardNumber.id)
    this.cardNumber.elem.on('change', this._onChageCardNumber)

    // 有効期限
    this.cardExpiry.elem = elements.create('cardExpiry', cardExpiryOptions)
    this.cardExpiry.elem.mount('#' + this.cardExpiry.id)
    this.cardExpiry.elem.on('change', this._onChageCardExpiry)

    // セキュリティコード
    this.cardCvc.elem = elements.create('cardCvc', cardCvcOptions)
    this.cardCvc.elem.mount('#' + this.cardCvc.id)
    this.cardCvc.elem.on('change', this._onChageCardCvc)
  },

  methods: {
    _onChageCardNumber(e) {
      // 入力しているカードのブランドを設定
      this.currentCardBrand = e.brand
      this.cardNumber.isOk = e.complete
      this.cardNumber.error = e.error?.message
    },
    _onChageCardExpiry(e) {
      this.cardExpiry.isOk = e.complete
      this.cardExpiry.error = e.error?.message
    },
    _onChageCardCvc(e) {
      this.cardCvc.isOk = e.complete
      this.cardCvc.error = e.error?.message
    },
    // トークンの取得。外部アクセス用
    async getToken() {
      try {
        if (!this.isOk) throw new Error()
        const result = await payjp.createToken(this.cardNumber.elem)
        if (result.error) throw new Error()
        return result.id
      } catch (e) {
        throw new Error(errorCodes.ORDER_GENERATE_CART_TOKEN_ERROR)
      }
    },
  },

  watch: {
    isOk(value) {
      this.$emit('updateStatus', value)
    },
  },
}
</script>

<style lang="sass">
@import '../../../sass/variables.sass'
@import '../../../sass/mixins.sass'

.payjp-card-form
  .card-brands
    > li
      display: inline-block
      width: 2rem
      height: 2rem
      background-size: cover
      margin-left: 0.3rem
      &.deactive
        opacity: 0.3
      &.visa
        background-image: url(../../../assets/visa.png)
      &.master
        background-image: url(../../../assets/master.png)
      &.jcb
        background-image: url(../../../assets/jcb.png)
      &.amex
        background-image: url(../../../assets/amex.png)
      &.diners
        background-image: url(../../../assets/diners.png)
      &.discover
        background-image: url(../../../assets/discover.png)

  .what-is-cvv
    margin-top: 0.3rem
    a
      font-size: $size-small
      color: darken($blue, 20%)
      border-bottom: 1px solid darken($blue, 20%)
      cursor: pointer

  .input-outer
    margin-top: 0.3rem
    border: 1px solid #ddd
    // ここにpaddingを設定すると、Safari12にて、入力欄タッチ時に、
    // まれに、ページ先頭にスクロールされてしまう
    background-color: white
    box-shadow: 0 2px 8px 0 rgb(0 0 0 / 15%) inset
    border-radius: 3px
    // safari12対策
    > div
      padding: 0.6rem

  .card-number > .input-outer
    max-width: 16rem
  .card-expiry > .input-outer
    max-width: 8rem
  .card-cvc > .input-outer
    max-width: 8rem

  p.caption
    margin-top: 0.5rem

  // エラーメッセージ
  p.error-message
    font-size: $size-small
    color: $red
    margin-top: 0.3rem
</style>
