// フォトいいね用の支払いフォーム

<template lang="pug">
.iine-payment-form
  ps-form-item(label='お支払い方法', required)
    ul

      //- カード決済
      li
        ps-radio(
          v-if='cardAvailable',
          radio-value='2',
          v-model='payment.id',
          full-width,
          @input='update'
        ) クレジットカード (手数料不要)
          .description(slot='description')
            payjp-card-form(
              @updateStatus='updateCardFormStatus',
              @clickWhatIsCvv='$emit("clickWhatIsCvv")',
              ref='form'
            )

      //- コンビニ決済
      li(style='margin-top: 0.5rem')
        ps-radio(
          v-if='conveniAvailable',
          radio-value='3',
          v-model='payment.id',
          full-width,
          @input='update',
        ) コンビニ決済 (手数料不要)
          .description(slot='description')
            p.caption お支払いするコンビニを選択してください。

            .conveni-group(:class='{ error: getError("conveni.cvs") }')

              ps-radio(
                v-for='item in convenis', :key='item.code',
                v-model='payment.conveniCvs', :radio-value='item.code',
                color='blue', full-width,
                @input='update'
              )
                span.conveni-icon(:class='item.code')
                | {{ item.name }}
</template>

<script>
import PayjpCardForm from './components/payjp-card-form'
import convenis from '@/helpers/convenis'

export default {
  name: 'IinePaymentForm',

  components: {
    PayjpCardForm,
  },

  props: {
    value: {},
    // カード決済が利用可能か
    cardAvailable: {
      type: Boolean,
      default: true,
    },
    // コンビニ決済が利用可能か
    conveniAvailable: {
      type: Boolean,
      default: true,
    },
    errors: {
      default: () => {
        return {}
      },
    },
  },

  data() {
    return {
      payment: {
        // 選択された支払い方法
        id: null,
        cardFormIsOK: false,
        conveniCvs: null,
      },
      convenis,
    }
  },

  created() {
    if (!this.value) return
    this.payment.id = this.value.id
    this.payment.conveniCvs = this.value.conveniCvs
  },

  methods: {
    updateCardFormStatus(isOk) {
      this.payment.cardFormIsOK = isOk
      this.update()
    },
    update() {
      this.$emit('input', _.cloneDeep(this.payment))
    },
    getError(key) {
      let error
      if (key === 'exp') {
        error = _.get(this.errors, 'card.exp_month') || _.get(this.errors, 'card.exp_year')
      } else {
        error = _.get(this.errors, key)
      }
      return error ? error.message : null
    },
    // カード決済用のトークンの取得。外部からの呼び出し用
    getCardToken() {
      if (!this.$refs.form) return Promise.reject()
      return this.$refs.form.getToken()
    },
  },
}
</script>

<style lang="sass">
.iine-payment-form
  .description
    padding: 0.5rem

  .conveni-group
    > *
      margin-bottom: 0.1rem
      .conveni-icon
        display: inline-block
        width: 1.8rem
        height: 1.8rem
        margin-right: 0.5rem
        background-size: cover
        &.seven
          background-image: url(../../assets/seven.gif)
        &.famima
          background-image: url(../../assets/famima.gif)
        &.lawson
          background-image: url(../../assets/lawson.gif)
        &.mini
          background-image: url(../../assets/mini.gif)
        &.circle
          background-image: url(../../assets/circle.gif)
        &.sunkus
          background-image: url(../../assets/sunkus.gif)
</style>
