// PC・タブレット用のヘッダナビゲーション

<template lang="pug">
header.pc-header-nav

  ps-row(valign='center', :gutter='15')

    ps-col(v-if='!sliderVisibility || okaoNow || isLongrun')
      img.logo(src='../../../assets/logo-white.png')
    // 位置表示とジャンプのためのスライダ。なおお顔検索中は表示しない
    ps-col(v-else)
      .slider-outer
        header-slider

    ps-col(:span='0')
      a.menu-item(@click='toggleDisplaySize')
        .icon(:class='`size-${displayPhotoSize}`')
        p 表示サイズ

    ps-col(:span='0')
      a.menu-item(@click='$emit("clickShowMenu")')
        .icon.menu
        p メニュー
</template>

<script>
import { mapGetters } from 'vuex'
import HeaderSlider from './header-slider'
export default {
  name: 'PcHeaderNav',

  components: {
    HeaderSlider,
  },

  computed: {
    ...mapGetters({
      currentPhotoIndex: 'browsing/currentPhotoIndex',
      minPhotoIndex: 'subSale/minPhotoIndex',
      // お顔検索中かどうか
      okaoNow: 'browsing/okaoNow',
      // 表示サイズ(large or small)
      displayPhotoSize: 'browsing/displayPhotoSize',
      // 年間販売かどうか
      isLongrun: 'sale/isLongrun',
    }),
    // PC用のスライダは先頭の写真を表示している時以外は表示
    sliderVisibility() {
      return this.currentPhotoIndex !== this.minPhotoIndex
    },
  },

  methods: {
    // 表示サイズのトグル
    toggleDisplaySize() {
      const size = this.displayPhotoSize === 'large' ? 'small' : 'large'
      this.$store.dispatch('browsing/setDisplayPhotoSize', size)
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../../../sass/variables.sass'
@import '../../../sass/mixins.sass'

.pc-header-nav
  z-index: 80
  position: fixed
  top: 0
  left: 0
  right: 0
  background-color: $orange

  // スライダのサイズを制限。またセンタリング
  .slider-outer
    max-width: 1000px
    margin: 0 auto

  .logo
    display: block
    max-width: 200px
    height: auto

  > *
    padding: 0 10px
    height: 50px

  a.menu-item
    display: block
    cursor: pointer
    transition: all 1s

    .icon
      width: 25px
      height: 25px
      background-repeat: no-repeat
      background-position: center center
      background-size: contain
      margin: 0 auto 1px
      transition: all 1s
      &.size-large
        background-image: url('../../../assets/thumb-large-white.png')
      &.size-small
        background-image: url('../../../assets/thumb-small-white.png')
      &.menu
        background-image: url('../../../assets/menu-white.png')
    p
      font-size: $size-smallest
      line-height: 1
      color: #fff

    &:hover
      opacity: 0.6
      .icon
        transform: rotate(15deg)
</style>
