// 注文情報のアクション

import validateOrderApiAsync from '@/api/sale/validate-order'
import commitOrderApiAsync from '@/api/sale/commit-order'

import * as errorCodes from '@/errors/codes'

import parseSwaggerSchemaError from '../../../helpers/parse-swagger-schema-error'

// 決済会社は決め打ち
const defaultCardPaymentService = 'payjp'

export default {
  async validateAsync(
    { state, getters },
    { saleId, isPouch, isPublic, isIndividual, useNewChildrenInfoSystem }
  ) {
    if (!getters['isSatisfied'](isPouch, isPublic, isIndividual, useNewChildrenInfoSystem)) {
      throw new Error(errorCodes.UNKNOWN_ERROR)
    }

    // APIによるバリデーション
    const body = await _setupBodyAsync(
      state,
      isPouch,
      isPublic,
      isIndividual,
      useNewChildrenInfoSystem
    )
    const response = await validateOrderApiAsync({ id: saleId }, body)
    let errors = {}
    if (!response.ok) {
      if (_.get(response, 'payload.code') === 'SCHEMA_VALIDATION_FAILED') {
        parseSwaggerSchemaError(response.payload, errors)
      } else {
        throw new Error(errorCodes.VALIDATE_ORDER_ERROR)
      }
    }

    // 成功ならnullを返す
    return _.isEmpty(errors) ? null : errors
  },

  // 注文の確定
  // 現状、失敗してfalseを返すのはクレジットカード決済の場合のみ
  async commitAsync(
    { state, dispatch, getters },
    { saleId, isPouch, isPublic, isIndividual, useNewChildrenInfoSystem }
  ) {
    if (!getters['isSatisfied'](isPouch, isPublic, isIndividual, useNewChildrenInfoSystem)) {
      throw new Error(errorCodes.UNKNOWN_ERROR)
    }

    const params = { id: saleId }
    const body = await _setupBodyAsync(
      state,
      isPouch,
      isPublic,
      isIndividual,
      useNewChildrenInfoSystem
    )
    const response = await commitOrderApiAsync(params, body)

    // エラー発生時の処理
    if (!response.ok) {
      // エラーコード
      const errorCode = _.get(response, 'payload.code')
      // カード払いかどうか
      const isCard = parseInt(_.get(state, 'payment.id'), 10) === 2

      // 復帰(リトライ)可能なエラーかそうでないか
      if (_.get(response, 'payload.retryable')) {
        // カード決済
        if (isCard) {
          // 入力内容誤り等
          console.log(errorCode)
          if (
            errorCode === 'SMBCClientError:Invalid' ||
            errorCode === 'CardError:Invalid' ||
            errorCode === 'CardError:Declined'
          ) {
            throw new Error(errorCodes.ORDER_RETRYABLE_CREDIT_ERROR)
          } else if (errorCode === 'CardError:Expired') {
            // 有効期限切れ
            throw new Error(errorCodes.ORDER_RETRYABLE_CREDIT_EXPIRY)
          } else {
            // その他
            throw new Error(errorCodes.ORDER_RETRYABLE_ERROR)
          }
        } else {
          // それ以外の決済
          throw new Error(errorCodes.ORDER_RETRYABLE_ERROR)
        }
      } else {
        // 復帰できないエラーはすべて同じエラーコードを
        throw new Error(errorCodes.ORDER_ERROR)
      }
    }

    dispatch('clearAll')

    // 成功ならペイロードを返す
    return response.payload
  },

  // 情報をクリア
  clearAll({ commit }) {
    commit('setUser', null)
    commit('setChild', null)
    commit('setDestination', null)
    commit('setPayment', { id: '2' })
    commit('setSelectedChildIndex', null)
    commit('setSelectedNewChildIds', [])
    commit('setDestType', 'same')
    commit('setInsufficientMemberOrOverrideOneTime', false)
  },
}

// bodyの作成
async function _setupBodyAsync(state, isPouch, isPublic, isIndividual, useNewChildrenInfoSystem) {
  let body = {}
  const locationTemplate = {
    zipcode: null,
    prefecture: null,
    address1: null,
    address2: null,
    address3: null,
    tel: null,
  }
  if (!isPouch) {
    // 集金袋でない = カード決済 or コンビニ決済
    body.user = _.assign({}, locationTemplate, state.user)
    // カード決済
    if (parseInt(state.payment.id, 10) === 2) {
      body.payment = {
        ...state.payment,
        agentCode: defaultCardPaymentService,
      }
    } else {
      // コンビニ決済
      body.payment = state.payment || {}
    }
  } else {
    body.user = _.pick(state.user, ['name1', 'name2', 'name1Kana', 'name2Kana'])
    body.payment = { id: 1 }
  }
  if (!isPublic) {
    if (useNewChildrenInfoSystem) {
      body.childIds = state.selectedNewChildIds
    } else {
      body.child = state.child || {}
    }
  }
  if (isIndividual && state.destType === 'other') {
    body.destination = _.assign({}, locationTemplate, state.destination)
  }
  console.log('_setupBody', body)
  return body
}
