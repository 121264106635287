// お顔画像の削除

import apiFactory from '../api-factory'

export default apiFactory({
  path: '/my/subs/<%= id %>/faces/<%= faceId %>',
  expectedParams: ['id', 'faceId'],
  method: 'DELETE',
  needAuth: true,
})
