// sizeId からサイズ名を取得

const sizeNameMap = {
  '1': { code: 'LD', name: 'L-DSC' },
  '2': { code: 'L', name: 'L' },
  '3': { code: 'LW', name: 'Lワイド' },
  '4': { code: 'KG', name: 'ハガキ(KG)' },
  '5': { code: '2LD', name: '2L-DSC' },
  '6': { code: '2L', name: '2L' },
  '7': { code: '2LW', name: '2Lワイド' },
  '8': { code: '2LG', name: '2LG' },
  '9': { code: '8PW', name: '8切ワイド' },
  '10': { code: '6PW', name: '6切ワイド' },
  '11': { code: '4PW', name: '4切ワイド' },
  '12': { code: 'PA', name: 'パノラマ' },
  '13': { code: '2L:FR', name: '2Lフレーム付き' },
  '41': { code: 'KT', name: '携帯待受画面' },
  '42': { code: 'PTDL', name: 'データ購入' },
  '43': { code: 'PTDL2', name: 'データ購入' },
  '44': { code: 'PTDL3', name: 'データ購入' },
  '45': { code: 'PTDL4', name: 'データ購入' },
  '46': { code: 'PTDL5', name: 'データ購入' },
  default: { code: 'DEFAULT', name: '写真購入' },
}

export default function(sizeId) {
  return sizeNameMap[sizeId] || sizeNameMap.default
}
