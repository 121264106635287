// ヘルプの内容表示ダイアログ

<script>
import helpDetailApi from '@/api/public/help'

export default {
  name: 'HelpDetail',

  props: {
    id: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      detail: null,
      loaded: false,
    }
  },

  created() {
    this.fetchDetailAsync()
  },

  methods: {
    async fetchDetailAsync() {
      this.loaded = false
      const response = await helpDetailApi({ id: this.id })

      if (!response.ok) {
        this.loaded = true
        return
      }

      this.detail = response.payload
      this.loaded = true
    },
  },
}
</script>

<template lang="pug">
.help-detail

  .loading(v-if='!loaded')
    ps-loading

  template(v-else)
    article.content(v-html='detail.html')
  
  .buttons
    ps-button(@click='$psdialog.close') 閉じる
</template>

<style lang="sass" scoped>
@import '../../../sass/variables.sass'

.help-detail
  > .loading
    height: 200px
    position: relative
  > h1
    border-bottom: 2px dotted lighten($blue, 20%)
    font-size: $size-large
    padding-bottom: 0.2rem
    font-weight: normal
    margin-bottom: 0.5rem
  > .content
    margin-bottom: 1rem
  > .buttons
    text-align: center
</style>

<style lang="sass">
.help-detail
  article.content
    > *
      margin-bottom: 0.5rem
      &:last-child
        margin-bottom: 0
    p
      line-height: 1.4

    ol
      li
        list-style-type: decimal
        margin-left: 2rem
        margin-bottom: 0.2rem
        &:last-child
          margin-bottom: 0
</style>
