// 写真一覧

<template lang="pug">
.photo-list

  .heading
    h3 {{ orgName }}
    h2
      | {{ subSaleName }}
      span(v-if='isOldSale') (過去の販売)
    p(v-if='!isLongrun')
      | 公開期間
      span {{ saleEnd }}
      | まで

  .main

    .new-release(v-if='showNewRelease')
      h3
        b お顔検索機能
        | が追加されました！
      p
        | スマホで撮影した写真など、お手持ちのデータを使ってお子様の写っている写真を検索する機能がつきました！
        | ぜひ
        b お顔検索ボタン
        | を押してお試しください。
      .close(
        @click='setShowNewRelease(false)'
      )
        ps-icon(icon='times')

    // 前を読み込むボタン
    header(v-if='hasPrev')
      a(v-if='lock')
        ps-icon(icon='camera-retro', spin)
        | &nbsp;読み込み中...
      a(v-else, @click='loadPrevAsync')
        ps-icon(icon='arrow-circle-up')
        | &nbsp;前を表示

    // 写真一覧本体
    // 複数の仮想的なページを持つ
    section.pages(ref='pages')
      ps-photo-page(
        v-for='(page, i) in pages',
        :items='page.items',
        :size='displayPhotoSize',
        :dirty='isDirty(i)',
        :key='page.key',
        :cartMap='cartPhotosMap',
        :customsMap='customsMap',
        :verbose='byShopConsole',
        add-to-cart-button-color='orange',
        @clickPhoto='$emit("clickPhoto", arguments[0])',
        @addToCart='addToCart(arguments[0])',
        @removeFromCart='removeFromCart(arguments[0])',
        v-my-id='`photos-page-${i}`',
        :overdue-charge='overdueCharge'
      )

    // 後ろを読み込むボタン
    footer(v-if='pages.length > 0')
      a.last-photo(v-if='!hasNext')
        ps-icon(icon='info-circle')
        | &nbsp;最後の写真です
      a(v-else-if='lock')
        ps-icon(icon='camera-retro', spin)
        | &nbsp;読み込み中...
      a(v-else, @click='this.loadNextAsync')
        ps-icon(icon='arrow-down')
        | &nbsp;続きを表示
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

// 年間販売についての説明ダイアログ
import AddOverdueToCartDialog from '@/dialogs/contents/add-overdue-to-cart'

const fetchNum = parseInt(process.env.VUE_APP_PHOTO_FETCH_NUM || 24, 10)
const fetchBottomThreshold = parseInt(process.env.VUE_APP_FETCH_BOTTOM_THRESHOLD || 2000, 10)
const garbageCollectThreshold = parseInt(process.env.VUE_APP_GARBAGE_COLLECT_THRESHOLD || 2000, 10)

// 次の写真群を読み込んだ回数(loadNextAsyncした回数)
// google analyticsへのイベント通知に利用
let loadNextCount = 0

export default {
  name: 'PhotoList',

  props: {},

  data() {
    return {
      pages: [],
      unsubscribe: null,
      // 前に読み込む写真の開始番号
      prevIndex: -1,
      // 次に読み込む写真の開始番号
      nextIndex: null,
      // 読み込みロック
      lock: false,
      // 削除対象のページの最大index
      dirtyMaxIndex: -1,
    }
  },

  computed: {
    ...mapGetters({
      orgName: 'sale/orgName',
      saleEnd: 'sale/end',
      cartPhotosMap: 'cart/photosMap',
      customsMap: 'cart/customsMap',
      subSaleName: 'subSale/name',
      isOldSale: 'subSale/isOldSale',
      maxPhotoIndex: 'subSale/maxPhotoIndex',
      minPhotoIndex: 'subSale/minPhotoIndex',
      getSizePricesByIndex: 'subSale/getSizePricesByIndex',
      displayPhotoSize: 'browsing/displayPhotoSize',
      currentPhotoIndex: 'browsing/currentPhotoIndex',
      // 写真館管理から来たかどうか
      // 写真一覧で価格情報を表示するかどうかの判定に使用
      byShopConsole: 'sale/byShopConsole',
      // 年間販売かどうか
      isLongrun: 'sale/isLongrun',
      // 年間販売の期間外販売手数料
      overdueCharge: 'sale/overdueCharge',
      // お顔検索が利用可能か
      canSearchFaces: 'sale/canSearchFaces',
      // 年間販売の説明ダイアログを表示するか
      showFirstTimeAddOverdueToCartDialog: 'browsing/showFirstTimeAddOverdueToCartDialog',
      screenType: 'app/screenType',
      // ニューリリース情報を表示するかどうか
      _showNewRelease: 'app/showNewRelease',
    }),
    hasPrev() {
      if (!this.prevIndex || !this.minPhotoIndex) return false
      return this.prevIndex >= this.minPhotoIndex
    },
    hasNext() {
      if (!this.nextIndex || !this.maxPhotoIndex) return false
      return this.nextIndex <= this.maxPhotoIndex
    },
    // ニューリリース情報を表示するかどうか
    // とりあえず2020/9月までは表示
    showNewRelease() {
      if (!this.canSearchFaces || !this._showNewRelease) return false
      const d = new Date()
      return d.getFullYear() === 2020 && d.getMonth() + 1 < 10
    },
  },

  created() {
    // すでにcurrentPhotoIndexがあればinitしておく
    if (this.currentPhotoIndex) this.init()
    this.unsubscribe = this.$store.subscribe(this.subscriber)
    this.$onBodyScroll(this.onScroll)
    this.$onBodyScrollEnd(this.onScrollEnd)
  },

  methods: {
    ...mapActions({
      getPhotosAsync: 'subSale/getPhotosAsync',
      setCurrentPhotoIndex: 'browsing/setCurrentPhotoIndex',
      addPhotoToCartAsync: 'cart/addPhotoAsync',
      removePhotoFromCartAsync: 'cart/removePhotoAsync',
      setError: 'app/setError',
      setShowNewRelease: 'app/setShowNewRelease',
    }),
    subscriber(mutation) {
      if (mutation.type !== 'browsing/setCurrentPhotoIndex') return
      this.init()
    },
    // currentPhotoIndex を先頭に初期化
    init() {
      this.nextIndex = this.currentPhotoIndex
      this.pages = []
      this.lock = false
      this.dirtyMaxIndex = -1
      this.loadNextAsync()
    },
    onScroll(e, info) {
      if (info.scrollBottom < fetchBottomThreshold) {
        this.loadNextAsync()
      }
    },
    async loadPrevAsync() {
      if (!this.hasPrev) return
      if (this.lock) return
      this.lock = true

      const num = fetchNum
      const from = this.prevIndex
      let payload
      try {
        payload = await this.getPhotosAsync({ num, from, reverse: true, silent: true })
      } catch (e) {
        this.setError(e)
        return
      }

      // APIアクセスに失敗
      if (!payload) {
        this.lock = false
        return
      }

      this.prevIndex = payload.prevIndex

      if (payload.items.length > 0) {
        this.$saveScrollBodyPosition()
        this.pages.unshift({ items: payload.items, key: `${from}-${num}` })
        this.$nextTick(() => {
          this.$restoreScrollBodyPositionAsync()
        })
      }

      this.lock = false
    },
    async loadNextAsync() {
      if (!this.hasNext) return
      if (this.lock) return
      this.lock = true

      // 最初の読み込みかどうか
      const firstTime = this.pages.length === 0

      // 最初の読み込みだけ通常の2倍
      const num = firstTime ? fetchNum * 2 : fetchNum
      const from = this.nextIndex
      let payload
      try {
        payload = await this.getPhotosAsync({ num, from, silent: true })
      } catch (e) {
        this.setError(e)
        return
      }

      // APIアクセスに失敗
      if (!payload) {
        this.lock = false
        return
      }

      // 初回ならprevIndexも更新
      if (firstTime) this.prevIndex = payload.prevIndex
      this.nextIndex = payload.nextIndex

      if (payload.items.length > 0) {
        this.pages.push({ items: payload.items, key: `${from}-${num}` })
      }

      this.lock = false

      // Google Analyticsが有効であれば、追加読み込みを3回に1回通知
      // 実際の目的はanalyticsのリアルタイムの値を正しい物にするため
      if (this.$ga && ++loadNextCount % 3 === 0) {
        this.$ga.event('photos', 'load next', loadNextCount)
      }
    },
    onScrollEnd() {
      const children = this.$refs.pages.children
      if (children.length === 0) return

      // dirtyなページが表示された
      if (this.dirtyMaxIndex >= 0) {
        const bbox = children[this.dirtyMaxIndex].getBoundingClientRect()
        if (bbox.bottom >= 0) {
          this.init()
          return
        }
      }

      // 各ページ要素
      let i = this.dirtyMaxIndex + 1
      for (; i < children.length; ++i) {
        const bbox = children[i].getBoundingClientRect()
        if (bbox.bottom < -garbageCollectThreshold) {
          this.dirtyMaxIndex = i
          // 一応nullにしておくことで、ガベージコレクトの対象となることを期待
          // 実際にメモリ使用量が減るかは未検証
          this.pages[i].items = null
        } else if (bbox.bottom > 0) break
      }
      // ページ要素内の各アイテム
      // 根本的な原因はつかめていないが、children[i]がIE11にて
      // undefinedな場合がまれに発生する。その場合には空リストを返すように修正。
      const grandson = children[i] ? children[i].children : []
      for (let j = 0; j < grandson.length; ++j) {
        const bbox = grandson[j].getBoundingClientRect()
        // ページ内に条件を満たすものがない場合もある
        if (bbox.top >= 0 && this.pages[i].items[j].index) {
          this.setCurrentPhotoIndex({
            index: this.pages[i].items[j].index,
            silent: true,
          })
          break
        }
      }
    },

    // ページがdirtyかどうか
    isDirty(i) {
      return i <= this.dirtyMaxIndex
    },

    async addToCart(item) {
      // 期間外か
      if (this.isLongrun && this.overdueCharge && item.isOverdue) {
        if (this.showFirstTimeAddOverdueToCartDialog) {
          await this.showDialogForLongrun()
          // 一度表示したのでフラグをfalseに
          this.$store.commit('browsing/setShowFirstTimeAddOverdueToCartDialog', false)
        }
      }
      try {
        await this.addPhotoToCartAsync({
          photoId: item.id,
          num: 1,
          sizeId: item.sizePrices[0].sizeId,
          silent: true,
        })
      } catch (e) {
        this.setError(e)
      }
    },

    async removeFromCart(item) {
      try {
        await this.removePhotoFromCartAsync({ photoId: item.id, silent: true })
      } catch (e) {
        this.setError(e)
      }
    },

    showDialogForLongrun() {
      return new Promise(resolve => {
        this.$psdialog.open({
          title: '期間外販売について',
          component: AddOverdueToCartDialog,
          props: {
            overdueCharge: this.overdueCharge,
          },
          size: 's',
          closeByClickBg: true,
          closeButton: true,
          onClose: () => {
            resolve()
          },
        })
      })
    },
  },

  beforeDestroy() {
    this.$offBodyScrollAll()
    this.unsubscribe()
  },
}
</script>

<style lang="sass" scoped>
@import '../../../sass/variables.sass'
@import '../../../sass/mixins.sass'
$brown: darken($orange, 40%)

.photo-list

  > .heading
    line-height: 1.5
    padding: 0.8rem 0.5rem
    background-color: rgba($white, .75)
    h3
      font-size: $size-normal
      font-weight: normal
    h2
      font-size: $size-medium
    p
      font-size: $size-small
      color: $red
      span
        display: inline-block
        margin: 0 5px
        padding: 0 2px
        background-color: $red
        color: $white
        border-radius: $radius-small

  > .main
    padding: 10px

    > .new-release
      background: #7dddec
      margin: 0 -4px 10px
      border-radius: $radius
      padding: 1rem 1rem 1rem 90px
      background-image: url(../../../assets/new-mark.png)
      background-repeat: no-repeat
      background-position: left center
      background-size: 80px
      position: relative
      +tablet
        margin: 0 0 10px
      +mobile
        margin: 0 4px 10px
        background-size: 60px
        padding: 0.5rem 0.5rem 0.5rem 60px
      h3
        font-weight: normal
        b
          color: $black
          letter-spacing: 0.1rem
          font-weight: bold
      p
        margin-top: 0.3rem
        color: #4F2E00
        font-size: $size-small
        line-hegiht: 1.5
      .close
        cursor: pointer
        position: absolute
        top: -5px
        right: -5px
        border-radius: 50%
        background: $grey
        width: 1.8rem
        height: 1.8rem
        text-align: center
        color: $white
        line-height: 1.8rem
        user-select: none

    > header, > footer
      a
        display: block
        color: $black-ter
        border: 1px solid rgba(#3e342a, 0.2)
        background-color: rgba(#3e342a, 0.15)
        padding: 0.3rem
        border-radius: $radius-small
        text-align: center
        margin: 10px 0
        cursor: pointer

        // 最後の写真
        &.last-photo
          border: 1px solid rgba($pink, 0.6)
          background-color: rgba($pink, 0.15)
          color: $red
</style>
