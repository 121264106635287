// 日付選択ダイアログ

<script>
export default {
  name: 'DateSelector',
  props: {
    date: String,
  },
  data() {
    return {
      value: this.date,
    }
  },
}
</script>

<template lang="pug">
.date-selector
  .main
    ps-calendar(v-model='value')
  .buttons
    ps-button(@click='$psdialog.close') 戻る
    ps-button(color='green', large-width, @click='$psdialog.close(value)') 決定
</template>

<style lang="sass" scoped>
.date-selector
  > .main
    margin-bottom: 0.5rem
  > .buttons
    text-align: center
    > :first-child
      margin-right: 1rem
</style>
