// パスワードを忘れた方向けのリマインダメールの送信

import apiFactory from '../api-factory'

export default apiFactory({
  path: '/my/password/reset',
  method: 'POST',
  bodyMapper: {
    email: 'email',
  },
  responseMapper: {
    token: 'token',
  },
})
