// アプリケーションの基本情報

// ステート
const state = {
  // 自分の情報
  user: null,
  // アクセストークン
  token: null,
  // リフレッシュトークン
  refresh: null,
  // 画面のタイプ
  screenType: null,
  // 現在発生しているエラーのエラーコード
  error: null,
  // APIアクセス中の数
  pendings: 0,
  // ニューリリース情報（現時点ではお顔検索の宣伝)を表示するかどうか
  showNewRelease: true,
}

// ゲッタ
const getters = {
  // ユーザ情報全体
  user(state) {
    return state.user
  },
  // アクセストークン
  token(state) {
    return state.token
  },
  // リフレッシュトークン
  refresh(state) {
    return state.refresh
  },
  // 自分のロール
  role(state) {
    return _.get(state.user, 'role', 'public')
  },
  // 名前
  name(state) {
    return _.get(state.user, 'name')
  },
  // メールアドレス
  email(state) {
    return _.get(state.user, 'email')
  },
  // ユーザId
  userId(state) {
    return _.get(state.user, 'id')
  },
  // 画面タイプ
  screenType(state) {
    return state.screenType
  },
  // 現在発生しているエラー
  error(state) {
    return state.error
  },
  // APIのレスポンス待ちがどうか
  pending(state) {
    return state.pendings > 0
  },
  // ニューリリース情報の表示
  showNewRelease(state) {
    return state.showNewRelease
  },
}

// ミューテーション
const mutations = {
  setUser(state, payload) {
    state.user = payload
  },
  setToken(state, payload) {
    state.token = payload
  },
  setRefresh(state, payload) {
    state.refresh = payload
  },
  setScreenType(state, payload) {
    state.screenType = payload
  },
  setError(state, payload) {
    state.error = payload
  },
  setPendings(state, payload) {
    state.pendings = payload
  },
  setShowNewRelease(state, payload) {
    state.showNewRelease = payload
  },
}

export default { state, getters, mutations }
