// カート以降のページ

<template lang="pug">
  .outro-page

    header
      common-header(@clickShowMenu='menuVisibility = true')

    .steps(v-if='currentStepNum')
      ps-container
        ps-steps(:steps='steps', :current='currentStepNum')

    section
      ps-container
      
        router-view

    common-footer.footer

    // メニュー
    slide-in-menu(v-if='menuVisibility', @clickBg='menuVisibility = false')
</template>

<script>
import { mapGetters } from 'vuex'

const steps = ['情報入力', '内容確認', '注文完了']
const stepNums = { OutroPageRegister: 1, OutroPageConfirm: 2, OutroPageCompleted: 3 }

export default {
  name: 'OutroPage',

  data() {
    return {
      steps,
      menuVisibility: false,
    }
  },

  created() {
    console.log('root', this.$route.name)
  },

  computed: {
    ...mapGetters({
      // マイページへのリンクボタンを表示するかの判定用
      role: 'app/role',
      screenType: 'app/screenType',
    }),
    currentStepNum() {
      const num = stepNums[this.$route.name]
      if (!num) return 0
      return this.skippingOption ? num - 1 : num
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../../sass/variables.sass'
@import '../../sass/mixins.sass'

.outro-page

  > header
    .buttons
      > :first-child
        margin-right: 10px
        +mobile
          display: none

  > .steps
    background-color: $white
    padding: 0.5rem 0
    margin-bottom: 1.5rem

  > section
    margin-bottom: 5rem

  .footer
    position: absolute
    bottom: 0
    left: 0
    right: 0
    // 注文手続きボタンと重なるので表示しない
    +touch
      display: none
</style>
