// 注文した写真の一覧の取得

import apiFactory from '../api-factory'

import getSizeInfo from '../../helpers/get-size-info'

export default apiFactory({
  path: '/my/orders/<%= code %>/items',
  method: 'GET',
  expectedParams: ['code'],
  needAuth: true,
  responseMapper: {
    thumbnail_token: 'token',
    thumbnail_base_url: 'thumbnailBaseUrl',
    items: {
      key: 'photos',
      transform: _transform,
    },
  },
})

function _transform(items) {
  return _.filter(items, item => item.service_type === 'photo').map(item => _transformPhoto(item))
}

// ここの写真アイテムを変換
function _transformPhoto(item) {
  // 通常、dataとoptsがないことはありえない
  if (!item.data || !item.opts) return null
  return {
    id: item.data.id,
    index: item.data.index,
    customId: _.get(item, 'opts[0].photo_custom_id'),
    trimmed: _.has(item, 'data.custom_params.trim'),
    dated: _.has(item, 'data.custom_params.date'),
    eventIdNotSaleId: item.data.event_id,
    width: item.data.width,
    height: item.data.height,
    orientation: item.data.width >= item.data.height ? 'landscape' : 'portrait',
    details: item.opts
      .map(opt => {
        return _.assign({ sizeId: opt.size_id, num: opt.num }, getSizeInfo(opt.size_id))
      })
      .filter(value => value.num > 0),
    thumbnailUrl: item.data.thumbnail_url || null,
  }
}
