// メールアドレス変更ダイアログ

<script>
import { mapActions } from 'vuex'

import changeEmailApiAsync from '@/api/user/change-email'

export default {
  name: 'ChangeEmail',

  data() {
    return {
      newEmail: null,
      error: null,
      // メール送信済みかどうか
      aleradySent: false,
    }
  },

  methods: {
    ...mapActions({
      setError: 'app/setError',
    }),

    // メールアドレスの変更を確定するための
    // アクティベーションメールの送信
    async updateEmailAsync() {
      // エラーはクリア
      this.error = null

      let response
      try {
        response = await changeEmailApiAsync(null, { email: this.newEmail })
      } catch (e) {
        this.setError(e)
        return
      }

      if (!response.ok) {
        this.error = 'メールアドレスが正しくありません。'
      } else {
        this.aleradySent = true
      }
    },
  },
}
</script>

<template lang="pug">
.change-email

  // tokenがない = メールアドレス入力前
  template(v-if='!aleradySent')

    p.description 新しいメールアドレスを入力してください。
    .form
      ps-form-item(label='新しいメールアドレス', hankaku, required, :error='error')
        ps-input(full-width, type='email', v-model='newEmail')

    .buttons
      ps-button(@click='$psdialog.close') 閉じる
      ps-button(
        color='green',
        :disabled='!newEmail',
        @click='updateEmailAsync'
      ) 確認メール送信

  // トークンがある = メールアドレスを入力してメール送信した
  template(v-else)
    p.email 「{{ newEmail }}」宛に確認メールを送信しました。

    p.description
      | メール本文のリンクから変更の手続きを行なってください。
      br
      | ※メールが受信できない場合は、メールの受信設定を確認して、初めからやり直して下さい。

    .buttons
      ps-button(@click='$psdialog.close') 閉じる


</template>

<style lang="sass" scoped>
@import '../../../sass/variables.sass'

.change-email

  > .description
    margin-bottom: 0.5rem

  > .email
    margin-bottom: 0.5rem

  > .error-message
    color: $red
    margin-bottom: 0.5rem

  > .form
    margin-bottom: 1rem
    &.confirm
      text-align: center

  > .buttons
    text-align: center
    > :first-child
      margin-right: 1rem
</style>
