// お顔画像の位置調整・トリミングを行うダイアログ

<template lang="pug">
.face-trimmer
  p 赤枠の太線の中にお顔が入るように「移動」「拡大」「回転」により調整してください。
  .trimmer
    face-photo-trimmer(
      :file='file',
      :rotate='rotate',
      :scale='scale',
      ref='trimmer'
    )
  .operations
    .zoom
      ps-row(:gutter='10', valign='center')
        ps-col
          p 拡大・縮小
        ps-col
          ps-button(color='blue', full-width, text-centered, @click='zoomOutPhoto')
            ps-icon(icon='minus')
        ps-col
          ps-button(color='blue', full-width, text-centered, @click='zoomInPhoto')
            ps-icon(icon='plus')
    .rotate
      ps-row(:gutter='10', valign='center')
        ps-col
          p 90度回転
        ps-col
          ps-button(color='pink', full-width, text-centered, @click='rotate90Anticlockwise')
            ps-icon(icon='undo')
        ps-col
          ps-button(color='pink', full-width, text-centered, @click='rotate90Clockwise')
            ps-icon(icon='redo')

    .buttons
      ps-button(@click='$psdialog.close') キャンセル
      ps-button(@click='ok', color='green', large-width) 確定
</template>

<script>
import FacePhotoTrimmer from '../../../components/face-photo-trimmer'

export default {
  name: 'FacePhotoAdjuster',

  components: {
    FacePhotoTrimmer,
  },

  props: {
    file: {
      required: true,
    },
  },

  data() {
    return {
      rotate: 0,
      scale: 1.0,
    }
  },

  methods: {
    // ズームイン
    zoomInPhoto() {
      this.scale += 0.2
    },

    // ズームアウト
    zoomOutPhoto() {
      if (this.scale - 0.2 < 1.0) this.scale = 1.0
      else this.scale -= 0.2
    },

    rotate90Clockwise() {
      this.rotate = (this.rotate + 90) % 360
    },

    rotate90Anticlockwise() {
      this.rotate = (this.rotate - 90 + 360) % 360
    },

    // 調整をリセット
    reset() {
      this.rotate = 0
      this.scale = 1.0
      if (this.$refs.trimmer) this.$refs.trimmer.resetPosition()
    },

    async ok() {
      const blobImage = await this.$refs.trimmer.getImageAsync()
      this.$psdialog.close({
        image: blobImage,
      })
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../../../sass/variables.sass'

.face-trimmer
  > p
    font-size: $size-small
    line-height: 1.1
  .trimmer
    max-width: 260px
    margin: 0.75rem auto 0
    border: 4px solid #8ed6d4
  .operations
    max-width: 300px
    margin: 0 auto
    .zoom
        margin-top: 0.5rem
    .rotate
        margin-top: 0.5rem
  .buttons
    margin-top: 0.75rem
    text-align: center
    > *
      margin: 0.5rem
</style>
