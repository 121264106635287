// エラー
// typeは error, warning, info がある。
// また、descriptionはサポート向け概要
// approachは対処方法

import * as codes from './codes'

export default {
  // 認証関連のエラー

  [codes.API_ACCESS_ERROR]: {
    message: `通信エラーが発生しました。再読み込みを行ってください。
      改善されない場合は、少し時間をおいてからアクセスしてください。`,
    type: 'warning',
    code: '1001',
    description:
      'サーバ側が混み合っている、またはお客様の回線の都合等により、一時的に通信ができない状態の場合に表示されます。',
    approach:
      '少し時間を置いて再度試してもらうと改善される可能性が高いです。また、改善されない場合、サーバ側に問題がある可能性があります。',
  },

  [codes.API_AUTH_ERROR]: {
    message: '認証エラーが発生しました。<br>お手数ですがトップページより再度お試しください。',
    type: 'error',
    code: '1002',
    description:
      'ブラウザに保存されている認証情報(ログインを維持するための情報)が正しくないか、期限切れの可能性があります。',
    approach: 'トップページに戻っていただき、再度ログインする必要があります。',
  },

  [codes.API_PARAMS_ERROR]: {
    message: `通信エラーが発生しました。再読み込みを行ってください。
      改善されない場合は、少し時間をおいてからアクセスしてください。`,
    type: 'warning',
    code: '1003',
    description: 'サーバとの通信に必要な情報の不備によるエラーの場合に表示されます。',
    approach: '画面側のプログラムの不具合の可能性があります。',
  },

  // ユーザ関連

  [codes.GET_USER_INFO_ERROR]: {
    message: 'ユーザー情報の取得に失敗しました。',
    type: 'warning',
    code: '2001',
    description: '登録済みのお名前等の情報の取得に失敗した場合に表示されます。',
    approach: 'サーバ側の不具合の可能性があります。',
  },

  [codes.GET_CHILDREN_ERROR]: {
    message: 'お子様(参加者)の一覧の取得に失敗しました。',
    type: 'warning',
    code: '2002',
    description: 'お子様(参加者)のリストの取得に失敗した場合に表示されます。',
    approach: 'サーバ側の不具合の可能性があります。',
  },

  [codes.ALREADY_REGISTERED_PASSWORD_ERROR]: {
    message: 'パスワードの登録に失敗しました。',
    type: 'warning',
    code: '2003',
    description: '何らかの理由によりパスワード登録に失敗した場合に表示されます。',
    approach: 'サーバ側の不具合の可能性があります。',
  },

  // パスワード登録時の例外。メッセージは暫定的
  [codes.UNKNOWN_REGISTERED_PASSWORD_ERROR]: {
    message:
      'すでにパスワード登録済みです。<br>お手数ですがトップページの「ログイン」からお進みください。',
    type: 'error',
    code: '2004',
    description:
      'パスワード登録後に、メールアクティベーション用のリンクより再度閲覧を進めた場合に表示されます。',
    approach: 'トップページから、以前登録したIdとパスワードを入力する必要があります。',
  },

  // メールアクティベーションコードがすでに使用済み
  [codes.ALREADY_USED_ACTIVATION_CODE_ERROR]: {
    message:
      '使用済みの確認コードです。<b>「やり直す」</b>を押すか、パスワード登録済みの方はトップページの<b>ログイン</b>を押してください。',
    type: 'info',
    code: '2005',
    description:
      'メールアクティベーション済みの確認コード(3ケタ)で再度アクティベーションしようとした際に表示されます。',
    approach:
      'もう一度、アクティベーション用のメールを再送するか、トップページのログインを利用してください。',
  },

  // 販売関連のエラー

  [codes.GET_SALE_INFO_ERROR]: {
    message: 'この販売の情報の取得に失敗しました。',
    type: 'error',
    code: '3001',
    description: '閲覧中の販売の基本情報の取得に失敗した際に表示されます。',
    approach: 'サーバ側の不具合の可能性があります。',
  },

  [codes.SALE_IS_OUT_OF_TERM]: {
    message: 'この販売は準備中または販売期間が終了しています。',
    type: 'error',
    code: '3002',
    description:
      '販売期間外にブラウザの履歴などから、閲覧期間の販売を表示しようとした際に表示されます。',
    approach: '販売期間外なので閲覧することはできません。',
  },

  [codes.GET_SUB_SALE_INFO_ERROR]: {
    message: 'この販売の情報の取得に失敗しました。',
    type: 'warning',
    code: '3003',
    description: '閲覧中の販売の詳細情報の取得に失敗した際に表示されます。',
    approach: 'サーバ側の不具合の可能性があります。',
  },

  [codes.GET_SIZE_PRICE_ERROR]: {
    message: 'この販売の情報の取得に失敗しました。',
    type: 'warning',
    code: '3004',
    description: '閲覧中の販売の価格とサイズの情報の取得に失敗した際に表示されます。',
    approach: 'サーバ側の不具合の可能性があります。',
  },

  [codes.GET_PAYMENT_METHODS_ERROR]: {
    message: 'この販売の情報の取得に失敗しました。',
    type: 'warning',
    code: '3005',
    description: '閲覧中の販売の支払方法の取得に失敗した際に表示されます。',
    approach: 'サーバ側の不具合の可能性があります。',
  },

  // 画像処理関連のエラー

  [codes.TRIM_ERROR]: {
    message: '写真のトリミングに失敗しました。',
    type: 'warning',
    code: '4001',
    description: 'トリミング処理に失敗した際に表示されます。',
    approach: 'サーバ側のトリミング処理部分に不具合が発生している可能性があります。',
  },

  [codes.DISCARD_TRIM_ERROR]: {
    message: 'トリミングの解除に失敗しました。',
    type: 'warning',
    code: '4002',
    description: 'トリミングの解除処理に失敗した際に表示されます。',
    approach: 'サーバ側のトリミング処理部分に不具合が発生している可能性があります。',
  },

  [codes.DATE_ERROR]: {
    message: '写真の日付入れに失敗しました。',
    type: 'warning',
    code: '4003',
    description: '写真への日付入れ処理に失敗した際に表示されます。',
    approach: 'サーバ側の日付入れ処理部分に不具合が発生している可能性があります。',
  },

  [codes.DISCARD_DATE_ERROR]: {
    message: '日付入れの取り消しに失敗しました。',
    type: 'warning',
    code: '4004',
    description: '写真への日付入れの取り消し処理に失敗した際に表示されます。',
    approach: 'サーバ側の日付入れ処理部分に不具合が発生している可能性があります。',
  },

  // カゴ関連のエラー

  [codes.GET_CART_INFO_ERROR]: {
    message: `買い物カゴの情報の取得に失敗しました。<br>
      誠にお手数ですが、弊社までご連絡くださいますようお願い申し上げます。`,
    type: 'warning',
    code: '5001',
    description: '買い物カゴに入っている商品のリストの取得に失敗した際に表示されます。',
    approach: 'サーバ側の不具合の可能性があります。',
  },

  [codes.UPDATE_CART_ERROR]: {
    message: '買い物カゴへの商品の追加・削除に失敗しました。',
    type: 'warning',
    code: '5002',
    description: '買い物カゴへの商品の追加や削除に失敗した際に表示されます。',
    approach: 'サーバ側の不具合の可能性があります。',
  },

  [codes.UPDATE_OPT_ERROR]: {
    message: 'オプション販売商品の追加・削除に失敗しました。',
    type: 'warning',
    code: '5003',
    description: 'オプション販売商品の買い物カゴへの追加や削除に失敗した際に表示されます。',
    approach: 'サーバ側の不具合の可能性があります。',
  },

  [codes.ADD_OPT_LIMIT_ERROR]: {
    message: `
      オプション販売商品の購入数の上限に達したため、カゴに追加できませんでした。
      フォトリコ本体は合計12冊まで、フォトリコ・ポケットシートは合計4冊まで購入可能となっております。
    `,
    type: 'info',
    code: '5004',
    description: 'オプション販売商品の購入点数の上限に達した際に表示されます。',
    approach: '購入上限までしかご購入いただけません。',
  },

  [codes.VALIDATE_ORDER_ERROR]: {
    message: '注文情報の内容チェック中にエラーが発生しました。',
    type: 'warning',
    code: '6001',
    description: '注文情報が正しいかをチェックする処理で予期せぬエラーが発生した際に表示されます。',
    approach: 'サーバ側の不具合の可能性があります。',
  },

  [codes.ORDER_GENERATE_CART_TOKEN_ERROR]: {
    message: 'クレジットカードの情報に誤りがある可能性があります。ご確認をお願いいたします。',
    type: 'info',
    code: '6005',
    description: 'クレジットカードの情報に誤りがある可能性があります。',
    approach: '',
  },

  [codes.ORDER_RETRYABLE_CREDIT_ERROR]: {
    message: `
      クレジットカード決済に失敗しました。<br>お手数ですが、「内容修正」ボタンを押して、
      カード番号・有効期限を再度ご確認ください。
    `,
    type: 'info',
    code: '6002',
    description:
      'クレジットカード決済時に、カード情報の誤りや与信枠確保の失敗など何らかのエラーが発生した際に表示されます。',
    approach:
      'カード番号等の情報に誤りがないかを確認の上、再入力いただくか、別のカードや別の支払い方法をお試しください。',
  },

  [codes.ORDER_RETRYABLE_CREDIT_EXPIRY]: {
    message: `クレジットカードの有効期限切れです。お手数ですが別のカードや別の支払い方法をお試しください。`,
    type: 'info',
    code: '6005',
    description: 'クレジットカードの有効期限が切れている際に表示されます。',
    approach: '別のカードや別の支払い方法をお試しください。',
  },

  [codes.ORDER_RETRYABLE_ERROR]: {
    message: `
      注文確定時に通信エラーが発生しました。再度お試しいただいても同じエラーが出る場合は、
      誠にお手数ですが、弊社までご連絡くださいますようお願い申し上げます。`,
    type: 'warning',
    code: '6003',
    description: '決済処理前にデータベースにエラーが発生した場合等に表示されます。',
    approach:
      'データベースが混み合ってエラーが発生したか、サーバ側に不具合がある可能性があります。',
  },

  [codes.ORDER_ERROR]: {
    message: `<b>注文確定時にエラーが発生しました。</b><br>
      決済処理が正しく行われなかった可能性があります。<br>
      誠にお手数ですが、弊社までご連絡くださいますようお願い申し上げます。`,
    type: 'error',
    code: '6004',
    description: '注文の確定処理に失敗した際に表示されます。',
    approach: 'サーバ側の不具合の可能性があります。',
  },

  // 注文履歴関連

  [codes.GET_ORDER_DETAIL_ERROR]: {
    message: '注文情報の取得に失敗しました。',
    type: 'error',
    code: '7002',
    description: '特定の注文の詳細情報の取得に失敗した場合に表示されます。',
    approach: 'サーバ側の不具合の可能性があります。',
  },

  // お顔検索関連

  [codes.ENQUE_OKAO_JOB_ERROR]: {
    message: 'お顔検索を実行できませんでした。',
    type: 'info',
    code: '8001',
    description: 'お顔検索ジョブの登録に失敗した場合に表示されます。',
    approach:
      'サーバ側の不具合または、販売が終了している、お子様情報に不備がある可能性があります。',
  },

  [codes.POLL_OKAO_JOB_ERROR]: {
    message: 'お顔検索を実行中にエラーが発生しました。',
    type: 'warning',
    code: '8002',
    description: 'お顔検索実行中にエラーが発生した場合に表示されます。',
    approach: 'サーバ側の不具合の可能性があります。',
  },

  [codes.GET_OKAO_PHOTOS_ERROR]: {
    message: 'お顔検索を実行中にエラーが発生しました。',
    type: 'warning',
    code: '8003',
    description: 'お顔検索終了後、検索結果の取得処理でエラーが発生した場合に表示されます。',
    approach: 'サーバ側の不具合の可能性があります。',
  },

  [codes.FACE_IMAGE_NOT_SET_ERROR]: {
    message: 'お顔検索用の画像が登録されていません。',
    type: 'warning',
    code: '8004',
  },

  [codes.SEARCH_FACES_ERROR]: {
    message: 'お顔検索に失敗しました。',
    type: 'warning',
    code: '8005',
  },

  // その他
  [codes.BROWSER_NOT_SUPPORT_ERROR]: {
    message:
      'サポートされていないブラウザです。お手数ですが別のパソコンやブラウザでお試しください。',
    type: 'error',
    code: '9001',
    description:
      '「ローカルストレージ」という機能が利用できないブラウザでこのエラーが表示されます。',
    approach: '最新のブラウザ、または別のブラウザを利用する必要があります。',
  },

  [codes.SAFARI_PRIVATE_BROWSE_ERROR]: {
    message:
      'ブラウザの「プライベートブラウズ」機能がオンになっている可能性があります。お手数ですがオフに設定の上、もう一度お試しください。',
    type: 'error',
    code: '9002',
    description:
      'iPhone、iPad,MacのSafariをご利用で、かつ「プライベートブラウズ」機能を有効にしている際に表示されます。',
    approach: 'プライベートブラウズ機能をオフにする必要があります。',
  },

  // デフォルト値
  default: {
    title: 'エラー',
    message: '予期せぬエラーが発生しました。',
    type: 'warning',
    code: '9999',
    description: '予期せぬ何らかなのエラーが発生した際に表示されます。',
    approach: 'エラーの原因が特定できません。',
  },
}
