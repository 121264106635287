// お子様の情報の修正と追加

<script>
import parseSwaggerSchemaError from '@/helpers/parse-swagger-schema-error'

import addChildApiAsync from '@/api/user/add-child'
import updateChildApiAsync from '@/api/user/update-child'

import * as errorCodes from '@/errors/codes'

export default {
  name: 'ChildInfo',

  props: {
    value: {},
    // idがあれば更新、なければ新規
    id: [String, Number],
  },

  data() {
    return {
      newValue: this.value ? _.cloneDeep(this.value) : null,
      errors: {},
    }
  },

  computed: {
    // 最低限入力項目が埋まっており、送信できる状態にあるか
    isValid() {
      const keys = ['name1', 'name2', 'name1Kana', 'name2Kana', 'relation', 'attribute']
      return _.size(_.pick(this.newValue, keys)) === keys.length
    },
  },

  methods: {
    // 新規追加
    async addAsync() {
      this.errors = {}
      const response = await addChildApiAsync(null, this.newValue)
      if (!response.ok) this._onError(response)
      else this.$psdialog.close()
    },

    // 更新
    async updateAsync() {
      this.errors = {}
      const params = { id: this.id }
      const response = await updateChildApiAsync(params, this.newValue)
      if (!response.ok) this._onError(response)
      else this.$psdialog.close()
    },

    _onError(response) {
      if (_.get(response, 'payload.code') === 'SCHEMA_VALIDATION_FAILED') {
        this.errors = parseSwaggerSchemaError(response.payload)
        alert('入力に誤りがあります。')
        console.log(this.errors)
      } else {
        // 予期せぬエラー。通常はここに来ないはず。
        throw new Error(errorCodes.UNKNOWN_ERROR)
      }
    },
  },
}
</script>

<template lang="pug">
.child-info

  .form
    ps-child-form(v-model='newValue', :errors='errors')

  .buttons
    ps-button(@click='$psdialog.close') 戻る
    ps-button(v-if='id', color='green', large-width, :disabled='!isValid', @click='updateAsync') 更新
    ps-button(v-else, color='green', large-width, :disabled='!isValid', @click='addAsync') 追加

</template>

<style lang="sass" scoped>
@import '../../../sass/variables.sass'
@import '../../../sass/mixins.sass'

.child-info
  .form
    margin-bottom: 1rem
  .buttons
    text-align: center
    > :first-child
      margin-right: 1rem
</style>
