// 郵便番号から住所への変換

import apiFactory from '../api-factory'

export default apiFactory({
  path: 'https://madefor.github.io/postal-code-api/api/v1/<%= code1 %>/<%= code2 %>.json',
  method: 'GET',
  expectedParams: ['code1', 'code2'],
  responseMapper: {
    'data[0].ja.prefecture': 'prefecture',
    'data[0].ja.address1': 'address1',
    'data[0].ja': {
      key: 'address2',
      transform: value => {
        return value.address2 + value.address3 + value.address4
      },
    },
  },
})
